import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { getOrders } from "../../utils/api";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../../components/CustomToastContent";
import SuccessIcon from "../../images/success_icon.svg";
import { useVisibility } from "../../context/VisibilityContext";
import { useTheme } from "../../context/ThemeContext";
import { BROKER_NAME } from "../../utils/constants";

const OrdersMobile = () => {
  const { theme } = useTheme();
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState("all");
  const access_token = localStorage.getItem("access_token");
  const broker = localStorage.getItem("broker");
  const client_id = localStorage.getItem("client_id");
  const { areButtonsVisible, setButtonsVisibility } = useVisibility();
  const shadowContainerRef = useRef(null);
  // const [symbol, setSymbol] = useState("");
  // const [qty, setQty] = useState(0);
  // this state is added to keep the track of checkbox
  const [checkboxEnabled, setCheckboxEnabled] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await getOrders(access_token, client_id, broker);
        console.log("My orders are:", response);
        if (response) {
          setOrders(response);
        } else {
          setOrders([]);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        shadowContainerRef.current &&
        !shadowContainerRef.current.contains(event.target)
      ) {
        setButtonsVisibility(false);
        setSelectedOrder(null);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [setButtonsVisibility]);
  const statusValue = (status) => {
    switch (status) {
      case 1:
        return "Canceled";
      case 2:
        return "Traded / Filled";
      case 3:
        return "Not used currently";
      case 4:
        return "Transit";
      case 5:
        return "Rejected";
      case 6:
        return "Pending";
      case 7:
        return "Expired";
      default:
        return "";
    }
  };

  //changing date format. In received object it is different than what is shown in design
  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const time = dateTime.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
    const date = dateTime.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    return `${time}, ${date}`;
  };

  const filteredOrders = orders.filter((order) => {
    if (filter === "executed" && order.status !== "Executed") {
      return false; // Skip non-executed orders
    }
    if (filter === "pending" && order.status !== "Pending") {
      return false; // Skip non-pending orders
    }
    if (filter === "rejected" && order.status !== "Rejected") {
      return false; // Skip non-rejected orders
    }
    return true;
  });

  const isActiveButton = (buttonName) => {
    return filter === buttonName ? "active" : "";
  };

  // const hasPendingOrders = filteredOrders.some((order) => order.status === 6)/
  const [showPosCheckButtons, setShowPosCheckButtons] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  useEffect(() => {
    const hasPendingOrders = filteredOrders.some(
      (order) => order.status === "Pending"
    );
    setCheckboxEnabled(hasPendingOrders);
  }, [filteredOrders]);

  const handleCancelSelected = async (orderId) => {
    try {
      const response = await axios.post(
        "https://9pmtao5xc1.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_cancelorders",
        {
          broker: broker,
          access_token: access_token,
          client_id: client_id,
          order_list: [{ id: orderId }],
        }
      );

      if (response.data.status) {
        // If the response status is true, fetch the updated orders
        const updatedOrders = await getOrders(access_token, client_id, broker);
        setOrders(updatedOrders);
      } else {
        console.log("Failed to cancel order:", response.data.message);
      }
    } catch (error) {
      console.error("Error cancelling order:", error);
    }
  };

  const handleOKClick = async () => {
    try {
      await handleCancelSelected(); // Wait for the cancellation process to complete
      const overlay = document.querySelector(".overlay");
      overlay.classList.remove("active");
      toast.success(
        <CustomToastContent
          heading="Order cancelled successfully!"
          message="Successfully cancelled all selected orders!"
        />,
        {
          className: "custom-success-toast",
          icon: <img src={SuccessIcon} alt="Success Icon" />,
        }
      );
    } catch (error) {
      console.error("Error handling cancel operation:", error);
    }
    console.log("Orders after cancellig", orders);
  };

  const handleCloseClick = () => {
    //console.log("Clicked closed!");
    const overlay = document.querySelector(".overlay");
    overlay.classList.remove("active"); // Remove the "active" class to hide the overlay
    toast.dismiss();
  };

  const showToast = () => {
    const overlay = document.querySelector(".overlay");
    overlay.classList.add("active");

    toast(
      <div className="d-flex align-items-center justify-content-between">
        <div>Sure, you want to delete this order?</div>
        <div className="d-flex justify-content-end align-items-center gap-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              onClick={handleCloseClick}>
              <path
                d="M1 1.00002L13.7742 13.7742M1 13.7742L13.7742 1"
                stroke="#FF3908"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div
            className="confirm-popup-ok d-flex justify-content-center align-items-center"
            onClick={handleOKClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="14"
              viewBox="0 0 19 14"
              fill="none">
              <path
                d="M1 6.65685L6.65685 12.3137L17.9706 1"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
      </div>,
      {
        position: "bottom-center",
        closeButton: false, // Hide the built-in close button
        icon: false,
        className: "confirm-popup-toast", // Apply the custom CSS class
        hideProgressBar: true,
        draggable: true,
        autoClose: false,
      }
    );
  };
  return (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <div className="overlay"></div>
      <div
        className={`${BROKER_NAME === "gmail" ? "" : "mt-3"} orders-page d-flex justify-content-between `}>
        <div className="btn-group-1 d-flex">
          <button
            className={`btn-1 all ${isActiveButton("all")}`}
            onClick={() => setFilter("all")}>
            All
          </button>
          <button
            className={`btn-1 executed ${isActiveButton("executed")}`}
            onClick={() => setFilter("executed")}>
            Executed
          </button>
          <button
            className={`btn-1 pending ${isActiveButton("pending")}`}
            onClick={() => setFilter("pending")}>
            Pending
          </button>
          <button
            className={`btn-1 rejected ${isActiveButton("rejected")}`}
            onClick={() => setFilter("rejected")}>
            Rejected
          </button>
        </div>
      </div>
      {filteredOrders.length === 0 ? (
        <h2 className="mt-5 fw-bolder text-center">
          You don't have any {filter !== "all" ? filter : ""} orders!
        </h2>
      ) : (
        <div className="order-mobile-container my-3">
          {filteredOrders.map((order, index) => (
            <div
              key={order.id}
              className={`single-order-container d-flex justify-content-between mb-3 ${
                selectedOrder === order ? "selected-order" : ""
              } ${index === filteredOrders.length - 1 ? "last-order-margin" : ""}`}
              onClick={(event) => {
                event.stopPropagation();
                if (areButtonsVisible && selectedOrder === order) {
                  setButtonsVisibility(false);
                  setSelectedOrder(null);
                } else {
                  setButtonsVisibility(true);
                  setSelectedOrder(order);
                }
              }}>
              <div className="order-info-div d-flex flex-column">
                <div className="d-flex gap-3">
                  <div
                    className={
                      order.side === -1 ? "text-danger" : "text-success"
                    }
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                    }}>
                    {order.side === -1 ? "SELL" : "BUY"}
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                    }}>
                    Qty: {`${order.filledQty}/${order.qty}`}
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                  }}>
                  {order.symbol}
                </div>
                <div
                  className="d-flex gap-3"
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                  }}>
                  <div>{order.orderType}</div>
                  <div>{order.productType}</div>
                </div>
              </div>
              <div className="order-status d-flex flex-column ">
                <div
                  className="mobile-order-status"
                  style={{
                    color:
                      order.status === "Executed"
                        ? "#5CA81D"
                        : order.status === "Pending"
                          ? "#black"
                          : order.status === "Rejected"
                            ? "#DD6565"
                            : "black",
                  }}>
                  {order.status}
                </div>

                <div className="ps-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="16"
                    viewBox="0 0 9 16"
                    fill="none"
                    className="mt-1 ms-4">
                    <path
                      d="M1.36267 1L7.8539 7.96317L1.36267 14.9263"
                      stroke={theme === "dark" ? "white" : "#14142B"}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              {areButtonsVisible && selectedOrder === order && (
                <div className="shadow-container" ref={shadowContainerRef}>
                  {order.message ? (
                    <div className="order-message my-1 px-3">
                      <div className="order-message-heading mb-1">Message</div>
                      <div className="order-message-body px-1">
                        {order.message}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="pos-check-buttons d-flex justify-content-between px-3">
                    {/* <button className="pos-button one-click">
                      Repeat order
                    </button> */}
                    <button
                      className={`pos-button exit-pos ${order.status !== "Pending" ? "disabled-cancel-button" : ""}`}
                      onClick={showToast}>
                      Cancel order
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default OrdersMobile;
