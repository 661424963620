import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const MARKET_DATA_URL =
  "https://sktkufqk95.execute-api.ap-south-1.amazonaws.com/default/og_marketdata";

const GmailWebSocketContext = createContext(null);

const GmailWebSocketProvider = ({ children }) => {
  const location = useLocation();
  const [symbolList, setSymbolList] = useState([]);
  const [symbolData, setSymbolData] = useState({});

  const updateSymbolList = (symbols, action) => {
    let updatedlist = {};
    if (action === "add") {
      updatedlist = symbolList.concat(symbols.map(String));
    } else {
      updatedlist = symbolList.filter(function (el) {
        return symbols.indexOf(el) < 0;
      });
    }
    setSymbolList(updatedlist);
  };

  const subscribeToSymbols = (symbols, exchange) => {
    let uniqueKeys = symbols.map(String).filter((symbol) => !symbolList.includes(symbol));
    let subscribe_list = exchange + "|";
    uniqueKeys.forEach((code) => {
      subscribe_list += code + "#" + exchange + "|";
    });
    subscribe_list = subscribe_list.slice(0, -1);
    updateSymbolList(uniqueKeys, "add");
    if (uniqueKeys.length !== 0) {
      const message = JSON.stringify({
        k: subscribe_list,
        t: "t",
      });
      // conn.current.send(message);
      fetchData(message);
    }

    return true;
  };

  const checkWebsocketConnection = () => {
    return true;
    // else return true;
  };

  async function fetchData(message) {
    try {
      const response = await axios.post(MARKET_DATA_URL, {
        message
      });
      let list_data = response.data;
      for (let i=0;i<list_data.length;i++){
        parseData(list_data[i]);
      }
    } catch (error) {
      console.error(
        "There was a problem with the Axios request:",
        error.message
      );
    }
  }

  const parseData = ((decodedMsg)=> {
    let ltp_value = decodedMsg.hasOwnProperty("lp")
      ? parseFloat(decodedMsg?.lp).toFixed(2)
      : symbolData[decodedMsg.tk]?.ltp;
    let open_value = decodedMsg.hasOwnProperty("o")
      ? parseFloat(decodedMsg?.o).toFixed(2)
      : symbolData[decodedMsg.tk]?.open;
    let high_value = decodedMsg.hasOwnProperty("h")
      ? parseFloat(decodedMsg?.h).toFixed(2)
      : symbolData[decodedMsg.tk]?.high;
    let low_value = decodedMsg.hasOwnProperty("l")
      ? parseFloat(decodedMsg?.l).toFixed(2)
      : symbolData[decodedMsg.tk]?.low;
    let change_value = decodedMsg.hasOwnProperty("cv")
      ? parseFloat(decodedMsg?.cv).toFixed(2)
      : symbolData[decodedMsg.tk]?.change;
    let changeper_value = decodedMsg.hasOwnProperty("pc")
      ? parseFloat(decodedMsg?.pc).toFixed(2)
      : symbolData[decodedMsg.tk]?.change_per;
    let oi_value = decodedMsg.hasOwnProperty("oi")
      ? parseFloat(decodedMsg?.oi).toFixed(2)
      : symbolData[decodedMsg.tk]?.oi;
    let prev_close = decodedMsg.hasOwnProperty("c")
      ? parseFloat(decodedMsg?.c).toFixed(2)
      : symbolData[decodedMsg.tk]?.prev_close;
    let lot_size = decodedMsg.hasOwnProperty("ls")
      ? parseFloat(decodedMsg?.ls).toFixed(2)
      : symbolData[decodedMsg.tk]?.lot_size;
    let volume = decodedMsg.hasOwnProperty("v")
      ? parseFloat(decodedMsg?.v).toFixed(2)
      : symbolData[decodedMsg.tk]?.volume;
    let required_format = {
      [decodedMsg.tk]: {
        ltp: ltp_value,
        change: (ltp_value - prev_close).toFixed(2),
        change_per: changeper_value,
        open: open_value,
        high: high_value,
        low: low_value,
        oi: oi_value,
        prev_close: prev_close,
        lot_size: lot_size,
        volume: volume,
      },
    };
    setSymbolData((prevData) => ({
      ...prevData,
      [Object.keys(required_format)]:
        required_format[Object.keys(required_format)],
    }));
  })

  return (
    <GmailWebSocketContext.Provider
      value={{
        checkWebsocketConnection,
        updateSymbolList,
        symbolData,
        symbolList,
        subscribeToSymbols
      }}>
      {children}
    </GmailWebSocketContext.Provider>
  );
};

export { GmailWebSocketProvider, GmailWebSocketContext as default };
