import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useTheme } from "../context/ThemeContext";
import { useFundsData } from "../context/FundsDataContext";
import axios from "axios";
import { useBasketContext } from "../context/BasketContext";
import Draggable from "react-draggable";
import { useBuySellModalContext } from "../context/BuySellModalContext";
import CustomToastContent from "../components/CustomToastContent";
import SuccessIcon from "../images/success_icon.svg";
import ErrorIcon from "../images/error_icon.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ButtonContext } from "../context/ButtonsContext";
import { Tooltip as ReactTooltip } from "react-tooltip";
import QuestionMark from "../images/QuestionMark";
import { useStatus } from "../context/StatusProvider";
import { BROKER_NAME } from "../utils/constants";
import Modal from "../modals/CommonModal";

const BuySellModal = () => {
  const { theme } = useTheme();
  const { isLive, isVirtual } = useContext(ButtonContext);

  const {
    buySellModalData,
    setBuySellModalData,
    addToBasket,
    toggleBuySellStatus,
    handleIncrement,
    handleDecrement,
    clearBuySellModalData,
    handlePriceChange,
    handleTriggerPriceChange,
  } = useBuySellModalContext();

  const broker = localStorage.getItem("broker");
  const access_token = localStorage.getItem("access_token");
  const client_id = localStorage.getItem("client_id");
  const [activeBuySellOption, setActiveBuySellOption] = useState("buy");
  const [activeIntradayNormalOption, setActiveIntradayNormalOption] =
    useState("");
  const [marginRequired, setMarginRequired] = useState(null);
  const [charges, setCharges] = useState(null);
  const { fundsData } = useFundsData();
  const [lots, setLots] = useState(1);
  const [price, setPrice] = useState("");
  const [trigger_price, setTriggerPrice] = useState("");
  const [lot_size, setLot_size] = useState(1);
  const [orderType, setOrderType] = useState("marketorder");
  const navigate = useNavigate();
  const [activeOptions, setActiveOptions] = useState([]);
  const [qtyButtonText, setQtyButtonText] = useState(
    `Sell ${lots * lot_size} Qty`
  );
  const [openModal, setOpenModal] = useState(false);

  const { status, setStatus } = useStatus();

  useEffect(() => {
    if (buySellModalData.length > 0) {
      const currData = buySellModalData[0];

      // Set Normal or Intraday option
      setActiveIntradayNormalOption(
        currData.productType === "Normal" ? "normal" : "intraday"
      );

      // Handle Market, Limit, and Trigger orders
      if (currData.orderType === "MARKET") {
        setOrderType("marketorder");
      } else if (currData.orderType === "LIMIT") {
        setOrderType("limitorder");
      } else if (
        currData.orderType === "TRIGGER" ||
        currData.orderType === "STOPLOSS"
      ) {
        setOrderType("triggerorder");
        if (currData.triggerPrice) {
          setTriggerPrice(currData.triggerPrice); // Set the trigger price for trigger/stoploss orders
        }
      }
    }
  }, [buySellModalData]);

  const handleBuySellToggle = (option) => {
    if (buySellModalData.length === 0) return;
    const updatedModalData = [
      {
        ...buySellModalData[0],
        buy_sell: option === "buy" ? "B" : "S",
      },
      ...buySellModalData.slice(1),
    ];

    setBuySellModalData(updatedModalData);
  };

  const handleIntradayNormalToggle = (option) => {
    if (buySellModalData.length === 0) return;
    const updatedModalData = [
      {
        ...buySellModalData[0],
        productType: option === "normal" ? "Normal" : "Intraday",
      },
      ...buySellModalData.slice(1),
    ];

    setActiveIntradayNormalOption(option);
    setBuySellModalData(updatedModalData);
  };

  const handleLotSizeChange = (newValue) => {
    const newLotsSize = parseInt(newValue);

    const updatedData = [...buySellModalData];
    const dataObject = updatedData[0];
    dataObject.lotSize = newLotsSize;
    setBuySellModalData(updatedData);
    setLots(newLotsSize);
  };

  //to update the qty in button
  useEffect(() => {
    setQtyButtonText(
      `${activeBuySellOption.charAt(0).toUpperCase() + activeBuySellOption.slice(1)} ${lots * lot_size} Qty`
    );
  }, [lots, activeBuySellOption]);

  const isActiveButton = (buttonName) => {
    return (orderType === buttonName &&
      buttonName !== "marketorder" &&
      buttonName !== "limitorder" &&
      buttonName !== "triggerorder") ||
      (orderType === buttonName &&
        buttonName !== "intraday" &&
        buttonName !== "normal")
      ? "active"
      : "";
  };

  function formatNumberWithCommasAndFontWeight(value) {
    const formattedValue = parseFloat(value).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue.split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1];

    return (
      <span>
        {integerPart}.<span style={{ fontWeight: 400 }}>{decimalPart}</span>
      </span>
    );
  }

  const [isPriceEnabled, setIsPriceEnabled] = useState(false);
  const [isTriggerPriceEnabled, setIsTriggerPriceEnabled] = useState(false);

  const handleOrderTypeChange = (type) => {
    setOrderType(type);

    if (type === "marketorder") {
      setIsPriceEnabled(false); // Disable price input
      setIsTriggerPriceEnabled(false); // Disable trigger price input
    } else if (type === "limitorder") {
      setIsPriceEnabled(true); // Enable price input
      setIsTriggerPriceEnabled(false); // Disable trigger price input
    } else if (type === "triggerorder") {
      setIsPriceEnabled(true); // Enable price input
      setIsTriggerPriceEnabled(true); // Enable trigger price input
    }

    if (type === "limitorder") {
      buySellModalData[0].orderType = "LIMIT";
    } else if (type === "triggerorder") {
      buySellModalData[0].orderType = "TRIGGER";
    } else {
      buySellModalData[0].orderType = "MARKET";
    }

    console.log(buySellModalData);
  };

  const handleToastToNavigate = (response) => {
    navigate(response.page);
  };

  const handleExecuteOrder = async () => {
    try {
      // Clear the basketData array by removing all items
      clearBuySellModalData();
      const payload = generateOrderPayload();
      const response = await sendOrder(payload);
      console.log("response", response);
      if (response.status) {
        toast.success(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-success-toast",
            icon: <img src={SuccessIcon} alt="Success Icon" />,
            onClick: () => handleToastToNavigate(response),
          }
        );
      } else {
        toast.error(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-error-toast",
            icon: <img src={ErrorIcon} alt="Error Icon" />,
          }
        );
      }
    } catch (error) {
      console.error("Error executing order:", error);
      toast.error(
        <CustomToastContent
          heading="Error executing order"
          message={error.message}
        />,
        {
          className: "custom-error-toast",
          icon: <img src={ErrorIcon} alt="Error Icon" />,
        }
      );
    }
  };

  const handleExecuteVirtualOrder = async () => {
    try {
      clearBuySellModalData();

      const payload = generateVirtualOrderPayload();
      console.log("payload", payload);
      const response = await sendVirtualOrder(payload);
      if (response.status) {
        toast.success(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-success-toast",
            icon: <img src={SuccessIcon} alt="Success Icon" />,
            onClick: () => handleToastToNavigate(response),
          }
        );
      } else {
        toast.error(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-error-toast",
            icon: <img src={ErrorIcon} alt="Error Icon" />,
          }
        );
      }
    } catch (error) {
      console.error("Error executing virtual order:", error);
      toast.error(
        <CustomToastContent
          heading="Error executing virtual order"
          message={error.message}
        />,
        {
          className: "custom-error-toast",
          icon: <img src={ErrorIcon} alt="Error Icon" />,
        }
      );
    }
  };

  const handleModifyOrder = async () => {
    try {
      const payload = generateModifyOrderPayload();
      const response = await sendModifyOrder(payload);
      if (response.status) {
        setStatus(response.status);
        toast.success(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-success-toast",
            icon: <img src={SuccessIcon} alt="Success Icon" />,
            onClick: () => handleToastToNavigate(response),
          }
        );
      } else {
        toast.error(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-error-toast",
            icon: <img src={ErrorIcon} alt="Error Icon" />,
          }
        );
      }
    } catch (error) {
      console.error("Error executing virtual order:", error);
      toast.error(
        <CustomToastContent
          heading="Error executing virtual order"
          message={error.message}
        />,
        {
          className: "custom-error-toast",
          icon: <img src={ErrorIcon} alt="Error Icon" />,
        }
      );
    }
  };

  const generateOrderPayload = () => {
    const orderList = buySellModalData.map((currData, ind) => ({
      isBuy: currData.buy_sell === "B" ? true : false,
      isCall: currData.side === "Call" ? true : false,
      isFut: false,
      code: currData.code,
      exchange: currData.exchange,
      productType: activeIntradayNormalOption === "normal" ? "NRML" : "MIS",
      orderType: getOrderType(),
      quantity: currData.lotSize * currData.lot_size,
      price: currData.price,
      trigger_price: currData.trigger_price,
      lot_size: currData.lot_size,
      freeze_qty: currData.freeze_qty,
      strike: currData.strike,
      expiry: currData.expiryDate,
      tradingSymbol: currData.tradingSymbol,
      lot_multiplier: currData.lot_multiplier,
      scrip: currData.scrip,
      expiry: currData.expiryDate,
    }));
    return {
      order_list: orderList,
      broker: broker,
      access_token: access_token,
      client_id: client_id,
    };
  };

  const generateModifyOrderPayload = () => {
    const orderList = buySellModalData.map((currData, ind) => ({
      order_id: currData.orderId,
      isBuy: currData.buy_sell === "B" ? true : false,
      isCall: currData.side === "Call" ? true : false,
      isFut: false,
      code: currData.code,
      exchange: currData.exchange,
      productType: activeIntradayNormalOption === "normal" ? "NRML" : "MIS",
      orderType: currData.orderType,
      quantity: currData.lotSize * currData.lot_size,
      price: currData.price,
      trigger_price: currData.trigger_price,
      lot_size: currData.lot_size,
      freeze_qty: currData.freeze_qty,
      strike: currData.strike,
      expiry: currData.expiryDate,
      tradingSymbol: currData.tradingSymbol,
      lot_multiplier: currData.lot_multiplier,
      scrip: currData.scrip,
      expiry: currData.expiryDate,
    }));
    return {
      order_list: orderList,
      broker: broker,
      main_broker: broker,
      access_token: access_token,
      client_id: client_id,
    };
  };

  const sendModifyOrder = async (payload) => {
    try {
      const response = await axios.post(
        "https://s2ox3qi5zk.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_modify_order",
        payload
      );
      return response.data;
    } catch (error) {
      console.log("Error modifying order:", error);
      return {
        status: false,
        message_header: "Error modifying order",
        message_body: error.message,
      };
    }
  };

  const generateVirtualOrderPayload = () => {
    const orderList = buySellModalData.map((currData, ind) => ({
      isBuy: currData.buy_sell === "B" ? true : false,
      isCall: currData.side === "Call" ? true : false,
      isFut: false,
      code: currData.code,
      exchange: currData.exchange,
      productType: activeIntradayNormalOption === "normal" ? "NRML" : "MIS",
      orderType: getOrderType(),
      quantity: currData.lotSize * currData.lot_size,
      price: currData.price,
      trigger_price: currData.trigger_price,
      lot_size: currData.lot_size,
      freeze_qty: currData.freeze_qty,
      strike: currData.strike,
      expiry: currData.expiry,
      tradingSymbol: currData.tradingSymbol,
      lot_multiplier: currData.lot_multiplier,
      scrip: currData.scrip,
      expiry: currData.expiryDate,
    }));
    return {
      order_list: orderList,
      broker: "virtual",
      main_broker: broker,
      access_token: access_token,
      client_id: client_id,
    };
  };

  const sendVirtualOrder = async (payload) => {
    try {
      const response = await axios.post(
        "https://hgb1gw5n6g.execute-api.ap-south-1.amazonaws.com/default/og_virtual_placeorder",
        payload
      );
      return response.data;
    } catch (error) {
      console.error("Error placing order:", error);
      return {
        status: false,
        message_header: "Error placing order",
        message_body: error.message,
      };
    }
  };

  const handleBuySellCloseClick = () => {
    clearBuySellModalData();
  };

  const sendOrder = async (payload) => {
    try {
      const response = await axios.post(
        "https://0eo1dv23p5.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_placeorder",
        payload
      );
      return response.data;
    } catch (error) {
      console.error("Error placing order:", error);
      return {
        status: false,
        message_header: "Error placing order",
        message_body: error.message,
      };
    }
  };

  useEffect(() => {
    const generateMarginPayload = () => {
      const orderList = buySellModalData.map((currData, ind) => ({
        isBuy: currData.buy_sell === "B" ? true : false,
        isCall: currData.side === "Call" ? true : false,
        isFut: false,
        code: currData.code,
        exchange: currData.exchange,
        productType: activeIntradayNormalOption === "normal" ? "NRML" : "MIS",

        orderType:
          currData.orderType === "MARKET"
            ? "MARKET"
            : currData.orderType === "LIMIT"
              ? "LIMIT"
              : currData.orderType === "TRIGGER"
                ? "TRIGGER"
                : "LIMIT",
        quantity: currData.lotSize * currData.lot_multiplier,
        price: currData.price,
        trigger_price: currData.trigger_price,
        lot_size: currData.lot_size,
        strike: currData.strike,
        expiry: currData.expiryDate,
        tradingSymbol: currData.tradingSymbol,
        lot_multiplier: currData.lot_multiplier,
        scrip: currData.scrip,
      }));
      return {
        order_list: orderList,
        broker: broker,
        access_token: access_token,
        client_id: client_id,
      };
    };

    const fetchMarginRequired = async () => {
      try {
        const payload = generateMarginPayload();

        const response = await axios.post(
          "https://7mwh6csmrh.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_margin",
          payload
        );

        setMarginRequired(response.data.marginRequired);
      } catch (error) {
        console.error("Error getting margin required:", error);
      }
    };

    const fetchCharges = async () => {
      try {
        const payload = await generateMarginPayload();
        const response = await axios.post(
          "https://pc933vy3c6.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_charges",
          payload
        );
        setCharges(response.data.total_charges);
      } catch (error) {
        console.error("Error getting charges required:", error);
      }
    };

    if (buySellModalData.length > 0) {
      fetchMarginRequired();
      fetchCharges();
    }
  }, [buySellModalData]);

  useEffect(() => {
    setLots(buySellModalData[0]?.lotSize);
    setPrice(buySellModalData[0]?.price);
    setTriggerPrice(buySellModalData[0]?.trigger_price);
    setLot_size(buySellModalData[0]?.lot_size);
    if (buySellModalData[0]?.buy_sell === "S") {
      setActiveBuySellOption("sell");
    } else if (buySellModalData[0]?.buy_sell === "B") {
      setActiveBuySellOption("buy");
    }
    if (buySellModalData[0]?.productType === "intraday") {
      setActiveIntradayNormalOption("MIS");
    } else if (buySellModalData[0]?.productType === "normal") {
      setActiveIntradayNormalOption("NRML");
    }
  }, [buySellModalData, price, trigger_price, lots, lot_size]);

  const handleQtyBtnClick = () => {
    if (isLive) {
      handleExecuteOrder();
    } else if (isVirtual) {
      handleExecuteVirtualOrder();
    }
  };
  // console.log("orderType", orderType);
  const getOrderType = () => {
    switch (orderType) {
      case "marketorder":
        return "MARKET";
      case "limitorder":
        return "LIMIT";
      case "triggerorder":
        return "TRIGGER";
      default:
        return "MARKET";
    }
  };

  // console.log("lot_size", lot_size);
  return (
    <div
      className="buy-sell-modal modal fade"
      id="staticBackdropBuySell"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div className="modal-dialog">
        <Draggable>
          <div className="modal-content">
            <div className="modal-header d-flex flex-column gap-2 border-bottom">
              <div className="d-flex justify-content-between align-items-center w-100 px-2">
                <p className="modal-title " id="staticBackdropLabel">
                  {buySellModalData[0]?.instrumentName}
                </p>
                <div>
                  <button
                    type="button"
                    className={
                      theme === "dark"
                        ? "btn-close-white btn-close"
                        : "btn-close"
                    }
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => handleBuySellCloseClick()}></button>
                </div>
              </div>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ gap: "360px" }}>
                <div className="toggle-containerr" style={{ height: "35px" }}>
                  <button
                    className={`toggle-buttonn buy ${
                      activeBuySellOption === "buy" ? "active" : ""
                    }`}
                    onClick={() => handleBuySellToggle("buy")}>
                    Buy
                  </button>
                  <button
                    className={`toggle-buttonn sell ${
                      activeBuySellOption === "sell" ? "active" : ""
                    }`}
                    onClick={() => handleBuySellToggle("sell")}>
                    Sell
                  </button>
                </div>
                <div className="toggle-container">
                  <button
                    className={`toggle-button ${
                      activeIntradayNormalOption === "intraday" ? "active" : ""
                    }`}
                    onClick={() => handleIntradayNormalToggle("intraday")}>
                    Intraday
                  </button>
                  <button
                    className={`toggle-button ${
                      activeIntradayNormalOption === "normal" ? "active" : ""
                    }`}
                    onClick={() => handleIntradayNormalToggle("normal")}>
                    Normal
                  </button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4">
              <div className="btn-group-1 d-flex gap-2">
                <button
                  className={`btn-1 marketorder ${isActiveButton("marketorder")}`}
                  onClick={() => handleOrderTypeChange("marketorder")}>
                  Market order
                </button>
                <button
                  className={`btn-1 limitorder ${isActiveButton("limitorder")}`}
                  onClick={() => handleOrderTypeChange("limitorder")}>
                  Limit order
                </button>
                <button
                  className={`btn-1 triggerorder ${isActiveButton(
                    "triggerorder"
                  )}`}
                  onClick={() => handleOrderTypeChange("triggerorder")}>
                  Stop Loss
                </button>
              </div>
              <div className="d-flex gap-3 mt-4">
                <div className="input-container">
                  <label htmlFor="lots" className="input-label">
                    <span>Lots</span>
                  </label>
                  <div className="d-flex buy-sell-lot-input">
                    <input
                      type="number"
                      id="lots"
                      className="number-input text-end pe-2 border-0"
                      value={lots}
                      onChange={(e) => handleLotSizeChange(e.target.value)}
                    />
                    <div className="buy-sell-inc-dec-div">
                      <button
                        className="buy-sell-plus-minus buy-sell-plus"
                        onClick={handleIncrement}>
                        +
                      </button>
                      <button
                        className="buy-sell-plus-minus buy-sell-minus"
                        onClick={handleDecrement}>
                        -
                      </button>
                    </div>
                  </div>
                </div>
                <div className="input-container">
                  <label htmlFor="price" className="input-label">
                    <span>Price</span>
                  </label>
                  <input
                    type="number"
                    id="price"
                    className={`number-input text-end pe-2 ${
                      isPriceEnabled ? "" : "disabled number-input-disabled"
                    }`}
                    value={price}
                    onChange={(e) => handlePriceChange(e)}
                    placeholder="Limit"
                    disabled={!isPriceEnabled}
                  />
                </div>
                {isTriggerPriceEnabled && (
                  <div className="input-container">
                    <label
                      htmlFor="triggerPrice"
                      className="input-label active">
                      <span>Trigger Price</span>
                    </label>
                    <input
                      type="number"
                      id="triggerPrice"
                      className="number-input text-end pe-2 buy-sell-lot-input"
                      value={trigger_price}
                      onChange={(e) => handleTriggerPriceChange(e)}
                      placeholder="Trigger price"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="modal-footer justify-content-between ">
              <div className="creator-bottom d-flex justify-content-start align-items-center px-2 mb-2 gap-4">
                <div className="d-flex flex-column">
                  <span className="creator-bottom-title ">
                    Estmtd margin{" "}
                    <span data-tooltip-id="estmtd-margin">
                      <QuestionMark />
                    </span>
                  </span>
                  <span className="creator-bottom-value">
                    {formatNumberWithCommasAndFontWeight(marginRequired)}
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <span className="creator-bottom-title">
                    Charges{" "}
                    <span data-tooltip-id="charges">
                      <QuestionMark />
                    </span>
                  </span>
                  <span className="creator-bottom-value">
                    ₹ {formatNumberWithCommasAndFontWeight(charges)}
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <span className="creator-bottom-title">Margin available</span>
                  <span className="creator-bottom-value">
                    {" "}
                    ₹{" "}
                    {formatNumberWithCommasAndFontWeight(
                      fundsData.marginAvailable
                    )}
                  </span>
                </div>
              </div>
              <div className="d-flex gap-3">
                {buySellModalData[0]?.orderStatus !== "Pending"
                  ? BROKER_NAME === "gmail" && (
                      <button
                        className={`${!isVirtual ? "buy-sell-btn-disabled" : "buy-sell-qty-btn-virtual"}`}
                        onClick={
                          isVirtual ? handleExecuteVirtualOrder : undefined
                        }
                        disabled={!isVirtual}
                        data-bs-dismiss="modal">
                        Draft Trade
                      </button>
                    )
                  : BROKER_NAME === "gmail" && (
                      <button
                        className={`${!isVirtual ? "buy-sell-btn-disabled" : "buy-sell-qty-btn-virtual"}`}
                        onClick={
                          buySellModalData[0]?.orderStatus !== "Pending"
                            ? handleExecuteVirtualOrder
                            : handleModifyOrder
                        }
                        disabled={!isVirtual}
                        data-bs-dismiss="modal">
                        Modify Draft
                      </button>
                    )}

                {isVirtual && (
                  <>
                    <button
                      className="buy-sell-btn-disabled"
                      // data-bs-dismiss="modal"
                      onClick={() => setOpenModal(!openModal)}>
                      {qtyButtonText}
                    </button>
                  </>
                )}

                <button
                  className={` ${
                    activeBuySellOption === "sell" ? "sell-bg" : "buy-bg"
                  } ${isVirtual ? "d-none" : "buy-sell-qty-btn"}`}
                  onClick={
                    buySellModalData[0]?.orderStatus !== "Pending"
                      ? handleExecuteOrder
                      : handleModifyOrder
                  }
                  disabled={!isLive}
                  data-bs-dismiss="modal">
                  {buySellModalData[0]?.orderStatus === "Pending"
                    ? "Modify Order"
                    : qtyButtonText}
                </button>
              </div>
            </div>
          </div>
        </Draggable>
      </div>
      <Modal isOpen={openModal} onClose={() => setOpenModal(!openModal)}>
        <p>Enable Live Trades</p>
      </Modal>
      <ReactTooltip
        id="estmtd-margin"
        place="top"
        variant={theme === "dark" ? "light" : "dark"}
        content="Margin estimate to execute the trade"
      />
      <ReactTooltip
        id="charges"
        place="top"
        variant={theme === "dark" ? "light" : "dark"}
        content="Charges estimate that may incur on live trade"
      />
      <ReactTooltip
        id="premium"
        place="top"
        variant={theme === "dark" ? "light" : "dark"}
        content="Negative premium indicates premium paid and positive indicates premium received"
      />
    </div>
  );
};

export default BuySellModal;
