import React, { useEffect, useState, useContext } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useSpring, animated, useTransition } from "@react-spring/web";
import { RxCross2 } from "react-icons/rx";
import { ButtonContext } from "../context/ButtonsContext";
const CommonModal = ({ isOpen, onClose }) => {
  const [showOverlay, setShowOverlay] = useState(isOpen);
  const { isVirtual } = useContext(ButtonContext);
  useEffect(() => {
    if (isOpen) {
      setShowOverlay(true);
      const timer = setTimeout(() => {
        onClose();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const springs = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? `translateY(0%)` : `translateY(-100%)`,
    config: {
      duration: 300,
    },
  });

  const modalTransition = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 300,
    },
  });

  const handleEscape = (e) => {
    if (e.keyCode === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  return modalTransition(
    (styles, isOpen) =>
      isOpen && (
        <>
          {showOverlay && (
            <animated.div
              style={styles}
              className="react-modal-overlay"
              onClick={onClose}>
              <animated.div
                style={springs}
                className="react-modal-wrapper"
                onClick={(e) => e.stopPropagation()}>
                <div className="react-modal-content">
                  <div className="react-modal-children">
                    <AiOutlineExclamationCircle className="excla" />
                    <div className="text-children">
                      {isVirtual ? (
                        <p>
                          You are currently in{" "}
                          <span className="span-bold">Draft mode, </span>
                          <a
                            target="_blank"
                            href="https://aliceblueonline.com/open-account-fill-kyc-request-call-back?C=1lyoptions">
                            Click here
                          </a>{" "}
                          to open broking account with us to trade live.
                        </p>
                      ) : (
                        <p>
                          You are currently in{" "}
                          <span className="span-bold">Live mode</span>, please
                          enable draft mode under profile if you wish to trade
                          virtually
                        </p>
                      )}
                    </div>
                  </div>
                  <div
                    className="icon-cross-div cursor-pointer"
                    onClick={onClose}>
                    <RxCross2 className="icon-cross" />
                  </div>
                </div>
              </animated.div>
            </animated.div>
          )}
        </>
      )
  );
};

export default CommonModal;
