import React, { useEffect, useState } from 'react'
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import axios from 'axios';

const DiscoverDesktop = ({data}) => {
  return (
    <div style={{display:'flex',flexDirection:'row',gap:'26px',height: '900px',margin:'0 auto',justifyContent:'space-between'}}>
      <LeftPanel data={data}/>
      <RightPanel data={data}/>
    </div>
  )
}

export default DiscoverDesktop;
