import React from "react";
import { Helmet } from "react-helmet-async";
import VirtualPositionsMobile from "./VirtualPositionsMobile";
import VirtualPositionsDesktop from "./VirtualPositionsDesktop";

const VirtualPositions = () => {
  function isMobile() {
    return (window.screen.width > 430 ) ? false : true;
  }

  return isMobile() ? (
    <>
      <Helmet>
        <title>Positions</title>
      </Helmet>
      <div className="mobile-screen d-grid" style={{ gridColumn: "span 4" }}>
        <VirtualPositionsMobile />
      </div>
    </>
  ) : (
    <>
      <Helmet>
        <title>Positions</title>
      </Helmet>
      <div className="desktop-screenn">
        <VirtualPositionsDesktop />
      </div>
    </>
  );
};

export default VirtualPositions;
