import React, { useEffect, useState } from "react";
import { useTheme } from "../context/ThemeContext";
import { useOptionChainContext } from "../context/OptionChainContext";

const SettingsModal = ({
  showCalls,
  setShowCalls,
  showPuts,
  setShowPuts,
  showDelta,
  setShowDelta,
  showTheta,
  setShowTheta,
  showGamma,
  setShowGamma,
  showVega,
  setShowVega,
  showOIChng,
  setShowOIChng,
  showOIBuilddup,
  setShowOIBuilddup,
  isCheckboxChecked,
  handleCheckboxChange,
  initialSliderValue,
  onSliderChange,
  sliderLength,
}) => {
  const { theme } = useTheme();

  const [showCallsTemp, setShowCallsTemp] = useState(showCalls);
  const [showPutsTemp, setShowPutsTemp] = useState(showPuts);
  const [showDeltaTemp, setShowDeltaTemp] = useState(showDelta);
  const [showThetaTemp, setShowThetaTemp] = useState(showTheta);
  const [showGammaTemp, setShowGammaTemp] = useState(showGamma);
  const [showVegaTemp, setShowVegaTemp] = useState(showVega);
  const [showOIChngTemp, setShowOIChngTemp] = useState(showOIChng);
  const [showOIBuilddupTemp, setShowOIBuilddupTemp] = useState(showOIBuilddup);
  const [sliderValue, setSliderValue] = useState(initialSliderValue || 20);

  const handleApplyClick = () => {
    setShowCalls(showCallsTemp);
    setShowPuts(showPutsTemp);
    setShowDelta(showDeltaTemp);
    setShowTheta(showThetaTemp);
    setShowGamma(showGammaTemp);
    setShowVega(showVegaTemp);
    setShowOIChng(showOIChngTemp);
    setShowOIBuilddup(showOIBuilddupTemp);
    onSliderChange(sliderValue);
    //  to close the modal
    // const modalElement = document.getElementById("staticBackdropsettings");
    // const bootstrapModal = new window.bootstrap.Modal(modalElement);
    // bootstrapModal.hide();
    // const modal = document.getElementById("staticBackdropsettings");
    // modal.classList.remove("show");
  };
  const handleResetClick = () => {
    setShowCallsTemp(true);
    setShowPutsTemp(true);
    setShowDeltaTemp(false);
    setShowThetaTemp(false);
    setShowGammaTemp(false);
    setShowVegaTemp(false);
    setShowOIChngTemp(false);
    setShowOIBuilddupTemp(false);
    setSliderValue(initialSliderValue || 20);
  };
  // useEffect(() => {
  //   console.log("strike", optionData.options.length);
  // }, [optionData]);

  const handleSliderChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    //even value
    const adjustedValue = Math.max(
      2,
      Math.min(sliderLength, newValue % 2 === 0 ? newValue : newValue - 1)
    );
    setSliderValue(adjustedValue);
    const progress = (adjustedValue / sliderLength) * 100;
    event.target.style.background = `linear-gradient(to right, #383FEC ${progress}%, #F1F1F1 ${progress}%)`;
  };
  return (
    <div
      className="settings-modal modal fade"
      id="staticBackdropsettings"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="staticBackdropLabel">
              Settings
            </p>
            <button
              type="button"
              className={
                theme === "dark" ? "btn-close-white btn-close" : "btn-close"
              }
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>

          <div className="modal-body py-0">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column gap-3">
                {/* <div className="modal-tag-titles">LTP Range</div>
                <div className="d-flex gap-4">
                  <input
                    type="number"
                    placeholder="Minimum LPT"
                    className="ltp-range text-center"
                  />
                  <input
                    type="number"
                    placeholder="Maximum LPT"
                    className="ltp-range text-center"
                  />
                </div> */}
                <div className="modal-tag-titles">Show/hide Columns</div>
                <div className="d-flex">
                  <ul className="list-group list-unstyled me-3">
                    <li className="mb-2">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        checked={showDeltaTemp}
                        onChange={() =>
                          setShowDeltaTemp((prevValue) => !prevValue)
                        }
                      />
                      Delta
                    </li>
                    <li className="mb-2">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        checked={showGammaTemp}
                        onChange={() =>
                          setShowGammaTemp((prevValue) => !prevValue)
                        }
                      />
                      Gamma
                    </li>
                    {/* <li className="mb-2">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        checked={showOIChngTemp}
                        onChange={() =>
                          setShowOIChngTemp((prevValue) => !prevValue)
                        }
                      />
                      OI Chng
                    </li> */}
                  </ul>
                  <ul className="list-group list-unstyled">
                    <li className="mb-2">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        checked={showThetaTemp}
                        onChange={() =>
                          setShowThetaTemp((prevValue) => !prevValue)
                        }
                      />
                      Theta
                    </li>
                    <li className="mb-2">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        checked={showVegaTemp}
                        onChange={() =>
                          setShowVegaTemp((prevValue) => !prevValue)
                        }
                      />
                      Vega
                    </li>
                    {/* <li className="mb-2">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        checked={showOIBuilddupTemp}
                        onChange={() =>
                          setShowOIBuilddupTemp((prevValue) => !prevValue)
                        }
                      />
                      OI Builddup
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="d-flex flex-column gap-3">
                {/* <div className="d-flex align-items-center gap-4">
                  <div className="modal-tag-titles">LTP % Chng</div>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle bg-white text-black"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      Open
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="/">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/* <div className="d-flex align-items-center justify-content-between gap-4">
                  <div className="modal-tag-titles">Default lots</div>
                  <input
                    type="number"
                    className="default-dots text-end"
                    value="1"
                    min="1"
                    max="100"
                  />
                </div> */}
                <div className="d-flex align-items-center justify-content-between gap-4">
                  <div className="modal-tag-titles">Display</div>
                  <div className="d-flex gap-3 align-items-center">
                    <div className="">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                        checked={showCallsTemp}
                        onChange={() =>
                          setShowCallsTemp((prevValue) => !prevValue)
                        }
                      />
                      Call
                    </div>
                    <div className="">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        value=""
                        id="defaultCheck2"
                        checked={showPutsTemp}
                        onChange={() =>
                          setShowPutsTemp((prevValue) => !prevValue)
                        }
                      />
                      Put
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="scalp-section d-flex justify-content-start align-items-center mt-3">
              <input
                checked={isCheckboxChecked}
                onChange={handleCheckboxChange}
                type="checkbox"
                className="scalp-order-checkbox"
              />
              <svg
                className="ms-1"
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none">
                <g clipPath="url(#clip0_509_803)">
                  <path
                    d="M9.60272 7.3488C10.8408 6.29681 13.9006 4.89919 13.9006 4.89919C13.9006 4.89919 14.4877 8.2114 14.3248 9.8279C14.0615 12.4421 10.7132 15.2731 10.7132 15.2731L7.17158 13.4138C7.17158 13.4138 7.60044 9.05009 9.60272 7.3488Z"
                    fill="url(#paint0_linear_509_803)"
                  />
                  <path
                    d="M7.22105 14.7843C7.63788 14.3656 8.16292 13.9342 8.16292 13.9342L9.71691 14.7501C9.71691 14.7501 9.62195 15.4235 9.55201 16.0081C9.48206 16.5927 7.54791 16.9997 7.54791 16.9997C7.54791 16.9997 6.80421 15.203 7.22105 14.7843Z"
                    fill="url(#paint1_linear_509_803)"
                  />
                  <path
                    d="M10.7132 15.2731C10.7132 15.2731 14.0615 12.4421 14.3248 9.8279C14.4877 8.2114 13.9006 4.89919 13.9006 4.89919C13.9006 4.89919 10.8408 6.29681 9.60272 7.3488C7.60044 9.05009 7.17158 13.4138 7.17158 13.4138M10.7132 15.2731L7.17158 13.4138M10.7132 15.2731L13.6645 16.8226L13.4579 12.1964M7.17158 13.4138L4.22026 11.8643L8.14552 9.40736M9.6802 7.20124C9.6802 7.20124 10.2557 8.25634 11.7314 9.03106C13.207 9.80578 14.4023 9.68034 14.4023 9.68034M8.16292 13.9342C8.16292 13.9342 7.63788 14.3656 7.22105 14.7843C6.80421 15.203 7.54791 16.9997 7.54791 16.9997C7.54791 16.9997 9.48206 16.5927 9.55201 16.0081C9.62195 15.4235 9.71691 14.7501 9.71691 14.7501L8.16292 13.9342Z"
                    stroke="#14142B"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_509_803"
                    x1="13.9006"
                    y1="4.89919"
                    x2="7.54789"
                    y2="16.9996"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF3908" />
                    <stop offset="1" stopColor="#FF7834" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_509_803"
                    x1="13.9006"
                    y1="4.89919"
                    x2="7.54789"
                    y2="16.9996"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF3908" />
                    <stop offset="1" stopColor="#FF7834" />
                  </linearGradient>
                  <clipPath id="clip0_509_803">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(7.43726) rotate(27.6994)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <div className="me-2">Scalp Orders</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none">
                <path
                  d="M8 9.27276V8.7824C8 8.15044 8.48535 7.62879 9.02662 7.30258C9.54918 6.98765 10.0481 6.42786 9.90909 5.45454C9.63637 3.54544 7.04545 4.5 7.04545 4.5M8 11.1818V11.5M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
                  stroke="#14142B"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div> */}

            {/* Number of strikes slider */}
            <div className="strike-range-container mt-3">
              <div className="d-flex justify-content-between align-items-center mb-1">
                <div className="modal-tag-titles">No. of strikes</div>
                <div className="selected-slider-value d-flex justify-content-center align-items-center">
                  {sliderValue}
                </div>
              </div>
              <input
                type="range"
                min="2"
                max={sliderLength}
                value={sliderValue}
                onChange={handleSliderChange}
                className="w-100"
                id="customRange"
                style={{
                  background: `linear-gradient(to right, #383FEC ${(sliderValue / sliderLength) * 100}%, #F1F1F1 ${(sliderValue / sliderLength) * 100}%)`,
                }}
              />
              <div className="d-flex justify-content-between align-items-center strike-slider-min-max">
                <div>2</div>
                <div>{sliderLength}</div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="reset-btn"
              onClick={handleResetClick}>
              Reset
            </button>

            <button
              type="button"
              className="apply-btn"
              onClick={handleApplyClick}
              data-bs-dismiss="modal">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
