import React, { useEffect, useRef, useState } from "react";
import IframeResizer from "iframe-resizer-react";
import { useTheme } from "../context/ThemeContext";
const getScrollbarWidth = () => {
  
  const container = document.createElement("div");
  container.style.visibility = "hidden";
  container.style.overflow = "scroll";
  container.style.msOverflowStyle = "scrollbar"; // Needed for Windows 10
  document.body.appendChild(container);

  const inner = document.createElement("div");
  container.appendChild(inner);

  const scrollbarWidth = container.offsetWidth - inner.offsetWidth;

  document.body.removeChild(container);

  return scrollbarWidth;
};

function MyComponent({ title, setIframeWidth }) {
  const {theme}=useTheme()
  const iframeRef = useRef(null);

  useEffect(() => {
    const scrollbarWidth = getScrollbarWidth();
    setIframeWidth(`calc(100vw - ${scrollbarWidth + 5}px )`);

    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const { iframeResizer } = require("iframe-resizer");
      iframeResizer(
        {
          checkOrigin: false,
          heightCalculationMethod: "bodyScrollHeight",
        },
        iframe
      );
    }
  }, []);

  const srcUrl =
    title === "Fibonacci calculator"
      ? "Fibonacci"
      : title === "Pivot points calculator"
        ? "PivotPointscal"
        : "OptionsPrice";

  return (
    <IframeResizer
      title="calculators"
      ref={iframeRef}
      src={`https://dpx2abi6sh9y5.cloudfront.net/${srcUrl}?theme=${theme}`}
      width={"100%"}
      style={{ margin: "0 auto" }}
    />
  );
}

export default function Calculator({ title }) {
  const [iframeWidth, setIframeWidth] = useState("100vw");
  return (
    <section style={{ paddingBottom: "70px", margin: "0 auto" }}>
      <div style={{ width: iframeWidth }}>
        <MyComponent title={title} setIframeWidth={setIframeWidth} />
      </div>
    </section>
  );
}
