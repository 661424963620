import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useRouteError } from "react-router-dom";
import getScreenerDetailsData from "../utils/api";
import WebsocketWrapperContext from "../context/WebsocketWrapperContext";
import { ShimmerContentBlock } from "react-shimmer-effects";
import { getStockDetails, getStockTechnicals } from "../utils/api";
import axios from "axios";

const ScreenersDetail = () => {
  const location = useLocation();
  const router = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const pattern = queryParams.get("pattern");
  const path = queryParams.get("path");
  let cardDetails;
  const [updatedScreenerDetails, setUpdatedScreenerDetails] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTimeframe, setSelectedTimeframe] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } = useContext(WebsocketWrapperContext);

  const [triggerSubscribe, setTriggerSubscribe] = useState(true);

  let connectionEstablished = false;

  useEffect(() => {
    handleLoadData();
  },[])

  const handleLoadData = async () => {
    try {
      const updatedScreenersDetails = await getScreenerDetailsData(
        path,
        pattern,
        null,
        null
      );
      setUpdatedScreenerDetails(updatedScreenersDetails);
    } catch (error) {
      console.error("Error while fetching screener details:", error);
    }
  };

  //Effect for websocket subscription
  useEffect(() => {
    const symbols = updatedScreenerDetails?.table_data?.map(
      (stock) => stock.code
    );
    if (!symbols){
      return;
    }
    connectionEstablished = checkWebsocketConnection();
    if (connectionEstablished) {
      if (triggerSubscribe) {
        if (symbols?.length > 0) {
          subscribeToSymbols(symbols, "NSE");
        }
        // If symbolList is empty the message is not sent to the websocket
        if (Object.keys(symbols)?.length !== 0) {
          setTriggerSubscribe(false);
        }
      }
    } else {
      setTriggerSubscribe(true);
    }
    return () => {};
  }, [checkWebsocketConnection, updatedScreenerDetails, triggerSubscribe]);

  useEffect(() => {
    setTriggerSubscribe(true);
  }, [updatedScreenerDetails]);

  function formatNumberWithCommasAndFontWeight(value) {
    const formattedValue = parseFloat(value).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue.split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1];

    return React.createElement(
      "span",
      { style: { fontWeight: 700 } },
      integerPart,
      ".",
      React.createElement("span", { style: { fontWeight: 400 } }, decimalPart)
    );
  }

  function parser(valueString) {
    if (valueString === null || valueString.trim() === "") {
      return "-";
    }

    const values = [];

    try {
      const parsedValue = JSON.parse(valueString);

      for (const key in parsedValue) {
        if (parsedValue.hasOwnProperty(key)) {
          const value = parsedValue[key];
          if (!isNaN(value)) {
            const roundedValue = parseFloat(value).toFixed(2);
            values.push(roundedValue);
          }
        }
      }

      if (values.length === 0) {
        return "-";
      }
    } catch (error) {
      console.error("Error parsing valueString:", error);
      return "-";
    }

    const result = values.join(", ");
    return result;
  }

  function calculateChangePercentage(current, previous) {
    const change = current - previous;
    const changePercentage = ((change / previous) * 100).toFixed(2);
    const parsedChangePercentage = parseFloat(changePercentage);
    const formattedChangePercentage = parsedChangePercentage.toLocaleString(
      "en-IN",
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    );
    return `${formattedChangePercentage}%`;
  }

  function downloadCSV() {
    const csvContent = "data:text/csv;charset=utf-8,";
    const header = Object.keys(updatedScreenerDetails.table_data[0]).join(",") + "\n";
    const rows = updatedScreenerDetails?.table_data?.map(
      (row) => Object.values(row).join(",") + "\n"
    );
    const csvData = csvContent + header + rows.join("");
    const encodedUri = encodeURI(csvData);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "screener_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const filteredTableData = updatedScreenerDetails?.table_data?.filter(
    (stock) => stock?.stock?.toLowerCase()?.includes(searchQuery.toLowerCase())
  );

  function shareData() {
    if (navigator.share) {
      navigator
        .share({
          title: "Share Screener Data",
          text: "Check out this screener data!",
          url: window.location.href,
        })
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      alert("Sharing is not supported on this device/browser.");
    }
  }

  const handleTimeframeChange = async (event) => {
    const timeframe = event.target.value;
    setSelectedTimeframe(timeframe);
    try {
      const updatedScreenersDetails = await getScreenerDetailsData(
        path,
        pattern,
        timeframe,
        selectedGroup
      );
      setUpdatedScreenerDetails(updatedScreenersDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGroupChange = async (event) => {
    const group = event.target.value;
    setSelectedGroup(group);
    try {
      const updatedScreenersDetails = await getScreenerDetailsData(
        path,
        pattern,
        selectedTimeframe,
        group
      );
      setUpdatedScreenerDetails(updatedScreenersDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickRow = async (stockName) => {
    try {
      const [stockDetails, stockTechnicals] = await Promise.all([
        getStockDetails(stockName, "Intraday"),
        getStockTechnicals(stockName, "1day"),
      ]);

      console.log("Stock Details:", stockDetails);
      console.log("Stock Technicals:", stockTechnicals);

      router(`/StockDetails?stock=${stockName}`, {
        state: {
          stockDetails: stockDetails,
          stockTechnicals: stockTechnicals,
        },
      });
    } catch (error) {
      console.error("Error fetching stock details:", error);
    }
  };

  return (
    <>
      <div className="analyse-screener-page">
        <div className="row">
          {/* {isSubscribed ? (
            <h1>ws sunscribed successfully</h1>
          ) : (
            <h1>ws not subsribed</h1>
          )} */}
          <div className="col-12 col-md-8">
            <div className="d-flex align-items-start justify-content-between flex-column">
              <div className="d-flex align-items-center">
                <div className="screenerdata-heading">
                  {updatedScreenerDetails?.heading}
                </div>
              </div>

              <div className=" d-flex justify-content-start mt-1 gap-3">
                {updatedScreenerDetails?.tags?.map((tag, index) => (
                  <div
                    key={index}
                    className="card-tag"
                    style={{
                      backgroundColor: tag.background,
                      color: tag.color,
                    }}>
                    {tag.tag_name}
                  </div>
                ))}
              </div>
            </div>
            <div className="screenerdata-desc py-1 md-text-base mt-3">
              {updatedScreenerDetails?.description}
            </div>
          </div>

          <div className="col-12 col-md-4 d-md-flex  d-none justify-content-end gap-3">
            <div
              className="download-share-btn  d-flex gap-2 align-items-center"
              role="button"
              onClick={downloadCSV}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="15"
                viewBox="0 0 13 15"
                fill="none">
                <path
                  d="M1 8.86339L6.5 14L12 8.86339M6.52494 13.0312V1"
                  stroke="#383FEC"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Download</span>
            </div>
            {/* <div
              className="download-share-btn d-flex gap-2 align-items-center"
              role="button"
              onClick={shareData}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none">
                <path
                  d="M8.91304 4.79605V2L14 6.75329L8.91304 11.5066V8.71053C8.91304 8.71053 7.5 8.43092 4.95652 9.54934C2.41304 10.6678 1 12 1 12C1 12 1.56522 8.76645 4.1087 6.75329C6.65217 4.74013 8.91304 4.79605 8.91304 4.79605Z"
                  stroke="#383FEC"
                />
              </svg>
              <span>Share</span>
            </div> */}
          </div>
        </div>

        <div className="d-flex align-items-center gap-2 gap-md-4 mt-3 mt-md-5 flex-wrap">
          <div className="searh-download-share d-flex justify-content-between align-items-center">
            <div className="search-container d-flex justify-content-between align-items-center px-2">
              <input
                type="text"
                placeholder={updatedScreenerDetails?.search_placeholder}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none">
                <path
                  d="M21 21L17.8421 17.8421M19.9474 10.4737C19.9474 15.7059 15.7059 19.9474 10.4737 19.9474C5.24151 19.9474 1 15.7059 1 10.4737C1 5.24151 5.24151 1 10.4737 1C15.7059 1 19.9474 5.24151 19.9474 10.4737Z"
                  stroke="#646464"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {window.innerWidth < 450 ? (
              <div className="ms-auto d-flex gap-2">
                <div
                  className="download-share-btn download-share-btn-mob  d-flex gap-2 align-items-center"
                  role="button"
                  onClick={downloadCSV}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="15"
                    viewBox="0 0 13 15"
                    fill="none">
                    <path
                      d="M1 8.86339L6.5 14L12 8.86339M6.52494 13.0312V1"
                      stroke="#383FEC"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* <div
                  className="download-share-btn download-share-btn-mob d-flex gap-2 align-items-center"
                  role="button"
                  onClick={shareData}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none">
                    <path
                      d="M8.91304 4.79605V2L14 6.75329L8.91304 11.5066V8.71053C8.91304 8.71053 7.5 8.43092 4.95652 9.54934C2.41304 10.6678 1 12 1 12C1 12 1.56522 8.76645 4.1087 6.75329C6.65217 4.74013 8.91304 4.79605 8.91304 4.79605Z"
                      stroke="#383FEC"
                    />
                  </svg>
                </div> */}
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="screenerdetails-stock-info-div d-flex justify-content-between justify-content-between">
            <div className="stocks-found d-flex justify-content-center align-items-center p-2 fw-bold">
              <div>
                {updatedScreenerDetails?.table_data?.length <= 1
                  ? `${updatedScreenerDetails?.table_data?.length} stock found`
                  : `${updatedScreenerDetails?.table_data?.length} stocks found`}
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center p-2 last-updated">
              <div>Last updated at {updatedScreenerDetails?.last_updated}</div>
            </div>
          </div>

          <div className="timeandgroup ms-md-auto py-2 d-flex gap-2 align-items-center">
            <div className="time-frame">Time frame</div>
            <select
              className="download-share-btn  px-1"
              value={selectedTimeframe || ""}
              onChange={handleTimeframeChange}>
              {updatedScreenerDetails?.timeframes?.map((timeframe) => (
                <option key={timeframe.value} value={timeframe.value}>
                  {timeframe.display}
                </option>
              ))}
            </select>
            <select
              className="download-share-btn px-1 ms-auto "
              value={selectedGroup || ""}
              onChange={handleGroupChange}>
              {updatedScreenerDetails?.stock_groups?.map((group) => (
                <option key={group} value={group}>
                  {group}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* table */}
        <div className="d-flex align-items-center gap-4 my-3 "style={{paddingBottom: "40px"}}>
          {window.innerWidth < 450 ? (
            <table className="table table-borderless w-100 screenerdetails-table">
              <thead>
                <tr>
                  <th className="mx-4 py-4 font-normal text-secondary text-start">
                    Stock
                  </th>
                  <th className="mx-4 py-4 font-normal text-secondary text-end">
                    Current Price (LTP)
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredTableData?.map((stock, index) => (
                  <tr
                    onClick={() => handleClickRow(stock.stock)}
                    key={index}
                    className="screenerdetails-table-border-bottom">
                    <td className="border-bottom-2 border-gray-200 mx-4 py-2 text-start">
                      <div className="stock_name">{stock.stock}</div>
                      <div className="stock_comp_name">
                        {stock.company_name}
                      </div>
                    </td>
                    <td className="border-bottom-2 border-gray-200 mx-4 py-2 text-end fw-bold">
                      ₹{" "}
                      {formatNumberWithCommasAndFontWeight(
                        symbolData[stock.code]?.ltp
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <table className="table table-borderless w-100 screenerdetails-table my-3">
              <colgroup>
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
                {path === "technicals"  || path === "technical indicators" ? (
                  <>
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "20%" }} />
                  </>
                ) : (
                  <>
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "20%" }} />
                  </>
                )}
              </colgroup>
              <thead>
                <tr>
                  <th className="mx-4 py-4 font-normal text-secondary text-start">
                    Stock
                  </th>
                  <th className="mx-4 py-4 font-normal text-secondary text-center">
                    Current Price (LTP)
                  </th>
                  {path === "technicals" || path === "technical indicators" ? (
                    <>
                      <th className="mx-4 py-4 font-normal text-secondary text-center">
                        Computed value
                      </th>
                      <th className="mx-4 py-4 font-normal text-secondary text-primary text-end">
                        Level
                      </th>
                    </>
                  ) : (
                    <>
                      <th className="mx-4 py-4 font-normal text-secondary text-center">
                        Change
                      </th>
                      <th className="mx-4 py-4 font-normal text-secondary text-center">
                        Previous Close
                      </th>
                      <th className="mx-4 py-4 font-normal text-secondary text-end">
                        Volume
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredTableData?.map((stock, index) => (
                  <tr
                    key={index}
                    onClick={() => handleClickRow(stock.stock)}
                    className="screenerdetails-table-border-bottom">
                    <td className="border-bottom-2 border-gray-200 mx-4 py-2 text-start">
                      <div className="stock_name">{stock.stock}</div>
                      <div className="stock_comp_name">
                        {stock.company_name}
                      </div>
                    </td>
                    <td className="border-bottom-2 border-gray-200 mx-4 py-2 text-center fw-bold">
                      ₹{" "}
                      {formatNumberWithCommasAndFontWeight(
                        symbolData[stock.code]?.ltp || stock?.ltp
                      )}
                    </td>
                    {path === "technicals" || path === "technical indicators" ? (
                      <>
                        <td
                          className={`border-bottom-2 border-gray-200 mx-4 py-2 text-center `}>
                          <div className="d-inline-block align-middle mb-1">
                            {parser(stock.value)}
                          </div>
                        </td>
                        <td className="border-bottom-2 border-gray-200 mx-4 py-2 text-end fw-bold">
                          {stock.level}
                        </td>
                      </>
                    ) : (
                      <>
                        <td
                          className={`border-bottom-2 border-gray-200 mx-4 py-2 text-center ${
                            parseFloat(
                              calculateChangePercentage(
                                parseFloat(symbolData[stock.code]?.ltp),
                                parseFloat(symbolData[stock.code]?.prev_close)
                              )
                            ) > 0
                              ? "text-success"
                              : "text-danger"
                          }`}>
                          <div className="d-inline-block align-middle mb-1">
                            {parseFloat(
                              calculateChangePercentage(
                                parseFloat(symbolData[stock.code]?.ltp),
                                parseFloat(symbolData[stock.code]?.prev_close)
                              )
                            ) > 0 ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                className="w-3 h-3 inline-block mx-1">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                className="w-3 h-3 inline-block mx-1"
                                style={{ transform: "rotate(180deg)" }}>
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                                />
                              </svg>
                            )}
                          </div>
                          {formatNumberWithCommasAndFontWeight(
                            symbolData[stock.code]?.change
                          )}{" "}
                          (
                          {formatNumberWithCommasAndFontWeight(
                            calculateChangePercentage(
                              parseFloat(symbolData[stock.code]?.ltp),
                              parseFloat(symbolData[stock.code]?.prev_close)
                            )
                          )}
                          )
                        </td>
                        <td className="border-bottom-2 border-gray-200 mx-4 py-2 text-center fw-bold">
                          ₹{" "}
                          {formatNumberWithCommasAndFontWeight(
                            symbolData[stock.code]?.prev_close
                          )}
                        </td>
                        <td className="border-bottom-2 border-gray-200 mx-4 py-2 text-end fw-bold">
                          {formatNumberWithCommasAndFontWeight(
                            symbolData[stock.code]?.volume ? symbolData[stock.code]?.volume : stock.volume
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {filteredTableData?.length === 0 && (
          <div className="text-center text-gray-500 font-normal fs-xl">
            No stocks with matching criteria found, please try different
            timeframes or checkback later!
          </div>
        )}
      </div>
    </>
  );
};

export default ScreenersDetail;
