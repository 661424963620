import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { useOptionChainContext } from "../context/OptionChainContext";
import { getSymbols } from "../utils/api";
import ExpiryDates from "../components/ExpiryDates";
import { ExpiryDatesProvider } from "../context/ExpiryDatesContext";
import axios from "axios";
import OptionChainTable from "../components/OptionChainTable";
import { useLtpContext } from "../context/LtpContext";
import ABWebSocketContext from "../context/ABWebsocketContext";
import WebsocketWrapperContext from "../context/WebsocketWrapperContext";
import { useTheme } from "../context/ThemeContext";
import { useDataContext } from "../context/DataContext";
import { useBuySellModalContext } from "../context/BuySellModalContext";
import { e, round } from "mathjs";
const OptionChainModal = ({
  parentCallback,
  parentCallback2,
  initialOptionData,
  selectedSymbol,
  selectedData,
  setData,
  showCalls,
  showPuts,
  calls,
  puts,
  setCalls,
  setPuts,
  showDelta,
  showGamma,
  showVega,
  showTheta,
  showOIChng,
  showOIBuilddup,
  maxPain,
}) => {
  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } = useContext(WebsocketWrapperContext);
  const { theme } = useTheme();
  // const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [activeDate, setActiveDate] = useState("");
  const { optionData, setOptionData } = useOptionChainContext();
  const [tempOptionData, setTempOptionData] = useState({});
  const { reducedData, setReducedData } = useState();
  const [allTradingSymbols, setAllTradingSymbols] = useState({});
  const [maxVolume, setMaxVolume] = useState(0);
  const [maxPutVolume, setMaxPutVolume] = useState(0);
  // const [calls, setCalls] = useState();
  // const [puts, setPuts] = useState();
  const [expiryDays, setExpiryDays] = useState(0);
  const { callsDataCon, setCallsDataCon, putsDataCon, setPutsDataCon } =
    useDataContext();
  const [divVisibility, setDivVisibility] = useState(false);
  const {setBuySellModalData,addToBuySellModal} = useBuySellModalContext();
  var iv = require("implied-volatility");
  var greeks = require("greeks");
  useLayoutEffect(() => {
    const storedSymbol = sessionStorage.getItem("selectedSymbol");
    if (storedSymbol) {
      // setSelectedSymbol(JSON.parse(storedSymbol));
    } else {
      const fetchSymbols = async () => {
        const symbols = await getSymbols();
        if (symbols) {
          const niftySymbol = symbols.find(
            (symbol) => symbol.scrip === "NIFTY"
          );
          // setSelectedSymbol(niftySymbol);
          sessionStorage.setItem("selectedSymbol", JSON.stringify(niftySymbol));
        }
      };

      fetchSymbols();
    }
  }, []);
  useEffect(() => {
    const updateMaxVolume = () => {
      let newMaxVolume = 0;
      calls?.forEach((call, index) => {
        const temp =
          symbolData[call.code]?.oi / symbolData[call.code]?.lot_size;
        if (temp > newMaxVolume) {
          newMaxVolume = temp;
        }
      });
      setMaxVolume(newMaxVolume);
    };

    const updateMaxPutVolume = () => {
      let newMaxPutVolume = 0;
      puts?.forEach((put, index) => {
        const temp = symbolData[put.code]?.oi / symbolData[put.code]?.lot_size;
        if (temp > newMaxPutVolume) {
          newMaxPutVolume = temp;
        }
      });
      setMaxPutVolume(newMaxPutVolume);
    };

    updateMaxVolume();
    updateMaxPutVolume();
  }, [calls, puts, symbolData, selectedDate, selectedSymbol]);

  useEffect(() => {
    if (optionData && optionData.options) {
      let callsdata = optionData.options.map((option) => option.call);
      let putsdata = optionData.options.map((option) => option.put);
      setCalls(callsdata);
      setPuts(putsdata);
    }
  }, [optionData, activeDate]);

  const handleSymbolSelection = async (symbol, date) => {
    const formattedDate = date.replaceAll(" ", "%20");
    setSelectedDate(formattedDate);
    setActiveDate(date);
    await fetchDataForSymbol(symbol.scrip, formattedDate);
    const targetDateTime = new Date(date);
    const currentDate = new Date();
    let timeToExpiration = (
      (targetDateTime - currentDate) /
      (24 * 60 * 60 * 1000)
    ).toFixed(0);
    setExpiryDays(parseInt(timeToExpiration) + 1);
  };

  useLayoutEffect(() => {
    if (
      Object.keys(tempOptionData).length > 0 &&
      symbolData[selectedSymbol?.code]
    ) {
      let underlying_ltp = symbolData[selectedSymbol?.code]?.ltp;
      let data = tempOptionData;
      const formattedData = data["options"].map((item) => ({
        ...item,
        strike: parseInt(item.strike),
      }));

      let minDiff = Math.abs(
        formattedData[0].strike - parseFloat(underlying_ltp)
      );
      let closestIdx = 0;

      // Iterate through the array to find the closest index
      formattedData.forEach((item, index) => {
        const diff = Math.abs(item.strike - parseFloat(underlying_ltp));
        if (diff < minDiff) {
          minDiff = diff;
          closestIdx = index;
        }
      });
      const selected = formattedData.slice(
        Math.max(0, closestIdx - 20),
        Math.min(formattedData.length, closestIdx + 20)
      );
      data["options"] = selected;
      setOptionData(data);

      parentCallback2(data);

      const call = getCodeTradingSymbolMappings(selected, "call");
      const put = getCodeTradingSymbolMappings(selected, "put");

      const tradingSymbols = call.concat(put);
      setAllTradingSymbols(tradingSymbols);
      setTempOptionData({});
      subscribeToSymbols(tradingSymbols, data?.options[0]?.call.exchange);
    }
  }, [tempOptionData, symbolData]);

  const handleLotSizeChange = (newValue, index, side) => {
    const newLotsSize = parseInt(newValue); // Convert the new value to an integer

    if (selectedData.length > 0) {
      const checkTradingSymbol =
        side === "calls"
          ? callsDataCon[index].trading_symbol
          : putsDataCon[index].trading_symbol;

      const positionIndex = selectedData.findIndex(
        (position) => position.tradingSymbol === checkTradingSymbol
      );

      if (positionIndex !== -1) {
        // Position found in selectedData
        const updatedBasketData = [...selectedData];
        updatedBasketData[positionIndex].lotSize = newLotsSize;
        setData(updatedBasketData);
      }
    } else {
      // Basket is empty, update options data
      if (side === "calls") {
        const updatedCallsDataCon = [...callsDataCon];
        updatedCallsDataCon[index].LotsSize = newLotsSize;
        setCallsDataCon(updatedCallsDataCon);
      } else {
        const updatedPutsDataCon = [...putsDataCon];
        updatedPutsDataCon[index].LotsSize = newLotsSize;
        setPutsDataCon(updatedPutsDataCon);
      }
    }
  };

  //function to handle lots size by clicking on increment
  const incrementValue = (index, side) => {
    if (
      selectedData.length > 0 &&
      selectedData.some(
        (position) =>
          position.tradingSymbol ===
          (side === "calls"
            ? callsDataCon[index].trading_symbol
            : putsDataCon[index].trading_symbol)
      )
    ) {
      const checkTradingSymbol =
        side === "calls"
          ? callsDataCon[index].trading_symbol
          : putsDataCon[index].trading_symbol;
      for (let i = 0; i < selectedData.length; i++) {
        if (selectedData[i].tradingSymbol === checkTradingSymbol) {
          let dataObject = selectedData[i];
          dataObject["lotSize"] = dataObject["lotSize"] + 1;

          setData((prevData) => [...prevData]);
        }
      }
    } else {
      if (side === "calls") {
        const updatedCallsDataCon = [...callsDataCon];
        updatedCallsDataCon[index].LotsSize += 1;
        setCallsDataCon(updatedCallsDataCon);
      } else {
        const updatedPutsDataCon = [...putsDataCon];
        updatedPutsDataCon[index].LotsSize += 1;
        setPutsDataCon(updatedPutsDataCon);
      }
    }
  };

  //function to handle lots size by clicking on decrement
  const decrementValue = (index, side) => {
    if (
      selectedData.length > 0 &&
      selectedData.some(
        (position) =>
          position.tradingSymbol ===
          (side === "calls"
            ? callsDataCon[index].trading_symbol
            : putsDataCon[index].trading_symbol)
      )
    ) {
      const checkTradingSymbol =
        side === "calls"
          ? callsDataCon[index].trading_symbol
          : putsDataCon[index].trading_symbol;
      for (let i = 0; i < selectedData.length; i++) {
        if (
          selectedData[i].tradingSymbol === checkTradingSymbol &&
          selectedData[i].lotSize > 1
        ) {
          let dataObject = selectedData[i];
          dataObject["lotSize"] = dataObject["lotSize"] - 1;
          setData((prevData) => [...prevData]);
        }
      }
    } else {
      if (side === "calls") {
        if (callsDataCon[index].LotsSize > 1) {
          const updatedCallsDataCon = [...callsDataCon];
          updatedCallsDataCon[index].LotsSize -= 1;
          setCallsDataCon(updatedCallsDataCon);
        }
      } else {
        if (putsDataCon[index].LotsSize > 1) {
          const updatedPutsDataCon = [...putsDataCon];
          updatedPutsDataCon[index].LotsSize -= 1;
          setPutsDataCon(updatedPutsDataCon);
        }
      }
    }
  };

  const handleRowMouseEnter = (index) => {
    setDivVisibility(true);
    setSelectedRowIndex(index);
  };

  const handleRowMouseLeave = () => {
    setDivVisibility(false);
    setSelectedRowIndex(null);
  };

  let uniqueStrikes = [];
  if (optionData && optionData.options) {
    uniqueStrikes = Array.from(
      new Set(optionData.options.map((option) => option.call.strike))
    );
    uniqueStrikes.sort((a, b) => a - b);
  } else {
    // Handle the case when options are not loaded yet
    // For example, you can set a loading state or show a loading indicator
  }

  // If uniqueStrikes is empty and options are not loaded yet, retry after a delay
  if (uniqueStrikes.length === 0 && !optionData) {
    setTimeout(() => {
      if (optionData && optionData.options) {
        uniqueStrikes = Array.from(
          new Set(optionData.options.map((option) => option.call.strike))
        );
        uniqueStrikes.sort((a, b) => a - b);
      }
    }, 1000);
  }

  if (calls) {
    calls.sort((a, b) => a.strike - b.strike);
  }
  if (puts) {
    puts.sort((a, b) => a.strike - b.strike);
  }

  const getCodeTradingSymbolMappings = (data, key) => {
    const codeTradingSymbol = [];
    for (const item of data) {
      const { code } = item[key];
      codeTradingSymbol.push(code);
    }
    return codeTradingSymbol;
  };

  const fetchDataForSymbol = async (symbol, date) => {
    let brokerName = localStorage.getItem("broker");

    if (!brokerName) {
      console.error("Broker name not found in local storage.");
      return;
    }

    brokerName = brokerName.toLowerCase().replace(/\s+/g, "");
    const apiEndpoint = `https://y18wfq6w5b.execute-api.ap-south-1.amazonaws.com/default/og_expirysymbols`;
    try {
      if (Object.keys(tempOptionData).length === 0) {
        const response = await axios.post(apiEndpoint, {
          id: symbol + date,
          broker: brokerName,
        });
        const data = response.data;
        setTempOptionData(data);

        if (Object.keys(allTradingSymbols).length > 0) {
          unsubscribeToSymbols(allTradingSymbols,  data?.options[0]?.call.exchange);
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  if(selectedData?.length > 0){
    for(let j = 0; j < callsDataCon.length; j++){
      let matching_flag = false;
     
      for(let i = 0; i < selectedData.length; i++){
        const checkTradingSymbol = selectedData[i].tradingSymbol;
        const direction = selectedData[i].isBuy ? "B" : "S";
        const side = selectedData[i].isCall ? "Put" : "Call";
        if (side === "Call") {
          if (callsDataCon[j].trading_symbol === checkTradingSymbol) {
            matching_flag = true;
            callsDataCon[j].LotsSize = selectedData[i].lotSize;
            if (direction === "B") {
              callsDataCon[j].buyBtnState = true;
              callsDataCon[j].sellBtnState = false;
            } else {
              callsDataCon[j].sellBtnState = true;
              callsDataCon[j].buyBtnState = false;
            }
          }
        }
      }
      
      if (!matching_flag){
        callsDataCon[j].buyBtnState = false;
        callsDataCon[j].sellBtnState = false;
      }
    }

    for (let j = 0; j < putsDataCon.length; j++) {
      let matching_flag = false;
      for (let i = 0; i < selectedData.length; i++) {
        const checkTradingSymbol = selectedData[i].tradingSymbol;
        const direction = selectedData[i].isBuy ? "B" : "S";
        const side = selectedData[i].isCall ? "Call" : "Put";
        if (side === "Put") {
          if (putsDataCon[j].trading_symbol === checkTradingSymbol) {
            matching_flag = true;
            putsDataCon[j].LotsSize = selectedData[i].lotSize;
            if (direction === "B") {
              putsDataCon[j].buyBtnState = true;
              putsDataCon[j].sellBtnState = false;
            } else {
              putsDataCon[j].sellBtnState = true;
              putsDataCon[j].buyBtnState = false;
            }
          }
        }
      }
      if (!matching_flag) {
        putsDataCon[j].buyBtnState = false;
        putsDataCon[j].sellBtnState = false;
      }
    }
  }

  useEffect(() => {
    if (optionData && optionData.options && optionData.options.length > 0) {
      let callsTempData = optionData.options?.map((option) => ({
        ...option.call,
        buyBtnState: false,
        sellBtnState: false,
        LotsSize: 1,
      }));

      let putsTempData = optionData.options?.map((option) => ({
        ...option.put,
        buyBtnState: false,
        sellBtnState: false,
        LotsSize: 1,
      }));
      setCallsDataCon(callsTempData);
      setPutsDataCon(putsTempData);
    }
  }, [optionData]);

  useLayoutEffect(() => {
    const fetchDefaultData = () => {
      if (
        selectedSymbol &&
        selectedSymbol.option_expiries &&
        selectedSymbol.option_expiries.length > 0
      ) {
        const defaultDate = selectedSymbol.option_expiries[0]; // Selecting the first date
        const formattedDate = defaultDate.replaceAll(" ", "%20");
        setActiveDate(defaultDate);
        const targetDateTime = new Date(defaultDate);
        const currentDate = new Date();
        let timeToExpiration = (
          (targetDateTime - currentDate) /
          (24 * 60 * 60 * 1000)
        ).toFixed(0);
        setExpiryDays(parseInt(timeToExpiration) + 1);
        if (optionData) {
          if (selectedSymbol.scrip + defaultDate !== optionData["id"]) {
            fetchDataForSymbol(selectedSymbol.scrip, formattedDate);
          }
        } else {
          fetchDataForSymbol(selectedSymbol.scrip, formattedDate);
        }
      }
    };
    fetchDefaultData();
  }, [selectedSymbol]);

  useLayoutEffect(() => {
    computeGreeks();
  }, [symbolData, optionData]);




  const computeGreeks = () => {
    //using parsefloat only once so we dont need to use multiple times
    const ltp = parseFloat(symbolData[selectedSymbol?.code]?.ltp);
    const riskFreeRate = 0.1 / 365; //moved this out of the loop

    // using forrach instead of map
    optionData?.options.forEach((data) => {
      const call_option = data.call;
      const put_option = data.put;
      const strike = data.strike;
      const isCallOption = strike > ltp;
      const optionType = isCallOption ? "call" : "put";
      const relevantOption = isCallOption ? call_option : put_option;

      const targetDateTimeString = put_option.expiry_date + " 15:29:59"; // Target date and time
      const targetDateTime = new Date(targetDateTimeString);
      const currentDate = new Date();
      let timeToExpiration = Math.max(
        0,
        (targetDateTime - currentDate) / (24 * 60 * 60 * 365 * 1000) ||
          0.0002 / 365
      );

      const currentStockPrice = parseFloat(
        symbolData[relevantOption?.code]?.ltp
      );

      if (!call_option.iv) {
        //instead of repeting same calculations for calls and puts, combining them here
        const initialGuessIv = iv.getImpliedVolatility(
          currentStockPrice,
          ltp,
          strike,
          timeToExpiration,
          riskFreeRate,
          optionType
        );
        if (initialGuessIv <= 1) {
          const ivValue = (initialGuessIv * 100).toFixed(2);
          const deltaValue = (
            greeks.getDelta(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "call"
            ) * 100
          ).toFixed(2);
          const thetaValue = (
            greeks.getTheta(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "call"
            ) * 100
          ).toFixed(2);
          const gammaValue = (
            greeks.getGamma(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "call"
            ) * 100
          ).toFixed(2);
          const vegaValue = (
            greeks.getVega(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "call"
            ) * 100
          ).toFixed(2);
          data.call.iv = ivValue;
          data.call.delta = deltaValue;
          data.call.theta = thetaValue;
          data.call.gamma = gammaValue;
          data.call.vega = vegaValue;
        }
      }
      if (!put_option.iv) {
        const initialGuessIv = iv.getImpliedVolatility(
          currentStockPrice,
          ltp,
          strike,
          timeToExpiration,
          riskFreeRate,
          optionType
        );
        if (initialGuessIv <= 1) {
          const ivValue = (initialGuessIv * 100).toFixed(2);
          const deltaValue = (
            greeks.getDelta(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "put"
            ) * 100
          ).toFixed(2);
          const thetaValue = (
            greeks.getTheta(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "put"
            ) * 100
          ).toFixed(2);
          const gammaValue = (
            greeks.getGamma(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "put"
            ) * 100
          ).toFixed(2);
          const vegaValue = (
            greeks.getVega(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "put"
            ) * 100
          ).toFixed(2);
          data.put.iv = ivValue;
          data.put.delta = deltaValue;
          data.put.theta = thetaValue;
          data.put.gamma = gammaValue;
          data.put.vega = vegaValue;
        }
      }
    });
  };

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedSide, setSelectedSide] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState({ top: 0, left: 0 });

  const getReducedData = () => {
    const sample = optionData && optionData.options && optionData.options;
    if (sample) {
      let f = sample.reduce((groupedOptions, option) => {
        const strike = option.call.strike;
        if (!groupedOptions[strike]) {
          groupedOptions[strike] = {
            call: option.call,
            put: option.put,
          };
        } else {
          groupedOptions[strike].call = {
            ...groupedOptions[strike].call,
            ...option.call,
          };
          groupedOptions[strike].put = {
            ...groupedOptions[strike].put,
            ...option.put,
          };
        }
        return groupedOptions;
      }, []);
      return f;
    }
  };

  if (selectedData?.length >= 1) {
    for (let j = 0; j < callsDataCon.length; j++) {
      let matching_flag = false;
      for (let i = 0; i < selectedData.length; i++) {
        const checkTradingSymbol = selectedData[i].tradingSymbol;
        const direction = selectedData[i].isBuy ? "B" : "S";
        const side = selectedData[i].isCall ? "Put" : "Call";
        if (side === "Call") {
          if (callsDataCon[j].trading_symbol === checkTradingSymbol) {
            matching_flag = true;
            callsDataCon[j].LotsSize = selectedData[i].lotSize;
            if (direction === "B") {
              callsDataCon[j].buyBtnState = true;
              callsDataCon[j].sellBtnState = false;
            } else {
              callsDataCon[j].sellBtnState = true;
              callsDataCon[j].buyBtnState = false;
            }
          }
        }
      }
      if (!matching_flag) {
        callsDataCon[j].buyBtnState = false;
        callsDataCon[j].sellBtnState = false;
      }
    }

    for (let j = 0; j < putsDataCon.length; j++) {
      let matching_flag = false;
      for (let i = 0; i < selectedData.length; i++) {
        const checkTradingSymbol = selectedData[i].tradingSymbol;
        const direction = selectedData[i].isBuy ? "B" : "S";
        const side = selectedData[i].isCall ? "Call" : "Put";
        if (side === "Put") {
          if (putsDataCon[j].trading_symbol === checkTradingSymbol) {
            matching_flag = true;
            putsDataCon[j].LotsSize = selectedData[i].lotSize;
            if (direction === "B") {
              putsDataCon[j].buyBtnState = true;
              putsDataCon[j].sellBtnState = false;
            } else {
              putsDataCon[j].sellBtnState = true;
              putsDataCon[j].buyBtnState = false;
            }
          }
        }
      }
      if (!matching_flag) {
        putsDataCon[j].buyBtnState = false;
        putsDataCon[j].sellBtnState = false;
      }
    }
  }

  const handleRowClick = (event, rowIndex, side) => {
    const rowRect = event.currentTarget.getBoundingClientRect();
    const top = rowRect.top + window.scrollY;
    const left = rowRect.left + window.scrollX;
    setSelectedSide(side);
    setSelectedRowIndex(rowIndex);
  };

  const containerRef = useRef(null);

  // Refs to store the latest
  const selectedRowIndexRef = useRef(null);
  const selectedSideRef = useRef(null);
  const optionDataRef = useRef(null);

  // const handleButtonClick = (event) => {
  //   const target = event.target;
  //   const isBuyButton = target.classList.contains("buy-btn");
  //   const isSellButton = target.classList.contains("sell-btn");
  //   if (isBuyButton) {
  //     setSelectedRowIndex(selectedRowIndexRef.current);
  //     setSelectedSide(selectedSideRef.current);
  //     // handleClick("Buy");
  //
  //   } else if (isSellButton) {
  //     setSelectedRowIndex(selectedRowIndexRef.current);
  //     setSelectedSide(selectedSideRef.current);
  //     // handleClick("Sell");
  //   }
  // };

  //This block of container is used because in a way we have two onclick elements at a single place
  // useEffect(() => {
  //   const container = containerRef.current;
  //   container.addEventListener("click", handleButtonClick);
  //   return () => {
  //     container.removeEventListener("click", handleButtonClick);
  //   };
  // }, []);

  useEffect(() => {
    selectedRowIndexRef.current = selectedRowIndex;
    selectedSideRef.current = selectedSide;
    // optionDataRef.current = optionData;
  }, [selectedRowIndex, selectedSide /*, optionData*/]);

  const checkIfPresentInBasket = (side, selectedOption, basketData) => {
    return basketData.some((position) => {
      const temp =
        side === "calls"
          ? selectedOption.call.trading_symbol
          : selectedOption.put.trading_symbol;
      const checker = position.isCALL ? "calls" : "puts";

      return checker == side && position.tradingSymbol == temp;
    });
  };

  const updateBasket = (trading_symbol, isBuy) => {
    setData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.tradingSymbol === trading_symbol) {
          if (item.isBuy === isBuy) {
            return null;
          } else {
            return { ...item, isBuy: isBuy };
          }
        }
        return item;
      });
      return updatedData.filter((item) => item !== null);
    });
  };

  const handleBuyClick = (buy_sell_type, rowIndex, lotsProp, Rside) => {
    setSelectedSide(Rside);
    if (callsDataCon && Rside === "calls") {
      const updatedCallsDataCon = [...callsDataCon];
      if (updatedCallsDataCon[rowIndex] && buy_sell_type === "Buy") {
        updatedCallsDataCon[rowIndex].buyBtnState =
          !updatedCallsDataCon[rowIndex].buyBtnState;
        setCallsDataCon(updatedCallsDataCon);
        if (updatedCallsDataCon[rowIndex].sellBtnState) {
          updatedCallsDataCon[rowIndex].sellBtnState = false;
        }
      }
      if (updatedCallsDataCon[rowIndex] && buy_sell_type === "Sell") {
        updatedCallsDataCon[rowIndex].sellBtnState =
          !updatedCallsDataCon[rowIndex].sellBtnState;
        setCallsDataCon(updatedCallsDataCon);
        if (updatedCallsDataCon[rowIndex].buyBtnState) {
          updatedCallsDataCon[rowIndex].buyBtnState = false;
        }
      } else {
        console.error(`Error: callsDataCon[${rowIndex}] is undefined`);
      }
    } else {
      console.error("Error: callsDataCon is undefined");
    }

    if (putsDataCon && Rside === "puts") {
      const updatedPutsDataCon = [...putsDataCon];
      if (updatedPutsDataCon[rowIndex] && buy_sell_type === "Buy") {
        updatedPutsDataCon[rowIndex].buyBtnState =
          !updatedPutsDataCon[rowIndex].buyBtnState;
        setPutsDataCon(updatedPutsDataCon);
        if (updatedPutsDataCon[rowIndex].sellBtnState) {
          updatedPutsDataCon[rowIndex].sellBtnState = false;
        }
      }
      if (updatedPutsDataCon[rowIndex] && buy_sell_type === "Sell") {
        updatedPutsDataCon[rowIndex].sellBtnState =
          !updatedPutsDataCon[rowIndex].sellBtnState;
        setPutsDataCon(updatedPutsDataCon);
        if (updatedPutsDataCon[rowIndex].buyBtnState) {
          updatedPutsDataCon[rowIndex].buyBtnState = false;
        }
      } else {
        console.error(`Error: putsDataCon[${rowIndex}] is undefined`);
      }
    } else {
      console.error("Error: putsDataCon is undefined");
    }

    const f = getReducedData();
    const selectedStrike = uniqueStrikes[selectedRowIndex];
    const selectedOption = f[selectedStrike];
    const existingPositionIndex = checkIfPresentInBasket(
      Rside,
      selectedOption,
      selectedData
    );
    if (
      selectedSymbol &&
      optionData &&
      optionData.options &&
      selectedRowIndex !== null &&
      Rside !== null
    ) {
      // const selectedOption = f[selectedRowIndex];
      const selectedStrike = uniqueStrikes[selectedRowIndex];
      const selectedOption = f[selectedStrike];
      const side = Rside === "calls" ? "Call" : "Put";
      const instrumentName =
        side === "Call"
          ? selectedOption.call.instrument_name
          : selectedOption.put.instrument_name;
      const lotSize =
        side === "Call"
          ? selectedOption.call.lot_size
          : selectedOption.put.lot_size;
      const quantity = lotsProp;
      const optionType = side === "Call" ? "CE" : "PE";
      const tradingSymbol =
        side === "Call"
          ? selectedOption.call.trading_symbol
          : selectedOption.put.trading_symbol;
      const expiryDate =
        side === "Call"
          ? selectedOption.call.expiry_date
          : selectedOption.put.expiry_date;
      const code =
        side === "Call" ? selectedOption.call.code : selectedOption.put.code;
      const strike =
        side === "Call"
          ? selectedOption.call.strike
          : selectedOption.put.strike;
      const buy_sell = buy_sell_type;
      let freeze_qty =
        side === "Call"
          ? selectedOption.call.freeze_qty
          : selectedOption.put.freeze_qty;
      let exchange =
        side === "Call"
        ? selectedOption.call.exchange
        : selectedOption.put.exchange;
        let lot_multiplier = parseFloat(optionData?.lot_size);
        const dataObject = {
          side,
          instrumentName,
          lotSize,
          quantity,
          optionType,
          tradingSymbol,
          expiryDate,
          code,
          buy_sell,
          strike,
          freeze_qty: freeze_qty,
          exchange: exchange,
          lot_multiplier: lot_multiplier,
          scrip: selectedSymbol.scrip,
        };
         
        if(selectedData.length > 0){
          if(existingPositionIndex){
            updateBasket(tradingSymbol, buy_sell === "Buy" ? true : false);
          }else{
            parentCallback([[dataObject]], "add");
          }
          
        }
      else{
        parentCallback([[dataObject]], "add");
      }
    }
  };

  const handleSellClick = (buy_sell_type, rowIndex, lotsProp, Rside) => {
    if (callsDataCon && Rside === "calls") {
      const updatedCallsDataCon = [...callsDataCon];
      if (updatedCallsDataCon[rowIndex] && buy_sell_type === "Buy") {
        updatedCallsDataCon[rowIndex].buyBtnState =
          !updatedCallsDataCon[rowIndex].buyBtnState;
        setCallsDataCon(updatedCallsDataCon);
        if (updatedCallsDataCon[rowIndex].sellBtnState) {
          updatedCallsDataCon[rowIndex].sellBtnState = false;
        }
      }
      if (updatedCallsDataCon[rowIndex] && buy_sell_type === "Sell") {
        updatedCallsDataCon[rowIndex].sellBtnState =
          !updatedCallsDataCon[rowIndex].sellBtnState;
        setCallsDataCon(updatedCallsDataCon);
        if (updatedCallsDataCon[rowIndex].buyBtnState) {
          updatedCallsDataCon[rowIndex].buyBtnState = false;
        }
      } else {
        console.error(`Error: callsDataCon[${rowIndex}] is undefined`);
      }
    } else {
      console.error("Error: callsDataCon is undefined");
    }

    if (putsDataCon && Rside === "puts") {
      const updatedPutsDataCon = [...putsDataCon];
      if (updatedPutsDataCon[rowIndex] && buy_sell_type === "Buy") {
        updatedPutsDataCon[rowIndex].buyBtnState =
          !updatedPutsDataCon[rowIndex].buyBtnState;
        setPutsDataCon(updatedPutsDataCon);
        if (updatedPutsDataCon[rowIndex].sellBtnState) {
          updatedPutsDataCon[rowIndex].sellBtnState = false;
        }
      }
      if (updatedPutsDataCon[rowIndex] && buy_sell_type === "Sell") {
        updatedPutsDataCon[rowIndex].sellBtnState =
          !updatedPutsDataCon[rowIndex].sellBtnState;
        setPutsDataCon(updatedPutsDataCon);
        if (updatedPutsDataCon[rowIndex].buyBtnState) {
          updatedPutsDataCon[rowIndex].buyBtnState = false;
        }
      } else {
        console.error(`Error: putsDataCon[${rowIndex}] is undefined`);
      }
    } else {
      console.error("Error: putsDataCon is undefined");
    }

    const f = getReducedData();
    const selectedStrike = uniqueStrikes[selectedRowIndex];
    const selectedOption = f[selectedStrike];
    const existingPositionIndex = checkIfPresentInBasket(
      Rside,
      selectedOption,
      selectedData
    );

    if (
      selectedSymbol &&
      optionData &&
      optionData.options &&
      selectedRowIndex !== null &&
      Rside !== null
    ) {
      // const selectedOption = f[selectedRowIndex];
      const selectedStrike = uniqueStrikes[selectedRowIndex];
      const selectedOption = f[selectedStrike];
      const side = Rside === "calls" ? "Call" : "Put";
      const instrumentName =
        side === "Call"
          ? selectedOption.call.instrument_name
          : selectedOption.put.instrument_name;
      const lotSize =
        side === "Call"
          ? selectedOption.call.lot_size
          : selectedOption.put.lot_size;
      const quantity = lotsProp;
      const optionType = side === "Call" ? "CE" : "PE";
      const tradingSymbol =
        side === "Call"
          ? selectedOption.call.trading_symbol
          : selectedOption.put.trading_symbol;
      const expiryDate =
        side === "Call"
          ? selectedOption.call.expiry_date
          : selectedOption.put.expiry_date;
      const code =
        side === "Call" ? selectedOption.call.code : selectedOption.put.code;
      const strike =
        side === "Call"
          ? selectedOption.call.strike
          : selectedOption.put.strike;
      const buy_sell = buy_sell_type === "calls" ? "Call" : "Put";
      let freeze_qty =
        side === "Call"
          ? selectedOption.call.freeze_qty
          : selectedOption.put.freeze_qty;
      let exchange =
        side === "Call"
        ? selectedOption.call.exchange
        : selectedOption.put.exchange;
        let lot_multiplier = parseFloat(optionData?.lot_size);
        const dataObject = {
          side,
          instrumentName,
          lotSize,
          quantity,
          optionType,
          tradingSymbol,
          expiryDate,
          code,
          buy_sell,
          strike,
          freeze_qty: freeze_qty,
          exchange: exchange,
          lot_multiplier: lot_multiplier,
          scrip: selectedSymbol.scrip,
        };
        if(selectedData.length > 0){
          if(existingPositionIndex){
            updateBasket(tradingSymbol, buy_sell === "Buy" ? true : false);
          }else{
            parentCallback([[dataObject]], "add");
          }
          
        }
      else{
        parentCallback([[dataObject]], "add");
      }
    }
  };

  const handleBuySellModalClick = (selectedSide, rowIndex, lotsProp) => {
    const f = getReducedData();
    const selectedStrike = uniqueStrikes[selectedRowIndex];
    const selectedOption = f[selectedStrike];

    if(selectedSymbol &&
      optionData &&
      optionData.options &&
      selectedRowIndex !== null)
      {
        const selectedStrike = uniqueStrikes[selectedRowIndex]; // Get the selected strike value
        const selectedOption = f[selectedStrike]; // Use the strike value as the key
        //  console.log("Selected Option in handleSellClick:", selectedOption);

        const lotSizeForOrder = lotsProp;
        const side = selectedSide === "calls" ? "Call" : "Put";
        const price =
          selectedSide === "calls"
            ? symbolData[calls[selectedRowIndex]?.code]?.ltp || 0
            : symbolData[puts[selectedRowIndex]?.code]?.ltp || 0;
        const trigger_price =
          selectedSide === "calls"
            ? symbolData[calls[selectedRowIndex]?.code]?.ltp || 0
            : symbolData[puts[selectedRowIndex]?.code]?.ltp || 0;
        const instrumentName =
          side === "Call"
            ? selectedOption.call.instrument_name
            : selectedOption.put.instrument_name;
        const lot_size =
          side === "Call"
            ? selectedOption.call.lot_size
            : selectedOption.put.lot_size;
        const optionType = side === "Call" ? "CE" : "PE";
        const tradingSymbol =
          side === "Call"
            ? selectedOption.call.trading_symbol
            : selectedOption.put.trading_symbol;
        const expiryDate =
          side === "Call"
            ? selectedOption.call.expiry_date
            : selectedOption.put.expiry_date;

        const quantity =
          side === "Call"
            ? selectedOption.call.lot_size
            : selectedOption.put.lot_size;

        const strike =
          side === "Call"
            ? selectedOption.call.strike
            : selectedOption.put.strike;
        const code =
          side === "Call" ? selectedOption.call.code : selectedOption.put.code;

        const buy_sell = "S";
        const underlying_code = selectedSymbol.code;
        let freeze_qty =
          side === "Call"
            ? selectedOption.call.freeze_qty
            : selectedOption.put.freeze_qty;
        let exchange =
          side === "Call"
            ? selectedOption.call.exchange
            : selectedOption.put.exchange;
        let lot_multiplier = parseFloat(optionData?.lot_size);

        const dataObject = {
          side,
          instrumentName,
          lotSize: lotSizeForOrder,
          lot_size,
          optionType,
          tradingSymbol,
          expiryDate,
          code,
          price,
          trigger_price,
          buy_sell,
          underlying_code,
          strike,
          quantity,
          freeze_qty: freeze_qty,
          exchange: exchange,
          lot_multiplier: lot_multiplier,
          scrip: selectedSymbol.scrip,
        };
        addToBuySellModal(dataObject);
      }
    
  }


  const getRowColor = (strike, isCall) => {
    if (theme === "light") {
      const ltpValue = symbolData[selectedSymbol?.code]?.ltp;
      if ((isCall && strike < ltpValue) || (!isCall && strike > ltpValue)) {
        return isCall ? "#FFF9E5" : "#F4F5FF"; // Light yellow for ITM call options, Light blue for ITM put options
      } else {
        return "white"; // White for OTM and ATM options
      }
    } else {
      const ltpValue = symbolData[selectedSymbol?.code]?.ltp;
      if ((isCall && strike < ltpValue) || (!isCall && strike > ltpValue)) {
        return isCall ? "#29292E" : "#29292E";
      } else {
        return "transparent";
      }
    }
  };

  const getPutRowColor = (strike) => {
    const ltpValue = symbolData[selectedSymbol?.code]?.ltp;
    if (theme === "light") {
      if (strike > ltpValue) {
        return "#F4F5FF";
      } else {
        return "white";
      }
    } else {
      if (strike > ltpValue) {
        return "#29292E";
      } else {
        return "transparent";
      }
    }
  };

  // Formatting volume
  function formatVolume(volume) {
    if (volume >= 10000000) {
      return `${(volume / 10000000)?.toFixed(2)}Cr`;
    } else if (volume >= 100000) {
      return `${(volume / 100000)?.toFixed(2)}L`;
    } else if (volume >= 1000) {
      return `${(volume / 1000)?.toFixed(2)}K`;
    } else if (volume) {
      return volume % 1 === 0 ? volume?.toFixed(0) : volume?.toFixed(2);
    } else {
      if (isNaN(volume)) {
        return "0";
      }
      return `${volume}`;
    }
  }

  const [lots, setLots] = useState(1);

  return (
    <ExpiryDatesProvider>
      <div
        ref={containerRef}
        className="modal fade option-chain-modal"
        id="staticBackdropOptionChainModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-xl option-chain-modal-dialog">
          <div
            className="modal-content option-chain-modal-content"
            style={{ width: "108%", position: "absolute", left: "-5%" }}>
            <div className="modal-body d-flex flex-column">
              <div className="">
                <div className="symbol-info d-flex flex-column">
                  <div className="symbol-name d-flex justify-content-between">
                    <div>{selectedSymbol?.scrip}</div>
                    <button
                      type="button"
                      className={
                        theme === "dark"
                          ? "btn-close-white btn-close mb-2"
                          : "btn-close mb-2"
                      }
                      data-bs-dismiss="modal"
                      aria-label="Close"></button>
                  </div>
                  <div>
                    <span className="lp">
                      {symbolData[selectedSymbol?.code]?.ltp}
                    </span>
                    <span
                      className="ch-chp me-2"
                      style={{
                        color:
                          symbolData[selectedSymbol?.code]?.change > 0
                            ? "#5CA81D" // Green color for positive change
                            : "#DD6565", // Red color for negative change
                      }}>
                      {symbolData[selectedSymbol?.code]?.change > 0 ? "+" : ""}
                      {symbolData[selectedSymbol?.code]?.change}(
                      {symbolData[selectedSymbol?.code]?.change_per}%)
                    </span>
                  </div>
                </div>
                <ExpiryDates
                  selectedSymbol={selectedSymbol}
                  handleSymbolSelection={handleSymbolSelection}
                  expiryDays={expiryDays}
                  activeDate={activeDate}
                />
              </div>
              <div
                className="option-chain-table mt-3"
                style={{ fontSize: "14px" }}>
                <div className="calls-puts-header d-flex text-center border-bottom py-1 text-center">
                  <div
                    className="calls text-center fw-bold"
                    style={{ width: "50%" }}>
                    {"Calls"}
                  </div>
                  <div
                    className="puts text-center fw-bold"
                    style={{ width: "50%" }}>
                    {"Puts"}
                  </div>
                </div>

                <div className="table-wrapper table-wrapper-sc-modal-desktop">
                  <table className="table">
                    <tr className="text-center" style={{ height: "33px" }}>
                      {showCalls && (
                        <>
                          {/* Call header columns */}
                          {showOIBuilddup && (
                            <th
                              scope="col"
                              className="table-header-columns"
                              style={{ borderRight: "1px solid #DEE2E6" }}>
                              OI Buildup
                            </th>
                          )}
                          {showOIChng && (
                            <th
                              scope="col"
                              className="table-header-columns"
                              style={{ borderRight: "1px solid #DEE2E6" }}>
                              OI Chg %
                            </th>
                          )}
                          {showVega && (
                            <th
                              scope="col"
                              className="table-header-columns"
                              style={{ borderRight: "1px solid #DEE2E6" }}>
                              Vega
                            </th>
                          )}

                          {showGamma && (
                            <th
                              scope="col"
                              className="table-header-columns"
                              style={{ borderRight: "1px solid #DEE2E6" }}>
                              Gamma
                            </th>
                          )}
                          {showTheta && (
                            <th
                              scope="col"
                              className="table-header-columns"
                              style={{ borderRight: "1px solid #DEE2E6" }}>
                              Theta
                            </th>
                          )}
                          {showDelta && (
                            <th
                              scope="col"
                              className="table-header-columns"
                              style={{ borderRight: "1px solid #DEE2E6" }}>
                              Delta
                            </th>
                          )}
                          <th
                            scope="col"
                            className="table-header-columns"
                            style={{ borderRight: "1px solid #DEE2E6" }}>
                            Volume
                          </th>
                          <th
                            scope="col"
                            className="table-header-columns"
                            style={{ borderRight: "1px solid #DEE2E6" }}>
                            OI Chg %
                          </th>
                          <th
                            scope="col"
                            className="table-header-columns"
                            style={{
                              width: "235px",
                              borderRight: "1px solid #DEE2E6",
                              textAlign: "left",
                            }}>
                            OI
                          </th>
                          <th
                            scope="col"
                            className="table-header-columns"
                            style={{ borderRight: "1px solid #DEE2E6" }}>
                            LTP Chg
                          </th>
                          <th
                            scope="col"
                            className="table-header-columns"
                            style={{ borderRight: "1px solid #DEE2E6" }}>
                            LTP
                          </th>
                        </>
                      )}
                      <th scope="col" className="table-header-columns">
                        Strike
                      </th>
                      <th
                        scope="col"
                        className="table-header-columns"
                        style={{ borderRight: "1px solid #DEE2E6" }}>
                        IV
                      </th>
                      {showPuts && (
                        <>
                          {/* Put header columns */}
                          <th
                            scope="col"
                            className="table-header-columns"
                            style={{ borderRight: "1px solid #DEE2E6" }}>
                            LTP
                          </th>
                          <th
                            scope="col"
                            className="table-header-columns"
                            style={{ borderRight: "1px solid #DEE2E6" }}>
                            LTP Chg
                          </th>
                          <th
                            scope="col"
                            className="table-header-columns"
                            style={{
                              width: "243px",
                              borderRight: "1px solid #DEE2E6",
                              textAlign: "right",
                            }}>
                            OI
                          </th>
                          <th
                            scope="col"
                            className="table-header-columns"
                            style={{ borderRight: "1px solid #DEE2E6" }}>
                            OI Chg %
                          </th>
                          <th
                            scope="col"
                            className="table-header-columns"
                            style={{ borderRight: "1px solid #DEE2E6" }}>
                            Volume
                          </th>
                          {showDelta && (
                            <th
                              scope="col"
                              className="table-header-columns"
                              style={{ borderRight: "1px solid #DEE2E6" }}>
                              Delta
                            </th>
                          )}
                          {showTheta && (
                            <th
                              scope="col"
                              className="table-header-columns"
                              style={{ borderRight: "1px solid #DEE2E6" }}>
                              Theta
                            </th>
                          )}

                          {showGamma && (
                            <th
                              scope="col"
                              className="table-header-columns"
                              style={{ borderRight: "1px solid #DEE2E6" }}>
                              Gamma
                            </th>
                          )}
                          {showVega && (
                            <th scope="col" className="table-header-columns">
                              Vega
                            </th>
                          )}
                          {showOIChng && (
                            <th scope="col" className="table-header-columns">
                              OI Chg %
                            </th>
                          )}
                          {showOIBuilddup && (
                            <th scope="col" className="table-header-columns">
                              OI Buildup
                            </th>
                          )}
                        </>
                      )}
                    </tr>
                    <tbody className={theme === "dark" ? "table-dark" : ""}>
                      {/* Render Call rows */}
                      {calls &&
                        puts &&
                        calls.map((call, index) => (
                          <tr
                            //  key={call.instrument_name}
                            className="text-center"
                            onMouseEnter={() => handleRowMouseEnter(index)}
                            onMouseLeave={handleRowMouseLeave}>
                            {showCalls && (
                              <>
                                {showOIBuilddup && (
                                  <td
                                    style={{
                                      backgroundColor: getRowColor(
                                        call.strike,
                                        true
                                      ),
                                      borderRight: "1px solid #DEE2E6",
                                    }}>
                                    {/* OI Buildup value */}
                                  </td>
                                )}
                                {showOIChng && (
                                  <td
                                    style={{
                                      backgroundColor: getRowColor(
                                        call.strike,
                                        true
                                      ),
                                      borderRight: "1px solid #DEE2E6",
                                    }}>
                                    {/* OI Chg value */}
                                  </td>
                                )}
                                {showVega && (
                                  <td
                                    style={{
                                      backgroundColor: getRowColor(
                                        call.strike,
                                        true
                                      ),
                                      borderRight: "1px solid #DEE2E6",
                                    }}>
                                    {call["vega"]}
                                  </td>
                                )}
                                {showGamma && (
                                  <td
                                    style={{
                                      backgroundColor: getRowColor(
                                        call.strike,
                                        true
                                      ),
                                      borderRight: "1px solid #DEE2E6",
                                    }}>
                                    {call["gamma"]}
                                  </td>
                                )}
                                {showTheta && (
                                  <td
                                    style={{
                                      backgroundColor: getRowColor(
                                        call.strike,
                                        true
                                      ),
                                      borderRight: "1px solid #DEE2E6",
                                    }}>
                                    {call["theta"]}
                                  </td>
                                )}
                                {showDelta && (
                                  <td
                                    style={{
                                      backgroundColor: getRowColor(
                                        call.strike,
                                        true
                                      ),
                                      borderRight: "1px solid #DEE2E6",
                                    }}>
                                    {call["delta"]}
                                  </td>
                                )}
                                <td
                                  data-side="calls"
                                  style={{
                                    backgroundColor: getRowColor(
                                      call.strike,
                                      true
                                    ),
                                    borderRight: "1px solid #DEE2E6",
                                  }}
                                  // onClick={(event) =>
                                  //   handleRowClick(event, index, "calls")
                                  // }
                                >
                                  {formatVolume(
                                    symbolData[call.code]?.volume /
                                      symbolData[call.code]?.lot_size
                                  )}
                                </td>

                                <td
                                  data-side="calls"
                                  style={{
                                    backgroundColor: getRowColor(
                                      call.strike,
                                      true
                                    ),
                                    borderRight: "1px solid #DEE2E6",
                                    color:
                                      symbolData[call.code]?.oi -
                                        parseFloat(call?.previous_oi) >=
                                      0
                                        ? "#5CA81D"
                                        : "#DD6565",
                                  }}>
                                  {formatVolume(
                                    call?.previous_oi > 0 ?
                                    round(
                                      ((symbolData[call.code]?.oi -
                                        parseFloat(call?.previous_oi)) /
                                        parseFloat(call?.previous_oi)) *
                                        100,
                                      0
                                    ) : 0
                                  )}{" "}
                                  %
                                </td>
                                <td
                                  data-side="calls"
                                  style={{
                                    backgroundColor: getRowColor(
                                      call.strike,
                                      true
                                    ),
                                    borderRight: "1px solid #DEE2E6",
                                    position: "relative",
                                    height: "fixed",
                                    textAlign: "left",
                                  }}>
                                  {(selectedRowIndex !== null &&
                                    selectedRowIndex === index &&
                                    divVisibility) ||
                                  (callsDataCon &&
                                    callsDataCon[index] &&
                                    (callsDataCon[index].buyBtnState ||
                                      callsDataCon[index].sellBtnState)) ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        position: "relative",
                                      }}>
                                      <div
                                        style={{
                                          position: "relative",
                                          zIndex: "2",
                                          paddingLeft: "0px",
                                        }}>
                                        {formatVolume(
                                          symbolData[call.code]?.oi /
                                            symbolData[call.code]?.lot_size
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          zIndex: 2,
                                          position: "absolute",
                                          left: "63%",
                                          transform: "translateX(-50%)",
                                        }}
                                        onClick={(event) =>
                                          handleRowClick(event, index, "calls")
                                        }>
                                        <span
                                          className="flex justify-content-center align-items-center gap-1"
                                          style={{
                                            position: "relative",
                                            zIndex: "2",
                                          }}>
                                          {/* Your buttons */}
                                          <div className="number">
                                            <span className="lots-text">
                                              Lots
                                            </span>
                                            <input
                                              className="lots-input"
                                              type="number"
                                              value={
                                                callsDataCon[index].LotsSize
                                              }
                                              onChange={(e) =>
                                                handleLotSizeChange(
                                                  e.target.value,
                                                  index,
                                                  "calls"
                                                )
                                              }
                                            />
                                            <div className="inc-dec-div">
                                              <button
                                                className="plus-modal"
                                                onClick={() =>
                                                  incrementValue(index, "calls")
                                                }>
                                                +
                                              </button>
                                              <button
                                                className="minus-modal"
                                                onClick={() =>
                                                  decrementValue(index, "calls")
                                                }>
                                                -
                                              </button>
                                            </div>
                                          </div>
                                          <button
                                            // key={index}
                                            className={`buy-btn ${callsDataCon && callsDataCon[index] && callsDataCon[index].buyBtnState ? "active" : ""}`}
                                            onClick={() =>
                                              handleBuyClick(
                                                "Buy",
                                                index,
                                                callsDataCon[index].LotsSize,
                                                "calls"
                                              )
                                            }>
                                            B
                                          </button>
                                          <button
                                            //  key={index}
                                            className={`sell-btn ${callsDataCon && callsDataCon[index] && callsDataCon[index].sellBtnState ? "active" : ""}`}
                                            onClick={() =>
                                              handleSellClick(
                                                "Sell",
                                                index,
                                                callsDataCon[index].LotsSize,
                                                "calls"
                                              )
                                            }>
                                            S
                                          </button>
                                          {/* <div
                                            className="scalp-svg"
                                            type="button"
                                            class="open-buy-sell-modal-btn d-flex align-items-center justify-content-center"
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdropBuySell"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="22"
                                              height="22"
                                              viewBox="0 0 22 22"
                                              fill="red">
                                              <g clipPath="url(#clip0_509_803)">
                                                <path
                                                  d="M9.60272 7.3488C10.8408 6.29681 13.9006 4.89919 13.9006 4.89919C13.9006 4.89919 14.4877 8.2114 14.3248 9.8279C14.0615 12.4421 10.7132 15.2731 10.7132 15.2731L7.17158 13.4138C7.17158 13.4138 7.60044 9.05009 9.60272 7.3488Z"
                                                  fill="url(#paint0_linear_509_803)"
                                                />
                                                <path
                                                  d="M7.22105 14.7843C7.63788 14.3656 8.16292 13.9342 8.16292 13.9342L9.71691 14.7501C9.71691 14.7501 9.62195 15.4235 9.55201 16.0081C9.48206 16.5927 7.54791 16.9997 7.54791 16.9997C7.54791 16.9997 6.80421 15.203 7.22105 14.7843Z"
                                                  fill="url(#paint1_linear_509_803)"
                                                />
                                                <path
                                                  d="M10.7132 15.2731C10.7132 15.2731 14.0615 12.4421 14.3248 9.8279C14.4877 8.2114 13.9006 4.89919 13.9006 4.89919C13.9006 4.89919 10.8408 6.29681 9.60272 7.3488C7.60044 9.05009 7.17158 13.4138 7.17158 13.4138M10.7132 15.2731L7.17158 13.4138M10.7132 15.2731L13.6645 16.8226L13.4579 12.1964M7.17158 13.4138L4.22026 11.8643L8.14552 9.40736M9.6802 7.20124C9.6802 7.20124 10.2557 8.25634 11.7314 9.03106C13.207 9.80578 14.4023 9.68034 14.4023 9.68034M8.16292 13.9342C8.16292 13.9342 7.63788 14.3656 7.22105 14.7843C6.80421 15.203 7.54791 16.9997 7.54791 16.9997C7.54791 16.9997 9.48206 16.5927 9.55201 16.0081C9.62195 15.4235 9.71691 14.7501 9.71691 14.7501L8.16292 13.9342Z"
                                                  stroke="#14142B"
                                                />
                                              </g>
                                              <defs>
                                                <linearGradient
                                                  id="paint0_linear_509_803"
                                                  x1="13.9006"
                                                  y1="4.89919"
                                                  x2="7.54789"
                                                  y2="16.9996"
                                                  gradientUnits="userSpaceOnUse">
                                                  <stop stopColor="#FF3908" />
                                                  <stop
                                                    offset="1"
                                                    stopColor="#FF7834"
                                                  />
                                                </linearGradient>
                                                <linearGradient
                                                  id="paint1_linear_509_803"
                                                  x1="13.9006"
                                                  y1="4.89919"
                                                  x2="7.54789"
                                                  y2="16.9996"
                                                  gradientUnits="userSpaceOnUse">
                                                  <stop stopColor="#FF3908" />
                                                  <stop
                                                    offset="1"
                                                    stopColor="#FF7834"
                                                  />
                                                </linearGradient>
                                                <clipPath id="clip0_509_803">
                                                  <rect
                                                    width="16"
                                                    height="16"
                                                    fill="white"
                                                    transform="translate(7.43726) rotate(27.6994)"
                                                  />
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </div> */}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        position: "relative",
                                        zIndex: "2",
                                      }}>
                                      {formatVolume(
                                        symbolData[call.code]?.oi /
                                          symbolData[call.code]?.lot_size
                                      )}
                                    </div>
                                  )}
                                  {/* Progress div */}
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: "50%",
                                      right: 0,
                                      width: `${
                                        (symbolData[call.code]?.oi /
                                          symbolData[call.code]?.lot_size /
                                          maxVolume) *
                                        100
                                      }%`,
                                      height: "4px",
                                      backgroundColor: "#f88b9f",
                                      zIndex: 0,
                                    }}
                                  />
                                </td>
                                <td
                                  data-side="calls"
                                  style={{
                                    backgroundColor: getRowColor(
                                      call.strike,
                                      true
                                    ),
                                    borderRight: "1px solid #DEE2E6",
                                  }}>
                                  {symbolData[call.code] && (
                                    <span>
                                      {/* {symbolData[call.code].change} */}
                                      <span
                                        style={{
                                          color:
                                            symbolData[call.code].change > 0
                                              ? "#5CA81D"
                                              : "#DD6565",
                                        }}>
                                        ({symbolData[call.code].change_per}%)
                                      </span>
                                    </span>
                                  )}
                                </td>
                                <td
                                  data-side="calls"
                                  style={{
                                    backgroundColor: getRowColor(
                                      call.strike,
                                      true
                                    ),
                                    borderRight: "1px solid #DEE2E6",
                                  }}
                                  // onClick={(event) =>
                                  //   handleRowClick(event, index, "calls")
                                  // }
                                >
                                  {symbolData[call.code]?.ltp}
                                  {/* {buySellTagsCalls[index] === "buy" && (
                          <span className="tag buy-tag">1 buy</span>
                        )}
                        {buySellTagsCalls[index] === "sell" && (
                          <span className="tag sell-tag">1 sell</span>
                        )} */}
                                </td>
                              </>
                            )}
                            <td
                              className={`strike ${call.strike === maxPain ? "max-pain-row" : ""}`}
                              style={{
                                height: "fixed",
                                width: "fixed",
                                position: "relative",
                              }}>
                              {call.strike}
                              {call.strike === maxPain && (
                                <div className="d-flex align-items-center justify-content-center m-auto max-pain-label">
                                  Max Pain
                                </div>
                              )}
                            </td>

                            <td
                              className="strike"
                              style={{
                                height: "fixed",
                                width: "fixed",
                                position: "relative",
                              }}>
                              {call.iv && !isNaN(parseFloat(call.iv))
                                ? parseFloat(call.iv).toFixed(2)
                                : "N/A"}
                            </td>

                            {showPuts && (
                              <>
                                <td
                                  data-side="puts"
                                  style={{
                                    backgroundColor: getPutRowColor(
                                      puts[index].strike
                                    ),
                                    borderRight: "1px solid #DEE2E6",
                                  }}>
                                  {symbolData[puts[index].code]?.ltp}
                                </td>

                                <td
                                  data-side="puts"
                                  onClick={(event) =>
                                    handleRowClick(event, index, "puts")
                                  }
                                  style={{
                                    backgroundColor: getPutRowColor(
                                      puts[index].strike
                                    ),
                                    position: "relative",
                                    borderRight: "1px solid #DEE2E6",
                                  }}>
                                  {symbolData[puts[index].code] && (
                                    <span>
                                      {/* {symbolData[puts[index].code].change} */}
                                      <span
                                        style={{
                                          color:
                                            symbolData[puts[index].code]
                                              .change_per > 0
                                              ? "#5CA81D"
                                              : "#DD6565",
                                        }}>
                                        (
                                        {
                                          symbolData[puts[index].code]
                                            .change_per
                                        }
                                        % )
                                      </span>
                                    </span>
                                  )}
                                </td>
                                <td
                                  data-side="puts"
                                  style={{
                                    backgroundColor: getPutRowColor(
                                      puts[index].strike
                                    ),
                                    borderRight: "1px solid #DEE2E6",
                                    position: "relative",
                                    height: "fixed",
                                    textAlign: "right",
                                  }}>
                                  {(selectedRowIndex !== null &&
                                    selectedRowIndex === index &&
                                    divVisibility) ||
                                  (putsDataCon &&
                                    putsDataCon[index] &&
                                    (putsDataCon[index].buyBtnState ||
                                      putsDataCon[index].sellBtnState)) ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row-reverse",
                                        alignItems: "center",
                                        position: "relative",
                                      }}>
                                      <div
                                        style={{
                                          position: "relative",
                                          zIndex: "2",
                                          paddingRight: "0px",
                                        }}>
                                        {formatVolume(
                                          symbolData[puts[index].code]?.oi /
                                            symbolData[puts[index].code]
                                              ?.lot_size
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          position: "absolute",
                                          left: "39%", // Position the buttons at 50% from the left
                                          transform: "translateX(-50%)", // Center the buttons horizontally
                                          zIndex: "2",
                                        }}>
                                        <span className="flex gap-2">
                                          {/* <div
                                            className="scalp-svg"
                                            type="button"
                                            class="open-buy-sell-modal-btn"
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdropBuySell"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="22"
                                              height="22"
                                              viewBox="0 0 22 22"
                                              fill="red">
                                              <g clipPath="url(#clip0_509_803)">
                                                <path
                                                  d="M9.60272 7.3488C10.8408 6.29681 13.9006 4.89919 13.9006 4.89919C13.9006 4.89919 14.4877 8.2114 14.3248 9.8279C14.0615 12.4421 10.7132 15.2731 10.7132 15.2731L7.17158 13.4138C7.17158 13.4138 7.60044 9.05009 9.60272 7.3488Z"
                                                  fill="url(#paint0_linear_509_803)"
                                                />
                                                <path
                                                  d="M7.22105 14.7843C7.63788 14.3656 8.16292 13.9342 8.16292 13.9342L9.71691 14.7501C9.71691 14.7501 9.62195 15.4235 9.55201 16.0081C9.48206 16.5927 7.54791 16.9997 7.54791 16.9997C7.54791 16.9997 6.80421 15.203 7.22105 14.7843Z"
                                                  fill="url(#paint1_linear_509_803)"
                                                />
                                                <path
                                                  d="M10.7132 15.2731C10.7132 15.2731 14.0615 12.4421 14.3248 9.8279C14.4877 8.2114 13.9006 4.89919 13.9006 4.89919C13.9006 4.89919 10.8408 6.29681 9.60272 7.3488C7.60044 9.05009 7.17158 13.4138 7.17158 13.4138M10.7132 15.2731L7.17158 13.4138M10.7132 15.2731L13.6645 16.8226L13.4579 12.1964M7.17158 13.4138L4.22026 11.8643L8.14552 9.40736M9.6802 7.20124C9.6802 7.20124 10.2557 8.25634 11.7314 9.03106C13.207 9.80578 14.4023 9.68034 14.4023 9.68034M8.16292 13.9342C8.16292 13.9342 7.63788 14.3656 7.22105 14.7843C6.80421 15.203 7.54791 16.9997 7.54791 16.9997C7.54791 16.9997 9.48206 16.5927 9.55201 16.0081C9.62195 15.4235 9.71691 14.7501 9.71691 14.7501L8.16292 13.9342Z"
                                                  stroke="#14142B"
                                                />
                                              </g>
                                              <defs>
                                                <linearGradient
                                                  id="paint0_linear_509_803"
                                                  x1="13.9006"
                                                  y1="4.89919"
                                                  x2="7.54789"
                                                  y2="16.9996"
                                                  gradientUnits="userSpaceOnUse">
                                                  <stop stopColor="#FF3908" />
                                                  <stop
                                                    offset="1"
                                                    stopColor="#FF7834"
                                                  />
                                                </linearGradient>
                                                <linearGradient
                                                  id="paint1_linear_509_803"
                                                  x1="13.9006"
                                                  y1="4.89919"
                                                  x2="7.54789"
                                                  y2="16.9996"
                                                  gradientUnits="userSpaceOnUse">
                                                  <stop stopColor="#FF3908" />
                                                  <stop
                                                    offset="1"
                                                    stopColor="#FF7834"
                                                  />
                                                </linearGradient>
                                                <clipPath id="clip0_509_803">
                                                  <rect
                                                    width="16"
                                                    height="16"
                                                    fill="white"
                                                    transform="translate(7.43726) rotate(27.6994)"
                                                  />
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </div> */}
                                          <button
                                            // key={index}
                                            // className={`buy-btn  ${putsDataCon && putsDataCon[index].buyBtnState ? 'active' : ''}`}
                                            className={`buy-btn ${putsDataCon && putsDataCon[index] && putsDataCon[index].buyBtnState ? "active" : ""}`}
                                            onClick={() =>
                                              handleBuyClick(
                                                "Buy",
                                                index,
                                                putsDataCon[index].LotsSize,
                                                "puts"
                                              )
                                            }>
                                            B
                                          </button>
                                          <button
                                            // key={index}
                                            className={`sell-btn ${putsDataCon && putsDataCon[index] && putsDataCon[index].sellBtnState ? "active" : ""}`}
                                            onClick={() =>
                                              handleSellClick(
                                                "Sell",
                                                index,
                                                putsDataCon[index].LotsSize,
                                                "puts"
                                              )
                                            }>
                                            S
                                          </button>
                                          <div className="number">
                                            <span className="lots-text">
                                              Lots
                                            </span>
                                            <input
                                              className="lots-input"
                                              type="number"
                                              value={
                                                putsDataCon[index].LotsSize
                                              }
                                              onChange={(e) =>
                                                handleLotSizeChange(
                                                  e.target.value,
                                                  index,
                                                  "puts"
                                                )
                                              }
                                            />
                                            <div className="inc-dec-div">
                                              <button
                                                className="plus-modal"
                                                onClick={() =>
                                                  incrementValue(index, "puts")
                                                }>
                                                +
                                              </button>
                                              <button
                                                className="minus-modal"
                                                onClick={() =>
                                                  decrementValue(index, "puts")
                                                }>
                                                -
                                              </button>
                                            </div>
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        position: "relative",
                                        zIndex: "2",
                                      }}>
                                      {formatVolume(
                                        symbolData[puts[index].code]?.oi /
                                          symbolData[puts[index].code]?.lot_size
                                      )}
                                    </div>
                                  )}

                                  {/* Progress bar */}
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: "50%",
                                      left: 0,
                                      width: `${Math.min((symbolData[puts[index].code]?.oi / symbolData[puts[index].code]?.lot_size / maxPutVolume) * 100, 100)}%`,
                                      height: "5px", // Adjust height as needed
                                      backgroundColor: "#19C37D",
                                      zIndex: 0,
                                    }}
                                  />
                                </td>
                                <td
                                  data-side="puts"
                                  // onClick={(event) =>
                                  //   handleRowClick(event, index, "puts")
                                  // }
                                  style={{
                                    backgroundColor: getPutRowColor(
                                      puts[index].strike
                                    ),
                                    borderRight: "1px solid #DEE2E6",
                                    color:
                                      symbolData[puts[index].code]?.oi -
                                        parseFloat(puts[index]?.previous_oi) >=
                                      0
                                        ? "#5CA81D"
                                        : "#DD6565",
                                  }}>
                                  {formatVolume(
                                    puts[index]?.previous_oi > 0 ?
                                    round(
                                      ((symbolData[puts[index].code]?.oi -
                                        parseFloat(puts[index]?.previous_oi)) /
                                        parseFloat(puts[index]?.previous_oi)) *
                                        100,
                                      0
                                    ) : 0
                                  )}{" "}
                                  %
                                </td>

                                <td
                                  data-side="puts"
                                  // onClick={(event) =>
                                  //   handleRowClick(event, index, "puts")
                                  // }
                                  style={{
                                    backgroundColor: getPutRowColor(
                                      puts[index].strike
                                    ),
                                    borderRight: "1px solid #DEE2E6",
                                  }}>
                                  {formatVolume(
                                    symbolData[puts[index].code]?.volume /
                                      symbolData[puts[index].code]?.lot_size
                                  )}
                                </td>

                                {showDelta && (
                                  <td
                                    style={{
                                      backgroundColor: getPutRowColor(
                                        puts[index].strike
                                      ),
                                      borderRight: "1px solid #DEE2E6",
                                    }}>
                                    {puts[index]["delta"]}
                                  </td>
                                )}
                                {showTheta && (
                                  <td
                                    style={{
                                      backgroundColor: getPutRowColor(
                                        puts[index].strike
                                      ),
                                      borderRight: "1px solid #DEE2E6",
                                    }}>
                                    {puts[index]["theta"]}
                                  </td>
                                )}
                                {showGamma && (
                                  <td
                                    style={{
                                      backgroundColor: getPutRowColor(
                                        puts[index].strike
                                      ),
                                      borderRight: "1px solid #DEE2E6",
                                    }}>
                                    {puts[index]["gamma"]}
                                  </td>
                                )}
                                {showVega && (
                                  <td
                                    style={{
                                      backgroundColor: getPutRowColor(
                                        puts[index].strike
                                      ),
                                    }}>
                                    {puts[index]["vega"]}
                                  </td>
                                )}
                                {showOIChng && (
                                  <td
                                    style={{
                                      backgroundColor: getPutRowColor(
                                        puts[index].strike
                                      ),
                                      borderRight: "1px solid #DEE2E6",
                                    }}>
                                    {/* OI Chg value */}
                                  </td>
                                )}
                                {showOIBuilddup && (
                                  <td
                                    style={{
                                      backgroundColor: getPutRowColor(
                                        puts[index].strike
                                      ),
                                      borderRight: "1px solid #DEE2E6",
                                    }}>
                                    {/* OI Buildup value */}
                                  </td>
                                )}
                              </>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ExpiryDatesProvider>
  );
};

export default OptionChainModal;
