import React, { useEffect, useState, useContext, useRef } from "react";
import StrategyMenu from "../components/StrategyMenu";
import { useBasketContext } from "../context/BasketContext";
import { useGraphContext } from "../context/GraphContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToastContent from "../components/CustomToastContent";
import SuccessIcon from "../images/success_icon.svg";
import ErrorIcon from "../images/error_icon.svg";
import { useFundsData } from "../context/FundsDataContext";
import { ButtonContext } from "../context/ButtonsContext";
import Modal from "../modals/CommonModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../context/ThemeContext";
import { Tooltip as ReactTooltip } from "react-tooltip";
import QuestionMark from "../images/QuestionMark";
import Draggable from "react-draggable";
import { BROKER_NAME } from "../utils/constants";

const BasketModal = () => {
  const { theme } = useTheme();
  const [isBuy, setIsBuy] = useState(true);
  const [isCALL, setIsCALL] = useState(true);
  const {
    basketData,
    removeFromBasket,
    buySellStatus,
    toggleBuySellStatus,
    handleIncrement,
    handleDecrement,
    clearBasket,
    handlePriceChange,
    handleInputBlur,
  } = useBasketContext();
  const { maxProfit, maxLoss, riskReward, breakevenRange, premium } =
    useGraphContext();
  const { fundsData } = useFundsData();
  const broker = localStorage.getItem("broker");
  const access_token = localStorage.getItem("access_token");
  const client_id = localStorage.getItem("client_id");
  const [activeOption, setActiveOption] = useState("intraday");
  const { isLive, isVirtual } = useContext(ButtonContext);
  const [activeOptions, setActiveOptions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setActiveOptions(Array(basketData.length).fill("intraday"));
  }, [basketData]);

  const handleToastToNavigate = (response) => {
    navigate(response.page);
  };

  const handleOptionChange = (index, option) => {
    setActiveOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = option;
      return updatedOptions;
    });
  };

  const [checkboxStates, setCheckboxStates] = useState(
    Array(basketData.length).fill(true)
  );

  const [StopLosses, setStopLoses] = useState(
    Array(basketData.length).fill("")
  );

  const [Targets, setTargets] = useState(Array(basketData.length).fill(""));

  const handleCheckboxChange = (index) => {
    setCheckboxStates((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index] = !updatedStates[index];
      return updatedStates;
    });
  };
  const HandelSLChange = (index, value) => {
    setStopLoses((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index] = value;
      return updatedStates;
    });
  };

  const HandelTargetChange = (index, value) => {
    setTargets((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index] = value;
      return updatedStates;
    });
  };

  const HandelDeleteindex = (index) => {
    setTargets((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates.splice(index, 1);
      return updatedStates;
    });
    setStopLoses((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates.splice(index, 1);
      console.log(updatedStates, index);
      return updatedStates;
    });
  };

  const handleExecuteOrder = async () => {
    try {
      // Clear the basketData array by removing all items
      clearBasket();
      const payload = generateOrderPayload();
      const response = await sendOrder(payload);
      // console.log("response", response);
      if (response.status) {
        toast.success(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-success-toast",
            icon: <img src={SuccessIcon} alt="Success Icon" />,
            onClick: () => handleToastToNavigate(response),
          }
        );
      } else {
        toast.error(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-error-toast",
            icon: <img src={ErrorIcon} alt="Error Icon" />,
          }
        );
      }
    } catch (error) {
      console.error("Error executing order:", error);
      toast.error(
        <CustomToastContent
          heading="Error executing order"
          message={error.message}
        />,
        {
          className: "custom-error-toast",
          icon: <img src={ErrorIcon} alt="Error Icon" />,
        }
      );
    }
  };

  const handleExecuteVirtualOrder = async () => {
    try {
      // Clear the basketData array by removing all items
      clearBasket();

      const payload = generateVirtualOrderPayload();
      const response = await sendVirtualOrder(payload);
      if (response.status) {
        toast.success(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-success-toast",
            icon: <img src={SuccessIcon} alt="Success Icon" />,
            onClick: () => handleToastToNavigate(response),
          }
        );
      } else {
        toast.error(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-error-toast",
            icon: <img src={ErrorIcon} alt="Error Icon" />,
          }
        );
      }
    } catch (error) {
      console.error("Error executing virtual order:", error);
      toast.error(
        <CustomToastContent
          heading="Error executing virtual order"
          message={error.message}
        />,
        {
          className: "custom-error-toast",
          icon: <img src={ErrorIcon} alt="Error Icon" />,
        }
      );
    }
  };

  // api calls
  const [orderPayload, setOrderPayload] = useState(null);
  const [marginRequired, setMarginRequired] = useState(null);
  const [charges, setCharges] = useState(null);

  const [isOrderPlaced, setIsOrderPlaced] = useState(false); // State to track if order is successfully placed

  // Function to generate order payload
  const generateOrderPayload = () => {
    const orderList = basketData.map((currData, ind) => ({
      isBuy: currData.buy_sell === "B" ? true : false,
      isCall: currData.side === "Call" ? true : false,
      isFut: false,
      code: currData.code,
      exchange: currData.exchange,
      productType: activeOptions[ind] === "normal" ? "NRML" : "MIS",
      orderType: !checkboxStates[ind] ? "MARKET" : "LIMIT",
      quantity: currData.lotSize * currData.lot_multiplier,
      price: currData.price,
      freeze_qty: currData.freeze_qty,
      strike: currData.strike,
      expiry: currData.expiryDate,
      tradingSymbol: currData.tradingSymbol,
      lot_multiplier: currData.lot_multiplier,
      scrip: currData.scrip,
      expiry: currData.expiryDate,
      stoploss_price:
        StopLosses[ind] || StopLosses[ind] !== "" ? StopLosses[ind] : null,
      target_price: Targets[ind] || Targets[ind] !== "" ? Targets[ind] : null,
    }));
    return {
      order_list: orderList,
      broker: broker,
      access_token: access_token,
      client_id: client_id,
    };
  };

  const generateVirtualOrderPayload = () => {
    const orderList = basketData.map((currData, ind) => ({
      isBuy: currData.buy_sell === "B" ? true : false,
      isCall: currData.side === "Call" ? true : false,
      isFut: false,
      code: currData.code,
      exchange: currData.exchange,
      productType: activeOptions[ind] === "normal" ? "NRML" : "MIS",
      orderType: !checkboxStates[ind] ? "MARKET" : "LIMIT",
      quantity: currData.lotSize * currData.lot_multiplier,
      price: currData.price,
      freeze_qty: currData.freeze_qty,
      strike: currData.strike,
      expiry: currData.expiry,
      tradingSymbol: currData.tradingSymbol,
      lot_multiplier: currData.lot_multiplier,
      scrip: currData.scrip,
      expiry: currData.expiryDate,
      stoploss_price:
        StopLosses[ind] || StopLosses[ind] !== "" ? StopLosses[ind] : null,
      target_price: Targets[ind] || Targets[ind] !== "" ? Targets[ind] : null,
    }));
    return {
      order_list: orderList,
      broker: "virtual",
      main_broker: broker,
      access_token: access_token,
      client_id: client_id,
    };
  };

  const sendVirtualOrder = async (payload) => {
    try {
      const response = await axios.post(
        "https://hgb1gw5n6g.execute-api.ap-south-1.amazonaws.com/default/og_virtual_placeorder",
        payload
      );
      return response.data;
    } catch (error) {
      console.error("Error placing order:", error);
      return {
        status: false,
        message_header: "Error placing order",
        message_body: error.message,
      };
    }
  };

  const sendOrder = async (payload) => {
    try {
      const response = await axios.post(
        "https://0eo1dv23p5.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_placeorder",
        payload
      );
      console.log("Order placed successfully:", response.data);
      setIsOrderPlaced(true);
      return response.data;
    } catch (error) {
      console.error("Error placing order:", error);
      return {
        status: false,
        message_header: "Error placing order",
        message_body: error.message,
      };
    }
  };

  // Effect to fetch margin required
  useEffect(() => {
    const generateMarginPayload = () => {
      // debugger
      const orderList = basketData.map((currData, ind) => ({
        isBuy: currData.buy_sell === "B" ? true : false,
        isCall: currData.side === "Call" ? true : false,
        isFut: false,
        code: currData.code,
        exchange: currData.exchange,
        productType: activeOptions[ind] === "normal" ? "NRML" : "MIS",
        orderType: !checkboxStates[ind] ? "MARKET" : "LIMIT",
        quantity: currData.lotSize * currData.lot_multiplier,
        price: currData.price,
        strike: currData.strike,
        expiry: currData.expiryDate,
        tradingSymbol: currData.tradingSymbol,
        lot_multiplier: currData.lot_multiplier,
        scrip: currData.scrip,
      }));
      return {
        order_list: orderList,
        broker: broker,
        access_token: access_token,
        client_id: client_id,
      };
    };

    const fetchMarginRequired = async () => {
      try {
        const payload = generateMarginPayload();

        const response = await axios.post(
          "https://7mwh6csmrh.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_margin",
          payload
        );
        setMarginRequired(response.data.marginRequired);
      } catch (error) {
        console.error("Error getting margin required:", error);
      }
    };
    const fetchCharges = async () => {
      try {
        const payload = await generateMarginPayload();
        const response = await axios.post(
          "https://pc933vy3c6.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_charges",
          payload
        );
        setCharges(response.data.total_charges);
      } catch (error) {
        console.error("Error getting charges required:", error);
      }
    };

    if (basketData.length > 0) {
      fetchMarginRequired();
      fetchCharges();
    }
  }, [basketData]);
  function formatNumberWithCommasAndFontWeight(value) {
    const formattedValue = parseFloat(value).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue.split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1];
    if (isNaN(value)) {
      return "-";
    }

    return (
      <span>
        {integerPart}.<span style={{ fontWeight: 400 }}>{decimalPart}</span>
      </span>
    );
  }
  return (
    <div
      class="modal fade basket-modal"
      id="staticBackdropBasket"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content ">
          <div class="modal-header px-0">
            <div className=" d-flex justify-content-start align-items-center px-3 gap-4 w-100">
              <div className="d-flex flex-column">
                <span className="creator-bottom-title">Max profit</span>
                <span className="strategy-menu-value text-success">
                  {maxProfit}
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="creator-bottom-title">Max loss </span>
                <span className="strategy-menu-value text-danger">
                  {maxLoss}
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="creator-bottom-title">Risk reward </span>
                <span className="strategy-menu-value">{riskReward}</span>
              </div>
              {/* <div className="d-flex flex-column">
                <span className="creator-bottom-title">Probability</span>
                <span className="strategy-menu-value text-danger">32.50%</span>
              </div> */}
              <div className="d-flex flex-column">
                <span className="creator-bottom-title">Breakeven between</span>
                <span className="strategy-menu-value">{breakevenRange}</span>
              </div>
            </div>
            <button
              type="button"
              className={
                theme === "dark" ? "btn-close-white btn-close" : "btn-close"
              }
              data-bs-dismiss="modal"
              style={{ margin: "0px" }}
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body px-0 ">
            <div className="table-container">
              <table className="strategy-tablee">
                <thead>
                  <tr className="text-center tr-strategy-headerr">
                    <th scope="col" className="text-start ps-3 py-2">
                      Instruments
                    </th>
                    <th scope="col">B/S</th>
                    <th scope="col">Lots</th>
                    <th scope="col">Product type</th>
                    <th scope="col">Market/Limit</th>
                    <th scope="col"></th>
                    <th scope="col" className="pe-3"></th>
                    <th scope="col" className="pe-3"></th>
                  </tr>
                </thead>
                <tbody className="tbody">
                  {basketData.map((item, index) => (
                    <tr
                      key={index}
                      className="strategy-table-roww text-center border-bottom"
                    >
                      <td className="basket-table-cell text-start ps-3">
                        <div className="d-flex align-items-center">
                          {item.instrumentName}
                        </div>
                      </td>

                      <td className="basket-table-cell ps-3 text-center">
                        <div
                          className={`bs-value d-flex align-items-center justify-content-center ${
                            item["buy_sell"] === "B" ? "buy" : "sell"
                          }`}
                          style={{
                            height: "30px",
                            width: "30px",
                            borderRadius: "4px",

                            background:
                              item["buy_sell"] === "B" ? "#70D11F" : "#FF3908",
                            color: "white",
                          }}
                          onClick={() => toggleBuySellStatus(index)}
                          role="button"
                        >
                          {item["buy_sell"] === "B" ? "B" : "S"}
                        </div>
                      </td>

                      <td className="basket-table-cell">
                        <div className="strategy-lots d-flex align-items-center justify-content-between px-2">
                          <span
                            role="button"
                            onClick={() => handleDecrement(index)}
                            style={{
                              color: "#C1C1C1",
                              fontSize: "20px",
                              fontWeight: "500",
                              lineHeight: "0px",
                            }}
                          >
                            -
                          </span>
                          <span>{item?.lotSize}</span>
                          <span
                            role="button"
                            className="plus"
                            onClick={() => handleIncrement(index)}
                            style={{
                              color: "#C1C1C1",
                              fontSize: "20px",
                              fontWeight: "500",
                              lineHeight: "0px",
                            }}
                          >
                            +
                          </span>
                        </div>
                      </td>

                      <td className="basket-table-cell">
                        {" "}
                        <div className="toggle-container">
                          <button
                            className={`toggle-button ${
                              activeOptions[index] === "intraday"
                                ? "active"
                                : ""
                            }`}
                            onClick={() =>
                              handleOptionChange(index, "intraday")
                            }
                          >
                            Intraday
                          </button>
                          <button
                            className={`toggle-button ${
                              activeOptions[index] === "normal" ? "active" : ""
                            }`}
                            onClick={() => handleOptionChange(index, "normal")}
                          >
                            Normal
                          </button>
                        </div>
                      </td>
                      <td className="basket-table-cell">
                        <div className="price d-flex align-items-center justify-content-between px-2">
                          <input
                            type="checkbox"
                            checked={!checkboxStates[index]}
                            onChange={() => handleCheckboxChange(index)}
                          />
                          {!checkboxStates[index] ? (
                            <div className="market">MARKET</div>
                          ) : (
                            <input
                              type="text"
                              value={item.price}
                              className="price-input pe-1"
                              onChange={(e) => handlePriceChange(e, index)}
                              onBlur={() => handleInputBlur(index)}
                            />
                          )}
                        </div>
                      </td>
                      {/* stop loss and add target */}
                      <td className="basket-table-cell">
                        <button
                          className="price d-flex align-items-center justify-content-center px-2"
                          onClick={
                            StopLosses[index] === "" || !StopLosses[index]
                              ? () => {
                                  HandelSLChange(index, item.price);
                                }
                              : null
                          }
                        >
                          {StopLosses[index] === "" || !StopLosses[index] ? (
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              Add SL
                            </div>
                          ) : (
                            <input
                              type="text"
                              value={
                                StopLosses[index] === "false"
                                  ? ""
                                  : StopLosses[index]
                              }
                              className="price-input pe-1"
                              onChange={(e) => {
                                e.target.value
                                  ? HandelSLChange(index, e.target.value)
                                  : HandelSLChange(index, "false");
                              }}
                              onBlur={(e) => {
                                HandelSLChange(index, e.target.value);
                              }} // Add onChange handler
                              id={`SL${index}`}
                            />
                          )}
                        </button>
                      </td>

                      {/* add targets */}
                      <td className="basket-table-cell">
                        <button
                          className="price d-flex align-items-center justify-content-center px-2"
                          onClick={
                            Targets[index] === "" || !Targets[index]
                              ? () => {
                                  HandelTargetChange(index, item.price);
                                }
                              : null
                          }
                        >
                          {Targets[index] === "" || !Targets[index] ? (
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              Add Target
                            </div>
                          ) : (
                            <input
                              type="text"
                              value={
                                Targets[index] === "false" ? "" : Targets[index]
                              }
                              className="price-input pe-1"
                              onChange={(e) => {
                                e.target.value
                                  ? HandelTargetChange(index, e.target.value)
                                  : HandelTargetChange(index, "false");
                              }}
                              onBlur={(e) => {
                                HandelTargetChange(index, e.target.value);
                              }} // Add onChange hand}ler
                              id={`Target${index}`}
                            />
                          )}
                        </button>
                      </td>
                      <td className="basket-table-action basket-table-cell gap-4">
                        {/* <div className="add-target d-flex align-items-center justify-content-center px-2">
                          Add SL
                        </div>
                        <div className="add-target d-flex align-items-center justify-content-center px-2">
                          Add target
                        </div> */}
                        <div className="d-flex align-items-center justify-content-center">
                          <svg
                            role="button"
                            onClick={() => {
                              HandelDeleteindex(index);
                              removeFromBasket(index);
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none">
                            <path
                              d="M12.6364 3.90909V3.18182C12.6364 1.97683 11.6595 1 10.4545 1H7.54545C6.34047 1 5.36364 1.97683 5.36364 3.18182V3.90909M1 3.90909H17M11.1818 8.27273V11.9091M6.81818 8.27273V11.9091M2.45455 3.90909H15.5455L15.0636 13.5452C14.9669 15.4805 13.3695 17 11.4318 17H6.56818C4.63045 17 3.03312 15.4805 2.93635 13.5452L2.45455 3.90909Z"
                              stroke="#C1C1C1"
                              strokeLinecap="round"
                            />
                          </svg>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer justify-content-between flex-column">
            <div className="creator-bottom d-flex justify-content-between align-items-center px-2 mb-2 w-100">
              <div className="d-flex flex-column">
                <span className="creator-bottom-title">Margin available</span>
                <span className="creator-bottom-value">
                  ₹{" "}
                  {formatNumberWithCommasAndFontWeight(
                    fundsData.marginAvailable
                  )}
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="creator-bottom-title">
                  Estmtd margin{" "}
                  <span data-tooltip-id="estmtd-margin">
                    <QuestionMark />
                  </span>
                </span>
                <span className="creator-bottom-value">
                  {" "}
                  {formatNumberWithCommasAndFontWeight(marginRequired)}
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="creator-bottom-title">
                  Charges{" "}
                  <span data-tooltip-id="charges">
                    <QuestionMark />
                  </span>
                </span>
                <span className="creator-bottom-value">
                  ₹ {formatNumberWithCommasAndFontWeight(charges)}
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="creator-bottom-title">
                  Premium{" "}
                  <span data-tooltip-id="premium">
                    <QuestionMark />
                  </span>
                </span>
                <span className="creator-bottom-value">
                  ₹ {formatNumberWithCommasAndFontWeight(premium)}
                </span>
              </div>
            </div>
            <div className="d-flex gap-3 ms-auto">
              {BROKER_NAME === "gmail" ? (
                <button
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="virtual-button bg-transparent"
                  onClick={handleExecuteVirtualOrder}>
                  Add draft position
                </button>
              ) : (
                ""
              )}
              {isLive ? (
                <button
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="basket-order-btn"
                  onClick={handleExecuteOrder}>
                  Execute order
                </button>
              ) : (
                <>
                  <button
                    className="execute-order-not-btn"
                    onClick={() => setOpenModal(!openModal)}>
                    Execute Order
                  </button>
                  <Modal
                    isOpen={openModal}
                    onClose={() => setOpenModal(!openModal)}>
                    <p>Enable Live Trades</p>
                  </Modal>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <ReactTooltip
        id="estmtd-margin"
        place="top"
        variant={theme === "dark" ? "light" : "dark"}
        content="Margin estimate to execute the trade"
      />
      <ReactTooltip
        id="charges"
        place="top"
        variant={theme === "dark" ? "light" : "dark"}
        content="Charges estimate that may incur on live trade"
      />
      <ReactTooltip
        id="premium"
        place="top"
        variant={theme === "dark" ? "light" : "dark"}
        content="Negative premium indicates premium paid and positive indicates premium received"
      />
    </div>
  );
};

export default BasketModal;
