import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import FiiDiiDesktop from "./FiiDiiDesktop";
import FiiDiiMobile from "./FiiDiiMobile";

const FiiDii = () => {
  
  const [isMobile, setIsMobileScreen] = useState(window.innerWidth <= 430);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth <= 430);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile ? (
    <>
      <Helmet>
        <title>FiiDii</title>
      </Helmet>
      <div className="mobile-screen d-grid" style={{ gridColumn: "span 4" }}>
        <FiiDiiMobile />
      </div>
    </>
  ) : (
    <>
      <Helmet>
        <title>FiiDii</title>
      </Helmet>

      <div className="desktop-screenn">
        <FiiDiiDesktop />
      </div>
    </>
  );
};

export default FiiDii;
