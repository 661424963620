import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";



const FiiTableComponent = ({ dateRange }) => { 
  const [fiiData, setFiiData] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [rowSpanData, setRowSpanData] = useState({});


  useEffect(() => {
    const fetchApi = async () => {
      try {
        const response = await axios.post('https://9vicvi691m.execute-api.ap-south-1.amazonaws.com/default/returnFiiDii', {
          path: "fiidii_data",
          date: `${dateRange}`
        });
        const sortedData = response.data.fiidii_data?.sort((a, b) => a.participant.localeCompare(b.participant));
        setFiiData(sortedData || []);
      } catch (error) {
        console.log("Error in fetching data for FIIDII", error);
        setFiiData([]);  
      }
    };

    fetchApi();
  }, [dateRange]);

  const toggleRow = (index) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [index]: !prevExpandedRows[index]
    }));
  };

  const calculateMaxTrend = (data) => {
    if (data.length === 0) return 0;
    const positiveMax = Math.max(...data.filter(item => item.Value >= 0).map(item => item.Value));
    const negativeMax = Math.min(...data.filter(item => item.Value < 0).map(item => item.Value));
    return Math.max(Math.abs(positiveMax), Math.abs(negativeMax));
  };

  const handleNormalRowSpan = (data, fiiData) => {
    if (!rowSpanData[data.participant]) {
      let answer = fiiData.filter(item => item.participant === data.participant).length;
      fiiData.forEach(item => {
        if (item.participant === data.participant) {
          if (item.child_data && item.child_data.length > 0) {
            answer += item.child_data.length;
          }
        }
      });  
      setRowSpanData(prevState => ({ ...prevState, [data.participant]: answer }));
      return answer;
    }
    return rowSpanData[data.participant];
  };

  return (
    <table border="1" cellPadding="10" cellSpacing="0" style={{ borderCollapse: 'collapse', width: '100%' }} className='table-fiidii'>
      <thead className='fii-header-table'>
        <tr className='table-fii-tr' key={1}>
          <th  style={{ width: window.innerWidth <= 480 ? '0%' : '8%', fontSize: '14px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', borderRight: '1px solid #C1C1C1' }}>Participant</th>
          <th style={{ width: '12%', fontSize: '14px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', textAlign: "start", borderRight: '1px solid #C1C1C1' }}>Segment</th>
          <th style={{ width: '30%',  fontSize: '14px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', borderRight: '1px solid #C1C1C1' }}>Trends</th>
          <th style={{ width: '10%', fontSize: '14px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal' }}>Change (In Crores)</th>
        </tr>
      </thead>
      <tbody>
        {fiiData.map((data, index) => {
          const isExpanded = expandedRows[index];
          const parentMaxTrend = calculateMaxTrend(fiiData || []);
          return (
            <React.Fragment> 
              <tr 
              className='fii-texts'
                style={{ 
                  borderBottom: index === fiiData.length - 1 ? 'none' : '1px solid #C1C1C1', 
                  cursor: data.child_data && data.child_data.length > 0 ? 'pointer' : 'default' 
                }}
                key={index}
                onClick={() => data.child_data && data.child_data.length > 0 && toggleRow(index)}
              >
                {(index === 0 || fiiData[index - 1].participant !== data.participant) && (
                  <td 
                    rowSpan={handleNormalRowSpan(data, fiiData)}
                    style={{ verticalAlign: 'top', borderRight: '1px solid #C1C1C1' }}
                  >
                    {data.participant}
                  </td>
                 )}  
                <td style={{ borderRight: '1px solid #C1C1C1', width: 'fit-content' }}>
                  {data.Name} {data.child_data && data.child_data.length > 0 && (isExpanded ? <FaChevronUp /> : <FaChevronDown />)}
                </td>
                <td style={{ borderRight: '1px solid #C1C1C1' ,height:'100%', width: '100%', display: 'flex',alignItems:'center'  }}>
                  {data.Value >= 0 ? (
                    <>
                      <div style={{ width: '50%', height: '10px' }}></div>
                      <div style={{ width: `${Math.abs(Number(data.Value)) / parentMaxTrend * 50}%`, background: '#5CA81D', height: '20px', borderRadius: '0px 4px 4px 0px' }}></div>
                    </>
                  ) : (
                    <>
                      <div style={{ width: `${Math.abs(Number(data.Value)) / parentMaxTrend * 50}%`, background: '#DD4B27', height: '20px', marginLeft: `${50 - (Math.abs(Number(data.Value)) / parentMaxTrend * 50)}%`, borderRadius: '4px 0px 0px 4px' }}></div>
                      <div style={{ width: '50%' }}></div>
                    </>
                  )}
                </td>
                <td style={{ margin: '0', paddingRight: '10px', textAlign: 'left', width: 'fit-content' }}>
                  {data.Value}
                </td>
              </tr>
              {isExpanded && data.child_data && data.child_data.length > 0  && (
                data.child_data.map((child, childIndex) => {
                  const childMaxTrend = calculateMaxTrend(data.child_data || []);
                  return (
                    <tr key={childIndex} className='fii-texts'>
                      <td style={{ borderRight: '1px solid #C1C1C1', borderBottom: '1px solid #C1C1C1' }}>{child.Name}</td>
                      <td style={{ borderRight: '1px solid #C1C1C1', borderBottom: '1px solid #C1C1C1', width: '100%',height:'100%', display: 'flex',alignItems:'center' }}>
                        {child.Value >= 0 ? (
                          <>
                            <div style={{ width: '50%', height: '10px' }}></div>
                            <div style={{ width: `${Math.abs(Number(child.Value)) / childMaxTrend * 50}%`, background: '#5CA81D', height: '20px', borderRadius: '0px 4px 4px 0px' }}></div>
                          </>
                        ) : (
                          <>
                            <div style={{ width: `${Math.abs(Number(child.Value)) / childMaxTrend * 50}%`, background: '#DD4B27', height: '20px', marginLeft: `${50 - (Math.abs(Number(child.Value)) / childMaxTrend * 50)}%`, borderRadius: '4px 0px 0px 4px' }}></div>
                            <div style={{ width: '50%' }}></div>
                          </>
                        )}
                      </td>
                      <td style={{ margin: '0', paddingRight: '10px', textAlign: 'left', width: 'fit-content', borderBottom: '1px solid #C1C1C1' }}>
                        {child.Value}
                      </td>
                    </tr>
                  );
                })
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default FiiTableComponent;
