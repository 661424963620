import React, { createContext, useContext, useState } from "react";

const GraphContext = createContext();

export const GraphProvider = ({ children }) => {
  const [x_axis, setxAxis] = useState([]);
  const [y_axis, setyAxis] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [maxProfit, setMaxProfit] = useState(0);
  const [maxLoss, setMaxLoss] = useState(0);
  const [riskReward, setRiskReward] = useState(0);
  const [breakevenRange, setBreakeven] = useState(0);
  const [totalGreeks, setTotalGreeks] = useState({});
  const [strategyDirection, setStrategyDirection] = useState('Bullish strategy');
  const [premium, setPremium] = useState(0);
  const [greenRange, setGreenRange] = useState([]);
  
  return (
    <GraphContext.Provider
      value={{
        x_axis,
        setxAxis,
        y_axis,
        setyAxis,
        graphData,
        setGraphData,
        maxProfit,
        setMaxProfit,
        maxLoss,
        setMaxLoss,
        riskReward,
        setRiskReward,
        breakevenRange, 
        setBreakeven,
        totalGreeks,
        setTotalGreeks,
        strategyDirection,
        setStrategyDirection,
        premium,
        setPremium,
        greenRange,
        setGreenRange,
      }}>
      {children}
    </GraphContext.Provider>
  );
};

export const useGraphContext = () => {
  return useContext(GraphContext);
};
