import React, { createContext, useState, useContext } from 'react';


export const ButtonContext = createContext();


export const ButtonProvider = ({ children }) => {
  const [isLive, setIsLive] = useState(true);
  const [isVirtual, setIsVirtual] = useState(false);

  const toggleLive = () =>setIsLive(!isLive);
  const toggleVirtual = () => setIsVirtual(!isVirtual);

  return (
    <ButtonContext.Provider
      value={{
        isLive,
        setIsLive,
        isVirtual,
        setIsVirtual,
        toggleLive,
        toggleVirtual
      }}
    >
      {children}
    </ButtonContext.Provider>
  );
};