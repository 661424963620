import axios from "axios";
import { useContext, useEffect, useMemo } from "react";
import WebsocketWrapperContext from "../../context/WebsocketWrapperContext";
import MenuModal from "../../modals/MenuModal";

const HeaderLogic = () => {
  const { checkWebsocketConnection, subscribeToSymbols, symbolData } =
    useContext(WebsocketWrapperContext);
  const symbols = useMemo(() => [26000, 26009, 26017], []);


  useEffect(() => {
    const connectionEstablised = checkWebsocketConnection();
    if (connectionEstablised) {
      subscribeToSymbols(symbols, "NSE");
    }
  }, [symbolData]);

  return {
    symbolData,
  };
};

export default HeaderLogic;
