import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FiiTableComponent from './FiiTableComponent';

const FiiDiiDesktop = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [fiiDateRange, setFiiDateRange] = useState([]);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState(null);

  const handleSelectChange = (event) => {
    setDateRange(event.target.value);
  };

  useEffect(() => {
    const fetchApiData = async () => {
      try {
        const response = await axios.post('https://9vicvi691m.execute-api.ap-south-1.amazonaws.com/default/returnFiiDii', {
          path: "date_range"
        });
        setFiiDateRange(response.data.date_range);
        setDateRange(response.data.date_range[0])
      } catch (error) {
        console.error('Error in FII data API', error);
        setError(error);
      }
    };

    fetchApiData();
  }, []);

  return (
    <div className='fiidii-desktop'>
      <div className='top-heading-fii-deskotp'>
        <h2 style={{ fontSize: '20px', fontStyle: 'normal', fontWeight: '700' }}>FII / DII Data</h2>
        <div className='date_range'>
            <select name="date" value={dateRange} onChange={handleSelectChange} className='date_range_select'>
              {fiiDateRange?.map((date, index) => (
                <option value={date} key={index}>{date}</option>
              ))}
            </select>
        </div>
      </div>
      <FiiTableComponent dateRange={dateRange} />
    </div>
  );
};

export default FiiDiiDesktop;
