import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import StrategyCreatorDesktop from "./StrategyCreatorDesktop";
import StrategyCreatorMobile from "./StrategyCreatorMobile";

const StrategyCreator = () => {
  
  const [isMobile, setIsMobileScreen] = useState(window.innerWidth <= 430);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth <= 430);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile ? (
    <>
      <Helmet>
        <title>Strategy Creator</title>
      </Helmet>
      <div className="mobile-screen d-grid" style={{ gridColumn: "span 4" }}>
        <StrategyCreatorMobile />
      </div>
    </>
  ) : (
    <>
      <Helmet>
        <title>Strategy Creator</title>
      </Helmet>

      <div className="desktop-screenn">
        <StrategyCreatorDesktop />
      </div>
    </>
  );
};

export default StrategyCreator;
