import axios from "axios";
import React from "react";
import HeaderLogic from "./HeaderLogic";
import "../../css/style.css";
import HeaderMobile from "./HeaderMobile";
import HeaderDesktop from "./HeaderDesktop";
import FooterMobile from "../FooterMobile";
import { useContext, useEffect, useMemo } from "react";
import { BROKER_NAME } from "../../utils/constants";

const Header = () => {
  const { symbolData } = HeaderLogic();

  const getAccessToken =
    "https://6zqeqxesw2.execute-api.ap-south-1.amazonaws.com/default/getV2AccessToken";

  const generateAccessToken = async (auth_code, user_id) => {
    try {
      const body = {
        authCode: auth_code,
        userId: user_id,
      };
      const response = await axios.post(getAccessToken, body);
      let settingData = response.data;
      return settingData;
    } catch (error) {
      console.error("Error fetching settings:", error);
      return null;
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("verifiedpnl")) {
      window.location.replace(
        window.location.href.replace("dashboard", "beta")
      );
    }
  }, []);

  //for automatically logging out if date doesn't match
  useEffect(() => {
    const storedDate = localStorage.getItem("login_date");
    const currentDate = new Date().toLocaleDateString();
    if (storedDate !== currentDate && storedDate != null) {
      localStorage.clear();
      window.location.href = "https://1lyoptions.com/";
    }
  }, []);

  useEffect(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const auth_code = urlParams.get("authCode");
    const user_id = urlParams.get("userId");
    const page = urlParams.get("page");
    const broker = urlParams.get("broker");
    const client_id = urlParams.get("client_id");
    const access_token = urlParams.get("access_token");
    const name = urlParams.get("name");

    const currentDate = new Date().toLocaleDateString();
    if (broker == "gmail") {
      localStorage.setItem("broker", broker);
      localStorage.setItem("user_id", user_id);
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("client_id", client_id);
      localStorage.setItem("login_date", currentDate);
      localStorage.setItem("name", name);
      window.location.replace("https://dashboard.1lyoptions.com");
    } else {
      let response = await generateAccessToken(auth_code, user_id);
      if (response?.body) {
        localStorage.setItem("login_date", currentDate);
        localStorage.setItem("access_token", response?.body.access_token);
        localStorage.setItem("client_id", user_id);
        localStorage.setItem("ws_token", response?.body.ws_access_token);
        localStorage.setItem("user_id", user_id);
        localStorage.setItem("broker", "aliceblue");
        if (page == "position") {
          window.location.replace("https://dashboard.1lyoptions.com/positions");
        } else {
          window.location.replace("https://dashboard.1lyoptions.com");
        }
      } else {
        console.log("Auth code not found.");
      }
    }
  }, []);

  const redirectToExternalUrl = () => {
    const userId = localStorage.getItem("user_id");
    const clientId = localStorage.getItem("client_id");
    const access_token = localStorage.getItem("access_token");
    const ws_session = localStorage.getItem("ws_token");

    if (userId) {
      window.location.href = `https://beta.1lyoptions.com/loginsuccess?s=ok&code=200&client_id=${clientId}&user_id=${userId}&broker=aliceblue&access_token=${access_token}&ws_token=${ws_session}`;
    } else {
      // Handle the case when userId is not found in localStorage
      // console.error('userId not found in localStorage');
    }
  };

  return (
    <div>
      {BROKER_NAME !== "gmail" && (
        <a onClick={() => redirectToExternalUrl()}>
          <div
            style={{
              fontSize: "12px",
              textAlign: "center",
              textDecoration: "underline",
              padding: "2px",
              background: "#383FEC",
              color: "#FFF",
              position: "fixed",
              width: "100%",
              zIndex: 9999,
              top: "0",
              cursor: "pointer",
            }}>
            Old version: Verified P&L, Straddle premium and Multistrike charts
          </div>
        </a>
      )}
      <div className="mobile-header">
        <HeaderMobile symbolData={symbolData} />
      </div>
      <div className="desktop-header">
        <HeaderDesktop symbolData={symbolData} />
      </div>

      <div className="mobile-header">
        <FooterMobile />
      </div>
    </div>
  );
};

export default Header;
