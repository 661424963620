import React from "react";
import StockDetailsMobile from "./StockDetailsMobile";
import StockDetailsDesktop from "./StockDetailsDesktop";
import { Helmet } from "react-helmet-async";

const StockDetails = () => {

  function isMobile() {
    return (window.screen.width > 430 ) ? false : true;
  }

  return isMobile() ?   (
    <>
      <Helmet>
        <title>Stock Details</title>
      </Helmet>
      <div className="mobile-screen d-grid" style={{ gridColumn: "span 4" }}>
        <StockDetailsMobile />
      </div>
    </>
  ) : (
    <>
      <Helmet>
        <title>Stock Details</title>
      </Helmet>
      <div>
        <StockDetailsDesktop />
      </div>
    </>
  ) ;
};

export default StockDetails;
