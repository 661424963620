import React, { useState } from "react";

export default function RiskDisclosure() {
  const [Proceed, setProceed] = useState(localStorage.getItem("proceed"));
  if (Proceed === "true") {
    return <></>;
  }

  return (
    <div
      className={
        Proceed === "true"
          ? "overlay"
          : "react-modal-overlay d-flex align-items-center justify-content-center"
      }
    >
      <div className="risk-container">
        <div className="risk-heading-container">
          <h1 className="funds-text">Risk Disclosure on Derivatives</h1>
        </div>
        <div>
          <ul>
            <li className="risk-ul-p">
              9 out of 10 individual traders in equity Futures and Options
              Segment, incurred net losses.
            </li>

            <li className="risk-ul-p">
              On an average, loss makers registered net trading loss close to
              50,000.
            </li>
            <li className="risk-ul-p">
              Over and above the net trading losses incurred, loss makers
              expended an additional 28% of net trading losses as transaction
              costs.
            </li>
            <li className="risk-ul-p">
              Those making net trading profits, incurred between 15% to 50% of
              such profits as transaction cost.
            </li>
          </ul>
          <div>
            <a
              href="https://www.sebi.gov.in/reports-and-statistics/research/jan-2023/study-analysis-of-profit-and-loss-of-individual-traders-dealing-in-equity-fando-segment_67525.html"
              target="_blank"
              rel="noopener noreferrer"
              className="risk-a-link"
            >
              <p
                className=""
                style={{
                  textAlign: "left",
                  color: "#3575D6",
                  width: "563px",
                  fontSize: "14px",
                  marginLeft: "25px",
                }}
              >
                SEBI study dated January 25, 2023 on Analysis of Profit and Loss
                of Individual Traders dealing in equity Futures and Options
                (F&O) Segment, wherein Aggregate Level findings are based on
                annual Profit/Loss incurred by individual traders in equity F&O
                during FY 2021-22
              </p>
            </a>
          </div>
        </div>
        <button
          onClick={function(){
            localStorage.setItem("proceed", "true");
            setProceed("true");
          }}
          className="proceed-button-risk "
        >
          Proceed
        </button>
      </div>
    </div>
  );
}
