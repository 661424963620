import React from "react";
import { ShimmerTable } from "react-shimmer-effects";

const PositionsTable = () => {
  return (
    <div className="group-table executed-table d-flex flex-column gap-3">
      <div className="px-3 border">
        <table className="w-100">
          <ShimmerTable row={3} col={5} />
        </table>
      </div>
    </div>
  );
};

export default PositionsTable;
