import React, { useContext, useEffect, useState } from "react";
import { IoArrowUpOutline } from "react-icons/io5";
import WebsocketWrapperContext from "../../context/WebsocketWrapperContext";
import { Link } from "react-router-dom";

let triggerSubscribe = true;
let connectionEstablised = false;
const RightPanel = ({ data }) => {
  const [symbols, setSymbols] = useState();

  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } = useContext(WebsocketWrapperContext);

  useEffect(() => {
    if (data && data.indian_markets && data?.indian_markets?.length > 0) {
      const symbols = data.indian_markets.map((item) => item.code);
      setSymbols(symbols);
    }
  }, [data]);
  
  function formatNumberWithCommasAndFontWeight(value) {
    const formattedValue = parseFloat(value).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue?.split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1];

    return (
      <span>
        {integerPart}.<span style={{ fontWeight: 400 }}>{decimalPart}</span>
      </span>
    );
  }
  
  useEffect(() => {
    connectionEstablised = checkWebsocketConnection();
    if (connectionEstablised && symbols?.length > 0) {
      if (triggerSubscribe) {
        subscribeToSymbols(symbols, "NSE");
        if (Object.keys(symbolList).length !== 0) {
          triggerSubscribe = false;
        }
      }
    } else {
      triggerSubscribe = true;
    }
  }, [symbolData]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}
    >
      {/* INDIAN MARKETS DIV  */}
      {/* <div className="right-p-top-div"> */}
      <div className="cards-header-d">
        <div className="cards-subheader-d">
          <h1 className="cards-heading">Indian markets</h1>
          <p className="cards-subheader">Most active indices in NSE & BSE</p>
        </div>
      </div>
      <div className="cards-markets-info">
        {data &&
          data.indian_markets &&
          data.indian_markets.map((item, index) => {
            return (
              <Link
                to={`https://dashboard.1lyoptions.com/stockDetails?stock=${item.symbol}`}
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className="opg-card" key={index}>
                  <div className="opg-card-content">
                    <div className="opg-card-header">
                      <div className="opg-card-info">
                        <div className="opg-card-symbol">{item.symbol}</div>
                        <div className="performance-item-exchange-d">
                          {item.exchange}
                        </div>
                      </div>
                      <IoArrowUpOutline className="asset-goto" />
                    </div>
                    <div className="opg-card-body">
                      <div className="opg-card-price">
                        <span className="integer-part">
                          {formatNumberWithCommasAndFontWeight(
                            symbolData[item?.code]?.ltp ? symbolData[item?.code]?.ltp : item.ltp
                            )}
                        </span>
                      </div>
                      <div className="opg-card-gain">
                        {(symbolData[item?.code]?.change ? symbolData[item?.code]?.change : item.change_per) >= 0 ? (
                          <div className="opg-card-gain-up">
                            <p className="opg-card-gain-text">
                              +
                              {formatNumberWithCommasAndFontWeight(
                              symbolData[item?.code]?.change_per ? symbolData[item?.code]?.change_per : item.change_per
                              )} %
                            </p>
                          </div>
                        ) : (
                          <div className="opg-card-gain-down">
                            <p className="opg-card-gain-text">
                            {formatNumberWithCommasAndFontWeight(
                              symbolData[item?.code]?.change_per ? symbolData[item?.code]?.change_per : item.change_per
                              )} %
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="opg-bar-details">
                    <div className="opg-bar-container">
                      <div
                        className="opg-bar-green"
                        style={{
                          width: `${(item.advances / (item.advances + item.declines)) * 100}%`,
                        }}
                      ></div>
                      <div
                        className="opg-bar-red"
                        style={{
                          width: `${(item.declines / (item.advances + item.declines)) * 100}%`,
                        }}
                      ></div>
                    </div>
                    <div className="opg-bar-text">
                      <div className="opg-bar-green-text">{item.advances}</div>
                      <div className="opg-bar-red-text">{item.declines}</div>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
      </div>
      {/* </div> */}
      {/* Global markets div  */}
      <div style={{ marginTop: "20px" }}>
        <div className="cards-header-d">
          <div className="cards-subheader-d">
            <h1 className="cards-heading">Global markets</h1>
            <p className="cards-subheader">
              Tracking global cues helps analyse
            </p>
          </div>
        </div>
        <div className="global-markets-list">
          {data &&
            data.global_markets &&
            data.global_markets.map((item, index) => (
              <div className="global-market-item opg-card" key={index}>
                <div className="global-market-header">
                  <div className="global-market-info">
                    <h3 className="global-market-symbol">{item.symbol}</h3>
                    {item.status === "Open" ? (
                      <div className="market-status-active"></div>
                    ) : (
                      <div className="market-status-inactive"></div>
                    )}
                  </div>
                  <div className="global-market-points">
                    <p className="market-price">
                      <span>{formatNumberWithCommasAndFontWeight(item?.change_value)}</span>
                    </p>
                  </div>
                </div>
                <div className="global-market-details">
                  <p className="market-price">
                    <span>{formatNumberWithCommasAndFontWeight(item?.ltp)}</span>
                  </p>
                  {item.change_per > 0 ? (
                    <p className="market-gain" style={{ color: "#19AF55" }}>
                      +
                      <span>{formatNumberWithCommasAndFontWeight(item?.change_per)}</span>
                    </p>
                  ) : (
                    <p className="market-gain" style={{ color: "red" }}>
                      <span>{formatNumberWithCommasAndFontWeight(item?.change_per)}</span>
                    </p>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
      <Link
        to="/screeners/candlesticks"
        className="cards-lower"
        style={{
          textDecoration: "none",
          color: "inherit",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            gap: "5px",
            paddingLeft: "15px",
          }}
        >
          <h3 className="cards-lower-heading">Candlesticks</h3>
          <div className="cards-number">22 patterns</div>
        </div>
        <IoArrowUpOutline className="asset-goto" style={{ color: "#9B9B9B" }} />
      </Link>

      <Link
        to="/screeners/scanners"
        className="cards-lower"
        style={{
          textDecoration: "none",
          color: "inherit",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            gap: "5px",
            paddingLeft: "15px",
          }}
        >
          <h3 className="cards-lower-heading">Scanners</h3>
          <div className="cards-number">14 patterns</div>
        </div>
        <IoArrowUpOutline className="asset-goto" style={{ color: "#9B9B9B" }} />
      </Link>

      <Link
        to="/screeners/technicals"
        className="cards-lower"
        style={{
          textDecoration: "none",
          color: "inherit",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            gap: "5px",
            paddingLeft: "15px",
          }}
        >
          <h3 className="cards-lower-heading">Technical indicators</h3>
          <div className="cards-number">10 patterns</div>
        </div>
        <IoArrowUpOutline className="asset-goto" style={{ color: "#9B9B9B" }} />
      </Link>
    </div>
  );
};

export default RightPanel;
