import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import lyoptions from "../../images/lyoptions.svg";
import logowhite from "../../images/logo_white.svg";
import UserIcon from "../../images/user-icon.svg";

import { CLIENT_ID, BROKER_NAME, USER_NAME } from "../../utils/constants";
import { ButtonContext } from "../../context/ButtonsContext";
import { getSetting } from "../../utils/api";
import { useTheme } from "../../context/ThemeContext";
import { toast } from "react-toastify";
import { Tooltip as ReactTooltip } from "react-tooltip";

const HeaderDesktop = ({ symbolData }) => {
  const { theme, setLightMode, setDarkMode } = useTheme();
  const navigate = useNavigate();
  const {
    isLive,
    setIsLive,
    isVirtual,
    setIsVirtual,
    toggleLive,
    toggleVirtual,
  } = useContext(ButtonContext);
  const [settingData, setSettingData] = useState(null);

  const broker = localStorage.getItem("broker");
  const clientId = localStorage.getItem("client_id");
  const access_token = localStorage.getItem("access_token");

  function formatNumberWithCommasAndFontWeight(value) {
    const formattedValue = parseFloat(value).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue.split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1];

    return (
      <span>
        {integerPart}.<span style={{ fontWeight: 400 }}>{decimalPart}</span>
      </span>
    );
  }

  const handleVirtualCheckboxChange = () => {
    toggleVirtual();
    toggleLive();
    getSetting(broker, clientId, "SET", access_token, "virtual", "screenMode");
  };
  const handleLiveToggle = () => {
    if (broker === "gmail") {
      toggleLive.disabled = true;
    } else {
      toggleVirtual();
      toggleLive();
      getSetting(broker, clientId, "SET", access_token, "live", "screenMode");
    }
  };
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "https://1lyoptions.com/";
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getSetting(broker, clientId, "GET", access_token);
        setSettingData(data);

        const storedMode = localStorage.getItem("initialTradeMode");
        if (broker === "gmail") {
          setIsVirtual(true);
          setIsLive(false);
          toggleLive.disabled = true;
          if (!storedMode) {
            localStorage.setItem("initialTradeMode", "virtual");
          }
        } else if (data && data.user_settings.trade_mode) {
          setIsLive(data.user_settings.trade_mode === "live");
          setIsVirtual(data.user_settings.trade_mode === "virtual");
        } else {
          setIsVirtual(true);
          setIsLive(false);
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetch();
  }, []);

  return (
    <div className="shadow-sm">
      <div
        className="grid-container"
        style={{ marginTop: broker === "gmail" ? "20px" : "40px" }}>
        <nav
          className="header fixed-top navbar navbar-expand-lg shadow-mb-sm "
          style={{ zIndex: "999", top: broker === "gmail" ? "0px" : "22px" }}>
          <div className="container-fluid " style={{ maxWidth: "1392px" }}>
            <Link
              className="navbar-brand d-flex align-items-center justify-content-center  "
              to="/">
              <img
                src={theme === "light" ? lyoptions : logowhite}
                alt="logo"
                className="app-logo"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-md-3  gap-md-3 text-decoration-none">
                {/* <li className="nav-item mb-2 mb-md-0">
                  <NavLink
                    // className="nav-link navigation"
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                    to="/backtest">
                    Backtest
                  </NavLink>
                </li>{" "} */}
                <li className="nav-item mb-2 mb-md-0">
                  <NavLink
                    // className="nav-link navigation"
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                    to="/">
                    Strategy creator
                  </NavLink>
                </li>{" "}
                <li className="nav-item mb-2 mb-md-0">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                    to="/optionchain">
                    Option chain
                  </NavLink>
                </li>{" "}
                <li className="nav-item mb-2 mb-md-0">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                    to="/positions">
                    {isLive ? "Positions" : "Draft positions"}
                  </NavLink>
                </li>{" "}
                <li className="nav-item mb-2 mb-md-0">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                    to="/orders">
                    {isLive ? "Orders" : "Draft orders"}
                  </NavLink>
                </li>
                <li className="nav-item mb-2 mb-md-0" type="button">
                  <div className="dropdown">
                    <button
                      style={{
                        textDecoration: "none",
                        color: theme === "light" ? "black" : "white",
                      }}
                      className="dropdown-toggle tools-dropdown-btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                      Tools{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="8"
                        viewBox="0 0 13 8"
                        fill="none">
                        <path
                          d="M12 1L6.5 7L1 1"
                          stroke={theme === "light" ? "#000000" : "#ffffff"}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <div
                      className="dropdown-menu tools-dropdown-content p-3 mt-3"
                      aria-labelledby="dropdownMenuButton">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column gap-3">
                          {/* <div className="d-flex flex-column">
                            <div className="tools-feature-title">
                              Verified P&L
                              <span className="tools-label ms-2">
                                Coming soon
                              </span>{" "}
                            </div>
                            <div className="tools-feature-name">
                              My Verified P&L’s
                            </div>
                          </div> */}
                          {/* <div className="d-flex flex-column">
                            <div className="tools-feature-title">
                              Market
                              <span className="tools-label ms-2">
                                Coming soon
                              </span>{" "}
                            </div>
                            <div className="tools-feature-name">
                              Strategy finder
                            </div>
                          </div> */}
                          <div className="d-flex flex-column">
                            <div className="d-flex flex-wrap">
                              <section style={{marginBottom:'12px',marginRight:'60px',display:'flex',flexDirection:'column'}}>                            
                              <div className="tools-feature-title">
                              Calculators
                            </div>
                            <NavLink
                              to="/Optionspricing"
                              className="tools-feature-name text-decoration-none">
                              Options pricing
                            </NavLink>
                            <NavLink
                              to="/pivotpoints"
                              className="tools-feature-name text-decoration-none">
                              Pivot points
                            </NavLink>
                            <NavLink
                              to="/fibbanoci"
                              className="tools-feature-name text-decoration-none">
                              Fibonacci retracement
                            </NavLink>
                            </section>
                              <section
                                style={{
                                  marginBottom: "12px",
                                  display: "flex",
                                  flexDirection: "column",
                                }}>
                                <div className="tools-feature-title">
                                  Market Overview
                                </div>
                                <NavLink
                                  to="/discover"
                                  className="tools-feature-name text-decoration-none">
                                  Discover
                                </NavLink>
                                <NavLink
                                  to="/fiidii"
                                  className="tools-feature-name text-decoration-none">
                                  FII & DII Data
                                </NavLink>
                              </section>
                            </div>
                            <div className="tools-feature-title">Analysis</div>
                            <NavLink
                              to="/screeners/candlesticks"
                              className="tools-feature-name text-decoration-none">
                              Candlesticks
                            </NavLink>
                            <NavLink
                              to="/screeners/scanners"
                              className="tools-feature-name text-decoration-none">
                              Scanners
                            </NavLink>
                            <NavLink
                              to="/screeners/technicals"
                              className="tools-feature-name text-decoration-none">
                              Technical indicators
                            </NavLink>
                          </div>
                        </div>
                        {/* <div className="d-flex flex-column">
                          <div className="tools-feature-title">
                            OI ANALYSIS
                            <span className="tools-label ms-2">
                              Coming soon
                            </span>{" "}
                          </div>
                          <div className="tools-feature-name">
                            Options premium
                          </div>
                          <div className="tools-feature-name">
                            Multi-strike chart
                          </div>
                          <div className="tools-feature-name">PCR chart</div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div
                className="d-flex flex-column flex-md-row list-unstyled justify-content-start justify-content-md-between"
                style={{ lineHeight: "normal" }}>
                <li className=" nav-item">
                  <Link className="nav-link " aria-current="page">
                    <div className="symbol-containers d-flex flex-column align-items-md-start">
                      <div className="symbol-name">NIFTY</div>
                      <div>
                        <span className="nifty">
                          {symbolData["26000"]?.ltp?.includes(".")
                            ? formatNumberWithCommasAndFontWeight(
                                symbolData["26000"]?.ltp
                              )
                            : formatNumberWithCommasAndFontWeight(
                                symbolData["26000"]?.ltp
                              )}
                        </span>

                        <span
                          className={`ch-chp me-2 ${
                            symbolData["26000"]?.change > 0
                              ? "text-success"
                              : "text-danger"
                          }`}>
                          {symbolData["26000"]?.change > 0 ? "+" : ""}
                          {symbolData["26000"]?.change}
                          {symbolData["26000"]?.change_per ? "(" : ""}
                          {symbolData["26000"]?.change_per}
                          {symbolData["26000"]?.change_per ? "%)" : "-"}
                          {/* {symbolData["101000000026000"]?.change > 0 ? "+" : ""}
                          {symbolData["101000000026000"]?.change}
                          {symbolData["101000000026000"]?.change_per ? "(" : ""}
                          {symbolData["101000000026000"]?.change_per}
                          {symbolData["101000000026000"]?.change_per  */}
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li className=" nav-item">
                  <Link className="nav-link" aria-current="page">
                    <div className="symbol-containers d-flex flex-column align-items-md-start">
                      <div className="symbol-name">BANKNIFTY</div>
                      <div>
                        <span className="nifty">
                          {symbolData["26009"]?.ltp?.includes(".")
                            ? formatNumberWithCommasAndFontWeight(
                                symbolData["26009"]?.ltp
                              )
                            : formatNumberWithCommasAndFontWeight(
                                symbolData["26009"]?.ltp
                              )}
                        </span>

                        <span
                          className={`ch-chp me-2 ${
                            symbolData["26009"]?.change > 0
                              ? "text-success"
                              : "text-danger"
                          }`}>
                          {symbolData["26009"]?.change > 0 ? "+" : ""}
                          {symbolData["26009"]?.change}
                          {symbolData["26009"]?.change_per ? "(" : ""}
                          {symbolData["26009"]?.change_per}
                          {symbolData["26009"]?.change_per ? "%)" : "-"}
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li className=" nav-item">
                  <Link className="nav-link " aria-current="page">
                    <div className="symbol-containers d-flex flex-column align-items-md-start">
                      <div className="symbol-name">INDIA VIX</div>
                      <div>
                        <span className="nifty">
                          {symbolData["26017"]?.ltp?.includes(".")
                            ? formatNumberWithCommasAndFontWeight(
                                symbolData["26017"]?.ltp
                              )
                            : formatNumberWithCommasAndFontWeight(
                                symbolData["26017"]?.ltp
                              )}
                        </span>

                        <span
                          className={`ch-chp me-2 ${
                            symbolData["26017"]?.change > 0
                              ? "text-success"
                              : "text-danger"
                          }`}>
                          {symbolData["26017"]?.change > 0 ? "+" : ""}
                          {symbolData["26017"]?.change}
                          {symbolData["26017"]?.change_per ? "(" : ""}
                          {symbolData["26017"]?.change_per}
                          {symbolData["26017"]?.change_per ? "%)" : "-"}
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
              </div>

              <div className="d-flex justify-content-center align-items-center gap-2">
                <img
                  src={UserIcon}
                  alt="User Profile Pic"
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                  }}
                  type="button"
                />
                <div className="d-flex flex-column gap-2">
                  <span className="client-id">
                    {BROKER_NAME === "gmail" ? USER_NAME : CLIENT_ID}
                    <span className="header-dropdown dropdown">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="8"
                        viewBox="0 0 13 8"
                        fill="none"
                        className="ms-1 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <path
                          d="M12 1L6.5 7L1 1"
                          stroke={theme === "light" ? "#1E2028" : "#ffffff"}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="dropdown-menu header-dropdown-menu">
                        {/* <div className="trade-mode-container">
                          <div className="trade-mode">Trade Mode</div>
                          <div className="toggle-container">
                            <button
                              className={`toggle-button ${isLive ? "active" : ""}`}
                              onClick={handleLiveToggle}
                              data-tooltip-id="disabled-live-tooltip">
                              Live
                            </button>
                            {broker === "gmail" ? (
                              <ReactTooltip
                                id="disabled-live-tooltip"
                                place="top"
                                clickable
                                // content="Please login with aliceblue or click here to open an account"
                                variant={theme === "dark" ? "light" : "dark"}>
                                <span>
                                  Please login with aliceblue or{" "}
                                  <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://1lyoptions.com/login">
                                    click here
                                  </a>{" "}
                                  to open an account
                                </span>
                              </ReactTooltip>
                            ) : (
                              ""
                            )}

                            <button
                              className={`toggle-button ${isVirtual ? "active" : ""}`}
                              onClick={handleVirtualCheckboxChange}>
                              Virtual
                            </button>
                          </div>
                        </div> */}
                        <div className="trade-mode-container">
                          <div className="trade-mode">Theme</div>
                          <div className="toggle-container">
                            <button
                              className={`toggle-button ${theme === "light" ? "active" : ""}`}
                              onClick={setLightMode}>
                              Light
                            </button>
                            <button
                              className={`toggle-button ${theme === "dark" ? "active" : ""}`}
                              onClick={setDarkMode}>
                              Dark
                            </button>
                          </div>
                        </div>
                        <div className="mt-3">
                          <NavLink
                            to="https://dashboard.1lyoptions.com/feedback"
                            className="tools-feature-name text-decoration-none">
                            Share your feedback
                          </NavLink>
                        </div>
                        <div
                          className="dropdown-logout mt-2 mb-3"
                          onClick={handleLogout}>
                          Logout
                        </div>
                      </div>
                    </span>
                  </span>
                  <span className="broker-name">
                    {BROKER_NAME?.toUpperCase()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {/* <ToolsModal /> */}
    </div>
  );
};

export default HeaderDesktop;
