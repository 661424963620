import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { getOrders, getSymbols } from "../utils/api";
import { useTheme } from "../context/ThemeContext";
import { ButtonContext } from "../context/ButtonsContext";
import PositionMetricsComputations from "../components/PositionMetricsComputations";
import axios from "axios";
import { useFundsData } from "../context/FundsDataContext";
import SuccessIcon from "../images/info_icon.svg";
import InfoIcon from "../images/info_icon.svg";
import { toast } from "react-toastify";
import CustomToastContent from "../components/CustomToastContent";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useOverlay } from "../context/OverlayContext";
import { useBuySellModalContext } from "../context/BuySellModalContext";
import BuySellModal from "./BuySellModal";
import WebsocketWrapperContext from "../context/WebsocketWrapperContext";
let triggerSubscribe = true;
let connectionEstablised = false;

const QuickView = ({ expanded, setExpanded }) => {
  const { isVirtual } = useContext(ButtonContext);
  const { isOverlayActive, showOverlay, hideOverlay } = useOverlay();
  const access_token = localStorage.getItem("access_token");
  const client_id = localStorage.getItem("client_id");
  const { theme } = useTheme();
  // const [expanded, setExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [orders, setOrders] = useState([]);
  const [checkboxEnabled, setCheckboxEnabled] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [filter, setFilter] = useState("pending");
  const [broker, setBroker] = useState("");
  const [response, setResponse] = useState(null);
  const mainBroker = localStorage.getItem("broker");
  const [searchPosition, setSearchPosition] = useState("");
  const [searchOrders, setSerchOrders] = useState("");
  const [duplicateData, setDuplicateData] = useState([]);
  const [mainIndex, setMainIndex] = useState(null);
  const [visibility, setVisibility] = useState(true);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const { buySellModalData, addToBuySellModal, setBuySellModalData } = useBuySellModalContext();
  useEffect(() => {
    if (isVirtual) {
      setBroker("virtual");
    } else {
      const storedBroker = mainBroker;
      setBroker(storedBroker);
    }
  }, [isVirtual]);

  const handleClick = (tab) => {
    setActiveTab(tab);
    handleFooterClick();
    if (!expanded) {
      setExpanded(true);
    }
  };

  const handleClose = () => {
    setExpanded(false);
    setActiveTab(null);
  };

  const handleFooterClick = () => {
    if (!expanded) {
      setExpanded(true);
    }
  };

  const fetchOrders = async (accessToken, clientId, broker, setOrders) => {
    try {
      const response = await getOrders(accessToken, clientId, broker);
      // console.log("My orders are:", response);
      if (response) {
        setOrders(response);
      } else {
        setOrders([]);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    if (expanded) {
      fetchOrders(access_token, client_id, broker, setOrders);
    }
  }, [expanded, access_token, client_id, broker, setOrders]);

  const filteredOrders = orders?.filter((order) => {
    if (filter === "pending" && order.status !== "Pending") {
      return false; // Skip non-pending orders
    }
    return true;
  });

  useEffect(() => {
    const hasPendingOrders = filteredOrders.some(
      (order) => order.status === "Pending"
    );
    setCheckboxEnabled(hasPendingOrders);
  }, [filteredOrders]);

  const handleCheckboxChange = (orderId) => {
    const order = orders.find((order) => order.id === orderId);
    if (order && order.status === "Pending") {
      const isChecked = selectedCheckboxes.includes(orderId);
      if (isChecked) {
        setSelectedCheckboxes(
          selectedCheckboxes?.filter((id) => id !== orderId)
        );
        setOrderList(orderList?.filter((id) => id !== orderId));
      } else {
        setSelectedCheckboxes([...selectedCheckboxes, orderId]);
        setOrderList([...orderList, order]);
      }
    }
  };
  const handleCancelSelected = async () => {
    try {
      if (selectedCheckboxes.length > 0) {
        const response = await axios.post(
          "https://9pmtao5xc1.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_cancelorders",
          {
            broker: broker,
            access_token: access_token,
            client_id: client_id,
            order_list: orderList,
          }
        );

        if (response.data.status) {
          // If the response status is true, fetch the updated orders
          const updatedOrders = await getOrders(
            access_token,
            client_id,
            broker
          );
          setOrders(updatedOrders);
        } else {
          // console.log("Failed to cancel orders:", response.data.message);
          toast.error(
            <CustomToastContent
              heading="Failed to cancel orders!"
              message={response.data.message}
            />,
            {
              className: "custom-error-toast",
              icon: <img src={InfoIcon} alt="Success Icon" />,
            }
          );
        }
      } else {
        // console.log("No orders selected to cancel.");
      }
    } catch (error) {
      console.error("Error cancelling orders:", error);
      return "";
    } finally {
      setSelectedCheckboxes([]);
      setOrderList([]);
    }
  };
  const handleRowMouseEnter = (row_index, index, duplicateData) => {
    setVisibility(true);
    setHoveredRowIndex(row_index);
    setMainIndex(index);
  };

  const handleRowMouseLeave = () => {
    setVisibility(false);
    setHoveredRowIndex(null);
    setMainIndex(null);
  };

  const handleExitModal = (trades, index) => {
    const trade_data = trades;
    const ltpPrice = symbolData[trade_data?.code]?.ltp
    const data_object = {
      side: trade_data.isBuy ? "Buy" : "Sell",
      instrumentName: trade_data.instrument_name,
      lotSize: Math.abs(trade_data.net_quantity / trade_data.lotSize),
      lot_size: trade_data.lotSize,
      productType: trade_data.product_type,
      tradingSymbol: trade_data.tradingSymbol,
      expiryDate: trade_data.expiry_date,
      code: trade_data.code,
      price: ltpPrice || 0,
      trigger_price: ltpPrice || 0,
      buy_sell: trade_data.isBuy ? "S" : "B",
      underlying_code: trade_data.underlying_symbol,
      strike: trade_data.strike,
      quantity: trade_data.net_quantity,
      exchange: trade_data.exchange,
      scrip: trade_data.scrip,
      lot_multiplier: trade_data.lotSize,
    };

    setBuySellModalData([data_object]);
  };


  const handleAddModal = (trades, index) => {
    const trade_data = trades;
    const ltpPrice = symbolData[trade_data?.code]?.ltp
    const data_object = {
      side: trade_data.isBuy ? "Buy" : "Sell",
      instrumentName: trade_data.instrument_name,
      lotSize: trade_data.lotSize / trade_data.lotSize,
      lot_size: trade_data.lotSize,
      productType: trade_data.product_type,
      tradingSymbol: trade_data.tradingSymbol,
      expiryDate: trade_data.expiry_date,
      code: trade_data.code,
      price: ltpPrice || 0,
      trigger_price: ltpPrice || 0,
      buy_sell: trade_data.isBuy ? "B" : "S",
      underlying_code: trade_data.underlying_symbol,
      strike: trade_data.strike,
      quantity: trade_data.lotSize,
      exchange: trade_data.exchange,
      scrip: trade_data.scrip,
      lot_multiplier: trade_data.lotSize,
    };

    setBuySellModalData([data_object]);
  };

  const handleModifyClick = (trade) => {
    const ltpPrice = symbolData[trade?.code]?.ltp
    const trade_data = {
      side: trade.side !== -1 ? "Buy" : "Sell",
      instrumentName: trade.symbol,
      lotSize: trade.lotSize / trade.lotSize,
      lot_size: trade.lotSize,
      productType: trade.productType,
      expiryDate: trade.expiry_date,
      code: trade.code,
      price: ltpPrice || 0,
      trigger_price: trade.limit_price || 0,
      buy_sell: trade.side === -1 ? "S" : "B",
      underlying_code: trade.underlying_symbol,
      strike: trade.strike,
      quantity: trade.lotSize,
      exchange: trade.exchange,
      scrip: trade.scrip,
      lot_multiplier: trade.lotSize,
      orderStatus:trade.status,
      orderType:trade.orderType,
    };
    setBuySellModalData([trade_data]);
  }

  const handleOKClick = async () => {
    try {
      await handleCancelSelected(); // Wait for the cancellation process to complete
      hideOverlay();
      toast.success(
        <CustomToastContent
          heading="Order cancelled successfully!"
          message="Successfully cancelled all selected orders!"
        />,
        {
          className: "custom-success-toast",
          icon: <img src={SuccessIcon} alt="Success Icon" />,
        }
      );
    } catch (error) {
      console.error("Error handling cancel operation:", error);
    }
    // console.log("Orders after cancellig", orders);
  };

  const handleCloseClick = () => {
    hideOverlay();
    toast.dismiss();
  };

  const showToast = () => {
    showOverlay();

    toast(
      <div className="d-flex align-items-center justify-content-between">
        <div>Sure, you want to delete this order?</div>
        <div className="d-flex justify-content-end align-items-center gap-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              onClick={handleCloseClick}>
              <path
                d="M1 1.00002L13.7742 13.7742M1 13.7742L13.7742 1"
                stroke="#FF3908"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div
            className="confirm-popup-ok d-flex justify-content-center align-items-center"
            onClick={handleOKClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="14"
              viewBox="0 0 19 14"
              fill="none">
              <path
                d="M1 6.65685L6.65685 12.3137L17.9706 1"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
      </div>,
      {
        position: "bottom-center",
        closeButton: false, // Hide the built-in close button
        icon: false,
        className: "confirm-popup-toast quick-view-toast", // Apply the custom CSS class
        hideProgressBar: true,
        draggable: true,
        autoClose: false,
      }
    );
  };
  /* ============= Posititions logic* ==========================*/
  const [metrics, setMetrics] = useState({
    total_positions: 0,
    open_positions: 0,
  });
  const [nsesubscribeSymbols, setNSESubscribeSymbols] = useState({});
  const [nfosubscribeSymbols, setNFOSubscribeSymbols] = useState({});
  const [bsesubscribeSymbols, setBSESubscribeSymbols] = useState({});
  const [bfosubscribeSymbols, setBFOSubscribeSymbols] = useState({});
  const [allPositions, setAllPositions] = useState([]);
  const [symbols, setSymbols] = useState([]);

  const [netProfit, setNetProfit] = useState(0);
  const metricsComputations = PositionMetricsComputations();

  const [pendingProfit, setPendingProfit] = useState(0);
  const [potentialProfit, setPotentialProfit] = useState(0);
  const [achievedPercent, setAchievedPercent] = useState(0);
  const { checkWebsocketConnection, subscribeToSymbols, symbolData } = useContext(WebsocketWrapperContext);

  useEffect(() => {
    if (broker !== "") {
      fetchPositions();
    }
  }, [broker]);

  useLayoutEffect(() => {
    if (buySellModalData?.length == 0 & allPositions?.length > 0){
      fetchPositions();
    }
  }, [buySellModalData])

  const fetchPositions = async () => {
    const apiEndpoint = `https://y0tp4rr2a5.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_positions`;
    const body = {
      access_token: access_token,
      broker: broker,
      main_broker: mainBroker,
      client_id: client_id,
    };
    try {
      const response = await axios.post(apiEndpoint, body);
      const sorted_response = response.data.all_positions
        .slice()
        .sort((a, b) => {
          if (a.net_quantity === 0 && b.net_quantity === 0) {
            return 0;
          } else if (a.net_quantity === 0) {
            return 1;
          } else if (b.net_quantity === 0) {
            return -1;
          } else {
            return a.net_quantity - b.net_quantity;
          }
        });

      setResponse(sorted_response);

      if (response.data?.nse_subscribe_symbols) {
        setNSESubscribeSymbols(response.data?.nse_subscribe_symbols);
        setNFOSubscribeSymbols(response.data?.nfo_subscribe_symbols);
        setMetrics(response.data?.metrics);
      }
      if (response.data?.bse_subscribe_symbols) {
        setBSESubscribeSymbols(response.data?.bse_subscribe_symbols);
        setBFOSubscribeSymbols(response.data?.bfo_subscribe_symbols);
      }
      const symbols = await getSymbols();
      setSymbols(symbols);
      setAllPositions(sorted_response);
    } catch (error) {
      console.error("Error fetching positions:", error);
    }
  };

  const exitPositions = async (positions) => {
    const apiEndpoint =
      "https://snji3aqoq9.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_exitpositions";
    const body = {
      access_token: access_token,
      broker: broker,
      main_broker: mainBroker,
      client_id: client_id,
      positions: positions,
    };
    try {
      const response = await axios.post(apiEndpoint, body);
      if (response?.data.status) {
        fetchPositions();
        setAllPositions([]);
        // console.log("positions afer api call", allPositions);

        toast.success(
          <CustomToastContent
            heading={response.data.message_header}
            message={response.data.message_body}
          />,
          {
            className: "custom-success-toast",
            icon: <img src={SuccessIcon} alt="Success Icon" />,
          }
        );
      } else {
        toast.error(
          <CustomToastContent
            heading={response.data.message_header}
            message={response.data.message_body}
          />,
          {
            className: "custom-error-toast",
            icon: <img src={InfoIcon} alt="Success Icon" />,
          }
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    let pendingProfit = 0;
    let totalPnl = 0;
    allPositions?.map((position, index) => {
      let ltp = symbolData[position?.code]?.ltp;
      if (ltp) {
        let pnl =
          parseFloat(position?.realised_pl) +
          (parseFloat(ltp) - parseFloat(position?.average_prc)) *
            parseFloat(position?.net_quantity);
        totalPnl = totalPnl + pnl;
      }
    });
    setNetProfit(totalPnl.toFixed(2));
    if (potentialProfit !== "Unlimited") {
      pendingProfit = potentialProfit - totalPnl;
      setPendingProfit(pendingProfit.toFixed(2));
      setAchievedPercent(((totalPnl / potentialProfit) * 100).toFixed(2) + "%");
    }
  }, [symbolData, allPositions, potentialProfit]);

  useLayoutEffect(() => {
    connectionEstablised = checkWebsocketConnection();
    if (connectionEstablised) {
      if (triggerSubscribe) {
        if (Object.keys(nsesubscribeSymbols).length !== 0) {
          subscribeToSymbols(nsesubscribeSymbols, "NSE");
        }
        if (Object.keys(nfosubscribeSymbols).length !== 0) {
          subscribeToSymbols(nfosubscribeSymbols, "NFO");
        }
        if (Object.keys(bsesubscribeSymbols).length !== 0) {
          subscribeToSymbols(bsesubscribeSymbols, "BSE");
        }
        if (Object.keys(bfosubscribeSymbols).length !== 0) {
          subscribeToSymbols(bfosubscribeSymbols, "BFO");
        }
        if (
          Object.keys(nfosubscribeSymbols).length !== 0 &&
          Object.keys(nsesubscribeSymbols).length !== 0 && 
          Object.keys(bfosubscribeSymbols).length !== 0 &&
          Object.keys(bsesubscribeSymbols).length !== 0
        ) {
          triggerSubscribe = false;
        } else {
          triggerSubscribe = true;
        }
      }
    } else {
      triggerSubscribe = true;
    }
  }, [symbolData, triggerSubscribe, nsesubscribeSymbols, nfosubscribeSymbols, bsesubscribeSymbols, bfosubscribeSymbols]);

  const [exitButtonEnabled, setExitButtonEnabled] = useState(false);

  function formatNumberWithFontWeight(number) {
    if (number === "Unlimited") {
      return <span style={{ fontWeight: 500 }}>Unlimited</span>;
    }
    if (isNaN(number)) {
      return <span>-</span>;
    }
    const formattedValue = parseFloat(number).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue.split(".");
    const formattedFraction = (
      <span style={{ fontWeight: 400 }}>{parts[1]}</span>
    );

    return (
      <span>
        {parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}.{""}
        {formattedFraction}
      </span>
    );
  }
  const handleExitSelected = () => {
    const selectedPositions = allPositions.filter(
      (position) => position.selected
    );
    if (selectedPositions.length > 0) {
      showPosToast(selectedPositions);
      // console.log("selected pos", selectedPositions);
    } else {
      toast.error("Please select at least one position to cancel.");
    }
  };

  const handlePosOKClick = (positions) => {
    hideOverlay();
    exitPositions(positions);
    const updatedPositions = allPositions.map((position) => ({
      ...position,
      selected: false,
    }));
    setAllPositions(updatedPositions);
  };

  const handlePosCloseClick = () => {
    hideOverlay();
    toast.dismiss();
  };

  const showPosToast = (positions) => {
    showOverlay();

    toast(
      <div className="d-flex align-items-center justify-content-between">
        <div>Sure, you want to exit all the selected postions?</div>
        <div className="d-flex justify-content-end align-items-center gap-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              onClick={handlePosCloseClick}>
              <path
                d="M1 1.00002L13.7742 13.7742M1 13.7742L13.7742 1"
                stroke="#FF3908"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div
            className="confirm-popup-ok d-flex justify-content-center align-items-center"
            onClick={() => handlePosOKClick(positions)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="14"
              viewBox="0 0 19 14"
              fill="none">
              <path
                d="M1 6.65685L6.65685 12.3137L17.9706 1"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
      </div>,
      {
        position: "bottom-center",
        closeButton: false,
        icon: false,
        className: "confirm-popup-toast quick-view-toast",
        hideProgressBar: true,
        draggable: true,
        autoClose: false,
      }
    );
  };

  const handleposCheckboxChange = (row_index) => {
    setAllPositions((prevPositions) => {
      return prevPositions.map((position, index) => {
        if (index === row_index) {
          const updatedPosition = {
            ...position,
            ltp: symbolData[position.code]?.ltp,
            selected: !position.selected,
          };
          return updatedPosition;
        } else {
          return position;
        }
      });
    });
  };

  const isAnyCheckboxChecked = allPositions?.some(
    (position) => position?.selected
  );

  // Search functionality
  const handlePositionSearch = (e) => {
    setSearchPosition(e.target.value);
  };

  const handleOrderSearch = (e) => {
    setSerchOrders(e.target.value);
  };

  const searchedPositions = allPositions?.filter((position) =>
    position?.instrument_name
      ?.toLowerCase()
      ?.includes(searchPosition?.toLowerCase())
  );

  const searchedOrders = filteredOrders?.filter((order) =>
    order?.symbol?.toLowerCase()?.includes(searchOrders?.toLowerCase())
  );
  return (
    <>
      {isOverlayActive && <div className="overlay"></div>}
      <div className="quickview-modal-content">
        <div className="quick-view-footer d-flex justify-content-between">
          <div
            className="d-flex justify-content-between"
            style={{ width: "1392px", margin: "auto" }}>
            <div className="d-flex gap-3">
              <button
                className={`quick-view-tab-btn ${activeTab === "positions" ? "active" : ""}`}
                onClick={(e) => {
                  handleClick("positions");
                }}>
                Positions{" "}
                <span
                  className="quick-view-pnl ps-1"
                  style={{ color: netProfit > 0 ? "#5CA81D" : "#DD6565" }}>
                  ₹ {formatNumberWithFontWeight(netProfit)}
                </span>
              </button>

              <button
                className={`quick-view-tab-btn ${activeTab === "orders" ? "active" : ""}`}
                onClick={(e) => {
                  handleClick("orders");
                }}>
                Orders
              </button>
            </div>
            <BuySellModal />
            {expanded && (
              <div role="button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  onClick={handleClose}>
                  <path
                    d="M1 1.00002L13.7742 13.7742M1 13.7742L13.7742 1"
                    stroke={theme === "dark" ? "white" : "black"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
        {expanded && (
          <div className="expanded-quickview-content">
            {activeTab === "positions" && (
              <>
                <div className="d-flex justify-content-between mb-2">
                  <div className="search-container d-flex justify-content-between align-items-center px-2">
                    <input
                      type="text"
                      placeholder="Search positions by symbol"
                      value={searchPosition}
                      onChange={handlePositionSearch}
                      className="search-input"
                    />

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none">
                      <path
                        d="M21 21L17.8421 17.8421M19.9474 10.4737C19.9474 15.7059 15.7059 19.9474 10.4737 19.9474C5.24151 19.9474 1 15.7059 1 10.4737C1 5.24151 5.24151 1 10.4737 1C15.7059 1 19.9474 5.24151 19.9474 10.4737Z"
                        stroke="#646464"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="d-flex gap-3 justify-content-center align-items-center">
                    {/* <button className="quick-view-exit-selected" onClick={showPosToast}>
                    Exit selected
                  </button> */}
                    <svg
                      width="24"
                      height="20"
                      viewBox="0 0 24 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => {
                        fetchPositions();
                      }}>
                      <path
                        d="M3.23682 7.51926C3.7607 5.69228 4.84952 4.07804 6.34722 2.9079C7.84492 1.73777 9.67462 1.07181 11.5741 1.00548C13.4735 0.939155 15.3452 1.47586 16.9209 2.53867C18.4966 3.60148 20.35 6.71401 21 8.5M21 8.5L17 7.5M21 8.5L22.5 5M20.1803 13.4987C19.5032 15.1033 18.3752 16.477 16.9329 17.4532C15.4907 18.4294 13.7962 18.9662 12.0549 18.9985C10.3137 19.0307 8.60043 18.5571 7.123 17.635C5.64558 16.7129 3.23602 14.0784 2.5 12.5M2.5 12.5L6.5 13M2.5 12.5L1.5 16.5"
                        stroke="#1E2028"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <button
                      className={`quickview-exit-pos ${
                        isAnyCheckboxChecked ? "enabled" : "disabled"
                      }`}
                      onClick={handleExitSelected}
                      disabled={!isAnyCheckboxChecked}>
                      Exit Selected
                    </button>
                  </div>
                </div>
                <div className="quickview-positions-table">
                  <div className="quick-view-pos-table-wrapper orders-page ">
                    {!response ? (
                      <table className="position-table w-100 quick-view-pos-table">
                        <thead>
                          <tr className="text-center position-table-header border border-1">
                            <th scope="col">
                              <input type="checkbox" />
                            </th>

                            <th scope="col" className="text-start">
                              Instruments
                            </th>
                            <th>B/S</th>
                            <th>Expiry</th>
                            <th scope="col">Product</th>
                            <th scope="col">Quanity</th>
                            <th scope="col">Avg price</th>
                            <th scope="col">LTP</th>
                            <th scope="col"> P&L</th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    ) : (
                      <table className="position-table w-100 quick-view-pos-table">
                        <thead>
                          <tr
                            key="position-headers"
                            className="text-center position-table-header ">
                            <th scope="col">
                              {/* <input
                            type="checkbox"
                            checked={headerCheckboxChecked}
                            onChange={() =>
                              handleHeaderCheckboxChange("groupbyunderlying")
                            }
                          /> */}
                            </th>
                            <th scope="col" className="text-start">
                              Instruments
                            </th>
                            <th>B/S</th>
                            <th>Expiry</th>
                            <th scope="col">Product</th>
                            <th scope="col">Quanity</th>
                            <th scope="col">Avg price</th>
                            <th scope="col">LTP</th>
                            <th scope="col"> P&L</th>
                          </tr>
                        </thead>
                        <tbody>
                          {searchedPositions &&
                            searchedPositions.map((obj, row_index) => (
                              <>
                                <tr
                                  key={row_index}
                                  className={`position-table-row  ${
                                    obj.net_quantity === 0
                                      ? "disabled-pos-row"
                                      : ""
                                  }`}
                                  onMouseEnter={() =>
                                    handleRowMouseEnter(row_index)
                                  }
                                  onMouseLeave={handleRowMouseLeave}>
                                  <td className="text-center">
                                    <input
                                      type="checkbox"
                                      className="pos-checkbox text-center"
                                      onChange={() =>
                                        handleposCheckboxChange(row_index)
                                      }
                                      disabled={obj.net_quantity === 0}
                                      defaultChecked={obj?.selected}
                                    />
                                  </td>
                                  {/* <td>
                              <input
                                type="checkbox"
                                disabled={obj.net_quantity === 0}
                              />
                            </td> */}
                                  <td className="table-data text-start">
                                    {obj.instrument_name}
                                    {hoveredRowIndex === row_index &&
                                      visibility && (
                                        <div className="virtual-position-buttons-quickview">
                                          <button
                                          className="add-btn"
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdropBuySell"
                                            onClick={() =>
                                              handleAddModal(obj, row_index)
                                            }>
                                            Add
                                          </button>
                                          <button
                                          className="exit-btn"
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdropBuySell"
                                            onClick={() =>
                                              handleExitModal(obj, row_index)
                                            }>
                                            Exit
                                          </button>
                                        </div>
                                      )}
                                  </td>
                                  <td
                                    className={`table-data buy-sell ${
                                      obj.net_quantity === 0
                                        ? "disabled-text"
                                        : obj.isBuy
                                          ? "pos-buy"
                                          : "pos-sell"
                                    }`}>
                                    {obj.net_quantity === 0
                                      ? "-"
                                      : obj.isBuy
                                        ? "BUY"
                                        : "SELL"}
                                  </td>

                                  <td className="table-data text-center">
                                    {obj.expiry_date}
                                  </td>
                                  <td className="d-flex align-items-center justify-content-center my-1">
                                    <div
                                      className={`product-type text-center d-flex align-items-center justify-content-center ${obj.product_type}`}>
                                      {obj.product_type}
                                    </div>
                                  </td>
                                  <td
                                    className="table-data text-center"
                                    style={{
                                      color:
                                        parseFloat(obj.net_quantity) > 0
                                          ? "#5CA81D"
                                          : "#DD6565",
                                    }}>
                                    {obj.net_quantity}
                                  </td>
                                  <td className="table-data text-center">
                                    {String(obj.average_prc).split(".")[0]}.
                                    <span style={{ fontWeight: 400 }}>
                                      {String(obj.average_prc).split(".")[1]}
                                    </span>
                                  </td>
                                  <td className="table-data text-center">
                                    {
                                      String(symbolData[obj.code]?.ltp).split(
                                        "."
                                      )[0]
                                    }
                                    .
                                    <span style={{ fontWeight: 400 }}>
                                      {
                                        String(symbolData[obj.code]?.ltp).split(
                                          "."
                                        )[1]
                                      }
                                    </span>
                                  </td>
                                  <td
                                    className="table-data text-center"
                                    style={{
                                      color:
                                        parseFloat(
                                          obj.realised_pl +
                                            (symbolData[obj.code]?.ltp -
                                              obj.average_prc) *
                                              obj.net_quantity
                                        ) > 0
                                          ? "#5CA81D"
                                          : "#DD6565",
                                    }}>
                                    {formatNumberWithFontWeight(
                                      obj.realised_pl +
                                        (symbolData[obj.code]?.ltp -
                                          obj.average_prc) *
                                          obj.net_quantity
                                    )}
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </>
            )}
            {activeTab === "orders" && (
              <>
                <div className="d-flex justify-content-between mb-2">
                  <div className="search-container d-flex justify-content-between align-items-center px-2">
                    <input
                      type="text"
                      placeholder="Search orders by symbol"
                      value={searchOrders}
                      onChange={handleOrderSearch}
                      className="search-input"
                    />

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none">
                      <path
                        d="M21 21L17.8421 17.8421M19.9474 10.4737C19.9474 15.7059 15.7059 19.9474 10.4737 19.9474C5.24151 19.9474 1 15.7059 1 10.4737C1 5.24151 5.24151 1 10.4737 1C15.7059 1 19.9474 5.24151 19.9474 10.4737Z"
                        stroke="#646464"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="d-flex gap-3 justify-content-center align-items-center">
                    {/* <button className="quick-view-exit-selected">
                    Exit orders
                  </button> */}
                    <svg
                      width="24"
                      height="20"
                      viewBox="0 0 24 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => {
                        fetchOrders();
                      }}>
                      <path
                        d="M3.23682 7.51926C3.7607 5.69228 4.84952 4.07804 6.34722 2.9079C7.84492 1.73777 9.67462 1.07181 11.5741 1.00548C13.4735 0.939155 15.3452 1.47586 16.9209 2.53867C18.4966 3.60148 20.35 6.71401 21 8.5M21 8.5L17 7.5M21 8.5L22.5 5M20.1803 13.4987C19.5032 15.1033 18.3752 16.477 16.9329 17.4532C15.4907 18.4294 13.7962 18.9662 12.0549 18.9985C10.3137 19.0307 8.60043 18.5571 7.123 17.635C5.64558 16.7129 3.23602 14.0784 2.5 12.5M2.5 12.5L6.5 13M2.5 12.5L1.5 16.5"
                        stroke="#1E2028"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <button
                      className={`quick-view-exit-selected ${
                        selectedCheckboxes.length > 0 ? "enabled" : "disabled"
                      }`}
                      disabled={selectedCheckboxes.length === 0}
                      onClick={showToast}>
                      Cancel Selected
                    </button>
                  </div>
                </div>
                <div className="quickview-orders-table orders-page">
                  {filteredOrders.length === 0 ? (
                    <>
                      <table className="table table-borderless mt-3">
                        <thead className="table-head">
                          <tr key="orders-headers" className="mb-5">
                            <th scope="col">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                            </th>
                            <th scope="col">Instrument</th>
                            <th scope="col">Product</th>
                            <th scope="col">Type</th>
                            <th scope="col">Filled Qty / Qty</th>
                            <th scope="col">Avg price</th>
                            <th scope="col">Trigger price</th>
                            <th scope="col">B/S</th>
                            <th scope="col">Order time</th>
                            <th scope="col">Order ID</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>

                      {filteredOrders.length === 0 && (
                        <h2 className="mt-5 text-center fw-bolder">
                          You don't have any {filter !== "all" ? filter : ""}{" "}
                          orders!
                        </h2>
                      )}
                    </>
                  ) : (
                    <table className="table table-borderless mt-3">
                      <thead className="table-head">
                        <tr className="mb-5">
                          <th scope="col">
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </th>
                          <th scope="col">Instrument</th>
                          <th scope="col" className="text-center">
                            Product
                          </th>
                          <th scope="col" className="text-center">
                            Type
                          </th>
                          <th scope="col" className="text-center">
                            Filled Qty / Qty
                          </th>
                          <th scope="col" className="text-center">
                            Avg price
                          </th>
                          <th scope="col" className="text-center">
                            Trigger price
                          </th>
                          <th scope="col" className="text-center">
                            B/S
                          </th>
                          <th scope="col" className="text-center">
                            Order time
                          </th>
                          <th scope="col" className="text-center">
                            Order ID
                          </th>
                          <th scope="col" className="text-center">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody className={theme === "dark" ? "table-dark" : ""}>
                        {searchedOrders.map((order,index) => (
                          <tr key={order.id}
                          onMouseEnter={() => handleRowMouseEnter(index)}
                          onMouseLeave={()=>handleRowMouseLeave()}
                          >
                            <td>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                disabled={order.status !== "Pending"}
                                checked={selectedCheckboxes.includes(order.id)}
                                onChange={() => handleCheckboxChange(order.id)}
                              />
                            </td>
                            <td>{order.symbol}
                            {hoveredRowIndex === index && order.status === "Pending" && visibility &&(<div className="modify-btn-container-quickeview">
                              <button 
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdropBuySell"
                                className="modify-btn"
                                onClick={()=>handleModifyClick(order)}
                              >
                                Modify
                              </button>
                            </div>)}
                            </td>
                            <td className="my-1">
                              <div className="product text-center d-flex align-items-center justify-content-center">
                                {order.productType}
                              </div>
                            </td>
                            <td className="product text-center">
                              {order.orderType}
                            </td>
                            {/* <td>Market</td> */}
                            <td className="text-center">{`${order.filledQty}/${order.qty}`}</td>
                            <td className="text-center">{order.tradedPrice}</td>
                            <td className="text-center">{order.stopPrice}</td>
                            <td
                              className={`text-center ${order.side === -1 ? "text-danger" : "text-success"}`}>
                              {order.side === -1 ? "SELL" : "BUY"}
                            </td>
                            <td className="text-center">
                              {order.orderDateTime}
                            </td>
                            <td className="text-center">{order.id}</td>
                            <td className="text-center">
                              <div className="d-flex align-items-center justify-content-center">
                                <span>{order.status}</span>
                                <span
                                  data-tooltip-id={
                                    order.message !== ""
                                      ? `order-info-tooltip-${order.id}`
                                      : null
                                  }
                                  role="button"
                                  className="ms-2 info-icon rounded-circle d-flex align-items-center justify-content-center">
                                  i
                                </span>
                              </div>
                              {order.message !== "" && (
                                <ReactTooltip
                                  id={`order-info-tooltip-${order.id}`}
                                  place="bottom"
                                  content={order.message}
                                  variant={theme === "dark" ? "light" : "dark"}
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default QuickView;
