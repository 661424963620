import ABWebSocketContext from "./ABWebsocketContext";
import ACAWebSocketContext from "./ACAWebsocketContext";
import GmailWebSocketContext from "./GmailWebSocketContext";
import React, { createContext, useContext } from "react";

const WebsocketWrapperContext = createContext(null);

export const WebsocketWrapperProvider = ({ children }) => {
  const broker = localStorage.getItem("broker");

  const abContext = useContext(ABWebSocketContext);
  const acaContext = useContext(ACAWebSocketContext);
  const gmailContext = useContext(GmailWebSocketContext);

  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } =
    broker === "aliceblue"
      ? abContext
      : broker === "acagarwal"
        ? abContext
        : broker === "gmail"
          ? gmailContext
          : abContext;

  return (
    <WebsocketWrapperContext.Provider
      value={{
        updateSymbolList,
        websocketConnection,
        checkWebsocketConnection,
        subscribeToSymbols,
        unsubscribeToSymbols,
        symbolList,
        symbolData,
      }}>
      {children}
    </WebsocketWrapperContext.Provider>
  );
};

export default WebsocketWrapperContext;
