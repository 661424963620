import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { ABWebSocketProvider } from "./context/ABWebsocketContext";
import { ThemeProvider } from "./context/ThemeContext";
import {
  CallsProvider,
  OptionChainProvider,
} from "./context/OptionChainContext";
import { LtpProvider } from "./context/LtpContext";
import { BasketProvider } from "./context/BasketContext";
import { GraphProvider } from "./context/GraphContext";
import { VisibilityProvider } from "./context/VisibilityContext";
import { ACAWebSocketProvider } from "./context/ACAWebsocketContext";
import { WebsocketWrapperProvider } from "./context/WebsocketWrapperContext";
import { FundsDataProvider } from "./context/FundsDataContext";
import { ButtonProvider } from "./context/ButtonsContext";
import { DataProvider } from "./context/DataContext";
import { BuySellModalProvider } from "./context/BuySellModalContext";
import { OverlayProvider } from "./context/OverlayContext";
import { StatusProvider } from "./context/StatusProvider";
import { GmailWebSocketProvider } from "./context/GmailWebSocketContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

let broker = localStorage.getItem("broker");
if (broker == null || broker == "null") {
  const urlParams = new URLSearchParams(window.location.search);
  broker = urlParams.get("broker");
  if (broker == null) {
    broker = "aliceblue";
  }
  localStorage.setItem("broker", broker);
}

root.render(
  <BrowserRouter>
    <HelmetProvider>
      {broker == "aliceblue" ? (
        <OverlayProvider>
          <ABWebSocketProvider>
            <WebsocketWrapperProvider>
              <FundsDataProvider>
                <GraphProvider>
                  <BuySellModalProvider>
                    <BasketProvider>
                      <LtpProvider>
                        <OptionChainProvider>
                          <CallsProvider>
                            <DataProvider>
                              <VisibilityProvider>
                                <ButtonProvider>
                                  <StatusProvider>
                                    <ThemeProvider>
                                      <App />
                                    </ThemeProvider>
                                  </StatusProvider>
                                </ButtonProvider>
                              </VisibilityProvider>
                            </DataProvider>
                          </CallsProvider>
                        </OptionChainProvider>
                      </LtpProvider>
                    </BasketProvider>
                  </BuySellModalProvider>
                </GraphProvider>
              </FundsDataProvider>
            </WebsocketWrapperProvider>
          </ABWebSocketProvider>
        </OverlayProvider>
      ) : (
        ""
      )}
      {broker == "acagarwal" ? (
        <ABWebSocketProvider>
          <WebsocketWrapperProvider>
            <FundsDataProvider>
              <GraphProvider>
                <BasketProvider>
                  <LtpProvider>
                    <OptionChainProvider>
                      <VisibilityProvider>
                        <ButtonProvider>
                          <App />
                        </ButtonProvider>
                      </VisibilityProvider>
                    </OptionChainProvider>
                  </LtpProvider>
                </BasketProvider>
              </GraphProvider>
            </FundsDataProvider>
          </WebsocketWrapperProvider>
        </ABWebSocketProvider>
      ) : (
        ""
      )}
      {broker == "gmail" ? (
        <OverlayProvider>
          <GmailWebSocketProvider>
            <WebsocketWrapperProvider>
              <FundsDataProvider>
                <GraphProvider>
                  <BuySellModalProvider>
                    <BasketProvider>
                      <LtpProvider>
                        <OptionChainProvider>
                          <CallsProvider>
                            <DataProvider>
                              <VisibilityProvider>
                                <ButtonProvider>
                                  <StatusProvider>
                                    <ThemeProvider>
                                      <App />
                                    </ThemeProvider>
                                  </StatusProvider>
                                </ButtonProvider>
                              </VisibilityProvider>
                            </DataProvider>
                          </CallsProvider>
                        </OptionChainProvider>
                      </LtpProvider>
                    </BasketProvider>
                  </BuySellModalProvider>
                </GraphProvider>
              </FundsDataProvider>
            </WebsocketWrapperProvider>
          </GmailWebSocketProvider>
        </OverlayProvider>
      ) : (
        ""
      )}
    </HelmetProvider>
  </BrowserRouter>
);

reportWebVitals();
