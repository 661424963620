import React from "react";
import PropTypes from "prop-types";

const CustomToastContent = ({ heading, message }) => (
  <div className="base-toast">
    <h6
      style={{
        color: "#000",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
      }}>
      {heading}
    </h6>
    <p
      style={{
        color: "#000",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
      }}>
      {message}
    </p>

  </div>
);

CustomToastContent.propTypes = {
  heading: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default CustomToastContent;
