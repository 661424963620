import React, { useContext, useEffect } from "react";
import StrategyMenu from "../components/StrategyMenu";
import ABWebSocketContext from "../context/ABWebsocketContext";
import AnalyseGraph from "../components/AnalyseGraph";
import { useGraphContext } from "../context/GraphContext";
import StrategyGraph from "../components/StrategyGraph";
import { useTheme } from "../context/ThemeContext";
import WebsocketWrapperContext from "../context/WebsocketWrapperContext";

const AnalyseMobile = ({ data, selectedData, selectedSymbol, type, index, closeCallBack }) => {
  const {theme}=useTheme();
  const { symbolData } = useContext(WebsocketWrapperContext);

  return (
    <div
      className=" modal "
      id="staticBackdropAnalyseMobile"
      data-bs-backdrop="static"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      data-bs-keyboard="false"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="analyse-mobile-modal modal-content">
          <div className="modal-header" style={{ padding: "12px 12px" }}>
            <h1
              className="modal-title analyse-modal-title"
              id="exampleModalLabel">
              Analyse positions
            </h1>
            <button
              type="button"
              onClick={()=>closeCallBack()}
              className={theme==='dark'?'btn-close-white btn-close':'btn-close'}
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div
            className="modal-body analyse-chain-modal-body"
            style={{ padding: "12px" }}>
            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
              {/* <PositionsTable /> */}
              <StrategyMenu data={data} />
              {selectedData && <StrategyGraph
                data={data}
                selectedData={selectedData}
                ltp={symbolData[selectedSymbol?.code]?.ltp}
                leastExpiry={selectedData[0]?.["expiry"]}
              />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyseMobile;
