import React, { createContext, useContext, useEffect, useState } from "react";
import { useGraphContext } from "./GraphContext";
import WebsocketWrapperContext from "./WebsocketWrapperContext";
import { useDataContext } from "./DataContext";

const BasketContext = createContext();

export const BasketProvider = ({ children }) => {
  const [basketData, setBasketData] = useState([]);
  const [buySellStatus, setBuySellStatus] = useState({});
  const {callsDataCon, putsDataCon} = useDataContext() || {} ;
  const {
    x_axis,
    setxAxis,
    y_axis,
    setyAxis,
    graphData,
    setGraphData,
    maxProfit,
    setMaxProfit,
    maxLoss,
    setMaxLoss,
    riskReward,
    setRiskReward,
    breakevenRange,
    setBreakeven,
    totalGreeks,
    setTotalGreeks,
    strategyDirection,
    setStrategyDirection,
    premium,
    setPremium,
  } = useGraphContext();
  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } = useContext(WebsocketWrapperContext);

  const addToBasket = (dataObject) => {
    setBasketData((prevData) => [...prevData, dataObject]);
  };

  const toggleBuySellStatus = (index) => {
    let dataObject = basketData[index];
    dataObject["buy_sell"] = basketData[index]["buy_sell"] === "B" ? "S" : "B";
    setBasketData((prevData) => [...prevData]);
  };

  // Function to handle price input change for a specific row
  const handlePriceChange = (e, ind) => {
    const newPrice = e.target.value.trim() === "" ? "" : e.target.value;
    const newstate = basketData.map((obj, index) => {
      if (index === ind) {
        return { ...obj, price: newPrice };
      }
      return obj;
    });
    setBasketData(newstate);
  };

  const handleInputBlur = (ind) => {
    const newstate = basketData.map((obj, index) => {
      if (index === ind) {
        return { ...obj, price: obj?.price == "" ? symbolData[obj.code]?.ltp : obj?.price };
      }
      return obj;
    });
    setBasketData(newstate);
  };

  const handleIncrement = (index) => {
    let dataObject = basketData[index];
    dataObject["lotSize"] = dataObject["lotSize"] + 1;
    setBasketData((prevData) => [...prevData]);
  };

  const handleDecrement = (index) => {
    let dataObject = basketData[index];
    if (dataObject["lotSize"] > 1) {
      dataObject["lotSize"] = dataObject["lotSize"] - 1;
      setBasketData((prevData) => [...prevData]);
    }
  };

  const removeFromBasket = (index) => {
    setBasketData((prevData) => {
      const newData = [...prevData];
      newData.splice(index, 1);
      return newData;
    });
  };

  const clearBasket = () => {
    setBasketData([]);
  };

  const formXaxis = (ltp) => {
    let g = [];
    let start = 90;
    let stop = 111;
    let step = 0.01;
    let spot = ltp;
    for (var i = start; i < stop; i += step) {
      let n = Math.round(((spot * i) / 100) * 100) / 100;
      g.push(n);
    }
    setxAxis(g);
  };

  const formYaxis = (ltp) => {
    let checkedarrays = [];
    let g = [];

    basketData.map((obj, i) => {
      checkedarrays.push(obj);
    });
    let h = [];
    let premium = 0;

    checkedarrays.map((obj) => {
      let strike = Number(obj.strike);
      let option_price = Number(obj.price);
      let option_type = obj.optionType;
      let y = [];
      let option_lot = obj.quantity * Number(obj.lotSize);
      let buy_sell = obj.buy_sell === "B" ? true : false;

      let price = buy_sell
        ? -(option_price * option_lot)
        : option_price * option_lot;
      premium = premium + price;

      if (option_type == "CE") {
        for (let i = 0; i < x_axis.length; i++) {
          let num1 = x_axis[i] - strike - option_price;
          let num2 = 0 - option_price;
          let m = Math.max(Number(num1), Number(num2));
          y.push(m * option_lot);
        }
      } else {
        for (let i = 0; i < x_axis.length; i++) {
          let num1 = strike - x_axis[i] - option_price;
          let num2 = 0 - option_price;
          let m = Math.max(Number(num1), Number(num2));
          y.push(m * option_lot);
        }
      }
      if (!buy_sell) {
        // if (obj.buy_sell == 'SELL') {
        for (let i = 0; i < y.length; i++) {
          y[i] = -y[i];
        }
      }
      if (h.length == 0) {
        for (let i = 0; i < y.length; i++) {
          // g.push(Math.round(Number(y[i]), 2));
          g[i] = Math.round(y[i] * 100) / 100;
        }
      } else {
        for (let i = 0; i < y.length; i++) {
          let num = g[i] + Number(y[i]);
          g[i] = Math.round(num * 100) / 100;
        }
      }
      h = g;
      // Calculate maxProfit and maxLoss
      const maxProfit = Math.max(...h);
      const maxLoss = Math.min(...h);
      let calculatedMaxProfit;
      let calculatedMaxLoss;
      const maxProfitIndex = h.indexOf(maxProfit);
      if (
        maxProfitIndex === h.length - 1 &&
        h[maxProfitIndex] > h[maxProfitIndex - 1]
      ) {
        calculatedMaxProfit = "Unlimited";
      } else if (
        maxProfitIndex === 0 &&
        h[maxProfitIndex] > h[maxProfitIndex + 1]
      ) {
        calculatedMaxProfit = "Unlimited";
      } else {
        calculatedMaxProfit = maxProfit;
      }
      const maxLossIndex = h.indexOf(maxLoss);
      if (
        maxLossIndex === h.length - 1 &&
        h[maxLossIndex] < h[maxLossIndex - 1]
      ) {
        calculatedMaxLoss = "Unlimited";
      } else if (maxLossIndex === 0 && h[maxLossIndex] < h[maxLossIndex + 1]) {
        calculatedMaxLoss = "Unlimited";
      } else {
        calculatedMaxLoss = maxLoss;
      }

      let calculatedRiskReward;
      if (
        calculatedMaxProfit === "Unlimited" ||
        calculatedMaxLoss === "Unlimited"
      ) {
        calculatedRiskReward = "N/A";
      } else {
        calculatedRiskReward = Math.abs(
          parseFloat(calculatedMaxProfit / calculatedMaxLoss).toFixed(2)
        );
        if (Math.abs(calculatedMaxProfit) > Math.abs(calculatedMaxLoss)) {
          calculatedRiskReward = calculatedRiskReward + ":1";
        } else {
          calculatedRiskReward = "1:" + calculatedRiskReward;
        }
      }
      let breakevenRange = "";
      let strategyDirection = "";
      const indices = [];
      for (let i = 1; i < h.length; i++) {
        if ((h[i - 1] < 0 && h[i] >= 0) || (h[i - 1] >= 0 && h[i] < 0)) {
          indices.push(x_axis[i]);
        }
      }
      if (indices.length == 1) {
        if (h[h.length - 1] > 0) {
          let percent_diff = parseFloat(
            ((indices[0] - ltp) / ltp) * 100
          ).toFixed(2);
          breakevenRange = indices[0] + "(" + percent_diff + "%) >";
          strategyDirection = "Bullish strategy";
        } else {
          let percent_diff = parseFloat(
            ((indices[0] - ltp) / ltp) * 100
          ).toFixed(2);
          breakevenRange = "< " + indices[0] + "(" + percent_diff + "%)";
          strategyDirection = "Bearish strategy";
        }
      }
      if (indices.length == 2) {
        if (h[h.length - 1] > 0) {
          let percent_diff = parseFloat(
            ((indices[1] - ltp) / ltp) * 100
          ).toFixed(2);
          breakevenRange = indices[1] + "(" + percent_diff + "%) >";
          strategyDirection = "Neutral strategy";
        }
        if (h[0] > 0) {
          let percent_diff = parseFloat(
            ((indices[0] - ltp) / ltp) * 100
          ).toFixed(2);
          breakevenRange =
            "< " +
            indices[0] +
            "(" +
            percent_diff +
            "%)" +
            " & " +
            breakevenRange;
          strategyDirection = "Neutral strategy";
        }
        if (
          h[x_axis.indexOf(indices[0])] > 0 &&
          h[x_axis.indexOf(indices[0]) - 1] < 0 &&
          h[x_axis.indexOf(indices[1]) - 1] > 0 &&
          h[x_axis.indexOf(indices[1]) + 1] < 0
        ) {
          let percent_diff = parseFloat(
            ((indices[0] - ltp) / ltp) * 100
          ).toFixed(2);
          breakevenRange = indices[0] + "(" + percent_diff + "%)";
          percent_diff = parseFloat(((indices[1] - ltp) / ltp) * 100).toFixed(
            2
          );
          breakevenRange =
            breakevenRange +
            " < Spot < " +
            indices[1] +
            "(" +
            percent_diff +
            "%)";
          strategyDirection = "Neutral strategy";
        }
      }
      setMaxProfit(calculatedMaxProfit);
      setMaxLoss(calculatedMaxLoss);
      setRiskReward(calculatedRiskReward);
      setBreakeven(breakevenRange);
      setStrategyDirection(strategyDirection);
    });
    setPremium(premium?.toFixed(2));
    setyAxis(h);
    if (h[0] === NaN) {
      formYaxis(ltp);
      return;
    }
  };

  useEffect(() => {
    let underlying_code = basketData[0]?.underlying_code;
    if (underlying_code) {
      formXaxis(symbolData[underlying_code]?.ltp);
    }
  }, [basketData]);

  useEffect(() => {
    let underlying_code = basketData[0]?.underlying_code;
    if (underlying_code) {
      formYaxis(symbolData[underlying_code]?.ltp);
    }
  }, [x_axis]);

  return (
    <BasketContext.Provider
      value={{
        basketData,
        setBasketData,
        addToBasket,
        removeFromBasket,
        buySellStatus,
        toggleBuySellStatus,
        handleIncrement,
        handleDecrement,
        clearBasket,
        handlePriceChange,
        handleInputBlur,
      }}>
      {children}
    </BasketContext.Provider>
  );
};

export const useBasketContext = () => {
  return useContext(BasketContext);
};
