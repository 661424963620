import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const Screener = () => {
  const { type } = useParams();
  const [screenersData, setScreenersData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTimeframe, setSelectedTimeframe] = useState(null);

  const [selectedGroup, SetSelectedGroup] = useState(null);
  const [cardDetails, setCardDetails] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://d6ewrw79kg.execute-api.ap-south-1.amazonaws.com/default/getAllScreeners",
          {
            path: type,
          }
        );

        setScreenersData(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [type]);

  function truncateDescription(description) {
    let maxLength = 35;
    if (document.documentElement.clientWidth < 450) {
      maxLength = 70;
    }

    const truncatedDescription =
      description?.length > maxLength
        ? description.substring(0, maxLength) + "..."
        : description;
    return truncatedDescription;
  }

  const router = useNavigate();
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    screenersData?.filter_options
  );
  const [filteredData, setFilteredData] = useState(screenersData?.data);
  const [searchQuery, setSearchQuery] = useState("");
  const filterRef = useRef(null);
  const [clickedCards, setClickedCards] = useState(
    Array(screenersData?.data?.length).fill(false)
  );

  useEffect(() => {
    setSelectedOptions(screenersData?.filter_options);
  }, [screenersData?.heading]);

  useEffect(() => {
    const newData = screenersData?.data?.filter(
      (pattern) =>
        pattern.tags.every((tag) => selectedOptions.includes(tag.tag_name)) &&
        pattern.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(newData);
  }, [selectedOptions, searchQuery]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setPopupVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };

  const toggleOption = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions?.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleSelectAll = () => {
    setSelectedOptions(screenersData?.filter_options);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClickCard = async (index) => {
    try {
      const patternName = filteredData[index]?.name.replace(/\s+/g, " ");
      let pathToSend = screenersData.heading.toLowerCase();
      router(
        `/screenerDetails?pattern=${encodeURIComponent(patternName)}&path=${encodeURIComponent(pathToSend)}`,
      );
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="analyse-screener-page">
      {loading && <div className="text-center h1 fw-bold">Loading...</div>}
      {error && <div>Error: {error.message}</div>}
      {screenersData && (
        <div>
          {/* Heading section */}

          <div className="row">
            <div className="col-md-6">
              <div className="d-flex align-items-center ">
                <div className="screenerdata-heading">
                  {screenersData?.heading}
                </div>
              </div>
              <div>
                <div className="ms-0 screenerdata-desc md-text-base">
                  {screenersData?.description}
                </div>
              </div>
            </div>
            <div className="col-md-6 search-and-filter">
              <div className="d-flex align-items-center justify-content-between justify-content-md-end gap-3">
                <div className="d-flex justify-content-between">
                  <div className="search-container d-flex justify-content-between align-items-center px-2">
                    <input
                      type="text"
                      placeholder={screenersData?.search_placeholder}
                      value={searchQuery}
                      onChange={handleSearch}
                      className="search-input"
                    />

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none">
                      <path
                        d="M21 21L17.8421 17.8421M19.9474 10.4737C19.9474 15.7059 15.7059 19.9474 10.4737 19.9474C5.24151 19.9474 1 15.7059 1 10.4737C1 5.24151 5.24151 1 10.4737 1C15.7059 1 19.9474 5.24151 19.9474 10.4737Z"
                        stroke="#646464"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>

                <div className="filter-container">
                  <div
                    onClick={togglePopup}
                    className="filter-btn align-items-center justify-content-center position-relative"
                    role="button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="13"
                      viewBox="0 0 14 13"
                      fill="none">
                      <path
                        d="M1 9.9375H8.76471M8.76471 9.9375C8.76471 11.0766 9.71281 12 10.8824 12C12.0519 12 13 11.0766 13 9.9375C13 8.79841 12.0519 7.875 10.8824 7.875C9.71281 7.875 8.76471 8.79841 8.76471 9.9375ZM5.23529 3.0625H13M5.23529 3.0625C5.23529 4.20159 4.28719 5.125 3.11765 5.125C1.9481 5.125 1 4.20159 1 3.0625C1 1.92341 1.9481 1 3.11765 1C4.28719 1 5.23529 1.92341 5.23529 3.0625Z"
                        stroke="#383FEC"
                        strokeLinecap="round"
                      />
                    </svg>
                    <div>Filter</div>
                  </div>
                  {popupVisible && (
                    <div className="popup-visible p-3">
                      <div className="popup-content">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <div className="filter-by">Filter by</div>
                          <div
                            className="view-more-card cursor-pointer"
                            onClick={handleSelectAll}>
                            Select all
                          </div>
                        </div>
                        {screenersData?.filter_options.map((option, index) => (
                          <div key={index} className="pb-1">
                            <label className="cursor-pointer screenerdata-desc">
                              <input
                                type="checkbox"
                                className="form-check-input me-2"
                                checked={selectedOptions.includes(option)}
                                onChange={() => toggleOption(option)}
                              />
                              {option}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Card */}
          <div class="my-3 my-md-4 card-grid">
            {filteredData?.length > 0 ? (
              filteredData.map((pattern, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => handleClickCard(index)}
                    className={`analyse-card cursor-pointer`}
                    style={{
                      transform: clickedCards[index]
                        ? "scale(1.02)"
                        : "scale(1)",
                      transition: "transform 0.2s ease-in-out",
                    }}>
                    <div className="row">
                      <div className="col-9 col-md-8">
                        <div className="card-pattern-name">{pattern.name}</div>

                        <div className="data-group-title mt-1">
                          {truncateDescription(pattern.description)}
                        </div>
                        <div className="view-more-card">View more</div>
                      </div>
                    {pattern?.image && 
                      <div className="col-3 col-md-4 d-lg-flex justify-content-end">
                      <img
                        src={pattern.image}
                        alt="Pattern"
                        className="w-md-100 pattern-image"
                      />
                    </div>
                    
                    }
                    </div>
                    <div className="row">
                      <div className="d-flex justify-content-start align-items-center gap-3 mt-auto">
                        {pattern.tags.map((tag, tagIndex) => (
                          <div
                            key={tagIndex}
                            className="card-tag"
                            style={{
                              backgroundColor: tag.background,
                              color: tag.color,
                            }}>
                            {tag.tag_name}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center font-normal text-2xl text-gray-500 col-span-4 mt-8">
                No stocks with matching criteria found, please try different timeframes or checkback later!
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Screener;
