import React, {useContext} from "react";
import WebsocketWrapperContext from "../context/WebsocketWrapperContext";
import { blackScholes } from "black-scholes";

var iv = require("implied-volatility");

const PositionMetricsComputations = () => {

  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } = useContext(WebsocketWrapperContext);

  const computeXandYAxis = (data) => {

    let x_axis = null;
    let metrics = null;
    let index;

    if (data?.["ltp"]){
      let resp = formXaxis(data?.["symbol"], data?.["ltp"]);
      index = resp?.low;
      x_axis = resp?.newArray;
    }

    metrics =  formYaxis(x_axis, data?.["selectedData"], data["lotsVal"], data?.["ltp"]);
    metrics["x_axis"] = x_axis;
    metrics["ltpIndex"] = index;
    return metrics;
    
  }

  function computeIV(optionPrice, spot, strike, timeToExpiration, optionType) {
    const riskFreeRate = 0.1 / 365; //moved this out of the loop
    const initialGuessIv = iv.getImpliedVolatility(
      optionPrice,
      spot,
      strike,
      timeToExpiration,
      riskFreeRate,
      optionType
    );
    return initialGuessIv;
  }

  function computeTargetLine(
    option_price,
    x_axis,
    spot,
    row,
    timeToExpiration,
    annualizedDaysCount,
    optionType
  ) {
    const riskFreeRate = 0.1 / 365;
    let targetPrices = [];
    let rowIv = computeIV(
      parseFloat(option_price),
      parseFloat(spot),
      row?.strike,
      annualizedDaysCount,
      optionType
    ).toFixed(2);
    if (parseInt(rowIv) > 1) {
      rowIv = rowIv / 100;
    }
    for (let val = 0; val < x_axis?.length; val++) {
      const optionPrice = blackScholes(
        x_axis[val],
        row?.strike,
        timeToExpiration,
        rowIv,
        riskFreeRate,
        optionType
      );
      if (isNaN(optionPrice)){
        targetPrices.push(0)
      } else {
        targetPrices.push(optionPrice);
      }
    }
    return targetPrices;
  }

  const formXaxis = (symbol, ltp) => {
    let step = parseFloat(symbol?.step);
    let valuesArray = [];

    if (!step){
      let x_axis = [];
      let start = 85; 
      let stop = 116; 
      let step = 0.01; 
      for (var i = start; i < stop; i += step) {
        let n = Math.round((ltp * i) / 100, 2);
        x_axis.push(Number(n));
      }
      valuesArray = x_axis;
    } else {
      const roundedValue = Math.round(ltp / step) * step;
      const lowerBound = roundedValue - roundedValue * 0.08;
      const upperBound = roundedValue + roundedValue * 0.08;
      for (let value = lowerBound; value <= upperBound; value += step) {
        let step_rounded = Math.floor(value / step) * step;
        valuesArray.push(step_rounded);
      }
    }    
    let newArray = [...valuesArray];
    let low = 0;
    let high = valuesArray.length - 1;
    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      if (valuesArray[mid] === ltp) {
        // If the element already exists, you can handle it as needed
        return;
      } else if (valuesArray[mid] < ltp) {
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }

    newArray.splice(low, 0, parseFloat(ltp));

    return {newArray, low};

  };

  const formYaxis = (x_axis, selectedData, lotsVal, ltp) => {
    let checkedarrays = [];
    let g = [];
    let calculatedMaxProfit;
    let calculatedMaxLoss;
    let calculatedRiskReward;

    let nearest_expirydate;
    if (selectedData[0].hasOwnProperty("expiry")) {
      nearest_expirydate = new Date(selectedData[0]?.expiry);
    } else {
      nearest_expirydate = new Date(selectedData[0]?.expiry_date);
    }
    let leastdate = nearest_expirydate;
    let ddate;
    let realisedpnl = 0;
    selectedData.map((obj, i) => {
      if (obj?.net_quantity != 0){
        if (obj?.isFut){
          let call_obj = { ...obj };
          let put_obj = { ...obj };

          if (obj?.isBuy){
            put_obj["isCALL"] = false;
            put_obj["isBuy"] = false;
            put_obj["strike"] = obj?.average_prc;
            put_obj["net_quantity"] = -put_obj["net_quantity"];
            put_obj["average_prc"] = 0;

            call_obj["isCALL"] = true;
            call_obj["isBuy"] = true;
            call_obj["strike"] = obj?.average_prc;
            call_obj["net_quantity"] = call_obj["net_quantity"];
            call_obj["average_prc"] = 0;
          } else {
            put_obj["isCALL"] = false;
            put_obj["isBuy"] = true;
            put_obj["strike"] = obj?.average_prc;
            put_obj["net_quantity"] = put_obj["net_quantity"];
            put_obj["average_prc"] = 0;
            
            call_obj["isCALL"] = true;
            call_obj["isBuy"] = false;
            call_obj["strike"] = obj?.average_prc;
            call_obj["net_quantity"] = -call_obj["net_quantity"];
            call_obj["average_prc"] = 0;
          }
          checkedarrays.push(call_obj);
          checkedarrays.push(put_obj);
        } else {
          checkedarrays.push(obj);
        }
        if (obj.hasOwnProperty("expiry")) {
          ddate = new Date(obj?.expiry);
        } else {
          ddate = new Date(obj?.expiry_date);
        }
        leastdate = new Date(Math.min(ddate, leastdate));
      } 
      else {
        realisedpnl += obj?.realised_pl;
      }
    });

    let h = [];

    let premium = 0;
    lotsVal = parseInt(lotsVal);

    checkedarrays.map((obj) => {
      let strike = Number(obj.strike);
      let option_price = obj.price
        ? obj.price
        : Number(symbolData[obj.code]?.ltp);
      
      if (!option_price){
        option_price = obj?.average_prc;
      }
      let option_type = obj.isCALL ? "call" : "put";
      let y = [];

      // if (!lotsVal){
      lotsVal = parseInt(obj?.net_quantity) / parseInt(obj?.lotSize);
      // }
      let option_lot = Math.abs(lotsVal * Number(obj.lotSize));

      let price = obj.isBuy
        ? -(option_price * option_lot)
        : option_price * option_lot;
      premium = premium + price;

      let expiry = obj.hasOwnProperty("expiry") ? obj?.expiry : obj?.expiry_date
      const expiryDate = new Date(expiry + " 15:29:00 GMT+05:30");
      const currentDate = new Date();
      let annualizedDaysCount = (expiryDate - currentDate)/ (1000 * 60 * 60 * 24 * 365);
      let timeToExpiration = (new Date(expiry) - leastdate) / (1000 * 60 * 60 * 24 * 365);
      let line = computeTargetLine(
        option_price,
        x_axis,
        ltp,
        obj,
        timeToExpiration,
        annualizedDaysCount,
        option_type
      );
      if (obj?.isBuy) {
        y = line.map((item) => (item - obj?.average_prc) * option_lot + realisedpnl);
      } else {
        y = line.map((item) => (obj?.average_prc - item) * option_lot + realisedpnl);
      }
      if (h.length == 0) {
        for (let i = 0; i < y.length; i++) {
          // g.push(Math.round(Number(y[i]), 2));
          g[i] = Math.round(y[i] * 100) / 100;
        }
      } else {
        for (let i = 0; i < y.length; i++) {
          let num = g[i] + Number(y[i]);
          g[i] = Math.round(num * 100) / 100;
        }
      }
      h = g;
      // Calculate maxProfit and maxLoss
      const maxProfit = Math.max(...h);
      const maxLoss = Math.min(...h);

      const maxProfitIndex = h.indexOf(maxProfit);
      if (
        maxProfitIndex === h.length - 1 &&
        h[maxProfitIndex] > h[maxProfitIndex - 1]
      ) {
        calculatedMaxProfit = "Unlimited";
      } else if (
        maxProfitIndex === 0 &&
        h[maxProfitIndex] > h[maxProfitIndex + 1]
      ) {
        calculatedMaxProfit = "Unlimited";
      } else {
        calculatedMaxProfit = maxProfit;
      }
      const maxLossIndex = h.indexOf(maxLoss);
      if (
        maxLossIndex === h.length - 1 &&
        h[maxLossIndex] < h[maxLossIndex - 1]
      ) {
        calculatedMaxLoss = "Unlimited";
      } else if (maxLossIndex === 0 && h[maxLossIndex] < h[maxLossIndex + 1]) {
        calculatedMaxLoss = "Unlimited";
      } else {
        calculatedMaxLoss = maxLoss;
      }

      if (
        calculatedMaxProfit === "Unlimited" ||
        calculatedMaxLoss === "Unlimited"
      ) {
        calculatedRiskReward = "N/A";
      } else {
        calculatedRiskReward = Math.abs(
          parseFloat(calculatedMaxProfit / calculatedMaxLoss).toFixed(2)
        );
        if (Math.abs(calculatedMaxProfit) > Math.abs(calculatedMaxLoss)) {
          calculatedRiskReward = calculatedRiskReward + ":1";
        } else {
          calculatedRiskReward = "1:" + calculatedRiskReward;
        }
      }
    });

    let breakevenRange = "";
    let strategyDirection = "Neutral";
    const indices = [];
    let green_range = [];


    for (let i = 1; i < h.length; i++) {
      if ((h[i - 1] < 0 && h[i] >= 0) || (h[i - 1] >= 0 && h[i] < 0)) {
        indices.push(x_axis[i]);
      }
    }
    if (indices.length == 1) {
      if (h[h.length - 1] >= 0) {
        let percent_diff = parseFloat(((indices[0] - ltp) / ltp) * 100).toFixed(
          2
        );
        breakevenRange = indices[0] + "(" + percent_diff + "%) >";
        strategyDirection = "Bullish strategy";
        green_range.push(indices[0]);
          green_range.push(x_axis[x_axis.length - 1]);
      } else {
        let percent_diff = parseFloat(((indices[0] - ltp) / ltp) * 100).toFixed(
          2
        );
        breakevenRange = "< " + indices[0] + "(" + percent_diff + "%)";
        strategyDirection = "Bearish strategy";
        green_range.push(x_axis[0]);
        green_range.push(indices[0]);
      }
    }
    if (indices.length == 2) {
      if (h[h.length - 1] >= 0) {
        let percent_diff = parseFloat(((indices[1] - ltp) / ltp) * 100).toFixed(
          2
        );
        breakevenRange = indices[1] + "(" + percent_diff + "%) >";
        strategyDirection = "Neutral strategy";
        green_range.push(x_axis[0]);
        green_range.push(indices[0]);
        green_range.push(indices[1]);
        green_range.push(x_axis[x_axis.length - 1]);
      }
      if (h[0] > 0) {
        let percent_diff = parseFloat(((indices[0] - ltp) / ltp) * 100).toFixed(
          2
        );
        breakevenRange =
          "< " +
          indices[0] +
          "(" +
          percent_diff +
          "%)" +
          " & " +
          breakevenRange;
        strategyDirection = "Neutral strategy";
      }
      if (
        h[x_axis.indexOf(indices[0])] > 0 &&
        h[x_axis.indexOf(indices[0]) - 1] <= 0 &&
        h[x_axis.indexOf(indices[1]) - 1] >= 0 &&
        h[x_axis.indexOf(indices[1]) + 1] < 0
      ) {
        let percent_diff = parseFloat(((indices[0] - ltp) / ltp) * 100).toFixed(
          2
        );
        breakevenRange = indices[0] + "(" + percent_diff + "%)";
        percent_diff = parseFloat(((indices[1] - ltp) / ltp) * 100).toFixed(2);
        breakevenRange =
          breakevenRange +
          " < Spot < " +
          indices[1] +
          "(" +
          percent_diff +
          "%)";
        strategyDirection = "Neutral strategy";
        green_range.push(indices[0]);
        green_range.push(indices[1]);
      }
    }

    return {
      maxProfit: calculatedMaxProfit,
      maxLoss: calculatedMaxLoss,
      riskReward: calculatedRiskReward,
      breakevenRange: breakevenRange,
      strategyDirection: strategyDirection,
      premium: premium?.toFixed(2),
      y_axis: h,
      greenRange: green_range
    };

  }

  return {
    computeXandYAxis
  }
  
}

export default PositionMetricsComputations;