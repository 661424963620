import React from "react";
import { useGraphContext } from "../context/GraphContext";
import { useTheme } from "../context/ThemeContext";
import { col } from "jstat";

const StrategyMenu = ({data}) => {
  const {theme}= useTheme();
  let maxProfit = data?.["maxProfit"];
  let maxLoss = data?.["maxLoss"];
  let riskReward = data?.["riskReward"];
  let breakevenRange = data?.["breakevenRange"];
  let strategyDirection = data?.["strategyDirection"];

  function formatNumberWithCommasAndFontWeight(value) {
    let parts = String(value).match(/^(\$)(\d+)(\.\d+)?/);
    if (!parts) {
      return value;
    }
    return (
      <span>
        {parts[0]}.<span style={{ fontWeight: 400 }}>{parts[1]}</span>
      </span>
    );
  }

  const color = strategyDirection === "Bullish strategy"
  ? "#5CA81D" // Green color for Bullish strategy
  : strategyDirection === "Bearish strategy"
  ? "#DD6565" // Red color for Bearish strategy
  : theme === 'light' ? "#383FEC" // Use light theme color
  : "#74B9F9"; // Fallback to dark theme color

  return (
    <div className="strategy-menu d-flex justify-content-md-start align-items-center px-3 gap-md-3 w-100 flex-wrap">
      <div
        className="strategy-menu-item d-flex flex-column"
        style={{ width: "130px" }}>
        <span className="creator-bottom-title">Type</span>
        <span className="strategy-menu-value ">
          <div
            className="bullish"
            style={{
              color:color,
            }}>
            {strategyDirection}{" "}
          </div>
        </span>
      </div>

      <div
        className="strategy-menu-item d-flex flex-column"
        style={{ width: "110px" }}>
        <span className="creator-bottom-title">Max profit</span>
        <span className="strategy-menu-value ">
          ₹ {formatNumberWithCommasAndFontWeight(maxProfit)}
        </span>
      </div>
      <div
        className="strategy-menu-item d-flex flex-column"
        style={{ width: "110px" }}>
        <span className="creator-bottom-title">Max loss </span>
        <span className="strategy-menu-value ">
          ₹ {formatNumberWithCommasAndFontWeight(maxLoss)}
        </span>
      </div>
      <div
        className="strategy-menu-item d-flex flex-column"
        style={{ width: "90px" }}>
        <span className="creator-bottom-title">Risk:Reward </span>
        <span className="strategy-menu-value">{riskReward}</span>
      </div>

      {/* <div
        className="strategy-menu-item d-flex flex-column ms-md-0 ps-md-0"
        style={{ width: "90px" }}>
        <span className="creator-bottom-title">Probability</span>
        <span className="strategy-menu-value text-danger">32.50%</span>
      </div> */}
      <div className="strategy-menu-item d-flex flex-column pe-md-0 me-md-0">
        <span className="creator-bottom-title">Breakeven range</span>
        <span className="strategy-menu-value">
          {formatNumberWithCommasAndFontWeight(breakevenRange)}
        </span>
      </div>
    </div>
  );
};

export default StrategyMenu;
