import React, { useEffect, useContext, useState, useLayoutEffect } from "react";
import { CLIENT_ID } from "../utils/constants";
import { Link, useLocation } from "react-router-dom";
import { ButtonContext } from "../context/ButtonsContext";
import { getSetting } from "../utils/api";
import { useTheme } from "../context/ThemeContext";

const MenuModal = () => {
  const {
    isLive,
    setIsLive,
    isVirtual,
    setIsVirtual,
    toggleLive,
    toggleVirtual,
  } = useContext(ButtonContext);
  const { theme, setLightMode, setDarkMode } = useTheme();
  const [settingData, setSettingData] = useState(null);
  const broker = localStorage.getItem("broker");
  const clientId = localStorage.getItem("client_id");
  const access_token = localStorage.getItem("access_token");
  const location = useLocation();

  const handleVirtualCheckboxChange = () => {
    toggleVirtual();
    toggleLive();
    getSetting(broker, clientId, "SET", access_token, "virtual");
  };
  const handleLiveToggle = () => {
    toggleVirtual();
    toggleLive();
    getSetting(broker, clientId, "SET", access_token, "live");
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "https://1lyoptions.com/";
  };

  //Use effect for setting value of trade mode in api
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getSetting(broker, clientId, "GET", access_token);
        setSettingData(data);
        if (data.user_settings.trade_mode === "live") {
          setIsLive(true);
          setIsVirtual(false);
        } else {
          setIsVirtual(true);
          setIsLive(false);
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    const modalCloseButton = document.querySelector(
      '[data-bs-dismiss="modal"]'
    );
    if (modalCloseButton) {
      modalCloseButton.click();
    }
  }, [location]);

  return (
    <>
      <div
        className="modal option-chain-modal menu-modal"
        id="menuModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true">
        <div
          className="modal-dialog modal-xl option-chain-modal-dialog"
          style={{ margin: "0" }}>
          <div
            className="modal-content option-chain-modal-content"
            style={{ height: "100vh" }}>
            <div className="modal-body d-flex flex-column">
              <div className="d-flex justify-content-between mt-3">
                <div className="d-flex flex-column">
                  <div className="menu-title">{CLIENT_ID}</div>
                  <div className="menu-description">
                    Find more tools & actions here
                  </div>
                </div>
                <button
                  type="button"
                  className={
                    theme === "dark"
                      ? "btn-close-white btn-close mb-2"
                      : "btn-close mb-2"
                  }
                  data-bs-dismiss="modal"
                  aria-label="Close"></button>
              </div>

              {/* <div className="d-flex flex-column mt-3">
                <div className="d-flex justify-content-between">
                  <div className="virtual-title">Verified P&L</div>
                  <span className="coming-soon">Coming soon</span>
                </div>
                <Link className="virtual-feature">My Verified P&L’s</Link>
              </div> */}
              {/* <div className="d-flex flex-column mt-3">
                <div className="d-flex justify-content-between">
                  <div className="virtual-title">Market</div>
                  <span className="coming-soon">Coming soon</span>
                </div>
                <Link className="virtual-feature">Strategy finder</Link>
              </div> */}
              {/* <div className="d-flex flex-column mt-3">
                <div className="d-flex justify-content-between">
                  <div className="virtual-title">OI Analysis</div>
                  <span className="coming-soon">Coming soon</span>
                </div>
                <Link className="virtual-feature">Options premium</Link>
                <Link className="virtual-feature">Multistrike charts</Link>
                <Link className="virtual-feature">PCR charts</Link>
              </div> */}
              <div className="d-flex flex-column mt-3">
                <div className="virtual-title" style={{ fontSize: "16px" }}>
                  Market Overview
                </div>
                <Link
                  className="virtual-feature"
                  to="/discover"
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}>
                  Discover
                </Link>
                <Link
                  className="virtual-feature"
                  to="/fiidii"
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}>
                  FII & DII Data
                </Link>

                <div className="virtual-title" style={{ fontSize: "16px" }}>
                  Calculators
                </div>
                <Link
                  className="virtual-feature"
                  to="/Optionspricing"
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}>
                  Options pricing
                </Link>
                <Link
                  className="virtual-feature"
                  to="/pivotpoints"
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}>
                  Pivot points
                </Link>
                <Link
                  className="virtual-feature"
                  to="/fibbanoci"
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}>
                  Fibonacci retracement
                </Link>
                <div className="d-flex justify-content-between">
                  <div className="virtual-title">Analysis</div>
                </div>
                <Link className="virtual-feature" to="/screeners/candlesticks">
                  Candlesticks
                </Link>
                <Link className="virtual-feature" to="/screeners/scanners">
                  Scanners
                </Link>
                <Link className="virtual-feature" to="/screeners/technicals">
                  Technical Indicators
                </Link>
              </div>
              <div className="d-flex flex-column mt-3">
                {/* <div className="trade-mode-mobile" >
                  <div className="trade-mode-text">Trade Mode</div>
                  <div className="toggle-container">
                    <button
                      className={`toggle-button ${isLive ? "active" : ""}`}
                      onClick={handleLiveToggle}>
                      Live
                    </button>
                    <button
                      className={`toggle-button ${isVirtual ? "active" : ""}`}
                      onClick={handleVirtualCheckboxChange}>
                      Virtual
                    </button>
                  </div>
                </div> */}
                <div className="trade-mode-mobile">
                  <div className="trade-mode-text">Theme</div>
                  <div className="toggle-container">
                    <button
                      className={`toggle-button ${theme === "light" ? "active" : ""}`}
                      onClick={setLightMode}>
                      Light
                    </button>
                    <button
                      className={`toggle-button ${theme === "dark" ? "active" : ""}`}
                      onClick={setDarkMode}>
                      Dark
                    </button>
                  </div>
                </div>
                <Link onClick={handleLogout} style={{ marginTop: "24px" }}>
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuModal;
