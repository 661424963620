import React, { useContext, useEffect, useState } from "react";
import {
  getStockDetails,
  getStockTechnicals,
  getStockList,
} from "../../utils/api";
import { useLocation, useNavigate } from "react-router-dom";
import BuySellModal from "../../modals/BuySellModal";
import SearchModal from "../../modals/SearchModal";
import StockSearchModal from "../../modals/StockSearchModal";
import { useBuySellModalContext } from "../../context/BuySellModalContext";
import ABWebSocketContext from "../../context/ABWebsocketContext";
import WebsocketWrapperContext from "../../context/WebsocketWrapperContext";
import Highcharts, { chart, color } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsTreemap from 'highcharts/modules/treemap';

if (typeof Highcharts === 'object') {
  highchartsTreemap(Highcharts);
}

function StockDetailsDesktop() {
  const location = useLocation();
  const router = useNavigate();
  const { buySellModalData, addToBuySellModal, setBuySellModalData } =
    useBuySellModalContext();
  const queryParams = new URLSearchParams(location.search);
  const stockName = queryParams.get("stock");

  const [activeTab, setActiveTab] = useState("Intraday");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [updatedStocks, setUpdatedStocks] = useState();
  const [technicals, setTechnicals] = useState();
  const [stockList, setStockList] = useState([]);
  const [selectedTimeframe, setSelectedTimeframe] = useState(null);

  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } = useContext(WebsocketWrapperContext);

  const [triggerSubscribe, setTriggerSubscribe] = useState(true);
  let connectionEstablished = false;

  let bullishRatio = 0;
  let bearishRatio = 0;
  let bullishRatio2 = 0;
  let bearishRatio2 = 0;
  let bullishCount = 0;
  let bearishCount = 0;
  let bullishCount2 = 0;
  let bearishCount2 = 0;

  //Effect for websocket subscription
  useEffect(() => {
    const symbols = updatedStocks?.code;
    connectionEstablished = checkWebsocketConnection();
    if (connectionEstablished) {
      if (triggerSubscribe) {
        const exchangeValue = updatedStocks?.exchange;
        subscribeToSymbols([symbols], exchangeValue);
        // If symbolList is empty the message is not sent to the websocket
        if (Object.keys(symbolList)?.length !== 0) {
          setTriggerSubscribe(false);
        }
      }
    } else {
      setTriggerSubscribe(true);
    }

    return () => {};
  }, [checkWebsocketConnection, updatedStocks]);

  useEffect(() => {
    setTriggerSubscribe(true);
  }, [updatedStocks]);

  //for scanners data
  if (updatedStocks?.scanner_data){
    bullishCount = (updatedStocks?.scanner_data?.filter(
      (scanner) => scanner.type === "Bullish"
    )?.length);
    bearishCount = (updatedStocks?.scanner_data?.filter(
      (scanner) => scanner.type === "Bearish"
    )?.length);
    const totalScanners = updatedStocks?.scanner_data?.length;
    bullishRatio = (bullishCount / totalScanners);
    bearishRatio = (bearishCount / totalScanners);
  }
  
  //for candlesticks data
  if (updatedStocks?.candlestick_data){
    bullishCount2 = (updatedStocks?.candlestick_data?.filter(
      (scanner) => scanner.type === "Bullish"
    )?.length);
    bearishCount2 = (updatedStocks?.candlestick_data?.filter(
      (scanner) => scanner.type === "Bearish"
    )?.length);
    const totalScanners2 = updatedStocks?.candlestick_data?.length;
    bullishRatio2 = (bullishCount2 / totalScanners2);
    bearishRatio2 = (bearishCount2 / totalScanners2);  
  }
  
  useEffect(() => {
    //api calls
    const fetchData = async () => {
      try {
        // console.log("Stock Name:", stockName);
        if (stockName) {
          const stockDetails = await getStockDetails(stockName, "Intraday");
          const stockTechnicals = await getStockTechnicals(stockName, "1day");
          const stockList = await getStockList();
          setStockList(stockList);
          setUpdatedStocks(stockDetails);
          setTechnicals(stockTechnicals);
        }
      } catch (error) {
        console.error("Error fetching stock details:", error);
      }
    };

    console.log("effect called");
    fetchData();
  }, [stockName]);

  const handleStockItemClick = (stock) => {
    searchClose();
    router({
      pathname: "/stockDetails",
      search: `?stock=${stock}`,
    });
  };

  //on changing intraday or swing
  useEffect(() => {
    const fetchData = async () => {
      try {
        const update = await getStockDetails(stockName, activeTab);
        setUpdatedStocks(update);
        // console.log(updatedStocks);
      } catch (error) {
        console.error("Error updating stock details:", error);
      }
    };

    fetchData();
  }, [activeTab, stockName]);

  // on changing time frame
  const handleTimeframeChange = async (event) => {
    const timeframe = event.target.value;
    setSelectedTimeframe(timeframe);
    try {
      const updated = await getStockTechnicals(stockName, timeframe);
      setTechnicals(updated);
      // console.log(updated);
    } catch (error) {
      console.log(error);
    }
  };

  //   //login modal
  //   const openModal = () => {
  //     setIsModalOpen(true)
  //   }

  //   const closeModal = () => {
  //     setIsModalOpen(false)
  //   }
  //   //search modal

  const searchOpen = () => {
    setIsModalOpen2(true);
  };
  const searchClose = () => {
    setIsModalOpen2(false);
  };

  function formatNumberWithCommasAndFontWeight(value) {
    const formattedValue = parseFloat(value).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue.split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1];

    return (
      <span style={{ fontWeight: 700 }}>
        {integerPart}.<span style={{ fontWeight: 400 }}>{decimalPart}</span>
      </span>
    );
  }

  const ltpValue = updatedStocks?.close;
  const openValue = updatedStocks?.open;
  const lowerLimit = updatedStocks?.low;
  const higherLimit = updatedStocks?.high;
  const [pointerPosition, setPointerPosition] = useState(0);
  const [barPosition, setBarPointerPosition] = useState(0);

  // console.log( "lowerLimit, higherLimit",lowerLimit, higherLimit)
  const calculatePointerPosition = () => {
    if (
      ltpValue !== undefined &&
      higherLimit !== undefined &&
      lowerLimit !== undefined &&
      openValue !== undefined
    ) {
      const range = parseFloat(higherLimit) - parseFloat(lowerLimit);
      let pointerPosition = parseFloat(ltpValue) - parseFloat(lowerLimit);
      let barPosition;
      if (parseFloat(ltpValue) < parseFloat(openValue)) {
        barPosition = parseFloat(ltpValue) - parseFloat(lowerLimit);
      } else {
        barPosition = parseFloat(openValue) - parseFloat(lowerLimit);
      }
      const position = (pointerPosition / range) * 100;

      barPosition = (barPosition / range) * 100;
      setPointerPosition(position);
      setBarPointerPosition(barPosition);

      const ltpOpenDifference = Math.abs(
        parseFloat(ltpValue) - parseFloat(openValue)
      );
      const thumbWidthPercentage = (ltpOpenDifference / range) * 100;
      // width of slider
      document.documentElement.style.setProperty(
        "--thumb-width",
        `${thumbWidthPercentage > 100 ? 100 : thumbWidthPercentage}%`
      );
      document.documentElement.style.setProperty(
        "--thumb-position",
        `${barPosition}%`
      );
    }
  };

  //slider color
  const sliderColor = openValue > ltpValue ? "#FF3908" : "#5ca81d";

  useEffect(() => {
    calculatePointerPosition();
  }, [ltpValue, higherLimit, lowerLimit]);

  //func to parse a value
  function parser(valueString) {
    if (valueString === null || valueString.trim() === "") {
      return "-";
    }

    const values = [];

    try {
      const parsedValue = JSON.parse(valueString);

      for (const key in parsedValue) {
        if (parsedValue.hasOwnProperty(key)) {
          const value = parsedValue[key];
          if (!isNaN(value)) {
            const roundedValue = parseFloat(value).toFixed(2);
            values.push(roundedValue);
          }
        }
      }

      if (values?.length === 0) {
        return "-";
      }
    } catch (error) {
      return "-";
    }

    const result = values.join(", ");
    return result;
  }
  const handleClick = (Name) => {
    const pattern = Name;
    const path = "candlesticks";
    router({
      pathname: "/screenerDetails",
      search: `?pattern=${pattern}&path=${path}`,
    });
  };

  const handleClick2 = (scannerName) => {
    const pattern = scannerName;
    const path = "scanners";
    router({
      pathname: "/screenerDetails",
      search: `?pattern=${pattern}&path=${path}`,
    });
  };
  //pointer position cpr values
  const [pointerPosition2, setPointerPosition2] = useState(0);

  useEffect(() => {
    function positionPointer(close, r3, s3) {
      const range2 = r3 - s3;
      const position = close - s3;
      let result = (position / range2) * 100;
      if (result > 100) {
        result = 100;
      }
      return result;
    }

    if (
      updatedStocks &&
      updatedStocks?.pivot_levels &&
      updatedStocks?.pivot_levels?.length > 0
    ) {
      const close = updatedStocks?.close;
      const r3 = updatedStocks?.pivot_levels[0]?.r3; // Use optional chaining here
      const s3 = updatedStocks?.pivot_levels[0]?.s3; // Use optional chaining here

      const newPointerPosition = positionPointer(close, r3, s3);
      setPointerPosition2(newPointerPosition);
      // console.log(newPointerPosition);
    }
  }, [updatedStocks]);

  //gap
  const bandRange =
    updatedStocks?.pivot_levels?.length > 0
      ? updatedStocks?.pivot_levels[0].band_range
      : null;

  // Map band_range values to descriptive terms
  let width;
  switch (bandRange) {
    case "Very Narrow":
      width = "1px";
      break;
    case "Narrow":
      width = "4px";
      break;
    case "Broad":
      width = "0.75rem";
      break;
    case "Very Broad":
      width = "1rem";
      break;
    default:
      width = "1px";
  }
  // console.log("bearishRatio2:", bearishRatio2);
  // console.log("bullishRatio2:", bullishRatio2);

  // const bearishLength = Math.round(bearishRatio2 * 15);
  // console.log("bearishLength:", bearishLength);

  // const bullishLength = Math.round(bullishRatio2 * 15);
  // console.log("bullishLength:", bullishLength);
  const navigate = useNavigate();

  const redirectToOptionChain = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const stock = urlParams.get("stock");
    if (stock) {
      navigate(`/optionchain?trading_symbol=${stock}`);
    }
  };

  const handleSellClick = (action) => {
    const tradeData = updatedStocks;
    const ltpPrice = symbolData[tradeData.code]?.ltp;

    const dataObject = {
      side: action === "Buy" ? "Buy" : "Sell",
      instrumentName: tradeData.trading_symbol,
      lotSize: 1,
      lot_size: 1,
      productType: null,
      tradingSymbol: tradeData.name,
      expiryDate: "",
      code: tradeData.code,
      price: ltpPrice || 0,
      trigger_price: ltpPrice || 0,
      buy_sell: action === "Buy" ? "B" : "S", // Determine the buy_sell value based on the action
      underlying_code: symbolData[tradeData.code].underlying_symbol,
      strike: symbolData[tradeData.code].strike,
      quantity: 1,
      exchange: tradeData.exchange,
      scrip: symbolData[tradeData.code].scrip,
      lot_multiplier: 1,
    };
    setBuySellModalData([dataObject]);
  };

  const handleBuyClick = (action) => {
    const tradeData = updatedStocks;
    const ltpPrice = symbolData[tradeData.code]?.ltp;
    const dataObject = {
      side: action === "Buy" ? "Buy" : "Sell",
      instrumentName: tradeData.name,
      lotSize: 1,
      lot_size: 1,
      productType: tradeData.product_type,
      tradingSymbol: tradeData.trading_symbol,
      expiryDate: "",
      code: tradeData.code,
      price: ltpPrice || 0,
      trigger_price: ltpPrice || 0,
      buy_sell: action === "Buy" ? "B" : "S", // Determine the buy_sell value based on the action
      underlying_code: tradeData.underlying_symbol,
      strike: tradeData.strike,
      quantity: 1,
      exchange: tradeData.exchange,
      scrip: tradeData.scrip,
      lot_multiplier: 1,
    };
    setBuySellModalData([dataObject]);
  };
//charts
  const options = {
    chart: {
      height: 800,
    },
    credits: { enabled: false },
    series: [{
      type: 'treemap',
      layoutAlgorithm: 'squarified',
      allowDrillToNode: true,
      animationLimit: 1000,
      dataLabels: {
        enabled: false
      },
      levelIsConstant: false,
      levels: [{
        level: 1,
        dataLabels: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          style: {
            fontSize: '15px',
            fontWeight: 'bold',
            color: 'white'
          }
        },
        borderWidth: 3
      }, {
        level: 2,
        layoutAlgorithm: 'sliceAndDice',
        dataLabels: {
          useHTML: true,
          enabled: true,
          align: 'center',
          style: {
            textOutline: 'none',
            color: 'white',
            textAlign: 'center',
            fontSize: '12px',
            fontWeight: 'normal'
          },
          formatter: function () {
            if (!this.point.node.isLeaf || !this.point.change) {
              return '<b>' + this.point.name + '</b>';
            } else {
              return '<div style="text-align: center;flex:1;justify-content:center;"><b>' + this.point.name + '</b><br>' +
                     '<p>' + this.point.change + '%</p></div>';
            }
          }
        },
        borderWidth: 2
      }],
      data: []
    }],
    title: {
      text: 'Heatmap',
      align: 'left',
      style: {
        fontSize: '18px',
        fontWeight: 'bold'
      }
    },
    subtitle: {
      text: 'Last updated on ',
      align: 'left',
      style: {
        fontSize: '14px'
      }
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
          var point = this.point;
          var roundedValue = point.value.toFixed(2); 
          var tooltipText = '<b>' + point.name + '</b><br>Index contribution: <b>' + roundedValue + '</b>';
          
          if (point.change !== null && point.change !== undefined) {
              tooltipText += '<br>Change: <b>' + point.change + '%</b>';
          }
          
          return tooltipText;
      },
      style: {
          fontSize: '12px'
      }
  }
  
  };
  
  const heatMapData = updatedStocks?.heat_map;

if (heatMapData && heatMapData.length > 0) {
  heatMapData.forEach(item => {
    if (item.hasOwnProperty('sector')) {
      options.series[0].data.push({
        id: item.sector,
        name: item.sector,
        value: null, 
        color: item.color || '#ccc' 
      });
    } else {
      options.series[0].data.push({
        name: item.name,
        parent: item.parent,
        value: parseFloat(item.size),
        color: item.color,
        change: item.change
      });
    }
  });
}

  return (
    <div className="stockPage">
      {isModalOpen2 && (
        <StockSearchModal
          stockList={stockList}
          onClose={searchClose}
          onStockItemClick={handleStockItemClick}
        />
      )}
      <div className="stock-header">
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="updatedstock-name fw-bold">
              {updatedStocks?.name}
            </div>
            <svg
              onClick={searchOpen}
              className="ms-2"
              role="button"
              style={{
                height: "2rem",
                width: "2rem",
              }}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.6725 16.6412L21 21M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
                stroke="grey"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div
              style={{
                paddingLeft: "1rem",
                gap: "0.75rem",
              }}
              className="flex">
              <div
                style={{
                  backgroundColor: "#FFE7E0",
                  color: "#FF420E",
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                  paddingTop: "0.125rem",
                  paddingBottom: "0.125rem",
                  fontSize: "0.875rem",
                  fontWeight: "500",
                }}>
                {updatedStocks?.exchange}
              </div>
              <div
                style={{
                  backgroundColor: "#FFE7E0",
                  color: "#FF420E",
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                  paddingTop: "0.125rem",
                  paddingBottom: "0.125rem",
                  fontSize: "0.875rem",
                  fontWeight: "500",
                }}>
                {updatedStocks?.is_index
                  ? "Index"
                  : updatedStocks?.is_nifty50
                  ? "Nifty 50"
                  : updatedStocks?.is_nifty100
                    ? "Nifty 100"
                    : updatedStocks?.is_nifty200
                      ? "Nifty 200"
                      : updatedStocks?.is_nifty500
                        ? "Nifty 500"
                        : "Stock"}
              </div>
            </div>
          </div>

          <h1
            style={{
              fontWeight: "bold",
              fontSize: "1.25rem",
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize: "24px",
              }}>
              <div>
                ₹ {formatNumberWithCommasAndFontWeight(updatedStocks?.close)}{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill={updatedStocks?.change_per >= 0 ? "#19AF55" : "red"}
                  viewBox="0 0 24 24"
                  style={{
                    width: "0.75rem",
                    height: "0.75rem",
                    marginBottom: "0.25rem",
                    display: "inline-block",
                    marginLeft: "0.25rem",
                    marginRight: "0.25rem",
                    transform: `${updatedStocks?.change_per < 0 ? "rotate(180deg)" : "none"}`,
                  }}>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                  />
                </svg>{" "}
              </div>
              <div
                className="mt-1"
                style={{
                  color: updatedStocks?.change_per >= 0 ? "#19AF55" : "red",
                  fontSize: "20px",
                }}>
                {formatNumberWithCommasAndFontWeight(updatedStocks?.change_per)}
                %
              </div>
            </div>
          </h1>

          <div
            style={{
              paddingTop: "0.125rem",
              paddingBottom: "0.125rem",
            }}>
            <button
              style={{
                backgroundColor:
                  activeTab === "Intraday" ? "#383FEC" : "#f7f7f7",
                color: activeTab === "Intraday" ? "#ffffff" : "#383FEC",
                borderRadius: "0.375rem",
                padding: "0.4rem 1rem",
                border: "none",
                cursor: "pointer",
                boxShadow:
                  activeTab === "Intraday"
                    ? "0 2px 5px rgba(0, 0, 0, 0.5)"
                    : "none",
                fontWeight: "600",
                fontSize: "0.875rem",
                marginRight:5
              }}
              onClick={() => setActiveTab("Intraday")}>
              Intraday
            </button>
            <button
              style={{
                backgroundColor: activeTab === "Swing" ? "#383FEC" : "#f7f7f7",
                color: activeTab === "Swing" ? "#ffffff" : "#383FEC",
                borderRadius: "0.275rem",
                padding: "0.4rem 1rem",
                border: "none",
                cursor: "pointer",
                boxShadow:
                  activeTab === "Swing"
                    ? "0 2px 5px rgba(0, 0, 0, 0.5)"
                    : "none",
                fontWeight: "600",
                fontSize: "0.875rem",
              }}
              onClick={() => setActiveTab("Swing")}>
              Swing
            </button>
          </div>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "0.5rem",
            }}>
            {updatedStocks?.is_fno === false ? (
              <div
                className="disabled-optionButtonInStockDetails">
                <svg
                  height="1.5rem"
                  width="1.5rem"
                  viewBox="0 0 21 21"
                  xmlns="http://www.w3.org/2000/svg">
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#636363"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    transform="translate(4 4)">
                    <path d="m5.5 7.5c.96940983 1.36718798 3.01111566 1.12727011 4.01111565 0l1.98888435-2c1.1243486-1.22807966 1.1641276-2.81388365 0-4-1.135619-1.15706921-2.86438099-1.15706947-4 0l-2 2" />

                    <path d="m7.5 6.56977319c-.96940983-1.36718798-3-1.1970433-4-.06977319l-2 1.97487373c-1.12434863 1.22807966-1.16412758 2.83900987 0 4.02512627 1.13561901 1.1570692 2.86438099 1.1570695 4 0l2-2" />
                  </g>
                </svg>
                Option chain
              </div>
            ) : (
              <div
                onClick={redirectToOptionChain}
                className="optionButtonInStockDetails">
                <svg
                  height="1.5rem"
                  width="1.5rem"
                  viewBox="0 0 21 21"
                  xmlns="http://www.w3.org/2000/svg">
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#383FEC"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    transform="translate(4 4)">
                    <path d="m5.5 7.5c.96940983 1.36718798 3.01111566 1.12727011 4.01111565 0l1.98888435-2c1.1243486-1.22807966 1.1641276-2.81388365 0-4-1.135619-1.15706921-2.86438099-1.15706947-4 0l-2 2" />

                    <path d="m7.5 6.56977319c-.96940983-1.36718798-3-1.1970433-4-.06977319l-2 1.97487373c-1.12434863 1.22807966-1.16412758 2.83900987 0 4.02512627 1.13561901 1.1570692 2.86438099 1.1570695 4 0l2-2" />
                  </g>
                </svg>
                Option chain
              </div>
            )}
            {!updatedStocks?.is_index ? (<><div
              data-bs-toggle="modal"
              data-bs-target="#staticBackdropBuySell"
              className="buy-sell-btn-stockdetails"
              onClick={() => handleBuyClick("Buy")}>
              {" "}
              Buy
            </div><div
              data-bs-toggle="modal"
              data-bs-target="#staticBackdropBuySell"
              className="buy-sell-btn-stockdetails sell-btn-stockdetails"
              onClick={() => handleSellClick("Sell")}>
                {" "}
                Sell
              </div></>) : (<>
                <button
                style={{height: "34px", width: "60px"}}
              className="buy-sell-btn-disabled">
              Buy
            </button>
            <button
              style={{height: "34px", width: "60px"}}
              className="buy-sell-btn-disabled">
              Sell
            </button></>)}
            
          </div>
          <div
            style={{
              display: "flex",
              gap: "2rem",
              marginTop: "2rem",
            }}>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
              }}>
              <div>
                <div
                className="span"
                  style={{
                    fontSize: "0.875rem",
                  }}>
                  Low
                </div>
                <div
                className="span"
                  style={{
                    fontSize: "0.875rem",
                    fontWeight: "500",
                  }}>
                  {updatedStocks?.low}
                </div>
              </div>
              <div>
                <div
                  style={{
                    textAlign: "center",
                    color: "#CBD5E0",
                    fontSize: "0.875rem",
                  }}>
                  Todays' Range
                </div>
                <div className="custom-range-container">
                  <div className="w-100">
                    <div
                      className="custom-range"
                      style={{ "--slider-color": sliderColor }}>
                      <div
                        style={{
                          width: "var(--thumb-width, 84%)",
                          left: `${barPosition}%`,
                          backgroundColor: "var(--slider-color)",
                          position: "absolute",
                          top: 4,
                          transform: "translateY(-50%)",
                          height: "8px",
                          flexShrink: 0,
                        }}></div>
                    </div>
                    <div
                      style={{
                        left: `calc(${pointerPosition}% - 1.8%)`,
                        position: "absolute",
                        top: -2,
                      }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="7"
                        viewBox="0 0 11 7"
                        fill="none">
                        <path
                          d="M4.40608 0.401811C4.90098 -0.133937 5.74756 -0.133937 6.24246 0.401811L10.3143 4.80965C11.0538 5.61025 10.486 6.90784 9.39606 6.90784L1.25248 6.90784C0.162566 6.90784 -0.405272 5.61025 0.334291 4.80965L4.40608 0.401811Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  textAlign: "right",
                }}>
                <div
                className="span"
                  style={{
                    fontSize: "0.875rem",
                  }}>
                  High
                </div>
                <div
                className="span"
                  style={{
                    fontSize: "0.875rem",
                    fontWeight: "500",
                  }}>
                  {updatedStocks?.high}
                </div>
              </div>
            </div>
            <div className="">
              <div
                style={{
                  fontWeight: "600",
                }}>
                Today's trend
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "0.25rem",
                  justifyContent: "center",
                }}>
                <div
                  style={{
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    color:
                      updatedStocks?.trend === "Bullish"
                        ? "#19AF55"
                        : "#FF420E",
                  }}>
                  {updatedStocks?.trend}
                </div>
                {updatedStocks?.trend === "Bullish" ? (
                  <svg
                    height="1.5rem"
                    width="1.5rem"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="#19AF55"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-trending-up">
                    <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                    <polyline points="17 6 23 6 23 12"></polyline>
                  </svg>
                ) : (
                  <svg
                    height="1.5rem"
                    width="1.5rem"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="#FF420E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-trending-down">
                    <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                    <polyline points="17 18 23 18 23 12"></polyline>
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="stockdeatail-grid mb-5">
        <div className="stockdetail-card">
          <div className="stockdetail-card-name ">Candlestick patterns</div>
          <div className="stockdetail-card-desc">
            Following candlestick patterns are identified in{" "}
            {updatedStocks?.name}{" "}
          </div>
          {/* candlesticks */}
          {updatedStocks?.candlestick_data?.length !== 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "0.25rem",
                justifyContent: "space-between",
              }}>
              {typeof bearishRatio2 === "number" &&
                typeof bullishRatio2 === "number" &&
                [...Array(Math.round(bearishRatio2 * 15))].map((_, index) => (
                  <div
                    key={`bearish-${index}`}
                    style={{
                      height: "4rem",
                      width: "0.375rem",
                      borderRadius: "0.375rem",
                      background:
                        "linear-gradient(#FF420E, rgba(255, 72, 18, 0.30))",
                    }}></div>
                ))}
              {typeof bearishRatio2 === "number" &&
                typeof bullishRatio2 === "number" &&
                [...Array(Math.round(bullishRatio2 * 15))].map((_, index) => (
                  <div
                    key={`bullish-${index}`}
                    style={{
                      height: "4rem",
                      width: "0.375rem",
                      borderRadius: "0.375rem",
                      background: "linear-gradient(#5CA81D, #D3FFAF)",
                    }}></div>
                ))}
            </div>
          )}

          <div className="d-flex justify-content-center align-align-items-center gap-3 mb-3">
            <div className="text-center">
              <div className="fw-semibold">{bearishCount2}</div>
              <div className="card-tag">Bearish</div>
            </div>
            <div className="text-center">
              <div className="fw-semibold">{bullishCount2}</div>
              <div className="card-tag-green">Bullish</div>
            </div>
          </div>
          {updatedStocks?.candlestick_data?.length === 0 ? (
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50%",
              }}>
              No data found
            </p>
          ) : (
            <div
              style={{
                overflowY:
                  updatedStocks?.candlestick_data?.length > 2
                    ? "auto"
                    : "visible",
                maxHeight:
                  updatedStocks?.candlestick_data?.length > 2
                    ? "12rem"
                    : "auto",
              }}>
              {updatedStocks?.candlestick_data.map((scanner, index) => (
                <div className="mb-3" key={index}>
                  <div
                    className="d-flex gap-3"
                    onClick={() => handleClick(scanner.name)}>
                    <img
                      width={60}
                      height={61}
                      src={scanner.image}
                      alt="Candlestick Pattern"
                    />
                    <div
                      style={{ width: "-webkit-fill-available" }}
                      className="d-flex flex-column justify-content-between gap-2">
                      <div className="d-flex justify-content-between">
                        <div className="stockdetail-scanner-name">
                          {scanner.name}
                        </div>
                        <div className="scanner-timeframe text-center">
                          {scanner.timeframe}
                        </div>
                      </div>
                      <div className="d-flex gap-2">
                        {scanner.tags.map((tag, i) => (
                          <div
                            className="stockdetail-card-tag"
                            key={i}
                            style={{
                              color: tag.color,
                              background: tag.background,
                            }}>
                            {tag.tag_name}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="stockdetail-card second-stockdetails-card">
          <div className="stockdetail-card-name ">CPR Levels</div>
          <div className="stockdetail-card-desc">
            Central Pivot Range with Resistance and support levels in{" "}
            {updatedStocks?.name}
          </div>

          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column gap-2">
              <div>CPR Trend</div>
              <div>{updatedStocks?.pivot_levels[0].trend}</div>
            </div>
            <div className="d-flex flex-column gap-2">
              <div>Virgin CPR</div>
              <div className="fw-bold text-center">
                {updatedStocks?.pivot_levels[0].virgin_cpr}
              </div>
            </div>
            <div className="d-flex flex-column gap-2">
              <div>Band Range</div>
              <div>{updatedStocks?.pivot_levels[0].band_range}</div>
            </div>
          </div>
          {/* changes making */}
          <div className="d-flex mx-3 mt-2 mb-3 position-relative justify-content-between">
            <svg
              style={{
                left: `calc(${Math.min(pointerPosition2, 98)}% - 2%)`,
                top: "25%",
              }}
              className="circle-dot position-absolute z-10"
              viewBox="0 0 512 512"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              fill="#383FEC"
              stroke="#383FEC">
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <title>circle-dot-filled</title>{" "}
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fill-rule="evenodd">
                  {" "}
                  <g
                    id="drop"
                    fill="#383FEC"
                    transform="translate(42.666667, 42.666667)">
                    {" "}
                    <path
                      d="M213.333333,3.55271368e-14 C331.15408,3.55271368e-14 426.666667,95.5125867 426.666667,213.333333 C426.666667,331.15408 331.15408,426.666667 213.333333,426.666667 C95.5125867,426.666667 3.55271368e-14,331.15408 3.55271368e-14,213.333333 C3.55271368e-14,95.5125867 95.5125867,3.55271368e-14 213.333333,3.55271368e-14 Z M213.333333,106.666667 C154.42296,106.666667 106.666667,154.42296 106.666667,213.333333 C106.666667,272.243707 154.42296,320 213.333333,320 C272.243707,320 320,272.243707 320,213.333333 C320,154.42296 272.243707,106.666667 213.333333,106.666667 Z"
                      id="Combined-Shape">
                      {" "}
                    </path>{" "}
                  </g>{" "}
                </g>{" "}
              </g>
            </svg>

            <div className="d-flex flex-column align-align-items-center position-relative">
              <div
                className="stick-height stick-width rounded-md"
                style={{
                  background:
                    "linear-gradient(#FF420E, rgba(255, 72, 18, 0.30))",
                }}></div>
              <p className="fw-semibold top-[110%]">S3</p>
            </div>

            <div
              className="stick-height stick-width rounded-md"
              style={{ background: "#DCDCDC" }}></div>
            <div
              className="stick-height stick-width rounded-md"
              style={{ background: "#DCDCDC" }}></div>
            <div className="d-flex flex-column align-items-center position-relative ">
              <div
                className="stick-height stick-width rounded-md"
                style={{
                  background:
                    "linear-gradient(#FF420E, rgba(255, 72, 18, 0.30))",
                }}></div>
              <p className="fw-semibold top-[110%]">S2</p>
            </div>
            <div
              className="stick-height stick-width rounded-md"
              style={{ background: "#DCDCDC" }}></div>
            <div className="d-flex flex-column align-items-center position-relative ">
              <div
                className="stick-height stick-width rounded-md "
                style={{
                  background:
                    "linear-gradient(#FF420E, rgba(255, 72, 18, 0.30))",
                }}></div>
              <p className="fw-semibold top-[110%]">S1</p>
            </div>
            <div className="flex">
              <div
                className="stick-height stick-width rounded-md"
                style={{
                  background:
                    "linear-gradient(180deg, #383FEC 0%, #A8ACFE 100%)",
                  marginRight: `${width}`,
                }}></div>
              <div
                className="stick-height stick-width rounded-md"
                style={{
                  background:
                    "linear-gradient(180deg, #383FEC 0%, #A8ACFE 100%)",
                  marginRight: `${width}`,
                }}></div>
              <div
                className="stick-height stick-width rounded-md"
                style={{
                  background:
                    "linear-gradient(180deg, #383FEC 0%, #A8ACFE 100%)",
                }}></div>
            </div>
            <div className="d-flex flex-column align-items-center position-relative">
              <div
                className="stick-height stick-width rounded-md"
                style={{
                  background: "linear-gradient(#5CA81D, #D3FFAF)",
                }}></div>
              <p className="fw-semibold top-[110%]">R1</p>
            </div>

            <div
              className="stick-height stick-width rounded-md"
              style={{ background: "#DCDCDC" }}></div>
            <div className="d-flex flex-column align-items-center position-relative ">
              <div
                className="stick-height stick-width rounded-md"
                style={{
                  background: "linear-gradient(#5CA81D, #D3FFAF)",
                }}></div>
              <p className="fw-semibold top-[110%]">R2</p>
            </div>
            <div
              className="stick-height stick-width rounded-md"
              style={{ background: "#DCDCDC" }}></div>
            <div
              className="stick-height stick-width rounded-md"
              style={{ background: "#DCDCDC" }}></div>
            <div className="d-flex flex-column align-items-center position-relative ">
              <div
                className="stick-height stick-width rounded-md "
                style={{
                  background: "linear-gradient(#5CA81D, #D3FFAF)",
                }}></div>
              <p className="fw-semibold top-[110%]">R3</p>
            </div>
          </div>
          {updatedStocks?.pivot_levels?.length > 0 && (
            <div className="grid grid-cols-3 gap-y-4 ">
              {updatedStocks?.pivot_levels[0] && (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column gap-2">
                      <div className="s1-r1">S1</div>
                      <div>
                        {formatNumberWithCommasAndFontWeight(
                          updatedStocks?.pivot_levels[0].s1
                        ) || "-"}
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <div className="s1-r1">S2</div>
                      <div>
                        {formatNumberWithCommasAndFontWeight(
                          updatedStocks?.pivot_levels[0].s2
                        ) || "-"}
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <div className="s1-r1">S3</div>
                      <div>
                        {formatNumberWithCommasAndFontWeight(
                          updatedStocks?.pivot_levels[0].s3
                        ) || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column gap-2">
                      <div className="s1-r1">R1</div>
                      <div>
                        {formatNumberWithCommasAndFontWeight(
                          updatedStocks?.pivot_levels[0].r1
                        ) || "-"}
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <div className="s1-r1">R2</div>
                      <div>
                        {formatNumberWithCommasAndFontWeight(
                          updatedStocks?.pivot_levels[0].r2
                        ) || "-"}
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <div className="s1-r1">R3</div>
                      <div>
                        {formatNumberWithCommasAndFontWeight(
                          updatedStocks?.pivot_levels[0].r3
                        ) || "-"}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div className="stockdetail-card third-stockdetails-card">
          <div className="stockdetail-card-name ">Scanners</div>
          <div className="stockdetail-card-desc">
            Following scanner patterns are identified in {updatedStocks?.name}
          </div>
          {/* candlesticks */}
          {updatedStocks?.scanner_data?.length !== 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "0.25rem",
                justifyContent: "space-between",
              }}>
              {[...Array(Math.round(bearishRatio * 15))].map((_, index) => (
                <div
                  key={`bearish-${index}`}
                  style={{
                    height: "4rem",
                    width: "0.375rem",
                    borderRadius: "0.375rem",
                    background:
                      "linear-gradient(#FF420E, rgba(255, 72, 18, 0.30))",
                  }}></div>
              ))}
              {[...Array(Math.round(bullishRatio * 15))].map((_, index) => (
                <div
                  key={`bullish-${index}`}
                  style={{
                    height: "4rem",
                    width: "0.375rem",
                    borderRadius: "0.375rem",
                    background: "linear-gradient(#5CA81D, #D3FFAF)",
                  }}></div>
              ))}
            </div>
          )}

          <div className="d-flex justify-content-center align-align-items-center gap-3 mb-3">
            <div className="text-center">
              <div className="fw-semibold">{bearishCount}</div>
              <div className="card-tag">Bearish</div>
            </div>
            <div className="text-center">
              <div className="fw-semibold">{bullishCount}</div>
              <div className="card-tag-green">Bullish</div>
            </div>
          </div>
          {updatedStocks?.scanner_data?.length === 0 ? (
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50%",
              }}>
              No data found
            </p>
          ) : (
            <div
              style={{
                overflowY:
                  updatedStocks?.scanner_data?.length > 2 ? "auto" : "visible",
                maxHeight:
                  updatedStocks?.scanner_data?.length > 2 ? "12rem" : "auto",
              }}>
              {updatedStocks?.scanner_data.map((scanner, index) => (
                <div className="mb-3" key={index}>
                  <div
                    className="d-flex gap-3"
                    onClick={() => handleClick2(scanner.name)}>
                    <img
                      width={60}
                      height={61}
                      src={scanner.image}
                      alt="Candlestick Pattern"
                    />
                    <div
                      style={{ width: "-webkit-fill-available" }}
                      className="d-flex flex-column justify-content-between gap-2">
                      <div className="d-flex justify-content-between">
                        <div className="stockdetail-scanner-name">
                          {scanner.name}
                        </div>
                        <div className="scanner-timeframe text-center">
                          {scanner.timeframe}
                        </div>
                      </div>
                      <div className="d-flex gap-2">
                        {scanner.tags.map((tag, i) => (
                          <div
                            className="stockdetail-card-tag"
                            key={i}
                            style={{
                              color: tag.color,
                              background: tag.background,
                            }}>
                            {tag.tag_name}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="mb-5 stock-detail-tables pt-3">
        <div className="d-flex justify-content-between px-4">
          <div>
            <div className="analyse-modal-title">Technical Indicators</div>
            <div className="last-updated">
              Last updated {technicals?.last_updated}
            </div>
          </div>
          <div className="timeandgroup ms-md-auto py-2 d-flex gap-2 align-align-items-center">
            <div className="time-frame d-flex align-items-center">
              Time frame
            </div>

            <select
              className="download-share-btn px-1 ms-auto "
              value={selectedTimeframe || ""}
              onChange={handleTimeframeChange}>
              {technicals?.timeframes?.map((timeframe, index) => (
                <option key={index} value={timeframe.value}>
                  {timeframe.display}
                </option>
              ))}
            </select>
          </div>
        </div>
        <table className="w-100 tech-indicator-table">
          <colgroup>
            <col style={{ width: "25%" }} />
            <col style={{ width: "25%" }} />
            <col style={{ width: "25%" }} />
            <col style={{ width: "25%" }} />
          </colgroup>
          <thead>
            <tr>
              <th className="px-4 py-4 font-normal text-[#777777] text-left">
                Indicator
              </th>
              <th className="px-4 py-4 font-normal text-[#777777] text-end">
                Current Price
              </th>
              <th className="px-4 py-4 font-normal text-[#777777] text-end">
                Computed value
              </th>
              <th className="px-4 py-4 font-normal text-[#777777] text-end">
                Level
              </th>
            </tr>
          </thead>
          <tbody>
            {technicals?.table_data?.map((data, index) => (
              <tr key={index} className="border-bottom-stock-detail">
                <td className="px-4 py-2 text-left">
                  <div className="data-indicator">{data.indicator}</div>
                  <div className="data-fullname">{data.full_name}</div>
                </td>
                <td className="px-4 py-2 text-end">
                  ₹ {formatNumberWithCommasAndFontWeight(data.ltp)}
                </td>
                <td className="px-4 py-2 text-end">
                  <div className="fw-bold">{parser(data.value)}</div>
                </td>

                <td className="px-4 py-2 text-end fw-bold">{data.level}</td>
              </tr>
            ))}
            {!technicals?.table_data && <p>No data found</p>}
          </tbody>
        </table>
      </div>
      <div className="mb-5 stock-detail-tables pt-3">
        <div className="px-4">
          <div className="analyse-modal-title">Pivot Levels</div>
          <div className="last-updated">
            Last updated {updatedStocks?.pivot_levels[0].updated_on}
          </div>
        </div>
        <table className="w-100 pivot-level-table">
          <colgroup>
            <col style={{ width: "12.5%" }} />
            <col style={{ width: "12.5%" }} />
            <col style={{ width: "12.5%" }} />
            <col style={{ width: "12.5%" }} />
            <col style={{ width: "12.5%" }} />
            <col style={{ width: "12.5%" }} />
            <col style={{ width: "12.5%" }} />
            <col style={{ width: "12.5%" }} />
          </colgroup>
          <thead>
            <tr>
              <th className="px-4 py-2 text-start">Type</th>
              <th className="px-4 py-2 text-end">S3</th>
              <th className="px-4 py-2 text-end">S2</th>
              <th className="px-4 py-2 text-end">S1</th>
              <th className="px-4 py-2 text-end">Pivot points</th>
              <th className="px-4 py-2 text-end">R1</th>
              <th className="px-4 py-2 text-end">R2</th>
              <th className="px-4 py-2 text-end">R3</th>
            </tr>
          </thead>
          <tbody>
            {updatedStocks?.pivot_levels?.map((pivotLevel, index) => (
              <tr key={index} className="border-bottom-stock-detail">
                <td className="px-4 py-2 text-start">
                  {pivotLevel.pivotlevel_name}
                </td>
                <td className="px-4 py-2 text-right text-end">
                  {pivotLevel.s3.toFixed(2)}
                </td>
                <td className="px-4 py-2 text-right text-end">
                  {pivotLevel.s2.toFixed(2)}
                </td>
                <td className="px-4 py-2 text-right text-end">
                  {pivotLevel.s1.toFixed(2)}
                </td>
                <td className="px-4 py-2 text-right text-end">
                  {formatNumberWithCommasAndFontWeight(pivotLevel.pp)}
                </td>
                <td className="px-4 py-2 text-right text-end">
                  {pivotLevel.r1.toFixed(2)}
                </td>
                <td className="px-4 py-2 text-right text-end">
                  {pivotLevel.r2.toFixed(2)}
                </td>
                <td className="px-4 py-2 text-right text-end">
                  {pivotLevel.r3.toFixed(2)}
                </td>
              </tr>
            ))}
            {updatedStocks?.pivot_levels?.length === 0 && (
              <tr>
                <td className="px-4 py-2" colSpan="8">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {updatedStocks?.heat_map.length!=0 &&
      <div className=''>
      <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
      </div>
    }
      <BuySellModal />
    </div>
  );
}

export default StockDetailsDesktop;
