import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";

const FundsDataContext = createContext();

export const useFundsData = () => useContext(FundsDataContext);

export const FundsDataProvider = ({ children }) => {
  const [fundsData, setFundsData] = useState({
    marginAvailable: 0,
    marginUtilized: 0,
    totalFunds: 0,
    payment_url: "",
  });

  const [totalFunds, setTotalFunds] = useState(0);
  const [utilisedFunds, setUtilisedFunds] = useState(0);

  const fetchBalanceAPI = async () => {
    try {
      const clientId = localStorage.getItem("client_id");
      const accessToken = localStorage.getItem("access_token");
      let broker = localStorage.getItem("broker");

      if (!broker) {
        console.error("Broker name not found in local storage.");
        return;
      }

      broker = broker.toLowerCase().replace(/\s+/g, "");

      const response = await axios.post(
        "https://h5x8c7zqd4.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_balance",
        {
          client_id: clientId,
          access_token: accessToken,
          broker: broker,
        }
      );

      setFundsData(response.data);
      setTotalFunds(response.data.totalFunds);
      setUtilisedFunds(response.data.marginUtilized);
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
  };

  useEffect(() => {
    fetchBalanceAPI();
  }, []);

  return (
    <FundsDataContext.Provider value={{ fundsData, totalFunds, utilisedFunds }}>
      {children}
    </FundsDataContext.Provider>
  );
};
