import React, { createContext, useState, useContext } from "react";

const OverlayContext = createContext();

export const useOverlay = () => useContext(OverlayContext);

export const OverlayProvider = ({ children }) => {
  const [isOverlayActive, setIsOverlayActive] = useState(false);

  const showOverlay = () => setIsOverlayActive(true);
  const hideOverlay = () => setIsOverlayActive(false);

  return (
    <OverlayContext.Provider value={{ showOverlay, hideOverlay }}>
      {children}
      {isOverlayActive && <div className="overlay active"></div>}
    </OverlayContext.Provider>
  );
};
