import React from "react";
import { Helmet } from "react-helmet-async";
import VirtualOrdersDesktop from "./VirtualOrdersDesktop";
import VirtualOrdersMobile from "./VirtualOrdersMobile";

const VirtualOrders = () => {
  function isMobile() {
    return (window.screen.width > 430 ) ? false : true;
  }

  return isMobile() ? (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <div className="mobile-screen d-grid" style={{ gridColumn: "span 4" }}>
        <VirtualOrdersMobile/>
      </div>
    </>
  ) : (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <div className="desktop-screen d-grid" style={{ gridColumn: "span 16" }}>
        <VirtualOrdersDesktop/>
      </div>
    </>
  );
};

export default VirtualOrders;
