import React, { useContext, useEffect, useState } from "react";
import getScreenerDetailsData from "../../utils/api";
import { RiExpandDiagonalLine } from "react-icons/ri";
import { IoArrowUpOutline } from "react-icons/io5";
import ChartMobile from "./ChartMobile";
import WebsocketWrapperContext from "../../context/WebsocketWrapperContext";
import axios from "axios";
import { Link } from "react-router-dom";
// import RadialChart from './RadialChart';
import { BROKER_NAME } from "../../utils/constants";
import { useTheme } from "../../context/ThemeContext";
import { useNavigate } from "react-router-dom";

let triggerSubscribe = true;
let connectionEstablised = false;

const DiscoverMobile = ({ data }) => {
  const navigate = useNavigate();
  const {theme}=useTheme()
  const tabsName = ["Top gainers", "Top losers", "Gap up", "Gap down"];
  const [isExpandPerformance, setIsExpandPerformance] = useState(false);
  const [isExpandIndian, setIsExpandIndian] = useState(false);
  const [isExpandGlobal, setIsExpandGlobal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [updatedScreenerDetails, setUpdatedScreenerDetails] = useState();
  const [currentTab, setCurrentTab] = useState(tabsName[0]);
  const [performanceData, setPerformanceData] = useState(null);
  const [optionsValue, setOptionsValue] = useState("NIFTY 50");

  const handleSelectChange = (event) => {
    setOptionsValue(event.target.value);
  };

  function formatNumberWithCommasAndFontWeight(value) {
    const formattedValue = parseFloat(value).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue?.split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1];

    return (
      <span>
        {integerPart}.<span style={{ fontWeight: 400 }}>{decimalPart}</span>
      </span>
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://cmtdqn92t6.execute-api.ap-south-1.amazonaws.com/default/viewScreenerDetails",
          {
            path: "scanners",
            name: `${currentTab}`,
            selected_timeframe: null,
            selected_group: `${optionsValue}`,
          }
        );
        setPerformanceData(response.data);
      } catch (error) {
        console.log("Error in fetching performance api", error);
      }
    };
    fetchData();
  }, [currentTab, optionsValue]);

  
  const handleIndianMarketExpand = () => {
    setIsExpandIndian(!isExpandIndian);
  };

  const handleGlobalMarketExpand = () => {
    setIsExpandGlobal(!isExpandGlobal);
  };

  const handlePerformanceMarketExpand = () => {
    setIsExpandPerformance(!isExpandPerformance);
  };

  const setMytab = (value) => {
    setCurrentTab(value);
  };
  const [symbols, setSymbols] = useState();

  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } = useContext(WebsocketWrapperContext);

  useEffect(() => {
    if (data && data.indian_markets && data.indian_markets.length > 0) {
      const symbols = data.indian_markets.map((item) => item.code);
      setSymbols(symbols);
    }
  }, [data]);

  useEffect(() => {
    connectionEstablised = checkWebsocketConnection();
    if (connectionEstablised && symbols?.length > 0) {
      if (triggerSubscribe) {
        subscribeToSymbols(symbols, "NSE");
        if (Object.keys(symbolList).length !== 0) {
          triggerSubscribe = false;
        }
      }
    } else {
      triggerSubscribe = true;
    }
  }, [symbolData]);

  // const handleGroupChange = async (event) => {
  //     const group = event.target.value;
  //     setSelectedGroup(group);
  //     try {
  //     //   const updatedScreenersDetails = await getScreenerDetailsData(
  //     //     path,
  //     //     pattern,
  //     //     selectedTimeframe,
  //     //     group
  //     //   );
  //       setUpdatedScreenerDetails(updatedScreenersDetails);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  const handleRowClick = async (stockName) => {
    const url = `/stockDetails?stock=${encodeURIComponent(stockName)}`;
    navigate(url);
  };
  return (
    <div
      className={`d-grid mb-5 ${BROKER_NAME === "gmail" ? "" : "mt-3"}`}
      style={{ gridColumn: "span 4" }}>
      <div className="scroll-items">
        {/* <RadialChart/> */}
        <ChartMobile data={data} />

        {isExpandIndian ? (
          <div className="expand-market-container">
            <div className="expand-market-heading">
              <div className="expand-market-text-info">
                <h2
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    width: "113px",
                    height: "22.64px",
                    margin: "0px",
                  }}
                >
                  Indian Market
                </h2>
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    width: "202px",
                    height: "19px",
                    color: "#7E7E7E",
                    margin: "0px",
                  }}
                >
                  Most active indices in NSE & BSE
                </p>
              </div>
              <div
                className="expand-market-icon"
                onClick={() => handleIndianMarketExpand()}
                style={{ cursor: "pointer" }}
              >
                <RiExpandDiagonalLine
                  style={{
                    fontSize: "24px",
                    color: theme==='light'?'black':'white',
                  }}
                />
              </div>
            </div>
            <div className="expand-market-assets">
              {data &&
                data.indian_markets &&
                data.indian_markets.map((item, index) => {
                  return (
                    <div className="expand-market-asset-main" key={index}>
                      <div className="expand-market-asset">
                        <div className="market-asset-heading">
                          <div className="asset-info">
                            <div className="asset-name">{item.symbol}</div>
                            <div className="asset-market">{item.exchange}</div>
                          </div>

                          <IoArrowUpOutline className="asset-goto" />
                        </div>
                        <div className="market-asset-price">
                          <div className="asset-price">
                            {symbolData[item?.code]?.ltp ? symbolData[item?.code]?.ltp : item.ltp}
                          </div>
                          <div className="asset-24hrsgain">
                            {symbolData[item?.code]?.change_per > 0 ? (
                              <div className="asset-24hrsgain-up">
                                <p style={{ color: "green", margin: "0px" }}>  
                                  <span>
                                  +
                                  {formatNumberWithCommasAndFontWeight(
                                  symbolData[item?.code]?.change_per ? symbolData[item?.code]?.change_per : item.change_per
                                  )} %
                                  </span>  
                                </p>
                              </div>
                            ) : (
                              <div className="asset-24hrsgain-down">
                                <p style={{ color: "red", margin: "0px" }}>
                                {formatNumberWithCommasAndFontWeight(
                                  symbolData[item?.code]?.change_per ? symbolData[item?.code]?.change_per : item.change_per
                                  )} %
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="bar-details">
                        <div className="bar-container" key={index}>
                          <div
                            className="bar"
                            style={{
                              width: `${(item.advances / (item.advances + item.declines)) * 100}%`,
                              backgroundColor: "green",
                              height: "4px",
                            }}
                          ></div>
                          <div
                            className="bar"
                            style={{
                              width: `${(item.declines / (item.advances + item.declines)) * 100}%`,
                              backgroundColor: "red",
                              height: "4px",
                            }}
                          ></div>
                        </div>
                        <div className="bar-text">
                          <div
                            style={{
                              height: "8px",
                              fontSize: "10px",
                              textAlign: "center",
                            }}
                          >
                            {item.advances}
                          </div>
                          <div
                            style={{
                              height: "8px",
                              fontSize: "10px",
                              textAlign: "center",
                            }}
                          >
                            {item.declines}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          <div className="indian-market-div">
            <div className="expand-market-heading">
              <div className="expand-market-text-info">
                <h2
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    width: "113px",
                    height: "22.64px",
                    margin: "0px",
                  }}
                >
                  Indian Market
                </h2>
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    width: "202px",
                    height: "19px",
                    color: "#7E7E7E",
                    margin: "0px",
                  }}
                >
                  Most active indices in NSE & BSE
                </p>
              </div>
              <div
                className="expand-market-icon"
                onClick={handleIndianMarketExpand}
                style={{ cursor: "pointer" }}
              >
                <RiExpandDiagonalLine
                  style={{
                    fontSize: "24px",
                    color: theme==='light'?'black':'white',
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {/* Global market  */}
        {isExpandGlobal ? (
          <div className="expand-global-container">
            <div className="expand-market-heading">
              <div className="expand-market-text-info">
                <h2
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    width: "113px",
                    height: "22.64px",
                    margin: "0px",
                  }}
                >
                  Global Market
                </h2>
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    width: "212px",
                    height: "19px",
                    color: "#7E7E7E",
                    margin: "0px",
                  }}
                >
                  Tracking global cues helps analyse
                </p>
              </div>
              <div
                className="expand-market-icon"
                onClick={() => handleGlobalMarketExpand()}
                style={{ cursor: "pointer" }}
              >
                <RiExpandDiagonalLine
                  style={{
                    fontSize: "24px",
                    color: theme==='light'?'black':'white',
                  }}
                />
              </div>
            </div>
            <div className="expand-market-global">
              {data &&
                data.global_markets &&
                data.global_markets.map((item, index) => (
                  <div className="expand-global-main" key={index}>
                    <div className="expand-global-heading">
                      <div className="global-heading-left">
                        <h2
                          style={{
                            fontWeight: "500",
                            fontSize: "14px",
                            textAlign: "center",
                            margin: "0px",
                          }}
                        >
                          {item.symbol}
                        </h2>
                        {item.status === "Open" ? (
                          <div className="global-active"></div>
                        ) : (
                          <div className="global-inactive"></div>
                        )}
                      </div>
                      <div className="global-heading-right">
                        <span>{formatNumberWithCommasAndFontWeight(item?.change_value)}</span>
                      </div>
                    </div>
                    <div className="expand-global-second">
                      <p className="global-price">
                        <span>{formatNumberWithCommasAndFontWeight(item.ltp)}</span>
                      </p>
                      {item.change_per > 0 ? (
                        <p className="global-gain" style={{ color: "#19AF55" }}>
                          +
                          <span>{formatNumberWithCommasAndFontWeight(item?.change_per)}</span> %
                        </p>
                      ) : (
                        <p className="global-gain" style={{ color: "red" }}>
                          <span>{formatNumberWithCommasAndFontWeight(item?.change_per)}</span> %
                        </p>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <div className="indian-market-div">
            <div className="expand-market-heading">
              <div className="expand-market-text-info">
                <h2
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    width: "113px",
                    height: "22.64px",
                    margin: "0px",
                  }}
                >
                  Global Market
                </h2>
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    width: "212px",
                    height: "19px",
                    color: "#7E7E7E",
                    margin: "0px",
                  }}
                >
                  Tracking global cues helps analyse
                </p>
              </div>
              <div
                className="expand-market-icon"
                onClick={() => {
                  handleGlobalMarketExpand();
                }}
                style={{ cursor: "pointer" }}
              >
                <RiExpandDiagonalLine
                  style={{
                    fontSize: "24px",
                    color: theme==='light'?'black':'white',
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {/* Performance DIV  */}
        {isExpandPerformance ? (
          <div className="expand-performance-container">
            <div
              className="expand-market-heading"
              style={{ marginBottom: "13px" }}
            >
              <div className="expand-market-text-info">
                <h2
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    width: "113px",
                    height: "22.64px",
                    margin: "0px",
                  }}
                >
                  Performance
                </h2>
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    width: "245px",
                    height: "19px",
                    color: "#7E7E7E",
                    margin: "0px",
                  }}
                >
                  Market performance updated reak-time
                </p>
              </div>
              <div
                className="expand-market-icon"
                onClick={() => handlePerformanceMarketExpand()}
                style={{ cursor: "pointer" }}
              >
                <RiExpandDiagonalLine
                  style={{
                    fontSize: "24px",
                    color: theme==='light'?'black':'white',
                  }}
                />
              </div>
            </div>
            <div className="nifty-dropdown-div">
              {performanceData && performanceData?.stock_groups && (
                <select
                  className="download-share-btn"
                  value={optionsValue}
                  onChange={handleSelectChange}
                >
                  {performanceData.stock_groups.map((group, index) => (
                    <option key={index} value={group}>
                      {group}
                    </option>
                  ))}
                </select>
              )}
              <a
                href={`/screenerDetails?pattern=${encodeURIComponent(currentTab)}&path=scanners`}
                style={{
                  fontSize: "14px",
                  width: "49px",
                  height: "19px",
                }}
                className="view-all"
              >
                View all
              </a>
            </div>
            <div class="tabsContainer">
              {tabsName.map((tab, index) => {
                return (
                  <div
                    class={`tabItem ${currentTab === tab ? "selectedTab" : ""}`}
                    key={index}
                    onClick={() => setMytab(tab)}
                  >
                    {tab}
                  </div>
                );
              })}
            </div>
            {performanceData &&
              performanceData.table_data &&
              performanceData.table_data.length > 0 &&
              performanceData.table_data.slice(0, 2).map((item, index) => (
                <div className="performance-div" 
                onClick={() => handleRowClick(item.stock)}>
                  <div className="performance-top">
                    <div className="performance-left">
                      <div className="performce-name">{item.stock}</div>
                      <div className="asset-market">{item.exchange}</div>
                    </div>
                    <div className="performce-price">
                      <div className="performce-price-text">
                        <span>{formatNumberWithCommasAndFontWeight(item.ltp)}</span>
                      </div>
                    </div>
                  </div>
                  <div className="performance-second">
                    <div className="sub-info-left">
                      <div className="sub-info-left-text">
                        {item.company_name}
                      </div>
                    </div>
                    <div className="sub-info-right">
                      {item.change > 0 ? (
                        <p className="global-gain" style={{ color: "#19AF55" }}>
                          +{item.change}
                        </p>
                      ) : (
                        <p className="global-gain" style={{ color: "red" }}>
                          {item.change}
                        </p>
                      )}
                      <IoArrowUpOutline className="asset-goto" />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="indian-market-div">
            <div className="expand-market-heading">
              <div className="expand-market-text-info">
                <h2
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    width: "113px",
                    height: "22.64px",
                    margin: "0px",
                  }}
                >
                  Performance
                </h2>
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    width: "245px",
                    height: "19px",
                    color: "#7E7E7E",
                    margin: "0px",
                  }}
                >
                  Market performance updated reak-time
                </p>
              </div>
              <div
                className="expand-market-icon"
                onClick={() => handlePerformanceMarketExpand()}
                style={{ cursor: "pointer" }}
              >
                <RiExpandDiagonalLine
                  style={{
                    fontSize: "24px",
                    color: theme==='light'?'black':'white',
                  }}
                />
              </div>
            </div>
          </div>
        )}

        <Link
          to="/screeners/candlesticks"
          className="last-div"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="heading-div">Candlesticks</div>
          <div style={{ cursor: "pointer" }}>
            <IoArrowUpOutline
              style={{
                fontSize: "24px",
                color: "black",
                marginRight: "10px",
                transform: "rotate(45deg)",
                color: "gray",
              }}
            />
          </div>
        </Link>

        <Link
          to="/screeners/scanners"
          className="last-div"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="heading-div">Scanner</div>
          <div style={{ cursor: "pointer" }}>
            <IoArrowUpOutline
              style={{
                fontSize: "24px",
                color: "black",
                marginRight: "10px",
                transform: "rotate(45deg)",
                color: "gray",
              }}
            />
          </div>
        </Link>

        <Link
          to="/screeners/technicals"
          className="last-div"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="heading-div">Technical indicators</div>
          <div style={{ cursor: "pointer" }}>
            <IoArrowUpOutline
              style={{
                fontSize: "24px",
                fontWeight: "400",
                color: "black",
                marginRight: "10px",
                transform: "rotate(45deg)",
                color: "gray",
              }}
            />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default DiscoverMobile;
