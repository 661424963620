import React, { createContext, useState, useContext } from "react";
const LtpContext = createContext();

export const LtpProvider = ({ children }) => {
  const [ltpValue, setLtpValue] = useState(null);

  return (
    <LtpContext.Provider value={{ ltpValue, setLtpValue }}>
      {children}
    </LtpContext.Provider>
  );
};

export const useLtpContext = () => useContext(LtpContext);
