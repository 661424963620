import React, { useState } from "react";
import { useTheme } from "../context/ThemeContext";

const SettingsModal = ({
  isOpen,
  onClose,
  showCalls,
  setShowCalls,
  showPuts,
  setShowPuts,
  showDelta,
  setShowDelta,
  showTheta,
  setShowTheta,
  showGamma,
  setShowGamma,
  showVega,
  setShowVega,
  showOIChng,
  setShowOIChng,
  showOIBuilddup,
  setShowOIBuilddup,
  initialSliderValue,
  onSliderChange,
  sliderLength,
}) => {
  const { theme } = useTheme();
  const [showCallsTemp, setShowCallsTemp] = useState(showCalls);
  const [showPutsTemp, setShowPutsTemp] = useState(showPuts);
  const [showDeltaTemp, setShowDeltaTemp] = useState(showDelta);
  const [showThetaTemp, setShowThetaTemp] = useState(showTheta);
  const [showGammaTemp, setShowGammaTemp] = useState(showGamma);
  const [showVegaTemp, setShowVegaTemp] = useState(showVega);
  const [showOIChngTemp, setShowOIChngTemp] = useState(showOIChng);
  const [showOIBuilddupTemp, setShowOIBuilddupTemp] = useState(showOIBuilddup);
  const [sliderValue, setSliderValue] = useState(initialSliderValue || 20);
  const handleApplyClick = () => {
    setShowCalls(showCallsTemp);
    setShowPuts(showPutsTemp);
    setShowDelta(showDeltaTemp);
    setShowTheta(showThetaTemp);
    setShowGamma(showGammaTemp);
    setShowVega(showVegaTemp);
    setShowOIChng(showOIChngTemp);
    setShowOIBuilddup(showOIBuilddupTemp);
    onSliderChange(sliderValue);

    onClose();
  };
  const handleResetClick = () => {
    setShowCallsTemp(true);
    setShowPutsTemp(true);
    setShowDeltaTemp(false);
    setShowThetaTemp(false);
    setShowGammaTemp(false);
    setShowVegaTemp(false);
    setShowOIChngTemp(false);
    setShowOIBuilddupTemp(false);
    setSliderValue(initialSliderValue || 20);
  };
  const handleSliderChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    //even value
    const adjustedValue = Math.max(
      2,
      Math.min(sliderLength, newValue % 2 === 0 ? newValue : newValue - 1)
    );
    setSliderValue(adjustedValue);
    const progress = (adjustedValue / sliderLength) * 100;
    event.target.style.background = `linear-gradient(to right, #383FEC ${progress}%, #F1F1F1 ${progress}%)`;
  };

  return (
    <>
      {isOpen && (
        <>
          <div className="basket-mobile-overlay"></div>
          <div
            className={`basket-mobile ${isOpen ? "show" : ""}`}
            style={{ height: "auto" }}>
            <div className="settings-modal d-flex flex-column gap-3 px-3">
              <div className="d-flex flex-column gap-2 mt-3">
                <div className="modal-header">
                  <p className="modal-title" id="staticBackdropLabel">
                    Settings
                  </p>
                  <button
                    type="button"
                    className={
                      theme === "dark"
                        ? "btn-close-white btn-close ms-auto"
                        : "btn-close ms-auto"
                    }
                    onClick={() => {
                      onClose();
                    }}></button>
                </div>

                <div className="modal-body py-0">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column gap-3">
                      <div className="modal-tag-titles">Show/hide Columns</div>
                      <div className="d-flex">
                        <ul className="list-group list-unstyled me-3">
                          <li className="mb-2">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              checked={showDeltaTemp}
                              onChange={() =>
                                setShowDeltaTemp((prevValue) => !prevValue)
                              }
                            />
                            Delta
                          </li>
                          <li className="mb-2">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              checked={showGammaTemp}
                              onChange={() =>
                                setShowGammaTemp((prevValue) => !prevValue)
                              }
                            />
                            Gamma
                          </li>
                        </ul>
                        <ul className="list-group list-unstyled">
                          <li className="mb-2">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              checked={showThetaTemp}
                              onChange={() =>
                                setShowThetaTemp((prevValue) => !prevValue)
                              }
                            />
                            Theta
                          </li>
                          <li className="mb-2">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              checked={showVegaTemp}
                              onChange={() =>
                                setShowVegaTemp((prevValue) => !prevValue)
                              }
                            />
                            Vega
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-3">
                      <div className="d-flex align-items-center justify-content-between gap-4">
                        <div className="modal-tag-titles">Display</div>
                        <div className="d-flex gap-3 align-items-center">
                          <div className="">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              value=""
                              id="defaultCheck1"
                              checked={showCallsTemp}
                              onChange={() =>
                                setShowCallsTemp((prevValue) => !prevValue)
                              }
                            />
                            Call
                          </div>
                          <div className="">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              value=""
                              id="defaultCheck2"
                              checked={showPutsTemp}
                              onChange={() =>
                                setShowPutsTemp((prevValue) => !prevValue)
                              }
                            />
                            Put
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="strike-range-container mt-3">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <div className="modal-tag-titles">No. of strikes</div>
                      <div className="selected-slider-value d-flex justify-content-center align-items-center">
                        {sliderValue}
                      </div>
                    </div>
                    <input
                      type="range"
                      min="2"
                      max={sliderLength}
                      value={sliderValue}
                      onChange={handleSliderChange}
                      className="w-100"
                      id="customRange"
                      style={{
                        background: `linear-gradient(to right, #383FEC ${(sliderValue / sliderLength) * 100}%, #F1F1F1 ${(sliderValue / sliderLength) * 100}%)`,
                      }}
                    />
                    <div className="d-flex justify-content-between align-items-center strike-slider-min-max">
                      <div>2</div>
                      <div>{sliderLength}</div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer" style={{ marginBottom: "12px" }}>
                  <button
                    type="button"
                    className="reset-btn"
                    onClick={handleResetClick}>
                    Reset
                  </button>

                  <button
                    type="button"
                    className="apply-btn"
                    style={{ marginLeft: "12px" }}
                    onClick={handleApplyClick}>
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SettingsModal;
