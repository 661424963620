import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import DiscoverMobile from "./DiscoverMobile";
import DiscoverDesktop from "./DiscoverDesktop";
import axios from "axios";

const Discover = () => {
  
  const [isMobile, setIsMobileScreen] = useState(window.innerWidth <= 430);
  const [apiData, setApiData] = useState(null);
  const [error, setError] = useState(null);

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://e5rm5v64eg.execute-api.ap-south-1.amazonaws.com/default/discoverData"
        );
          setApiData(response.data);
      } catch (error) {
        setError(error);
        console.log("API response error:", error);
      } 
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth <= 430);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile ? (
    <>
      <Helmet>
        <title>Discover</title>
      </Helmet>
      <div className="mobile-screen d-grid" style={{ gridColumn: "span 4" }}>
        <DiscoverMobile data={apiData}/>
      </div>
    </>
  ) : (
    <>
      <Helmet>
        <title>Discover</title>
      </Helmet>

      <div className="desktop-screenn">
        <DiscoverDesktop data={apiData}/>
      </div>
    </>
  );
};

export default Discover;
