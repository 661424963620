import React, {
  createContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

const ws_token = localStorage.getItem("ws_token");
const client_id = localStorage.getItem("client_id");

const ABWebSocketContext = createContext(null);

const ABWebSocketProvider = ({ children }) => {
  const location = useLocation();
  const conn = useRef(null);
  const previousPathname = useRef(null);
  const [symbolList, setSymbolList] = useState([]);
  const [readyState, setReadyState] = useState(false);
  const [symbolData, setSymbolData] = useState({});
  const [msg, setMsg] = useState({});

  let requestSent = false;

  //const MARKET_DATA_URL = `wss://f5zy.aliceblueonline.com/`;
  const MARKET_DATA_URL = `wss://ws1.aliceblueonline.com/NorenWS/`;

  // useEffect(() => {
  //   if (msg) {
  //     setSymbolData((prevData) => ({
  //       ...prevData,
  //       [Object.keys(msg)]: msg[Object.keys(msg)],
  //     }));
  //   }
  // }, [msg, tempsymbolData]);

  useEffect(() => {
    // TODO: Uncomment heart beat
    setInterval(() => {
      checkWebsocketConnection();

      if (conn.current.readyState === WebSocket.OPEN && readyState) {
        var initCon = {
          k: "",
          t: "h",
        };
        conn.current.send(JSON.stringify(initCon));
      }
      if (conn.current.readyState === WebSocket.CLOSED) {
        setReadyState(false);
      }
    }, 5000);

    return () => {};
  }, [readyState]);

  useLayoutEffect(() => {
    handleNavigation();
    // TODO: Uncomment heart beat
    return () => {};
  }, [location.pathname, readyState, symbolList]);

  useEffect(() => {
    conn.current.onmessage = async (event) => {
      let decodedMsg = JSON.parse(event.data);

      if (decodedMsg["t"] === "ck") {
        setReadyState(true);
        setSymbolData({});
      }
      if (Object.keys(symbolList).length !== 0) {
        // try {
        let ltp_value = decodedMsg.hasOwnProperty("lp")
          ? parseFloat(decodedMsg?.lp).toFixed(2)
          : symbolData[decodedMsg.tk]?.ltp;
        let open_value = decodedMsg.hasOwnProperty("o")
          ? parseFloat(decodedMsg?.o).toFixed(2)
          : symbolData[decodedMsg.tk]?.open;
        let high_value = decodedMsg.hasOwnProperty("h")
          ? parseFloat(decodedMsg?.h).toFixed(2)
          : symbolData[decodedMsg.tk]?.high;
        let low_value = decodedMsg.hasOwnProperty("l")
          ? parseFloat(decodedMsg?.l).toFixed(2)
          : symbolData[decodedMsg.tk]?.low;
        let change_value = decodedMsg.hasOwnProperty("cv")
          ? parseFloat(decodedMsg?.cv).toFixed(2)
          : symbolData[decodedMsg.tk]?.change;
        let changeper_value = decodedMsg.hasOwnProperty("pc")
          ? parseFloat(decodedMsg?.pc).toFixed(2)
          : symbolData[decodedMsg.tk]?.change_per;
        let oi_value = decodedMsg.hasOwnProperty("oi")
          ? parseFloat(decodedMsg?.oi).toFixed(2)
          : symbolData[decodedMsg.tk]?.oi;
        let prev_close = decodedMsg.hasOwnProperty("c")
          ? parseFloat(decodedMsg?.c).toFixed(2)
          : symbolData[decodedMsg.tk]?.prev_close;
        let lot_size = decodedMsg.hasOwnProperty("ls")
          ? parseFloat(decodedMsg?.ls).toFixed(2)
          : symbolData[decodedMsg.tk]?.lot_size;
        let volume = decodedMsg.hasOwnProperty("v")
          ? parseFloat(decodedMsg?.v).toFixed(2)
          : symbolData[decodedMsg.tk]?.volume;
        let required_format = {
          [decodedMsg.tk]: {
            ltp: ltp_value,
            change: (ltp_value - prev_close).toFixed(2),
            change_per: changeper_value,
            open: open_value,
            high: high_value,
            low: low_value,
            oi: oi_value,
            prev_close: prev_close,
            lot_size: lot_size,
            volume: volume,
          },
        };
        setSymbolData((prevData) => ({
          ...prevData,
          [Object.keys(required_format)]:
            required_format[Object.keys(required_format)],
        }));
      }
    };
  }, [symbolList, readyState, symbolData]);

  const handleNavigation = () => {
    if (location.pathname !== previousPathname.current) {
      previousPathname.current = location.pathname;
    }
  };

  const updateSymbolList = (symbols, action) => {
    let updatedlist = {};
    if (action === "add") {
      updatedlist = symbolList.concat(symbols.map(String));
    } else {
      updatedlist = symbolList.filter(function (el) {
        return symbols.indexOf(el) < 0;
      });
    }
    setSymbolList(updatedlist);
  };

  function sendConnectionRequest() {
    requestSent = true;
    var encrcptToken = ws_token;
    var initCon = {
      susertoken: encrcptToken,
      t: "c",
      actid: client_id + "_API",
      uid: client_id + "_API",
      source: "API",
    };
    conn.current.send(JSON.stringify(initCon));
  }

  const checkWebsocketConnection = () => {
    if (!conn.current) {
      openWebSocket();
    }
    if (!(conn.current.readyState === WebSocket.OPEN && readyState)) {
      waitForSocketConnection(conn.current, function () {});
    }
    if (conn.current.readyState === WebSocket.CLOSED && !readyState) {
      openWebSocket();
    }
    if (conn.current.readyState === WebSocket.OPEN) return true;
    // else return true;
  };

  const subscribeToSymbols = (symbols, exchange) => {
    if (conn.current.readyState === WebSocket.OPEN && readyState) {
      let uniqueKeys = symbols.map(String).filter((symbol) => !symbolList.includes(symbol));
      let subscribe_list = exchange + "|";
      uniqueKeys.forEach((code) => {
        subscribe_list += code + "#" + exchange + "|";
      });
      subscribe_list = subscribe_list.slice(0, -1);
      updateSymbolList(uniqueKeys, "add");
      if (uniqueKeys.length !== 0) {
        const message = JSON.stringify({
          k: subscribe_list,
          t: "t",
        });
        conn.current.send(message);
      }
    }
    return true;
  };

  const unsubscribeToSymbols = (symbols, exchange) => {
    if (conn.current.readyState === WebSocket.OPEN && readyState) {
      let global_symbols = [26000, 26009];
      let uniqueKeys = symbols.filter(
        (symbol) => !global_symbols.includes(symbol)
      );
      let subscribe_list = exchange + "|";
      uniqueKeys.forEach((code) => {
        subscribe_list += code + "#" + exchange + "|";
      });
      subscribe_list = subscribe_list.slice(0, -1);
      updateSymbolList(uniqueKeys, "remove");
      const message = JSON.stringify({
        k: subscribe_list,
        t: "u",
      });
      conn.current.send(message);
    }
  };

  function waitForSocketConnection(socket, callback) {
    setTimeout(function () {
      if (socket.readyState === 1) {
        if (!requestSent) {
          sendConnectionRequest();
          return true;
        }
        if (callback != null) {
          callback();
        }
      } else {
        waitForSocketConnection(socket, callback);
      }
    }, 5); // wait 5 milisecond for the connection...
  }

  const openWebSocket = () => {
    if (!conn.current || conn.current.readyState !== WebSocket.OPEN) {
      conn.current = new WebSocket(MARKET_DATA_URL);
    }
  };

  return (
    <ABWebSocketContext.Provider
      value={{
        websocketConnection: conn.current,
        checkWebsocketConnection,
        subscribeToSymbols,
        unsubscribeToSymbols,
        updateSymbolList,
        symbolList,
        symbolData, // Include the "msg" object in the context value
      }}>
      {children}
    </ABWebSocketContext.Provider>
  );
};

export { ABWebSocketProvider, ABWebSocketContext as default };
