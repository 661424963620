import { Parser } from "binary-parser";
import pako from "pako";

import React, {
  createContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
var socketIoClient = require("socket.io-client");

const client_id = localStorage.getItem("client_id");
const ws_token = localStorage.getItem("ws_token");

const ACAWebSocketContext = createContext(null);
// var XtsMarketDataAPI = require('xts-marketdata-api').XtsMarketDataAPI;
export const ACAWebSocketProvider = ({ children }) => {
  const location = useLocation();
  const conn = useRef(null);
  const previousPathname = useRef(null);
  const [symbolList, setSymbolList] = useState([]);
  const [readyState, setReadyState] = useState(false);
  const [symbolData, setSymbolData] = useState({});
  const [msg, setMsg] = useState({});

  let requestSent = false;

  const MARKET_DATA_URL = `https://symphony.acagarwal.com:3000`;
  // const MARKET_DATA_URL = `wss://symphony.acagarwal.com:3000/feedmarketdata/socket.io/?userID=${client_id}&token=${ws_token}&EIO=1&transport=websocket`;

  useEffect(() => {
    // TODO: Uncomment heart beat
    setInterval(() => {
      if (conn.current?.connected && readyState) {
        var initCon = {
          k: "",
          t: "h",
        };
        conn.current.emit(JSON.stringify(initCon));
      }
      if (conn.current?.connected === WebSocket.CLOSED) {
        setReadyState(false);
      }
    }, 5000);

    return () => {};
  }, [readyState]);

  useLayoutEffect(() => {
    handleNavigation();
    // TODO: Uncomment heart beat
    return () => {};
  }, [location.pathname, readyState, symbolList]);

  function pako_inflate_raw(data) {
    let inflate = pako.inflateRaw(data);
    return inflate;
  }

  useEffect(() => {
    openWebSocket();

    conn.current.on("connect", () => {
      setReadyState(true);
      setSymbolData({});
    });

    conn.current.on("message", (data) => {
      console.log("Received message:", data);
    });

    conn.current.on("1501-json-full", function (data) {
      console.log("data is " + data);
    });

    conn.current.on("xts-binary-packet", function (data) {
      console.log("xts is " + data);
      try {
        const a = new Uint8Array(data);
        let offset = 0;
        let isnextpacket = true;
        const datalen = a.length;
        let packetcount = 0;
        let br = new Parser().endianess("little").buffer(data);
        let packetSize = 0;
        let uncompressedPacketSize = 0;
        let nextdata = a;
        let count = 0;
        while (isnextpacket) {
          nextdata = a.slice(offset);
          br = new Parser().endianess("little").buffer(nextdata);
          const isGzipCompressed = br.int8("isGzipCompressed");
          offset++;

          if (isGzipCompressed === 1) {
            nextdata = a.slice(offset);
            br = new Parser().endianess("little").buffer(nextdata);
            const messageCode = br.uint16("messageCode");
            const exchangeSegment = br.int16("exchangeSegment");
            const exchangeInstrumentID = br.int32("exchangeInstrumentID");
            const bookType = br.int16("bookType");
            const marketType = br.int16("marketType");
            uncompressedPacketSize = br.uint16("uncompressedPacketSize");
            const compressedPacketSize = br.uint16("compressedPacketSize");
            offset += 16;
            const filteredByteArray = a.slice(
              offset,
              offset + compressedPacketSize
            );
            const inflate = pako_inflate_raw(filteredByteArray);
            const result = new Uint8Array(inflate);
            const r = new Parser().endianess("little").buffer(result);
            const currentsize = compressedPacketSize + offset;
            if (currentsize < a.length) {
              isnextpacket = true;
              packetcount = 1;
              offset = currentsize;
            } else {
              isnextpacket = false;
            }
            const messageCodeStr = r.uint16("messageCode").toString();
            if (messageCodeStr.includes("1501")) {
              // Touchline.deserialize(r, count);
            } else if (messageCodeStr.includes("1502")) {
              // MarketDepthEvent.deserialize(r, count);
            } else if (messageCodeStr.includes("1510")) {
              // OpenInterest.deserialize(r, count);
            } else if (messageCodeStr.includes("1512")) {
              // LTPEvent.deserialize(r, count);
            }
          } else if (isGzipCompressed === 0) {
            let count = offset;
            while (isnextpacket && datalen > 1) {
              const messageCode = br.uint16("messageCode").toString();
              const exchangeSegment = br.int16("exchangeSegment");
              const exchangeInstrumentID = br.int32("exchangeInstrumentID");
              const bookType = br.int16("bookType");
              const marketType = br.int16("marketType");
              uncompressedPacketSize = br.uint16("uncompressedPacketSize");
              const compressedPacketSize = br.uint16("compressedPacketSize");
              count = offset;
              if (messageCode.includes("1501")) {
                // Touchline.deserialize(r, count);
              } else if (messageCode.includes("1502")) {
                // MarketDepthEvent.deserialize(r, count);
              } else if (messageCode.includes("1510")) {
                // OpenInterest.deserialize(r, count);
              } else if (messageCode.includes("1510")) {
                // OpenInterest.deserialize(r, count);
              }
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    });

    // conn.current.on = async (event) => {
    //   console.log(event)
    //   let decodedMsg;
    //   debugger
    //   // conn.current.on("connect", () => {
    //   //     // Handle the event data here
    //   //     console.log("Received event:");
    //   //     setReadyState(true);
    //   //     setSymbolData({});
    //   // });
    //   // return;
    //   try {
    //     decodedMsg = JSON.parse(event.data);
    //   } catch {
    //     const match = event.data.match(/\{.*\}/);;
    //     const jsonString = match[0];
    //     try {
    //       decodedMsg = JSON.parse(jsonString);
    //     } catch (error) {
    //       console.error('Error parsing JSON:', error);
    //     }
    //   }
    //   if (decodedMsg === 40) {
    //     setReadyState(true);
    //     setSymbolData({});
    //   }
    //   if (Object.keys(symbolList).length !== 0) {
    //     // try {
    //     let ltp_value = decodedMsg.hasOwnProperty("lp")
    //       ? parseFloat(decodedMsg?.lp).toFixed(2)
    //       : symbolData[decodedMsg.tk]?.ltp;
    //     let open_value = decodedMsg.hasOwnProperty("o")
    //       ? parseFloat(decodedMsg?.o).toFixed(2)
    //       : symbolData[decodedMsg.tk]?.open;
    //     let high_value = decodedMsg.hasOwnProperty("h")
    //       ? parseFloat(decodedMsg?.h).toFixed(2)
    //       : symbolData[decodedMsg.tk]?.high;
    //     let low_value = decodedMsg.hasOwnProperty("l")
    //       ? parseFloat(decodedMsg?.l).toFixed(2)
    //       : symbolData[decodedMsg.tk]?.low;
    //     let change_value = decodedMsg.hasOwnProperty("cv")
    //       ? parseFloat(decodedMsg?.cv).toFixed(2)
    //       : symbolData[decodedMsg.tk]?.change;
    //     let changeper_value = decodedMsg.hasOwnProperty("pc")
    //       ? parseFloat(decodedMsg?.pc).toFixed(2)
    //       : symbolData[decodedMsg.tk]?.change_per;
    //     let oi_value = decodedMsg.hasOwnProperty("oi")
    //       ? parseFloat(decodedMsg?.oi).toFixed(2)
    //       : symbolData[decodedMsg.tk]?.oi;
    //     let prev_close = decodedMsg.hasOwnProperty("c")
    //       ? parseFloat(decodedMsg?.c).toFixed(2)
    //       : symbolData[decodedMsg.tk]?.prev_close;
    //     let lot_size = decodedMsg.hasOwnProperty("ls")
    //       ? parseFloat(decodedMsg?.ls).toFixed(2)
    //       : symbolData[decodedMsg.tk]?.lot_size;
    //     let volume = decodedMsg.hasOwnProperty("v")
    //       ? parseFloat(decodedMsg?.v).toFixed(2)
    //       : symbolData[decodedMsg.tk]?.volume;
    //     let required_format = {
    //       [decodedMsg.tk]: {
    //         ltp: ltp_value,
    //         change: (ltp_value - prev_close).toFixed(2),
    //         change_per: changeper_value,
    //         open: open_value,
    //         high: high_value,
    //         low: low_value,
    //         oi: oi_value,
    //         prev_close: prev_close,
    //         lot_size: lot_size,
    //         volume: volume,
    //       },
    //     };
    //     setSymbolData((prevData) => ({
    //       ...prevData,
    //       [Object.keys(required_format)]:
    //         required_format[Object.keys(required_format)],
    //     }));
    //   }
    // };
  }, [symbolList, readyState, symbolData]);

  const handleNavigation = () => {
    if (location.pathname !== previousPathname.current) {
      previousPathname.current = location.pathname;
    }
  };

  const updateSymbolList = (symbols, action) => {
    let updatedlist = {};
    if (action === "add") {
      updatedlist = symbolList.concat(symbols);
    } else {
      updatedlist = symbolList.filter(function (el) {
        return symbols.indexOf(el) < 0;
      });
    }
    setSymbolList(updatedlist);
  };

  function sendConnectionRequest() {
    requestSent = true;
    conn.current.emit("join");
  }

  const checkWebsocketConnection = () => {
    // if (!conn.current) {
    //   openWebSocket();
    // }
    if (!conn.current?.connected) {
      waitForSocketConnection(conn.current, function () {});
    }
    if (conn.current?.connected) return true;
    // else return true;
  };

  const subscribeToSymbols = (symbols, exchange) => {
    if (conn.current?.connected && readyState) {
      let uniqueKeys = symbols.filter((symbol) => !symbolList.includes(symbol));
      let exchangeSegment = 1;
      let subscribe_list = [];
      uniqueKeys.forEach((code) => {
        subscribe_list.push({
          exchangeSegment: exchangeSegment,
          exchangeInstrumentID: code,
        });
      });
      let marketData = {
        instruments: subscribe_list,
        xtsMessageCode: "1501",
      };
      updateSymbolList(symbols, "add");
      if (uniqueKeys.length !== 0) {
        fetch(`${MARKET_DATA_URL}/feedmarketdata/instruments/subscription`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: ws_token,
          },
          body: JSON.stringify(marketData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            console.log("Response:", data);
            // Handle the response data here
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
    return true;
  };

  const unsubscribeToSymbols = (symbols, exchange) => {
    if (conn.current?.connected && readyState) {
      let global_symbols = [26000, 26009];
      let uniqueKeys = symbols.filter(
        (symbol) => !global_symbols.includes(symbol)
      );
      let subscribe_list = exchange + "|";
      uniqueKeys.forEach((code) => {
        subscribe_list += code + "#" + exchange + "|";
      });
      subscribe_list = subscribe_list.slice(0, -1);
      updateSymbolList(uniqueKeys, "remove");
      const message = JSON.stringify({
        k: subscribe_list,
        t: "u",
      });
      conn.current.send(message);
    }
  };

  function waitForSocketConnection(socket, callback) {
    setTimeout(function () {
      if (socket?.connected) {
        if (!requestSent) {
          sendConnectionRequest();
          return true;
        }
        if (callback != null) {
          callback();
        }
      } else {
        waitForSocketConnection(socket, callback);
      }
    }, 5); // wait 5 milisecond for the connection...
  }

  // useEffect(() => {
  //     openWebSocket();

  // conn.current.on('connect', () => {
  //   setReadyState(true);
  //   setSymbolData({});
  // });
  //     conn.current.on = async (event) => {
  //       console.log(event)
  //     }

  // conn.current.on('message', (data) => {
  //   console.log('Received message:', data);
  // });

  //     conn.current.on("1501-json-full",function(data){
  //       console.log("data is "+data);
  //     });

  //     conn.current.on("xts-binary-packet",function(data){
  //       console.log("xts is "+data);
  //     });

  //     return () => {
  //       conn.current.disconnect();
  //     };
  // }, []);

  const openWebSocket = () => {
    if (!conn.current?.connected) {
      conn.current = socketIoClient(MARKET_DATA_URL, {
        path: "/feedmarketdata/socket.io",
        query: {
          userID: client_id,
          token: ws_token,
          publishFormat: "JSON",
          broadcastMode: "Full",
        },
        forceNew: true,
        transports: ["websocket"],
      });
    }
  };

  return (
    <ACAWebSocketContext.Provider
      value={{
        websocketConnection: conn.current,
        checkWebsocketConnection,
        subscribeToSymbols,
        unsubscribeToSymbols,
        updateSymbolList,
        symbolList,
        symbolData, // Include the "msg" object in the context value
      }}>
      {children}
    </ACAWebSocketContext.Provider>
  );
};

export default ACAWebSocketContext;
