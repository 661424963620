import React, {
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
  useRef,
  useCallback,
} from "react";
import axios from "axios";
import { getSymbols } from "../utils/api";
import { useLtpContext } from "../context/LtpContext";
import { useBasketContext } from "../context/BasketContext";
import ABWebSocketContext from "../context/ABWebsocketContext";
import CustomToastContent from "./CustomToastContent";
import { toast } from "react-toastify";
import SuccessIcon from "../images/success_icon.svg";
import WebsocketWrapperContext from "../context/WebsocketWrapperContext";
import { useTheme } from "../context/ThemeContext";
import { useDataContext } from "../context/DataContext";
import { ButtonContext } from "../context/ButtonsContext";
const OptionChainTableMobile = ({
  parentCallback2,
  optionData,
  showCalls,
  showPuts,
  symbol,
  calls,
  puts,
  clickEnable,
  showDelta,
  showTheta,
  showGamma,
  showVega,
  showOIChng,
  showOIBuilddup,
  isScalpOrderChecked,
  selectedDate,
  objectData,
}) => {
  const { theme } = useTheme();
  const { symbolData } = useContext(WebsocketWrapperContext);
  const { callsDataCon, setCallsDataCon, putsDataCon, setPutsDataCon } =
    useDataContext();
  const [displayCalls, setDisplayCalls] = useState(showCalls);
  const [displayPuts, setDisplayPuts] = useState(showPuts); //taking from modal table
  const [selectedSymbol, setSelectedSymbol] = useState(null); // const [selectedDate, setSelectedDate] = useState("");
  const [clickTrigger, setClickTrigger] = useState(false);
  const [activeDate, setActiveDate] = useState("");
  const [reducedData, setReducedData] = useState();
  const { isLive, isVirtual } = useContext(ButtonContext);
  const broker = localStorage.getItem("broker");
  const access_token = localStorage.getItem("access_token");
  const client_id = localStorage.getItem("client_id");
  const [scrollX, setScrollX] = useState(0);
  const [btnColor, setBtnColor] = useState({});
  const [atmIndex, setAtmindex] = useState(0);
  const [ltp, setLtp] = useState(0);
  const [tempIndex, setTempIndex] = useState(null);
  // const { ltpValue } = useLtpContext();
  const { addToBasket, basketData, setBasketData, removeFromBasket } =
    useBasketContext();
  const ltpValue = symbolData[selectedSymbol?.code]?.ltp || 0;
  var iv = require("implied-volatility");
  var jStat = require("jstat");
  var greeks = require("greeks");

  // Update the state variables when props change
  useEffect(() => {
    setDisplayCalls(showCalls);
    setDisplayPuts(showPuts);
  }, [showCalls, showPuts]);

  const getRowColor = (strike, isCall) => {
    if (theme === "light") {
      if ((isCall && strike < ltpValue) || (!isCall && strike > ltpValue)) {
        return isCall ? "#FFF9E5" : "#F4F5FF"; // Light yellow for ITM call options, Light blue for ITM put options
      } else {
        return "white"; // White for OTM and ATM options
      }
    } else {
      if ((isCall && strike < ltpValue) || (!isCall && strike > ltpValue)) {
        return isCall ? "#29292E" : "#29292E";
      } else {
        return "transparent";
      }
    }
  };

  // copying of data to callsdatacon and putsdatacon
  useEffect(() => {
    if (calls && calls.length > 0) {
      let callsTempData = calls.map((call) =>
        call
          ? { ...call, buyBtnState: false, sellBtnState: false, LotsSize: 1 }
          : null
      );

      setCallsDataCon(callsTempData);
    }
  }, [calls, selectedDate]);

  useEffect(() => {
    if (puts && puts.length > 0) {
      let putsTempData = puts.map((put) =>
        put
          ? { ...put, buyBtnState: false, sellBtnState: false, LotsSize: 1 }
          : null
      );
      setPutsDataCon(putsTempData.filter(Boolean));
    }
  }, [puts, selectedDate]);
  //Function  to handle lots size by typing
  const handleLotSizeChange = (newValue, index, side) => {
    const newLotsSize = parseInt(newValue); // Convert the new value to an integer

    if (basketData.length > 0) {
      const checkTradingSymbol =
        side === "calls"
          ? callsDataCon[index].trading_symbol
          : putsDataCon[index].trading_symbol;

      const positionIndex = basketData.findIndex(
        (position) => position.tradingSymbol === checkTradingSymbol
      );

      if (positionIndex !== -1) {
        // Position found in basketData
        const updatedBasketData = [...basketData];
        updatedBasketData[positionIndex].lotSize = newLotsSize;
        setBasketData(updatedBasketData);
      }
    } else {
      // Basket is empty, update options data
      if (side === "calls") {
        const updatedCallsDataCon = [...callsDataCon];
        updatedCallsDataCon[index].LotsSize = newLotsSize;
        setCallsDataCon(updatedCallsDataCon);
      } else {
        const updatedPutsDataCon = [...putsDataCon];
        updatedPutsDataCon[index].LotsSize = newLotsSize;
        setPutsDataCon(updatedPutsDataCon);
      }
    }
  };

  //function to handle lots size by clicking on increment
  const incrementValue = (index, side) => {
    if (
      basketData.length > 0 &&
      basketData.some(
        (position) =>
          position.tradingSymbol ===
          (side === "calls"
            ? callsDataCon[index].trading_symbol
            : putsDataCon[index].trading_symbol)
      )
    ) {
      const checkTradingSymbol =
        side === "calls"
          ? callsDataCon[index].trading_symbol
          : putsDataCon[index].trading_symbol;
      for (let i = 0; i < basketData.length; i++) {
        if (basketData[i].tradingSymbol === checkTradingSymbol) {
          let dataObject = basketData[i];
          dataObject["lotSize"] = dataObject["lotSize"] + 1;

          setBasketData((prevData) => [...prevData]);
        }
      }
    } else {
      if (side === "calls") {
        const updatedCallsDataCon = [...callsDataCon];
        updatedCallsDataCon[index].LotsSize += 1;
        setCallsDataCon(updatedCallsDataCon);
      } else {
        const updatedPutsDataCon = [...putsDataCon];
        updatedPutsDataCon[index].LotsSize += 1;
        setPutsDataCon(updatedPutsDataCon);
      }
    }
  };

  //function to handle lots size by clicking on decrement
  const decrementValue = (index, side) => {
    if (
      basketData.length > 0 &&
      basketData.some(
        (position) =>
          position.tradingSymbol ===
          (side === "calls"
            ? callsDataCon[index].trading_symbol
            : putsDataCon[index].trading_symbol)
      )
    ) {
      const checkTradingSymbol =
        side === "calls"
          ? callsDataCon[index].trading_symbol
          : putsDataCon[index].trading_symbol;
      for (let i = 0; i < basketData.length; i++) {
        if (
          basketData[i].tradingSymbol === checkTradingSymbol &&
          basketData[i].lotSize > 1
        ) {
          let dataObject = basketData[i];
          dataObject["lotSize"] = dataObject["lotSize"] - 1;
          setBasketData((prevData) => [...prevData]);
        }
      }
    } else {
      if (side === "calls") {
        if (callsDataCon[index].LotsSize > 1) {
          const updatedCallsDataCon = [...callsDataCon];
          updatedCallsDataCon[index].LotsSize -= 1;
          setCallsDataCon(updatedCallsDataCon);
        }
      } else {
        if (putsDataCon[index].LotsSize > 1) {
          const updatedPutsDataCon = [...putsDataCon];
          updatedPutsDataCon[index].LotsSize -= 1;
          setPutsDataCon(updatedPutsDataCon);
        }
      }
    }
  };

  const returnAtmIndex = () => {
    let index = 0;
    const ltp = symbolData[symbol?.code]?.ltp || 0;
    if (optionData.options && optionData.options) {
      let curr = optionData.options[0].strike,
        diff = Math.abs(ltp - curr);
      for (let val = 0; val < optionData.options.length; val++) {
        let newdiff = Math.abs(ltp - optionData.options[val].strike);
        if (newdiff < diff) {
          diff = newdiff;
          curr = optionData.options[val];
          index = val;
        }
      }
    }

    setAtmindex(index);
  };

  useEffect(() => {
    returnAtmIndex();
  }, [calls, symbol]);

  const getPutRowColor = (strike) => {
    if (theme === "light") {
      if (strike > ltpValue) {
        return "#F4F5FF";
      } else {
        return "white";
      }
    } else {
      if (strike > ltpValue) {
        return "#29292E";
      } else {
        return "transparent";
      }
    }
  };

  useEffect(() => {
    const storedSymbol = sessionStorage.getItem("selectedSymbol");
    if (storedSymbol) {
      setSelectedSymbol(JSON.parse(storedSymbol));
    } else {
      const fetchSymbols = async () => {
        const symbols = await getSymbols();
        if (symbols) {
          const niftySymbol = symbols.find(
            (symbol) => symbol.scrip === "NIFTY"
          );
          setSelectedSymbol(niftySymbol);
          sessionStorage.setItem("selectedSymbol", JSON.stringify(niftySymbol));
        }
      };

      fetchSymbols();
    }
  }, [symbol]);

  useEffect(() => {
    returnAtmIndex();
  }, [calls, symbol]);

  // const fetchDataForSymbol = async (symbol, date) => {
  //   let brokerName = localStorage.getItem("broker");

  //   if (!brokerName) {
  //     console.error("Broker name not found in local storage.");
  //     return;
  //   }

  //   brokerName = brokerName.toLowerCase().replace(/\s+/g, "");
  //   const apiEndpoint = `https://y18wfq6w5b.execute-api.ap-south-1.amazonaws.com/default/og_expirysymbols?id=${symbol}${date}&broker=${brokerName}`;
  //   try {
  //     const response = await axios.get(apiEndpoint);
  //     const data = response.data;
  //     const f = getReducedData();
  //     setReducedData(f);
  //     console.log("Received data is: ", data);
  //   } catch (error) {
  //     console.log("Error: ", error);
  //   }
  // };

  useEffect(() => {
    if (selectedSymbol && selectedDate) {
      const f = getReducedData();
      setReducedData(f);
    }
  }, [selectedSymbol, selectedDate]);

  // useEffect(() => {
  //   if (selectedSymbol) {
  //     const formattedDate = selectedDate.replaceAll(" ", "%20");
  //     fetchDataForSymbol(selectedSymbol.scrip, formattedDate);
  //   }
  // }, [selectedSymbol, selectedDate]);

  // useEffect(() => {
  //   // Fetch data for the selected symbol and date when either of them changes
  //   if (selectedSymbol && selectedDate) {
  //     const formattedDate = selectedDate.replaceAll(" ", "%20");
  //     fetchDataForSymbol(selectedSymbol.scrip, formattedDate);
  //   }
  // }, [selectedSymbol, selectedDate]);

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedSide, setSelectedSide] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState({
    top: 0,
    left: 0,
  });
  const getReducedData = () => {
    const sample = optionData && optionData.options && optionData.options;
    if (sample) {
      let f = sample.reduce((groupedOptions, option) => {
        // console.log("Processing option:", option);
        const strike = option.call.strike;
        //console.log("Strike:", strike);

        if (!groupedOptions[strike]) {
          // console.log("Creating new entry for strike:", strike);
          groupedOptions[strike] = {
            call: option.call,
            put: option.put || {}, // Handle missing put data
          };
        } else {
          //console.log("Adding to existing entry for strike:", strike);
          groupedOptions[strike].call = {
            ...groupedOptions[strike].call,
            ...option.call,
          };
          groupedOptions[strike].put = {
            ...groupedOptions[strike].put,
            ...option.put,
          };
        }
        return groupedOptions;
      }, {});
      // console.log("Final groupedOptions:", f);
      return f;
    }
  };
  const [divVisibility, setDivVisibility] = useState(false);

  const [selectedRowLtp, setSelectedRowLtp] = useState(0);
  const handleRowClick = (event, rowIndex, side) => {
    setDivVisibility(true);
    setClickTrigger(true);
    if (clickEnable) {
      const rowRect = event.currentTarget.getBoundingClientRect();
      const top = rowRect.top + window.scrollY;
      const left = rowRect.left + window.scrollX;
      setSelectedSide(side);
      setSelectedRowIndex(rowIndex);
      setSelectedPosition({ top, left });

      setSelectedRowLtp(
        side === "calls"
          ? symbolData[calls[rowIndex]?.code]?.ltp || 0
          : symbolData[puts[rowIndex]?.code]?.ltp || 0
      );
    }
  };

  const generateVirtualOrderPayload = (basketData) => {
    const orderList = basketData.map((currData, ind) => ({
      isBuy: currData.buy_sell === "B" ? true : false,
      isCall: currData.side === "Call" ? true : false,
      isFut: false,
      code: currData.code,
      exchange: currData.exchange,
      // productType: activeOptions[ind] === "normal" ? "NRML" : "MIS",
      productType: "NRML",
      orderType: "MARKET",
      quantity: currData.lotSize * currData.lot_multiplier,
      price: currData.price,
      freeze_qty: currData.freeze_qty,
      strike: currData.strike,
      expiry: currData.expiry,
      tradingSymbol: currData.tradingSymbol,
      lot_multiplier: currData.lot_multiplier,
      scrip: currData.scrip,
      expiry: currData.expiryDate,
    }));
    return {
      order_list: orderList,
      broker: "virtual",
      main_broker: broker,
      access_token: access_token,
      client_id: client_id,
    };
  };

  const generateOrderPayload = (basketData) => {
    const orderList = basketData.map((currData, ind) => ({
      isBuy: currData.buy_sell === "B" ? true : false,
      isCall: currData.side === "Call" ? true : false,
      isFut: false,
      code: currData.code,
      exchange: currData.exchange,
      // productType: activeOptions[ind] === "normal" ? "NRML" : "MIS",
      productType: "NRML",
      orderType: "MARKET",
      quantity: currData.lotSize * currData.lot_multiplier,
      price: currData.price,
      freeze_qty: currData.freeze_qty,
      strike: currData.strike,
      expiry: currData.expiryDate,
      tradingSymbol: currData.tradingSymbol,
      lot_multiplier: currData.lot_multiplier,
      scrip: currData.scrip,
      expiry: currData.expiryDate,
    }));
    return {
      order_list: orderList,
      broker: broker,
      access_token: access_token,
      client_id: client_id,
    };
  };

  const checkIfPresentInBasket = (side, selectedOption, basketData) => {
    return basketData.some((position) => {
      const temp =
        side === "Call"
          ? selectedOption.call.trading_symbol
          : selectedOption.put.trading_symbol;
      return position.side === side && position.tradingSymbol === temp;
    });
  };

  const sendOrder = async (payload) => {
    try {
      const response = await axios.post(
        "https://0eo1dv23p5.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_placeorder",
        payload
      );
      return response.data;
    } catch (error) {
      console.error("Error placing order:", error);
      return {
        status: false,
        message_header: "Error placing order",
        message_body: error.message,
      };
    }
  };

  const sendVirtualOrder = async (payload) => {
    try {
      const response = await axios.post(
        "https://hgb1gw5n6g.execute-api.ap-south-1.amazonaws.com/default/og_virtual_placeorder",
        payload
      );
      return response.data;
    } catch (error) {
      console.error("Error placing order:", error);
      return {
        status: false,
        message_header: "Error placing order",
        message_body: error.message,
      };
    }
  };

  const containerRef = useRef(null);
  const selectedRowIndexRef = useRef(null);
  const selectedSideRef = useRef(null);
  const optionDataRef = useRef(null);

  const handleButtonClick = (event) => {
    const target = event.target;
    const isBuyButton = target.classList.contains("buy-btn");
    const isSellButton = target.classList.contains("sell-btn");

    //these are workig fine
    // console.log("Clicked Element:", target);
    // console.log("Buy button yes or no", isBuyButton);
    // console.log("sell button yes or no?", isSellButton);

    if (isBuyButton) {
      setSelectedRowIndex(selectedRowIndexRef.current);
      setSelectedSide(selectedSideRef.current);
      handleBuyClick();
    } else if (isSellButton) {
      setSelectedRowIndex(selectedRowIndexRef.current);
      setSelectedSide(selectedSideRef.current);
      handleSellClick();
    }
  };

  //This block of container is used because in a way we have two onclick elements at a single place
  // useEffect(() => {
  //   const container = containerRef.current;
  //   if (container) {
  //     container.addEventListener("click", handleButtonClick);
  //   }

  //   return () => {
  //     if (container) {
  //       container.removeEventListener("click", handleButtonClick);
  //     }
  //   };
  // }, []);

  // all button false if basket is empty
  if (basketData.length <= 0) {
    for (let i = 0; i < callsDataCon.length; i++) {
      if (callsDataCon[i]) {
        callsDataCon[i].buyBtnState = false;
        callsDataCon[i].sellBtnState = false;
      }
    }
    for (let i = 0; i < putsDataCon.length; i++) {
      if (putsDataCon[i]) {
        putsDataCon[i].buyBtnState = false;
        putsDataCon[i].sellBtnState = false;
      }
    }
  }

  if (basketData.length > 0) {
    for (let j = 0; j < callsDataCon.length; j++) {
      let matching_flag = false;
      for (let i = 0; i < basketData.length; i++) {
        const checkTradingSymbol = basketData[i].tradingSymbol;
        const direction = basketData[i].buy_sell;
        const side = basketData[i].side;
        if (side === "Call") {
          if (callsDataCon[j].trading_symbol === checkTradingSymbol) {
            matching_flag = true;
            callsDataCon[j].LotsSize = basketData[i].lotSize;
            if (direction === "B") {
              callsDataCon[j].buyBtnState = true;
              callsDataCon[j].sellBtnState = false;
            } else {
              callsDataCon[j].sellBtnState = true;
              callsDataCon[j].buyBtnState = false;
            }
          }
        }
      }
      if (!matching_flag) {
        callsDataCon[j].buyBtnState = false;
        callsDataCon[j].sellBtnState = false;
      }
    }

    for (let j = 0; j < putsDataCon.length; j++) {
      let matching_flag = false;
      for (let i = 0; i < basketData.length; i++) {
        const checkTradingSymbol = basketData[i].tradingSymbol;
        const direction = basketData[i].buy_sell;
        const side = basketData[i].side;
        if (side === "Put") {
          if (putsDataCon[j].trading_symbol === checkTradingSymbol) {
            matching_flag = true;
            putsDataCon[j].LotsSize = basketData[i].lotSize;
            if (direction === "B") {
              putsDataCon[j].buyBtnState = true;
              putsDataCon[j].sellBtnState = false;
            } else {
              putsDataCon[j].sellBtnState = true;
              putsDataCon[j].buyBtnState = false;
            }
          }
        }
      }
      if (!matching_flag) {
        putsDataCon[j].buyBtnState = false;
        putsDataCon[j].sellBtnState = false;
      }
    }
  }

  const updateBasket = (trading_symbol, isBuy) => {
    setBasketData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.tradingSymbol === trading_symbol) {
          if (item.buy_sell === (isBuy ? "B" : "S")) {
            return null;
          } else {
            return { ...item, buy_sell: isBuy ? "B" : "S" };
          }
        }
        return item;
      });
      setBtnColor({ updatedData });
      // Remove null entries (items to be deleted)
      return updatedData.filter((item) => item !== null);
    });
  };

  useEffect(() => {
    selectedRowIndexRef.current = selectedRowIndex;
    selectedSideRef.current = selectedSide;
  }, [selectedRowIndex, selectedSide]);

  let uniqueStrikes = Array.from(
    new Set(
      optionData.options
        ? optionData.options.map((option) => option.call.strike)
        : null
    )
  );
  uniqueStrikes.sort((a, b) => a - b);
  if (uniqueStrikes.length == 0) {
    setTimeout(() => {
      uniqueStrikes = Array.from(
        new Set(
          optionData.options
            ? optionData.options.map((option) => option.call.strike)
            : null
        )
      );
    }, 1000);
  }
  if (calls) {
    calls.sort((a, b) => a.strike - b.strike);
  }
  if (puts) {
    puts.sort((a, b) => a.strike - b.strike);
  }

  // const [isDivVisible, setDivVisible] = useState(true);
  const [lotSizeInput, setLotSizeInput] = useState(1);

  const handleBuyClick = useCallback(
    (selectedSide, rowIndex, lotsProp) => {
      setSelectedSide(selectedSide);
      if (callsDataCon && selectedSide === "calls") {
        const updatedCallsDataCon = [...callsDataCon];
        if (updatedCallsDataCon[rowIndex]) {
          updatedCallsDataCon[rowIndex].buyBtnState =
            !updatedCallsDataCon[rowIndex].buyBtnState;
          setCallsDataCon(updatedCallsDataCon);
          if (updatedCallsDataCon[rowIndex].sellBtnState) {
            updatedCallsDataCon[rowIndex].sellBtnState = false;
          }
        } else {
          console.error(`Error: callsDataCon[${rowIndex}] is undefined`);
        }
      } else {
        console.error("Error: callsDataCon is undefined");
      }
      if (putsDataCon && selectedSide === "puts") {
        const updatedPutsDataCon = [...putsDataCon];
        if (updatedPutsDataCon[rowIndex]) {
          updatedPutsDataCon[rowIndex].buyBtnState =
            !updatedPutsDataCon[rowIndex].buyBtnState;
          // updatedPutsDataCon[rowIndex].LotsSize = lotSizeInput;
          setPutsDataCon(updatedPutsDataCon);
          if (updatedPutsDataCon[rowIndex].sellBtnState) {
            updatedPutsDataCon[rowIndex].sellBtnState = false;
          }
        } else {
          console.error(`Error: putsDataCon[${rowIndex}] is undefined`);
        }
      } else {
        console.error("Error: putsDataCon is undefined");
      }

      console.log(callsDataCon[rowIndex]);
      const d = getReducedData();
      const selectedStrike = uniqueStrikes[selectedRowIndex];
      const selectedOption = d[selectedStrike];
      const side = selectedSide === "calls" ? "Call" : "Put";
      const existingPositionIndex = checkIfPresentInBasket(
        side,
        selectedOption,
        basketData
      );

      const f = getReducedData();
      if (
        selectedSymbol &&
        reducedData &&
        selectedRowIndex !== null &&
        selectedSide !== null
      ) {
        // const selectedOption = f[selectedRowIndex];
        const selectedStrike = uniqueStrikes[selectedRowIndex]; // Get the selected strike value
        const selectedOption = f[selectedStrike]; // Use the strike value as the key
        // parseInt(lotSizeInput)
        const lotSizeForOrder = lotsProp;
        const side = selectedSide === "calls" ? "Call" : "Put";
        const instrumentName =
          side === "Call"
            ? selectedOption.call.instrument_name
            : selectedOption.put.instrument_name;

        //console.log("instrumentName in buy click:", instrumentName);
        const price =
          selectedSide === "calls"
            ? symbolData[calls[selectedRowIndex]?.code]?.ltp || 0
            : symbolData[puts[selectedRowIndex]?.code]?.ltp || 0;
        const lotSize =
          side === "Call"
            ? selectedOption.call.lot_size
            : selectedOption.put.lot_size;
        const optionType = side === "Call" ? "CE" : "PE";
        const tradingSymbol =
          side === "Call"
            ? selectedOption.call.trading_symbol
            : selectedOption.put.trading_symbol;
        const expiryDate =
          side === "Call"
            ? selectedOption.call.expiry_date
            : selectedOption.put.expiry_date;
        const code =
          side === "Call" ? selectedOption.call.code : selectedOption.put.code;

        const buy_sell = "B";
        const underlying_code = selectedSymbol.code;
        const quantity =
          side === "Call"
            ? selectedOption.call.lot_size
            : selectedOption.put.lot_size;

        const strike =
          side === "Call"
            ? selectedOption.call.strike
            : selectedOption.put.strike;

        let freeze_qty =
          side === "Call"
            ? selectedOption.call.freeze_qty
            : selectedOption.put.freeze_qty;
        let exchange =
          side === "Call"
            ? selectedOption.call.exchange
            : selectedOption.put.exchange;
        let lot_multiplier = parseFloat(optionData?.lot_size);

        const dataObject = {
          side,
          instrumentName,
          lotSize: lotSizeForOrder,
          optionType,
          tradingSymbol,
          expiryDate,
          code,
          price,
          buy_sell,
          underlying_code,
          strike,
          quantity,
          freeze_qty: freeze_qty,
          exchange: exchange,
          lot_multiplier: lot_multiplier,
          scrip: selectedSymbol.scrip,
        };
        if (isScalpOrderChecked) {
          if (isLive) {
            const payload = generateOrderPayload([dataObject]);
            const response = sendOrder(payload);
            console.log("response", response);
            toast.success(
              <CustomToastContent
                heading="Order placed successfully"
                message="Scalp order placed successfully"
              />,
              {
                className: "custom-success-toast",
                icon: <img src={SuccessIcon} alt="Success Icon" />,
              }
            );
          } else {
            const payload = generateVirtualOrderPayload([dataObject]);
            const response = sendVirtualOrder(payload);
            toast.success(
              <CustomToastContent
                heading="Order placed successfully"
                message="Scalp order placed successfully"
              />,
              {
                className: "custom-success-toast",
                icon: <img src={SuccessIcon} alt="Success Icon" />,
              }
            );
          }
          return;
        } else {
          parentCallback2([dataObject, "B"]);
          if (existingPositionIndex) {
            updateBasket(tradingSymbol, true);
          } else {
            addToBasket(dataObject);
            setBtnColor(dataObject);
            setLotSizeInput(1);
          }
        }
      }
      if (selectedSide === "calls") {
        const updatedTags = [...buySellTagsCalls];
        updatedTags[selectedRowIndex] = "buy";
        setBuySellTagsCalls(updatedTags);
      } else if (selectedSide === "puts") {
        const updatedTags = [...buySellTagsPuts];
        updatedTags[selectedRowIndex] = "buy";
        setBuySellTagsPuts(updatedTags);
      }
    },
    [
      lotSizeInput,
      objectData,
      selectedRowIndex,
      selectedSide,
      reducedData,
      basketData,
      optionData,
      removeFromBasket,
      setSelectedSide,
      uniqueStrikes,
    ]
  );

  const handleSellClick = useCallback(
    (selectedSide, rowIndex, lotsProp) => {
      // setDivVisible(false);
      // updateDivVisibility(callsDataCon, putsDataCon);
      // if(objectData.isBuy){
      //   setObjectData({...objectData,isBuy:false});
      // }else{
      //   setObjectData({...objectData,isBuy:true});
      // }

      setSelectedSide(selectedSide);
      if (callsDataCon && selectedSide === "calls") {
        const updatedCallsDataCon = [...callsDataCon];
        if (updatedCallsDataCon[rowIndex]) {
          updatedCallsDataCon[rowIndex].sellBtnState =
            !updatedCallsDataCon[rowIndex].sellBtnState;
          if (updatedCallsDataCon[rowIndex].buyBtnState) {
            updatedCallsDataCon[rowIndex].buyBtnState = false;
          }

          setCallsDataCon(updatedCallsDataCon);
        } else {
          console.error(`Error: callsDataCon[${rowIndex}] is undefined`);
        }
      } else {
        console.error("Error: callsDataCon is undefined");
      }
      if (putsDataCon && selectedSide === "puts") {
        const updatedPutsDataCon = [...putsDataCon];
        if (updatedPutsDataCon[rowIndex]) {
          updatedPutsDataCon[rowIndex].sellBtnState =
            !updatedPutsDataCon[rowIndex].sellBtnState;
          if (updatedPutsDataCon[rowIndex].buyBtnState) {
            updatedPutsDataCon[rowIndex].buyBtnState = false;
          }
          setPutsDataCon(updatedPutsDataCon);
        } else {
          console.error(`Error: putsDataCon[${rowIndex}] is undefined`);
        }
      } else {
        console.error("Error: putsDataCon is undefined");
      }

      const d = getReducedData();
      const selectedStrike = uniqueStrikes[selectedRowIndex];
      const selectedOption = d[selectedStrike];
      const side = selectedSide === "calls" ? "Call" : "Put";
      const existingPositionIndex = checkIfPresentInBasket(
        side,
        selectedOption,
        basketData
      );
      const f = getReducedData();

      if (
        selectedSymbol &&
        reducedData &&
        selectedRowIndex !== null &&
        selectedSide !== null
      ) {
        //console.log("Selected Row Indexxxx:", selectedRowIndex);
        const selectedStrike = uniqueStrikes[selectedRowIndex]; // Get the selected strike value
        const selectedOption = f[selectedStrike]; // Use the strike value as the key
        //  console.log("Selected Option in handleSellClick:", selectedOption);

        const lotSizeForOrder = lotsProp;
        const side = selectedSide === "calls" ? "Call" : "Put";
        const price =
          selectedSide === "calls"
            ? symbolData[calls[selectedRowIndex]?.code]?.ltp || 0
            : symbolData[puts[selectedRowIndex]?.code]?.ltp || 0;
        const instrumentName =
          side === "Call"
            ? selectedOption.call.instrument_name
            : selectedOption.put.instrument_name;
        const lotSize =
          side === "Call"
            ? selectedOption.call.lot_size
            : selectedOption.put.lot_size;
        const optionType = side === "Call" ? "CE" : "PE";
        const tradingSymbol =
          side === "Call"
            ? selectedOption.call.trading_symbol
            : selectedOption.put.trading_symbol;
        const expiryDate =
          side === "Call"
            ? selectedOption.call.expiry_date
            : selectedOption.put.expiry_date;

        const quantity =
          side === "Call"
            ? selectedOption.call.lot_size
            : selectedOption.put.lot_size;

        const strike =
          side === "Call"
            ? selectedOption.call.strike
            : selectedOption.put.strike;
        const code =
          side === "Call" ? selectedOption.call.code : selectedOption.put.code;

        const buy_sell = "S";
        const underlying_code = selectedSymbol.code;
        let freeze_qty =
          side === "Call"
            ? selectedOption.call.freeze_qty
            : selectedOption.put.freeze_qty;
        let exchange =
          side === "Call"
            ? selectedOption.call.exchange
            : selectedOption.put.exchange;
        let lot_multiplier = parseFloat(optionData?.lot_size);

        const dataObject = {
          side,
          instrumentName,
          lotSize: lotSizeForOrder,
          optionType,
          tradingSymbol,
          expiryDate,
          code,
          price,
          buy_sell,
          underlying_code,
          strike,
          quantity,
          freeze_qty: freeze_qty,
          exchange: exchange,
          lot_multiplier: lot_multiplier,
          scrip: selectedSymbol.scrip,
        };
        if (isScalpOrderChecked) {
          if (isLive) {
            const payload = generateOrderPayload([dataObject]);
            const response = sendOrder(payload);
            console.log("response", response);
            toast.success(
              <CustomToastContent
                heading="Order placed successfully"
                message="Scalp order placed successfully"
              />,
              {
                className: "custom-success-toast",
                icon: <img src={SuccessIcon} alt="Success Icon" />,
              }
            );
          } else {
            const payload = generateVirtualOrderPayload([dataObject]);
            const response = sendVirtualOrder(payload);
            toast.success(
              <CustomToastContent
                heading="Order placed successfully"
                message="Scalp order placed successfully"
              />,
              {
                className: "custom-success-toast",
                icon: <img src={SuccessIcon} alt="Success Icon" />,
              }
            );
          }
          return;
        } else {
          parentCallback2([dataObject, "S"]);
          if (existingPositionIndex) {
            updateBasket(tradingSymbol, false);
          } else {
            addToBasket(dataObject);
            setBtnColor(dataObject);
            setLotSizeInput(1);
          }
        }
        if (selectedSide === "calls") {
          const updatedTags = [...buySellTagsCalls];
          updatedTags[selectedRowIndex] = "sell";
          setBuySellTagsCalls(updatedTags);
        } else if (selectedSide === "puts") {
          const updatedTags = [...buySellTagsPuts];
          updatedTags[selectedRowIndex] = "sell";
          setBuySellTagsPuts(updatedTags);
        }
      }
    },
    [
      lotSizeInput,
      optionData,
      uniqueStrikes,
      reducedData,
      lotSizeInput,
      basketData,
      removeFromBasket,
      setSelectedSide,
      uniqueStrikes,
      selectedRowIndex,
      selectedSide,
      optionData,
      uniqueStrikes,
      lotSizeInput,
    ]
  );

  useEffect(() => {
    if (selectedSymbol) {
      const symbolCode = selectedSymbol.code;
      const ltpValue = symbolData[symbolCode]?.ltp || 0;
      setLtp(ltpValue);
    }
  }, [selectedSymbol, symbolData]);

  useLayoutEffect(() => {
    const computeGreeks = () => {
      const spot = parseFloat(symbolData[selectedSymbol?.code]?.ltp);
      const riskFreeRate = 0.1 / 365;

      calls?.forEach((call_option, i) => {
        const strike = parseFloat(call_option?.strike);
        const targetDateTimeString = call_option.expiry_date + " 15:29:59";
        const targetDateTime = new Date(targetDateTimeString);
        const currentDate = new Date();
        let timeToExpiration = Math.max(
          0,
          (targetDateTime - currentDate) / (24 * 60 * 60 * 365 * 1000) ||
            0.00002 / 365
        );

        if (
          strike !== 0 &&
          timeToExpiration > 0 &&
          symbol?.scrip === selectedSymbol?.scrip
        ) {
          const isCallOption = strike > spot;
          const optionType = isCallOption ? "call" : "put";
          const relevantOption = isCallOption ? call_option : puts[i];
          let initialGuessIv;
          if (symbolData[relevantOption?.code] && !relevantOption.iv) {
            const currentStockPrice = parseFloat(
              symbolData[relevantOption?.code]?.ltp
            );

            initialGuessIv = iv.getImpliedVolatility(
              currentStockPrice,
              spot,
              strike,
              timeToExpiration,
              riskFreeRate,
              optionType
            );
          }
          if (initialGuessIv <= 1) {
            call_option.iv = (initialGuessIv * 100).toFixed(2);
            call_option.delta = (
              greeks.getDelta(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "call"
              ) * 100
            ).toFixed(2);
            call_option.theta = (
              greeks.getTheta(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "call"
              ) * 100
            ).toFixed(2);
            call_option.gamma = (
              greeks.getGamma(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "call"
              ) * 100
            ).toFixed(2);
            call_option.vega = (
              greeks.getVega(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "call"
              ) * 100
            ).toFixed(2);

            puts[i].iv = (initialGuessIv * 100).toFixed(2);
            puts[i].delta = (
              greeks.getDelta(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "put"
              ) * 100
            ).toFixed(2);
            puts[i].theta = (
              greeks.getTheta(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "put"
              ) * 100
            ).toFixed(2);
            puts[i].gamma = (
              greeks.getGamma(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "put"
              ) * 100
            ).toFixed(2);
            puts[i].vega = (
              greeks.getVega(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "put"
              ) * 100
            ).toFixed(2);
          }
        }
      });
    };

    computeGreeks();
  }, [symbolData, selectedSymbol, calls, puts]);

  const callsColSpan = showCalls ? 8 : 0;
  const putsColSpan = showPuts ? 7 : 0;

  // Formatting volume
  function formatVolume(volume) {
    if (volume >= 10000000) {
      return `${(volume / 10000000).toFixed(2)}Cr`;
    } else if (volume >= 100000) {
      return `${(volume / 100000).toFixed(2)}L`;
    } else if (volume >= 1000) {
      return `${(volume / 1000).toFixed(2)}K`;
    } else {
      if (isNaN(volume)) {
        return "0";
      }
      return `${volume}`;
    }
  }

  function formatOI(volume) {
    if (isNaN(volume)) {
      return "0";
    }
    return `${volume}`;
  }

  const [buySellTagsCalls, setBuySellTagsCalls] = useState(
    calls ? new Array(calls.length).fill(null) : []
  );

  const [buySellTagsPuts, setBuySellTagsPuts] = useState(
    puts ? new Array(puts.length).fill(null) : []
  );

  const [scrollValue, setScrollValue] = useState(0);

  // useEffect(() => {
  //   if (clickTrigger) {
  //     setTempIndex(selectedRowIndex);
  //   }
  //   console.log("TempInedx",tempIndex)
  // }, [clickTrigger]);

  // Function to handle range input change
  const handleRangeChange = (e) => {
    setScrollValue(e.target.value);
    // Calculate the scroll position based on the range input value
    const scrollPosition =
      (e.target.value / 100) *
      (containerRef.current.scrollWidth - containerRef.current.clientWidth);
    containerRef.current.scrollLeft = scrollPosition;
  };

  const listenRightScrollEvent = (event) => {
    const leftSide = document.getElementById("left-side");
    const rightSide = document.getElementById("right-side");
    const maxScrollLeftLeftSide = leftSide.scrollWidth - leftSide.clientWidth;
    const maxScrollLeftRightSide =
      rightSide.scrollWidth - rightSide.clientWidth;
    const scrollPercentageRightSide =
      event.currentTarget.scrollLeft / maxScrollLeftRightSide;
    const scrollLeftLeftSide =
      (1 - scrollPercentageRightSide) * maxScrollLeftLeftSide;

    // left side
    leftSide.scrollLeft = scrollLeftLeftSide;
  };

  const listenLeftScrollEvent = (event) => {
    const leftSide = document.getElementById("left-side");
    const rightSide = document.getElementById("right-side");
    const maxScrollLeftLeftSide = leftSide.scrollWidth - leftSide.clientWidth;
    const maxScrollLeftRightSide =
      rightSide.scrollWidth - rightSide.clientWidth;
    const scrollPercentageLeftSide =
      event.currentTarget.scrollLeft / maxScrollLeftLeftSide;
    const scrollLeftRightSide =
      (1 - scrollPercentageLeftSide) * maxScrollLeftRightSide;

    // right side
    rightSide.scrollLeft = scrollLeftRightSide;
  };

  return (
    <div className="outer">
      {divVisibility && (
        <div className="trade-placing-div grid-container">
          <div
            className="trade-placing-child"
            style={{ display: "flex", gap: "10px" }}>
            <div>
              {selectedSide === "calls"
                ? `${callsDataCon[selectedRowIndex].strike} ${callsDataCon[selectedRowIndex].segment}`
                : `${putsDataCon[selectedRowIndex].strike} ${putsDataCon[selectedRowIndex].segment}`}
            </div>
            <span className="lots-text">Lots</span>
            <div className="number">
              <button
                style={{
                  background: "transparent",
                  border: "none",
                  padding: 0,
                  margin: 0,
                  textAlign: "center",
                }}
                onClick={() => decrementValue(selectedRowIndex, selectedSide)}>
                -
              </button>
              <input
                className="lots-input-mobile"
                type="number"
                value={
                  selectedSide === "calls"
                    ? callsDataCon[selectedRowIndex].LotsSize
                    : putsDataCon[selectedRowIndex].LotsSize
                }
                onChange={(e) =>
                  handleLotSizeChange(
                    e.target.value,
                    selectedRowIndex,
                    selectedSide
                  )
                }
              />
              <button
                style={{
                  background: "transparent",
                  border: "none",
                  padding: 0,
                  margin: 0,
                }}
                onClick={() => incrementValue(selectedRowIndex, selectedSide)}>
                +
              </button>
            </div>
            {/* <div className="scalp-svg-mobile">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="30"
                    viewBox="0 0 22 22"
                    fill="red"
                  >
                    <g clipPath="url(#clip0_509_803)">
                      <path
                        d="M9.60272 7.3488C10.8408 6.29681 13.9006 4.89919 13.9006 4.89919C13.9006 4.89919 14.4877 8.2114 14.3248 9.8279C14.0615 12.4421 10.7132 15.2731 10.7132 15.2731L7.17158 13.4138C7.17158 13.4138 7.60044 9.05009 9.60272 7.3488Z"
                        fill="url(#paint0_linear_509_803)"
                      />
                      <path
                        d="M7.22105 14.7843C7.63788 14.3656 8.16292 13.9342 8.16292 13.9342L9.71691 14.7501C9.71691 14.7501 9.62195 15.4235 9.55201 16.0081C9.48206 16.5927 7.54791 16.9997 7.54791 16.9997C7.54791 16.9997 6.80421 15.203 7.22105 14.7843Z"
                        fill="url(#paint1_linear_509_803)"
                      />
                      <path
                        d="M10.7132 15.2731C10.7132 15.2731 14.0615 12.4421 14.3248 9.8279C14.4877 8.2114 13.9006 4.89919 13.9006 4.89919C13.9006 4.89919 10.8408 6.29681 9.60272 7.3488C7.60044 9.05009 7.17158 13.4138 7.17158 13.4138M10.7132 15.2731L7.17158 13.4138M10.7132 15.2731L13.6645 16.8226L13.4579 12.1964M7.17158 13.4138L4.22026 11.8643L8.14552 9.40736M9.6802 7.20124C9.6802 7.20124 10.2557 8.25634 11.7314 9.03106C13.207 9.80578 14.4023 9.68034 14.4023 9.68034M8.16292 13.9342C8.16292 13.9342 7.63788 14.3656 7.22105 14.7843C6.80421 15.203 7.54791 16.9997 7.54791 16.9997C7.54791 16.9997 9.48206 16.5927 9.55201 16.0081C9.62195 15.4235 9.71691 14.7501 9.71691 14.7501L8.16292 13.9342Z"
                        stroke="#14142B"
                      />
                    </g>
                    <defs>
                      <linearGradient
                        id="paint0_linear_509_803"
                        x1="13.9006"
                        y1="4.89919"
                        x2="7.54789"
                        y2="16.9996"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#FF3908" />
                        <stop offset="1" stopColor="#FF7834" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_509_803"
                        x1="13.9006"
                        y1="4.89919"
                        x2="7.54789"
                        y2="16.9996"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#FF3908" />
                        <stop offset="1" stopColor="#FF7834" />
                      </linearGradient>
                      <clipPath id="clip0_509_803">
                        <rect
                          width="16"
                          height="16"
                          fill="white"
                          transform="translate(7.43726) rotate(27.6994)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div> */}
            <div
              className={`b-tag-mobile ${
                selectedSide === "calls"
                  ? callsDataCon &&
                    callsDataCon[selectedRowIndex] &&
                    callsDataCon[selectedRowIndex].buyBtnState
                    ? "active"
                    : ""
                  : selectedSide === "puts" &&
                      putsDataCon &&
                      putsDataCon[selectedRowIndex] &&
                      putsDataCon[selectedRowIndex].buyBtnState
                    ? "active"
                    : ""
              }`}
              onClick={() =>
                handleBuyClick(
                  selectedSide,
                  selectedRowIndex,
                  selectedSide === "calls"
                    ? callsDataCon[selectedRowIndex].LotsSize
                    : putsDataCon[selectedRowIndex].LotsSize
                )
              }>
              B
            </div>

            <div
              className={`s-tag-mobile ${
                selectedSide === "calls"
                  ? callsDataCon &&
                    callsDataCon[selectedRowIndex] &&
                    callsDataCon[selectedRowIndex].sellBtnState
                    ? "active"
                    : ""
                  : selectedSide === "puts" &&
                      putsDataCon &&
                      putsDataCon[selectedRowIndex] &&
                      putsDataCon[selectedRowIndex].sellBtnState
                    ? "active"
                    : ""
              }`}
              onClick={() =>
                handleSellClick(
                  selectedSide,
                  selectedRowIndex,
                  selectedSide === "calls"
                    ? callsDataCon[selectedRowIndex].LotsSize
                    : putsDataCon[selectedRowIndex].LotsSize
                )
              }>
              S
            </div>
          </div>
        </div>
      )}
      <div
        ref={containerRef}
        className="option-chain-table"
        style={{
          fontSize: "14px",
          overflowX: "auto",
          height: divVisibility ? "589px" : "auto",
        }}>
        <div className="calls-puts-header d-flex text-center border-bottom py-1">
          <div className="calls text-center fw-bold" style={{ width: "50%" }}>
            {showCalls && "Calls"}
          </div>
          <div className="puts text-center fw-bold" style={{ width: "50%" }}>
            {showPuts && "Puts"}
          </div>
        </div>
        <div className="table-wrapper">
          <div
            className="table-responsive tabla parallax-table d-flex"
            style={{ position: "relative", height: "50%", left: "0px" }}>
            <table
              className="table table-responsive left-side"
              id="left-side"
              style={{ width: "90%", marginBottom: "0px" }}
              onScroll={(event) => listenLeftScrollEvent(event)}>
              <thead>
                <tr className="text-center sticky-header">
                  {showCalls && (
                    <>
                      {showOIBuilddup && (
                        <th scope="col" className="table-header-columns">
                          OI Buildup
                        </th>
                      )}
                      {showOIChng && (
                        <th scope="col" className="table-header-columns">
                          OI Chng
                        </th>
                      )}
                      {showVega && (
                        <th scope="col" className="table-header-columns">
                          Vega
                        </th>
                      )}

                      {showGamma && (
                        <th scope="col" className="table-header-columns">
                          Gamma
                        </th>
                      )}
                      {showTheta && (
                        <th scope="col" className="table-header-columns">
                          Theta
                        </th>
                      )}
                      {showDelta && (
                        <th scope="col" className="table-header-columns">
                          Delta
                        </th>
                      )}
                      <th scope="col" className="table-header-columns">
                        Volume
                      </th>
                      <th scope="col" className="table-header-columns">
                        OI Chg %
                      </th>
                      <th scope="col" className="table-header-columns">
                        OI
                      </th>
                      <th scope="col" className="table-header-columns">
                        Chng <br />
                        Chng%
                      </th>
                      <th scope="col" className="table-header-columns">
                        LTP
                      </th>
                      <th className="table-header-columns">Strike</th>
                      <th className="table-header-columns">IV</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody
                className={theme === "dark" ? "table-dark" : ""}
                style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                {calls &&
                  puts &&
                  calls.map((call, index) => (
                    <tr key={call.instrument_name} className="text-center">
                      {showCalls && (
                        <>
                          {showOIBuilddup && (
                            <td
                              style={{
                                backgroundColor: getRowColor(call.strike, true),
                              }}></td>
                          )}
                          {showOIChng && (
                            <td
                              style={{
                                backgroundColor: getRowColor(call.strike, true),
                              }}></td>
                          )}
                          {showVega && (
                            <td
                              style={{
                                backgroundColor: getRowColor(call.strike, true),
                              }}>
                              {call["vega"]}
                            </td>
                          )}
                          {showGamma && (
                            <td
                              style={{
                                backgroundColor: getRowColor(call.strike, true),
                              }}>
                              {call["gamma"]}
                            </td>
                          )}
                          {showTheta && (
                            <td
                              style={{
                                backgroundColor: getRowColor(call.strike, true),
                              }}>
                              {call["theta"]}
                            </td>
                          )}
                          {showDelta && (
                            <td
                              style={{
                                backgroundColor: getRowColor(call.strike, true),
                              }}>
                              {call["delta"]}
                            </td>
                          )}
                          <td
                            data-side="calls"
                            style={{
                              backgroundColor: getRowColor(call.strike, true),
                            }}
                            onClick={(event) =>
                              handleRowClick(event, index, "calls")
                            }>
                            {formatVolume(
                              symbolData[call.code]?.volume /
                                symbolData[call.code]?.lot_size
                            )}
                          </td>
                          <td
                            data-side="calls"
                            style={{
                              backgroundColor: getRowColor(call.strike, true),
                              color:
                                symbolData[call.code]?.oi -
                                  parseFloat(call?.previous_oi) >=
                                0
                                  ? "#5CA81D"
                                  : "#DD6565",
                            }}
                            onClick={(event) =>
                              handleRowClick(event, index, "calls")
                            }>
                            {formatOI(
                              Math.round(
                                ((symbolData[call.code]?.oi -
                                  parseFloat(call?.previous_oi)) /
                                  parseFloat(call?.previous_oi)) *
                                  100,
                                0
                              )
                            )}
                            %
                          </td>
                          <td
                            data-side="calls"
                            style={{
                              backgroundColor: getRowColor(call.strike, true),
                            }}
                            onClick={(event) =>
                              handleRowClick(event, index, "calls")
                            }>
                            {formatVolume(
                              symbolData[call.code]?.oi /
                                symbolData[call.code]?.lot_size
                            )}
                          </td>
                          <td
                            data-side="calls"
                            style={{
                              backgroundColor: getRowColor(call.strike, true),
                            }}
                            onClick={(event) =>
                              handleRowClick(event, index, "calls")
                            }>
                            {/* {selectedSide !== null &&
                            selectedRowIndex !== null &&
                            selectedSide == "calls" &&
                            selectedRowIndex == index &&
                            divVisibility && (
                              <div
                                style={{
                                  position: "absolute",
                                  zIndex: 1,
                                  left: "0px",
                                }}>
                                <span className="d-flex justify-content-center align-items-center gap-2">
                                  <input
                                    className="hover-lots-value px-1 text-end"
                                    value={lotSizeInput}
                                    onChange={(e) =>
                                      setLotSizeInput(e.target.value)
                                    }
                                  />
                                  <button
                                    className={`buy-btn ${callsDataCon && callsDataCon[index] && callsDataCon[index].buyBtnState ? 'active' : ''}`}
                                    onClick={() => handleBuyClick("calls",index,callsDataCon[index].LotsSize)}>
                                    Buy
                                  </button>
                                  <button
                                    className="sell-btn"
                                    onClick={()=>handleSellClick("calls",index,callsDataCon[index].LotsSize)}>
                                    Sell
                                  </button>
                                </span>
                              </div>
                            )} */}
                            {symbolData[call.code] && (
                              <span>
                                {symbolData[call.code].change}
                                <span
                                  style={{
                                    color:
                                      symbolData[call.code].change > 0
                                        ? "#5CA81D"
                                        : "#DD6565",
                                    position: "sticky",
                                    left: 0,
                                  }}>
                                  <br />
                                  {symbolData[call.code].change_per}%
                                </span>
                              </span>
                            )}
                          </td>
                          <td
                            data-side="calls"
                            style={{
                              backgroundColor: getRowColor(call.strike, true),
                              position: "sticky",
                              left: 0,
                            }}
                            onClick={(event) =>
                              handleRowClick(event, index, "calls")
                            }>
                            {symbolData[call.code]?.ltp}
                          </td>

                          <td className="strike">
                            {callsDataCon &&
                              callsDataCon[index] &&
                              (callsDataCon[index].buyBtnState ||
                                callsDataCon[index].sellBtnState) && (
                                <>
                                  {callsDataCon[index].buyBtnState && (
                                    <div className="b-tag">B</div>
                                  )}
                                  {callsDataCon[index].sellBtnState && (
                                    <div className="s-tag">S</div>
                                  )}
                                </>
                              )}
                            {call.strike}
                            {putsDataCon &&
                              putsDataCon[index] &&
                              (putsDataCon[index].buyBtnState ||
                                putsDataCon[index].sellBtnState) && (
                                <>
                                  {putsDataCon[index].buyBtnState && (
                                    <div className="bp-tag">B</div>
                                  )}
                                  {putsDataCon[index].sellBtnState && (
                                    <div className="sp-tag">S</div>
                                  )}
                                </>
                              )}
                          </td>
                          <td className="strike">
                            {call.iv && !isNaN(parseFloat(call.iv))
                              ? parseFloat(call.iv).toFixed(2)
                              : "N/A"}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>

            <table
              className="table table-responsive right-side"
              id="right-side"
              style={{ width: "44%" }}
              onScroll={(event) => listenRightScrollEvent(event)}>
              <thead>
                <tr className="text-center sticky-header">
                  {showPuts && (
                    <>
                      <th scope="col" className="table-header-columns">
                        LTP
                      </th>
                      <th scope="col" className="table-header-columns">
                        Chng <br />
                        Chng%
                      </th>
                      <th scope="col" className="table-header-columns">
                        OI
                      </th>
                      <th scope="col" className="table-header-columns">
                        OI Chg %
                      </th>
                      <th scope="col" className="table-header-columns">
                        Volume
                      </th>
                      {showDelta && (
                        <th scope="col" className="table-header-columns">
                          Delta
                        </th>
                      )}
                      {showTheta && (
                        <th scope="col" className="table-header-columns">
                          Theta
                        </th>
                      )}

                      {showGamma && (
                        <th scope="col" className="table-header-columns">
                          Gamma
                        </th>
                      )}
                      {showVega && (
                        <th scope="col" className="table-header-columns">
                          Vega
                        </th>
                      )}
                      {showOIChng && (
                        <th scope="col" className="table-header-columns">
                          OI Chng
                        </th>
                      )}
                      {showOIBuilddup && (
                        <th scope="col" className="table-header-columns">
                          OI Buildup
                        </th>
                      )}
                    </>
                  )}
                </tr>
              </thead>
              <tbody
                className={theme === "dark" ? "table-dark" : ""}
                style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                {calls &&
                  puts &&
                  calls.map((call, index) => (
                    <tr key={call.instrument_name} className="text-center">
                      {showPuts && (
                        <>
                          <td
                            data-side="puts"
                            onClick={(event) =>
                              handleRowClick(event, index, "puts")
                            }
                            style={{
                              backgroundColor: getPutRowColor(
                                puts[index].strike
                              ),
                            }}>
                            {symbolData[puts[index].code]?.ltp}
                          </td>
                          <td
                            data-side="puts"
                            onClick={(event) =>
                              handleRowClick(event, index, "puts")
                            }
                            style={{
                              backgroundColor: getPutRowColor(
                                puts[index].strike
                              ),
                            }}>
                            {/* {selectedSide !== null &&
                            selectedRowIndex !== null &&
                            selectedSide == "puts" &&
                            selectedRowIndex == index &&
                            divVisibility && (
                              <div
                                style={{
                                  position: "absolute",
                                  zIndex: 1,
                                  right: "0px",
                                }}>
                                <span className="d-flex justify-content-center align-items-center gap-2">
                                  <input
                                    className="hover-lots-value px-1 text-end"
                                    value={lotSizeInput}
                                    onChange={(e) =>
                                      setLotSizeInput(e.target.value)
                                    }
                                  />
                                  <button
                                    className="buy-btn"
                                    onClick={()=>handleBuyClick("puts",index,putsDataCon[index].LotsSize)}>
                                    Buy
                                  </button>
                                  <button
                                    className="sell-btn"
                                    onClick={()=>handleSellClick("puts",index,putsDataCon[index].LotsSize)}>
                                    Sell
                                  </button>
                                </span>
                              </div>
                            )} */}
                            {symbolData[puts[index].code] && (
                              <span>
                                {symbolData[puts[index].code].change}
                                <span
                                  style={{
                                    color:
                                      symbolData[puts[index].code].change_per >
                                      0
                                        ? "#5CA81D"
                                        : "#DD6565",
                                  }}>
                                  <br />
                                  {symbolData[puts[index].code].change_per}%
                                </span>
                              </span>
                            )}
                          </td>
                          <td
                            data-side="puts"
                            onClick={(event) =>
                              handleRowClick(event, index, "puts")
                            }
                            style={{
                              backgroundColor: getPutRowColor(
                                puts[index].strike
                              ),
                            }}>
                            {formatVolume(
                              symbolData[puts[index].code]?.oi /
                                symbolData[puts[index].code]?.lot_size
                            )}
                          </td>
                          <td
                            data-side="puts"
                            onClick={(event) =>
                              handleRowClick(event, index, "puts")
                            }
                            style={{
                              backgroundColor: getPutRowColor(
                                puts[index].strike
                              ),
                              color:
                                symbolData[puts[index].code]?.oi -
                                  parseFloat(puts[index]?.previous_oi) >=
                                0
                                  ? "#5CA81D"
                                  : "#DD6565",
                            }}>
                            {formatOI(
                              Math.round(
                                ((symbolData[puts[index].code]?.oi -
                                  parseFloat(puts[index]?.previous_oi)) /
                                  parseFloat(puts[index]?.previous_oi)) *
                                  100,
                                0
                              )
                            )}
                            %
                          </td>
                          <td
                            data-side="puts"
                            onClick={(event) =>
                              handleRowClick(event, index, "puts")
                            }
                            style={{
                              backgroundColor: getPutRowColor(
                                puts[index].strike
                              ),
                            }}>
                            {formatVolume(
                              symbolData[puts[index].code]?.volume /
                                symbolData[puts[index].code]?.lot_size
                            )}
                          </td>
                          {showDelta && (
                            <td
                              style={{
                                backgroundColor: getPutRowColor(
                                  puts[index].strike
                                ),
                              }}>
                              {puts[index]["delta"]}
                            </td>
                          )}
                          {showTheta && (
                            <td
                              style={{
                                backgroundColor: getPutRowColor(
                                  puts[index].strike
                                ),
                              }}>
                              {puts[index]["theta"]}
                            </td>
                          )}
                          {showGamma && (
                            <td
                              style={{
                                backgroundColor: getPutRowColor(
                                  puts[index].strike
                                ),
                              }}>
                              {puts[index]["gamma"]}
                            </td>
                          )}
                          {showVega && (
                            <td
                              style={{
                                backgroundColor: getPutRowColor(
                                  puts[index].strike
                                ),
                              }}>
                              {puts[index]["vega"]}
                            </td>
                          )}
                          {showOIChng && (
                            <td
                              style={{
                                backgroundColor: getPutRowColor(
                                  puts[index].strike
                                ),
                              }}></td>
                          )}
                          {showOIBuilddup && (
                            <td
                              style={{
                                backgroundColor: getPutRowColor(
                                  puts[index].strike
                                ),
                              }}></td>
                          )}
                        </>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptionChainTableMobile;
