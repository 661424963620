import React from "react";
import { Helmet } from "react-helmet-async";
import OrdersMobile from "./OrdersMobile";
import OrdersDesktop from "./OrdersDesktop";

const Orders = () => {
  function isMobile() {
    return (window.screen.width > 430 ) ? false : true;
  }

  return isMobile() ? (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <div className="mobile-screen d-grid" style={{ gridColumn: "span 4" }}>
        <OrdersMobile />
      </div>
    </>
  ) : (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <div className="desktop-screen d-grid" style={{ gridColumn: "span 16" }}>
        <OrdersDesktop />
      </div>
    </>
  );
};

export default Orders;
