import React, {
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
  useRef,
  startTransition,
} from "react";
import SearchModal from "../modals/SearchModal";
import toMarketData from "../utils/decoder";
import { getSymbols } from "../utils/api";
import WebsocketWrapperContext from "../context/WebsocketWrapperContext";
import { useTheme } from "../context/ThemeContext";
import { IoSettingsOutline } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";
let triggerSubscribe = true;
let connectionEstablished = false;

//React.memo => so the component will only re-render if its props change
const SymbolSelection = React.memo(
  ({ fetchDataForSymbol, updateSelectedSymbol }) => {
    const {
      updateSymbolList,
      websocketConnection,
      checkWebsocketConnection,
      subscribeToSymbols,
      unsubscribeToSymbols,
      symbolList,
      symbolData,
    } = useContext(WebsocketWrapperContext);
    const [searchParams] = useSearchParams();

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [selectedSymbol, setSelectedSymbol] = useState(null);
    const [underlyingCodes, setUnderlyingCodes] = useState([]);
    const prevUnderlyingCodeRef = useRef(null);
    const [marketStatus, setMarketStatus] = useState(false);
    // State to keep track of whether the API call has been made
    const [MarketStatusApiCalled, setMarketStatusApiCalled] = useState(false);

    //This effect runs when we pass the trading_symbol through query params
    useEffect(() => {
      const symbolFromUrl = searchParams.get("trading_symbol");
      // console.log("selected sym in url effect", selectedSymbol);

      if (symbolFromUrl && symbolFromUrl !== selectedSymbol?.scrip) {
        const fetchSymbols = async () => {
          try {
            const symbols = await getSymbols();
            const tradingSymbolFromURL = symbols.find(
              (symbol) => symbol.scrip === symbolFromUrl
            );
            if (tradingSymbolFromURL) {
              setSelectedSymbol(tradingSymbolFromURL);
              sessionStorage.setItem(
                "selectedSymbol",
                JSON.stringify(tradingSymbolFromURL)
              );
              subscribeToSymbols([tradingSymbolFromURL.code], "NSE");
            }
          } catch (error) {
            console.error("Error fetching symbols:", error);
          }
        };
        fetchSymbols();
      }
    }, [searchParams, fetchDataForSymbol, subscribeToSymbols, selectedSymbol]);

    useEffect(() => {
      const storedSymbol = sessionStorage.getItem("selectedSymbol");
      if (storedSymbol) {
        setSelectedSymbol(JSON.parse(storedSymbol));
      } else {
        const fetchSymbols = async () => {
          const symbols = await getSymbols();
          if (symbols) {
            const niftySymbol = symbols.find(
              (symbol) => symbol.scrip === "NIFTY"
            );
            if (niftySymbol) {
              setSelectedSymbol(niftySymbol);
              sessionStorage.setItem(
                "selectedSymbol",
                JSON.stringify(niftySymbol)
              );
            }
          }
        };
        fetchSymbols();
      }
    }, []);

    useEffect(() => {
      const fetchMarketStatus = async () => {
        const apiEndpoint = `https://m3k25pie2e.execute-api.ap-south-1.amazonaws.com/default/optiongeeks_market_status`;
        try {
          const response = await fetch(apiEndpoint);
          const data = await response?.json();
          let status = JSON.parse(data?.body)?.market_open;
          setMarketStatus(status);
          setMarketStatusApiCalled(true);
        } catch (error) {
          console.error("Error fetching data:", error);
        } // Call the API
      };

      if (!MarketStatusApiCalled) {
        fetchMarketStatus();
      }
    }, [MarketStatusApiCalled]);

    const handleSymbolSelection = (symbol) => {
      if (selectedSymbol.code !== symbol?.code) {
        if (
          selectedSymbol.code !== "26000" &&
          selectedSymbol.code !== "26009"
        ) {
          unsubscribeToSymbols([selectedSymbol?.code], selectedSymbol.exchange);
        }
        triggerSubscribe = true;
        // subscribeToSymbols({[symbol.code]: symbol.trading_symbol})
      }
      setSelectedSymbol(symbol);
      sessionStorage.setItem("selectedSymbol", JSON.stringify(symbol));
      updateSelectedSymbol(symbol);

      if (prevUnderlyingCodeRef.current !== symbol.underlying_code) {
        setUnderlyingCodes((prevCodes) => [symbol.underlying_code]);
        prevUnderlyingCodeRef.current = symbol.underlying_code;
        console.log("Array of underlying codes", underlyingCodes);
      }
    };

    useEffect(() => {
      if (selectedSymbol) {
        const trading_symbol = selectedSymbol.code;
        const symbolCode = selectedSymbol.code;
        const symbols = [symbolCode];
        const exchange = selectedSymbol.exchange;
        connectionEstablished = checkWebsocketConnection();
        if (connectionEstablished) {
          if (triggerSubscribe) {
            subscribeToSymbols(symbols, exchange);
            // If symbolList is empty the message is not sent to the websocket
            if (Object.keys(symbolList).length !== 0) {
              triggerSubscribe = false;
            }
          }
        } else {
          triggerSubscribe = true;
        }
      }

      return () => {};
    }, [checkWebsocketConnection, subscribeToSymbols, selectedSymbol]);

    // const handleMostTradedSymbolSelection = async (symbol) => {
    //   let selectedSymbol = null;
    //   if (symbol === "NIFTY") {
    //     const niftySymbols = await getSymbols();
    //     selectedSymbol = niftySymbols.find(
    //       (symbol) => symbol.scrip === "NIFTY"
    //     );
    //   } else if (symbol === "BANKNIFTY") {
    //     const bankNiftySymbols = await getSymbols();
    //     selectedSymbol = bankNiftySymbols.find(
    //       (symbol) => symbol.scrip === "BANKNIFTY"
    //     );
    //   } else if (symbol === "FINNIFTY") {
    //     const finNiftySymbols = await getSymbols();
    //     selectedSymbol = finNiftySymbols.find(
    //       (symbol) => symbol.scrip === "FINNIFTY"
    //     );
    //   }

    //   handleSymbolSelection(selectedSymbol);
    // };

    useEffect(() => {
      // Function to handle subscription and unsubscription
      const handleSubscription = (symbols) => {
        // if (!connectionEstablished) {
        //   triggerSubscribe = true;
        //   return;
        // }
        // if (triggerSubscribe) {
        //   subscribeToSymbols(symbols);
        //   triggerSubscribe = false;
        // } else {
        //   unsubscribeToSymbols(symbols);
        //   subscribeToSymbols(symbols);
        // }
      };

      if (selectedSymbol) {
        const trading_symbol = selectedSymbol.trading_symbol;
        const symbolCode = selectedSymbol.code;
        const symbols = { [symbolCode]: trading_symbol };
        handleSubscription(symbols);
      }

      return () => {
        // Unsubscribe from the previous symbol when component unmounts or selectedSymbol changes
        // unsubscribeToSymbols(selectedSymbol?.underlying_code);
      };
    }, [
      selectedSymbol,
      subscribeToSymbols,
      unsubscribeToSymbols,
      connectionEstablished,
    ]);
    const ltpValue = symbolData[selectedSymbol?.code]?.ltp;
    //console.log("data", symbolData[selectedSymbol?.code]);
    const openValue = symbolData[selectedSymbol?.code]?.open;
    const lowerLimit = symbolData[selectedSymbol?.code]?.low;
    const higherLimit = symbolData[selectedSymbol?.code]?.high;
    const [pointerPosition, setPointerPosition] = useState(0);
    const [barPosition, setBarPointerPosition] = useState(0);

    // console.log( "lowerLimit, higherLimit",lowerLimit, higherLimit)
    const calculatePointerPosition = () => {
      if (
        ltpValue !== undefined &&
        higherLimit !== undefined &&
        lowerLimit !== undefined &&
        openValue !== undefined
      ) {
        const range = parseFloat(higherLimit) - parseFloat(lowerLimit);
        let pointerPosition = parseFloat(ltpValue) - parseFloat(lowerLimit);
        let barPosition;
        if (parseFloat(ltpValue) < parseFloat(openValue)) {
          barPosition = parseFloat(ltpValue) - parseFloat(lowerLimit);
        } else {
          barPosition = parseFloat(openValue) - parseFloat(lowerLimit);
        }
        const position = (pointerPosition / range) * 100;

        barPosition = (barPosition / range) * 100;
        setPointerPosition(position);
        setBarPointerPosition(barPosition);

        const ltpOpenDifference = Math.abs(
          parseFloat(ltpValue) - parseFloat(openValue)
        );
        const thumbWidthPercentage = (ltpOpenDifference / range) * 100;
        // debugger
        // width of slider
        document.documentElement.style.setProperty(
          "--thumb-width",
          `${thumbWidthPercentage > 100 ? 100 : thumbWidthPercentage}%`
        );
        document.documentElement.style.setProperty(
          "--thumb-position",
          `${barPosition}%`
        );
      }
    };

    //slider color
    const sliderColor = openValue > ltpValue ? "#FF3908" : "#5ca81d";

    useEffect(() => {
      calculatePointerPosition();
    }, [ltpValue, higherLimit, lowerLimit]);

    const { theme } = useTheme();
    // console.log(pointerPosition);
    return (
      <>
        {/* <div className="top-optionchain-page" style={{justifyContent:'center'}}>
          <div style={{display:"flex",flexDirection:'row',gap:'50px',justifyContent:'center',alignItems:'center'}}>
          <div className="details-optionchain">              
            <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  className="search-svg" 
                  >
                  <path
                    d="M18.3333 18.3333L15.8333 15.8333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
            </svg>
            <div className="symbol-name-style">
              {selectedSymbol?.scrip}{" "}
            </div>
            <div className="info-div">
              <div className="info-price" style={{ fontSize: '16px', fontWeight: 700 }}>
                <span className="lp">
                  ₹
                  {typeof ltpValue === "string" && ltpValue.includes(".") ? (
                    <>
                      {
                        parseFloat(ltpValue)
                          .toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                          .split(".")[0]
                      }
                      .
                      <span style={{ fontSize: '16px', fontWeight: 500 }}>
                        {
                          parseFloat(ltpValue)
                            .toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            .split(".")[1]
                        }
                      </span>
                    </>
                  ) : (
                    <>
                      {parseFloat(ltpValue).toLocaleString("en-IN").split(".")[0]}
                      .
                      <span style={{ fontSize: '16px', fontWeight: 500  }}>
                        {
                          parseFloat(ltpValue)
                            .toLocaleString("en-IN")
                            .split(".")[1]
                        }
                      </span>
                    </>
                  )}
                </span>
              </div>
              <div className="info-change">
                <span
                  className="ch-chp me-2"
                  style={{
                    color:
                      symbolData[selectedSymbol?.code]?.change > 0
                        ? "#5CA81D"
                        : "#DD6565",
                      fontSize:'14px',
                      fontWeight:'700',
                  }}>
                  {symbolData[selectedSymbol?.code]?.change > 0 ? "+" : ""}
                  {String(symbolData[selectedSymbol?.code]?.change).split(".")[0]}
                  .
                  <span style={{ fontWeight: 400 }}>
                    {
                      String(symbolData[selectedSymbol?.code]?.change).split(
                        "."
                      )[1]
                    }
                  </span>
                  (
                  {
                    String(symbolData[selectedSymbol?.code]?.change_per).split(
                      "."
                    )[0]
                  }
                  .
                  <span style={{ fontWeight: 400 }}>
                    {
                      String(symbolData[selectedSymbol?.code]?.change_per).split(
                        "."
                      )[1]
                    }
                  </span>
                  %)
                </span>
              </div>
            </div>
          </div>
          <div className="information">
            <div className="icon-div-optionchain">
              <IoSettingsOutline /> 
            </div>
            <div className="info-type">Info</div>
          </div>
        </div>
      </div> */}
        <div className="symbol-selection d-flex flex-column">
          <div className="symbol-info d-flex flex-column">
            <div className="symbol-name d-flex justify-content-between">
              <div>
                {selectedSymbol?.scrip}{" "}
                {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className="ms-1 mb-1">
                <rect width="16" height="16" fill="#D9D9D9" />
                <path
                  d="M4 4H12M5.09091 6.66667H10.9091M4 9.33333H12M5.09091 12H10.9091"
                  stroke="#1E2028"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> */}
              </div>
              {/* search symbol  */}
              <div
                role="button"
                className="search d-flex justify-content-center align-items-center"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  className="search-svg">
                  <path
                    d="M18.3333 18.3333L15.8333 15.8333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <SearchModal onSymbolSelect={handleSymbolSelection} />
            </div>
            <div className="market-info d-flex align-items-center justify-content-start gap-2">
              <span>NSE</span>
              {marketStatus ? (
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    className="mb-1">
                    <circle cx="5" cy="5" r="5" fill="#70D11F" />
                  </svg>{" "}
                  MARKET OPEN
                </span>
              ) : (
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    className="mb-1">
                    <circle cx="5" cy="5" r="5" fill="#FF3908" />
                  </svg>{" "}
                  MARKET CLOSED
                </span>
              )}
            </div>
            <div className="sym-div d-flex justify-content-between align-items-center">
              <div className="lp-chp-values mt-2">
                <span className="lp">
                  {typeof ltpValue === "string" && ltpValue.includes(".") ? (
                    <>
                      {
                        parseFloat(ltpValue)
                          .toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                          .split(".")[0]
                      }
                      .
                      <span style={{ fontWeight: 400 }}>
                        {
                          parseFloat(ltpValue)
                            .toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            .split(".")[1]
                        }
                      </span>
                    </>
                  ) : (
                    <>
                      {
                        parseFloat(ltpValue)
                          .toLocaleString("en-IN")
                          .split(".")[0]
                      }
                      .
                      <span style={{ fontWeight: 400 }}>
                        {
                          parseFloat(ltpValue)
                            .toLocaleString("en-IN")
                            .split(".")[1]
                        }
                      </span>
                    </>
                  )}
                </span>

                <span
                  className="ch-chp me-2"
                  style={{
                    color:
                      symbolData[selectedSymbol?.code]?.change > 0
                        ? "#5CA81D"
                        : "#DD6565",
                  }}>
                  {symbolData[selectedSymbol?.code]?.change > 0 ? "+" : ""}
                  {
                    String(symbolData[selectedSymbol?.code]?.change).split(
                      "."
                    )[0]
                  }
                  .
                  <span style={{ fontWeight: 400 }}>
                    {
                      String(symbolData[selectedSymbol?.code]?.change).split(
                        "."
                      )[1]
                    }
                  </span>
                  (
                  {
                    String(symbolData[selectedSymbol?.code]?.change_per).split(
                      "."
                    )[0]
                  }
                  .
                  <span style={{ fontWeight: 400 }}>
                    {
                      String(
                        symbolData[selectedSymbol?.code]?.change_per
                      ).split(".")[1]
                    }
                  </span>
                  %)
                </span>
              </div>
              <div className="custom-range-container">
                <div className="w-100">
                  <div className="d-flex justify-content-between">
                    <span className="range-label">
                      {lowerLimit !== undefined
                        ? lowerLimit
                        : String.fromCharCode(160)}
                    </span>
                    <span className="range-label">
                      {higherLimit !== undefined
                        ? higherLimit
                        : String.fromCharCode(160)}
                    </span>
                  </div>
                  <div
                    className="custom-range"
                    style={{ "--slider-color": sliderColor }}>
                    <div
                      className="slider"
                      style={{
                        width: "var(--thumb-width, 84%)",
                        left: `${barPosition}%`,
                        backgroundColor: "var(--slider-color)",
                      }}></div>
                  </div>
                  <div
                    style={{
                      left: `calc(${pointerPosition}% - 1.8%)`,
                      position: "absolute",
                      top: "20px",
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="7"
                      viewBox="0 0 11 7"
                      fill="none">
                      <path
                        d="M4.40608 0.401811C4.90098 -0.133937 5.74756 -0.133937 6.24246 0.401811L10.3143 4.80965C11.0538 5.61025 10.486 6.90784 9.39606 6.90784L1.25248 6.90784C0.162566 6.90784 -0.405272 5.61025 0.334291 4.80965L4.40608 0.401811Z"
                        fill={theme === "dark" ? "white" : "black"}
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default SymbolSelection;
