import React, { useEffect } from "react";
import { useTheme } from "../../context/ThemeContext";

const MarketMoodChart = ({ value }) => {
  const { theme } = useTheme();
  const minValue = 0;
  const maxValue = 100;
  const minAngle = -180;
  const maxAngle = 0;

  const valueToAngle = (val) => {
    return (
      ((val - minValue) * (maxAngle - minAngle)) / (maxValue - minValue) +
      minAngle
    );
  };

  const angle = valueToAngle(value);

  // Calculate the position for the value label based on the angle
  const radius = 180; // Adjust this based on your SVG's viewBox and design
  const cx = 250.5; // Center X based on your SVG's viewBox
  const cy = 239.5; // Center Y based on your SVG's viewBox

  const angleInRadians = (angle * Math.PI) / 180;
  const labelX = cx + (radius + 30) * Math.cos(angleInRadians);
  const labelY = cy + (radius + 50) * Math.sin(angleInRadians);

  return (
    <div>
      {theme === "light" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="300"
          viewBox="0 0 541 257"
          fill="none">
          <path
            d="M491.205 254.125C491.205 138.628 390.998 44.9993 267.386 44.9993C143.774 44.9993 43.5662 138.628 43.5662 254.125"
            stroke="url(#paint0_linear_2905_99)"
            strokeWidth="40"
            strokeDasharray="3.5 6"
          />
          <path
            d="M5.56616 256C5.56616 117.929 122.868 6.00024 267.566 6.00024"
            stroke="url(#paint1_linear_2905_99)"
            strokeMiterlimit="16"
          />
          <path
            d="M529.566 256C529.566 117.929 412.265 6 267.566 6"
            stroke="url(#paint2_linear_2905_99)"
            strokeMiterlimit="16"
          />
          <rect
            x="29.7549"
            y="122.01"
            width="18"
            height="76.8447"
            transform="rotate(21.9745 29.7549 122.01)"
            fill="white"
          />
          <path
            d="M18.2008 189.101L16.806 194.206L8.43679 191.92L9.8315 186.815L10.8733 187.099L9.64622 191.591L9.33709 190.947L12.4047 191.785L13.3819 188.208L14.389 188.483L13.4117 192.06L16.5372 192.914L15.9203 193.305L17.1474 188.814L18.2008 189.101Z"
            fill="black"
          />
          <path
            d="M18.5628 186.327L18.2808 187.592L15.8929 184.957L12.556 186.303L12.8406 185.027L15.2961 184.05L13.4804 182.157L13.7598 180.904L16.2088 183.54L19.4793 182.216L19.1947 183.492L16.7796 184.454L18.5628 186.327Z"
            fill="black"
          />
          <path
            d="M13.9559 180.46L14.8084 177.136L15.7267 177.371L14.8742 180.696L13.9559 180.46ZM20.2064 179.709L19.9262 180.802L12.4636 178.888L12.7438 177.796L20.2064 179.709Z"
            fill="black"
          />
          <path
            d="M16.9643 172.756L17.9558 173.043L17.816 173.527C17.6674 174.042 17.6996 174.492 17.9129 174.879C18.1206 175.255 18.4974 175.522 19.0431 175.68L22.0292 176.542L21.7164 177.625L16.0901 176.001L16.3829 174.987L17.5394 175.221L17.5095 175.324C17.1627 175.141 16.9162 174.87 16.7699 174.511C16.616 174.15 16.6022 173.751 16.7287 173.313C16.7575 173.213 16.7918 173.123 16.8316 173.043C16.8659 172.953 16.9102 172.857 16.9643 172.756Z"
            fill="black"
          />
          <path
            d="M23.5351 171.537C23.3595 172.077 23.0819 172.517 22.7024 172.856C22.3153 173.193 21.8666 173.409 21.3562 173.504C20.8383 173.596 20.2902 173.548 19.712 173.36C19.1262 173.17 18.6498 172.889 18.2827 172.517C17.9181 172.138 17.6759 171.706 17.5559 171.221C17.4384 170.728 17.4675 170.212 17.6431 169.672C17.8163 169.139 18.08 168.716 18.4344 168.402C18.7913 168.081 19.2098 167.881 19.6899 167.801C20.1724 167.714 20.6914 167.761 21.2468 167.941L21.6462 168.071L20.1029 172.857L19.3914 172.6L20.5899 168.914C20.1258 168.763 19.7072 168.783 19.3342 168.973C18.9612 169.163 18.698 169.493 18.5447 169.965C18.4284 170.323 18.4081 170.657 18.4838 170.967C18.5543 171.268 18.7194 171.536 18.9792 171.772C19.2339 171.998 19.5704 172.179 19.9889 172.315C20.6584 172.532 21.2252 172.549 21.6893 172.363C22.1459 172.175 22.4669 171.796 22.6524 171.225C22.7884 170.807 22.8161 170.437 22.7353 170.116C22.6545 169.796 22.4691 169.538 22.1793 169.342L22.5058 168.338C23.0547 168.668 23.4198 169.111 23.601 169.666C23.7822 170.221 23.7602 170.845 23.5351 171.537Z"
            fill="black"
          />
          <path
            d="M25.8621 167.019L25.469 168.077L19.9689 166.031L20.3203 165.086L21.3869 165.317L21.2467 165.418C20.9811 165.191 20.8029 164.89 20.712 164.515C20.6163 164.13 20.6494 163.719 20.8111 163.285C20.9923 162.797 21.2741 162.441 21.6565 162.216C22.0388 161.991 22.4656 161.911 22.9367 161.975L22.8656 162.167C22.4586 161.93 22.1866 161.594 22.0496 161.159C21.9127 160.724 21.9362 160.259 22.1202 159.764C22.3545 159.134 22.7222 158.708 23.2235 158.484C23.7247 158.261 24.294 158.268 24.9314 158.505L28.5306 159.843L28.1458 160.878L24.8053 159.636C24.3704 159.474 23.9949 159.463 23.6788 159.601C23.358 159.729 23.1279 159.981 22.9884 160.356C22.8909 160.618 22.8644 160.873 22.9091 161.12C22.9491 161.357 23.0569 161.572 23.2325 161.765C23.4081 161.959 23.6421 162.11 23.9346 162.218L27.1964 163.431L26.8074 164.477L23.4556 163.231C23.0207 163.069 22.6504 163.055 22.3446 163.189C22.0312 163.32 21.8049 163.573 21.6655 163.948C21.5679 164.21 21.5414 164.465 21.5861 164.712C21.6261 164.949 21.7339 165.164 21.9095 165.357C22.0776 165.548 22.3079 165.698 22.6003 165.806L25.8621 167.019Z"
            fill="black"
          />
          <path
            d="M30.4149 156.458C30.1949 156.981 29.8817 157.396 29.4753 157.703C29.0615 158.006 28.5963 158.184 28.0799 158.236C27.556 158.285 27.0138 158.192 26.4533 157.956C25.8854 157.718 25.434 157.398 25.0991 156.997C24.7674 156.589 24.5619 156.138 24.4827 155.645C24.4066 155.144 24.4786 154.632 24.6986 154.108C24.9155 153.592 25.2135 153.192 25.5928 152.909C25.9752 152.618 26.4089 152.454 26.8939 152.414C27.3821 152.368 27.8954 152.458 28.4338 152.684L28.821 152.846L26.8848 157.487L26.1971 157.172L27.6982 153.598C27.2483 153.409 26.8296 153.394 26.442 153.552C26.0545 153.71 25.7647 154.018 25.5726 154.475C25.427 154.822 25.3789 155.153 25.4285 155.469C25.4737 155.775 25.616 156.056 25.8553 156.312C26.0902 156.559 26.4106 156.767 26.8162 156.937C27.4653 157.21 28.0288 157.273 28.5068 157.127C28.9774 156.977 29.3288 156.626 29.5612 156.073C29.7316 155.667 29.7899 155.301 29.7361 154.975C29.6823 154.649 29.519 154.376 29.2464 154.158L29.6554 153.184C30.175 153.558 30.5019 154.03 30.6363 154.598C30.7706 155.167 30.6968 155.786 30.4149 156.458Z"
            fill="black"
          />
          <path
            d="M25.8441 145.237L33.6144 149.096L33.0912 150.15L25.321 146.29L25.8441 145.237ZM28.6158 142.085L26.3256 146.695L25.3584 146.215L27.6485 141.604L28.6158 142.085ZM31.8311 144.405L29.8185 148.457L28.862 147.982L30.8746 143.93L31.8311 144.405Z"
            fill="black"
          />
          <path
            d="M37.1478 142.484C36.8766 142.983 36.5236 143.365 36.0886 143.629C35.6465 143.89 35.166 144.02 34.6469 144.02C34.1208 144.017 33.5906 143.87 33.0564 143.58C32.5151 143.285 32.0979 142.922 31.8048 142.49C31.5155 142.05 31.356 141.581 31.3265 141.083C31.3007 140.577 31.4234 140.075 31.6946 139.576C31.9619 139.084 32.2984 138.716 32.7041 138.472C33.1136 138.221 33.5616 138.1 34.0481 138.109C34.5385 138.112 35.0402 138.252 35.5534 138.531L35.9224 138.732L33.5326 143.155L32.8798 142.773L34.7302 139.368C34.3014 139.135 33.8863 139.078 33.4849 139.196C33.0835 139.315 32.7644 139.592 32.5276 140.028C32.3481 140.359 32.2672 140.683 32.285 141.003C32.2995 141.311 32.413 141.605 32.6255 141.884C32.8347 142.153 33.1326 142.392 33.5193 142.602C34.1379 142.938 34.6922 143.057 35.1824 142.959C35.6656 142.858 36.0504 142.543 36.3368 142.016C36.5469 141.629 36.6414 141.271 36.6204 140.941C36.5994 140.611 36.4642 140.323 36.2148 140.079L36.719 139.151C37.1985 139.575 37.4768 140.077 37.5537 140.656C37.6307 141.235 37.4953 141.844 37.1478 142.484Z"
            fill="black"
          />
          <path
            d="M39.5651 137.679C39.2473 138.216 38.8556 138.555 38.39 138.698C37.9284 138.834 37.4704 138.768 37.016 138.499C36.5548 138.226 36.2842 137.843 36.2044 137.349C36.1177 136.852 36.2254 136.293 36.5276 135.672L37.3448 134.008L37.2003 133.923C36.918 133.756 36.6637 133.675 36.4373 133.68C36.2041 133.682 36.0001 133.752 35.8253 133.89C35.6436 134.024 35.4856 134.205 35.3511 134.432C35.1108 134.838 35.0094 135.206 35.0471 135.535C35.0819 135.853 35.2543 136.104 35.5641 136.287L35.0629 137.134C34.7118 136.926 34.4584 136.655 34.3027 136.321C34.147 135.988 34.0926 135.616 34.1395 135.207C34.1905 134.791 34.3444 134.366 34.601 133.932C34.8496 133.512 35.1412 133.192 35.4761 132.972C35.808 132.741 36.1764 132.629 36.5812 132.636C36.9832 132.632 37.4114 132.765 37.8658 133.034L41.0362 134.91L40.5473 135.736L39.6704 135.357C39.8821 135.658 39.9783 136.022 39.9589 136.448C39.9367 136.862 39.8055 137.272 39.5651 137.679ZM38.9762 136.954C39.2492 136.492 39.3254 136.049 39.2048 135.625C39.0774 135.196 38.7761 134.841 38.3011 134.56L38.0326 134.401L37.3719 135.753C37.1606 136.205 37.0771 136.573 37.1214 136.86C37.1697 137.14 37.3178 137.353 37.5656 137.499C37.8342 137.658 38.0902 137.689 38.3338 137.591C38.5747 137.483 38.7888 137.27 38.9762 136.954Z"
            fill="black"
          />
          <path
            d="M38.1821 127.661L39.0522 128.215L38.7812 128.64C38.493 129.092 38.3966 129.534 38.4919 129.965C38.5847 130.384 38.8706 130.747 39.3495 131.052L41.97 132.723L41.3635 133.675L36.4261 130.526L36.9938 129.635L38.037 130.187L37.9789 130.278C37.6982 130.004 37.5384 129.674 37.4995 129.289C37.4538 128.899 37.5536 128.512 37.7988 128.128C37.8547 128.04 37.9131 127.963 37.9739 127.898C38.0322 127.821 38.1016 127.742 38.1821 127.661Z"
            fill="black"
          />
          <rect
            x="174.489"
            y="14.999"
            width="13.8317"
            height="36.0015"
            transform="rotate(69.0201 174.489 14.999)"
            fill="white"
          />
          <path
            d="M150.428 27.4076L154.114 35.262L153.049 35.7616L149.364 27.9071L150.428 27.4076ZM154.559 26.6625L149.899 28.8492L149.44 27.8715L154.1 25.6848L154.559 26.6625ZM155.634 30.4793L151.538 32.4009L151.085 31.4341L155.18 29.5124L155.634 30.4793Z"
            fill="black"
          />
          <path
            d="M160.544 32.2092C160.022 32.4314 159.508 32.5108 159.003 32.4474C158.494 32.3766 158.037 32.1799 157.63 31.8573C157.22 31.5274 156.897 31.0826 156.659 30.5231C156.418 29.9562 156.317 29.4123 156.356 28.8914C156.403 28.3674 156.57 27.9009 156.856 27.4921C157.151 27.0801 157.559 26.7629 158.082 26.5407C158.597 26.3216 159.09 26.2426 159.559 26.3038C160.036 26.3619 160.462 26.5459 160.837 26.8558C161.219 27.1626 161.525 27.5847 161.753 28.1221L161.918 28.5087L157.295 30.4871L157.022 29.782L160.589 28.2654C160.398 27.8163 160.108 27.5134 159.72 27.3569C159.332 27.2003 158.909 27.219 158.453 27.4131C158.107 27.5602 157.842 27.7643 157.657 28.0254C157.477 28.2759 157.383 28.5766 157.375 28.9274C157.372 29.2678 157.457 29.6404 157.629 30.0453C157.905 30.6932 158.265 31.1312 158.709 31.3594C159.151 31.5802 159.648 31.5732 160.2 31.3384C160.605 31.1662 160.902 30.9444 161.091 30.6729C161.279 30.4013 161.352 30.0922 161.309 29.7454L162.281 29.3322C162.393 29.9628 162.299 30.5287 161.999 31.03C161.699 31.5312 161.214 31.9243 160.544 32.2092Z"
            fill="black"
          />
          <path
            d="M166.061 29.8728C165.478 30.0958 164.96 30.1184 164.507 29.9407C164.061 29.7601 163.744 29.4232 163.555 28.9301C163.363 28.4295 163.39 27.9611 163.634 27.525C163.876 27.0815 164.308 26.7106 164.93 26.4125L166.605 25.6177L166.545 25.4608C166.427 25.1545 166.279 24.9331 166.098 24.7966C165.914 24.6527 165.711 24.5806 165.488 24.5801C165.263 24.5723 165.026 24.6155 164.78 24.7098C164.339 24.8785 164.031 25.1033 163.856 25.3845C163.685 25.6553 163.664 25.9588 163.793 26.295L162.874 26.6466C162.728 26.2655 162.698 25.8959 162.784 25.5376C162.87 25.1794 163.058 24.8546 163.349 24.5634C163.648 24.2692 164.032 24.0322 164.503 23.8521C164.959 23.6777 165.386 23.6084 165.785 23.6442C166.189 23.6696 166.547 23.811 166.86 24.0683C167.177 24.3153 167.43 24.6853 167.619 25.1785L168.935 28.6193L168.038 28.9623L167.588 28.1197C167.566 28.4879 167.415 28.8325 167.135 29.1537C166.86 29.4645 166.502 29.7042 166.061 29.8728ZM166.051 28.9389C166.551 28.7474 166.886 28.4479 167.056 28.0404C167.223 27.6254 167.208 27.1602 167.01 26.6446L166.899 26.3532L165.541 27.0012C165.095 27.2233 164.8 27.4602 164.656 27.7121C164.52 27.9611 164.504 28.2201 164.607 28.4891C164.718 28.7805 164.9 28.9638 165.151 29.0389C165.407 29.1037 165.707 29.0704 166.051 28.9389Z"
            fill="black"
          />
          <path
            d="M172.257 20.9657L172.593 21.9414L172.117 22.1056C171.61 22.2804 171.26 22.5661 171.067 22.9627C170.879 23.3492 170.877 23.8109 171.062 24.3479L172.075 27.2862L171.009 27.6538L169.1 22.1177L170.098 21.7735L170.573 22.854L170.47 22.8892C170.421 22.5001 170.501 22.1425 170.71 21.8165C170.916 21.483 171.235 21.2419 171.666 21.0932C171.765 21.0593 171.858 21.0356 171.946 21.022C172.04 20.9983 172.143 20.9795 172.257 20.9657Z"
            fill="black"
          />
          <rect
            x="400.85"
            y="30.7847"
            width="13.8317"
            height="37.9703"
            transform="rotate(114.564 400.85 30.7847)"
            fill="white"
          />
          <path
            d="M370.168 21.601C369.583 21.3834 369.031 21.3315 368.51 21.4452C367.99 21.5589 367.528 21.8138 367.125 22.21C366.721 22.6062 366.399 23.1267 366.16 23.7715C365.917 24.4238 365.825 25.0256 365.885 25.577C365.952 26.1311 366.153 26.6114 366.488 27.0178C366.826 27.4168 367.273 27.7195 367.828 27.9259C368.18 28.057 368.523 28.1334 368.856 28.155C369.19 28.1766 369.504 28.1355 369.798 28.0318C370.096 27.9205 370.364 27.7386 370.603 27.4861C370.85 27.2364 371.048 26.9092 371.199 26.5043L371.483 25.7395L371.804 26.4606L368.565 25.2554L368.929 24.277L372.686 25.6746L371.016 30.1621L370.094 29.8189L370.542 28.27L370.621 28.5044C370.401 28.7297 370.119 28.9022 369.776 29.022C369.432 29.1418 369.052 29.2008 368.634 29.1991C368.219 29.1899 367.791 29.103 367.348 28.9384C366.584 28.6538 365.973 28.2303 365.517 27.6678C365.063 27.0978 364.787 26.4403 364.688 25.6952C364.59 24.9502 364.697 24.1577 365.01 23.318C365.316 22.4932 365.753 21.8236 366.32 21.309C366.895 20.7972 367.549 20.4729 368.283 20.3362C369.016 20.1995 369.781 20.279 370.575 20.5747C371.168 20.7951 371.667 21.1046 372.074 21.5034C372.483 21.8947 372.772 22.3438 372.942 22.8509C373.122 23.3532 373.156 23.8822 373.043 24.4377L371.874 24.0025C371.916 23.4294 371.775 22.9372 371.45 22.5261C371.135 22.1102 370.708 21.8019 370.168 21.601Z"
            fill="black"
          />
          <path
            d="M378.271 26.143L377.876 27.0964L377.41 26.9035C376.915 26.6983 376.464 26.6801 376.056 26.8489C375.659 27.0133 375.352 27.3579 375.134 27.8826L373.944 30.7539L372.902 30.322L375.144 24.9122L376.12 25.3164L375.758 26.4396L375.658 26.3982C375.879 26.0741 376.176 25.8594 376.549 25.7541C376.925 25.6413 377.323 25.6722 377.744 25.8468C377.84 25.8866 377.926 25.9308 378.001 25.9792C378.087 26.0234 378.177 26.078 378.271 26.143Z"
            fill="black"
          />
          <path
            d="M378.233 33.3443C377.717 33.1078 377.312 32.7815 377.019 32.3655C376.728 31.9423 376.566 31.4717 376.53 30.9539C376.498 30.4287 376.608 29.8898 376.861 29.337C377.118 28.777 377.452 28.336 377.863 28.0141C378.282 27.6955 378.739 27.5045 379.235 27.441C379.737 27.3809 380.247 27.4691 380.763 27.7057C381.272 27.9389 381.662 28.2495 381.933 28.6376C382.212 29.0291 382.363 29.4678 382.386 29.9539C382.417 30.4432 382.311 30.9534 382.068 31.4843L381.893 31.8662L377.317 29.7832L377.654 29.1059L381.178 30.72C381.381 30.2764 381.41 29.8583 381.264 29.4659C381.118 29.0736 380.819 28.7741 380.368 28.5676C380.027 28.411 379.697 28.3524 379.38 28.3919C379.073 28.4274 378.788 28.5607 378.524 28.7916C378.27 29.0186 378.052 29.3322 377.868 29.7322C377.575 30.3723 377.494 30.9335 377.625 31.4159C377.759 31.891 378.099 32.2534 378.645 32.5033C379.045 32.6865 379.409 32.7564 379.737 32.713C380.065 32.6696 380.342 32.5152 380.569 32.2497L381.529 32.6894C381.139 33.1968 380.657 33.5086 380.085 33.6247C379.512 33.7409 378.895 33.6475 378.233 33.3443Z"
            fill="black"
          />
          <path
            d="M383.944 36.2559C383.436 36.0023 383.042 35.6627 382.763 35.2372C382.487 34.8045 382.34 34.3288 382.321 33.81C382.307 33.2841 382.435 32.7491 382.706 32.2051C382.982 31.6539 383.33 31.2243 383.752 30.9163C384.181 30.6119 384.644 30.4362 385.141 30.3893C385.646 30.346 386.152 30.4511 386.66 30.7047C387.162 30.9548 387.541 31.2782 387.799 31.6752C388.064 32.0757 388.2 32.5192 388.208 33.0058C388.223 33.4959 388.099 34.0022 387.839 34.5248L387.651 34.9006L383.147 32.6663L383.506 32.0006L386.974 33.7312C387.192 33.2946 387.235 32.8777 387.102 32.4807C386.969 32.0837 386.681 31.7744 386.237 31.553C385.901 31.3851 385.573 31.3156 385.255 31.3445C384.947 31.3697 384.658 31.4934 384.386 31.7154C384.125 31.9339 383.896 32.24 383.7 32.6337C383.385 33.2636 383.286 33.8218 383.4 34.3083C383.519 34.7876 383.847 35.1612 384.383 35.4291C384.777 35.6256 385.139 35.7076 385.468 35.6751C385.797 35.6426 386.079 35.4975 386.315 35.2397L387.26 35.7113C386.853 36.2053 386.361 36.5008 385.785 36.5979C385.209 36.695 384.595 36.581 383.944 36.2559Z"
            fill="black"
          />
          <path
            d="M389.11 39.1755C388.602 38.9219 388.222 38.5895 387.971 38.1782C387.724 37.7598 387.604 37.2931 387.611 36.7779C387.625 36.2662 387.764 35.7456 388.028 35.2159C388.296 34.679 388.634 34.253 389.041 33.9378C389.452 33.6155 389.905 33.4255 390.399 33.3679C390.892 33.3103 391.397 33.41 391.912 33.6672C392.32 33.8708 392.639 34.1371 392.868 34.4661C393.108 34.7915 393.235 35.1634 393.25 35.582L394.997 32.0816L395.995 32.5799L392.052 40.4826L391.15 40.0326L391.541 39.0341C391.194 39.2991 390.806 39.4407 390.377 39.459C389.954 39.4808 389.532 39.3863 389.11 39.1755ZM389.769 38.3647C390.113 38.5361 390.446 38.6038 390.768 38.5678C391.097 38.5353 391.399 38.4135 391.674 38.2022C391.95 37.9908 392.178 37.7027 392.361 37.3376C392.543 36.9725 392.634 36.62 392.633 36.2802C392.637 35.9331 392.552 35.6184 392.38 35.3358C392.216 35.0569 391.961 34.8317 391.618 34.6603C391.274 34.4888 390.94 34.4247 390.614 34.4679C390.292 34.5039 389.997 34.6293 389.729 34.8442C389.464 35.0519 389.243 35.3347 389.064 35.6927C388.882 36.0577 388.786 36.412 388.775 36.7555C388.765 37.0989 388.842 37.4101 389.007 37.6891C389.171 37.968 389.426 38.1932 389.769 38.3647Z"
            fill="black"
          />
          <rect
            x="534.302"
            y="209.572"
            width="20.0998"
            height="87.6305"
            transform="rotate(164.403 534.302 209.572)"
            fill="white"
          />
          <path
            d="M521.683 180.236C521.472 179.648 521.156 179.192 520.733 178.868C520.311 178.544 519.818 178.355 519.255 178.302C518.692 178.25 518.087 178.339 517.439 178.572C516.784 178.807 516.265 179.125 515.882 179.526C515.502 179.935 515.265 180.398 515.17 180.917C515.084 181.432 515.14 181.969 515.34 182.526C515.467 182.88 515.63 183.191 515.829 183.46C516.027 183.729 516.261 183.942 516.53 184.1C516.807 184.256 517.119 184.344 517.466 184.364C517.816 184.391 518.194 184.332 518.601 184.186L519.369 183.91L519.025 184.62L517.857 181.367L518.839 181.015L520.194 184.787L515.688 186.405L515.355 185.479L516.828 184.822L516.7 185.034C516.386 185.011 516.072 184.907 515.759 184.722C515.446 184.537 515.155 184.284 514.887 183.964C514.627 183.641 514.417 183.257 514.257 182.813C513.982 182.045 513.912 181.305 514.047 180.593C514.19 179.879 514.514 179.244 515.02 178.688C515.526 178.133 516.201 177.703 517.044 177.401C517.873 177.103 518.666 177.005 519.425 177.107C520.187 177.216 520.856 177.506 521.434 177.979C522.012 178.452 522.444 179.087 522.731 179.885C522.944 180.48 523.03 181.061 522.987 181.629C522.952 182.194 522.795 182.705 522.517 183.162C522.25 183.624 521.867 183.99 521.37 184.262L520.948 183.088C521.413 182.751 521.699 182.325 521.803 181.812C521.918 181.303 521.878 180.778 521.683 180.236Z"
            fill="black"
          />
          <path
            d="M522.763 190.127L521.779 190.44L521.626 189.96C521.464 189.449 521.187 189.093 520.795 188.89C520.413 188.692 519.951 188.68 519.41 188.852L516.448 189.794L516.106 188.72L521.687 186.944L522.007 187.95L520.915 188.398L520.883 188.295C521.273 188.255 521.628 188.343 521.949 188.56C522.278 188.775 522.511 189.099 522.649 189.534C522.681 189.633 522.702 189.727 522.714 189.816C522.735 189.909 522.752 190.013 522.763 190.127Z"
            fill="black"
          />
          <path
            d="M518.232 194.499C518.079 193.952 518.067 193.432 518.196 192.939C518.333 192.444 518.587 192.017 518.96 191.655C519.34 191.292 519.824 191.029 520.409 190.866C521.003 190.701 521.555 190.672 522.067 190.778C522.58 190.893 523.021 191.119 523.389 191.457C523.759 191.802 524.02 192.248 524.172 192.796C524.323 193.335 524.337 193.834 524.215 194.291C524.095 194.756 523.857 195.155 523.501 195.486C523.147 195.825 522.689 196.073 522.126 196.23L521.722 196.343L520.362 191.502L521.097 191.322L522.136 195.056C522.606 194.926 522.944 194.678 523.15 194.313C523.356 193.949 523.392 193.528 523.259 193.05C523.158 192.688 522.991 192.398 522.756 192.181C522.531 191.97 522.245 191.837 521.898 191.784C521.561 191.737 521.181 191.772 520.757 191.89C520.078 192.079 519.597 192.379 519.313 192.79C519.037 193.199 518.979 193.693 519.14 194.271C519.258 194.695 519.439 195.018 519.684 195.241C519.928 195.463 520.225 195.576 520.575 195.578L520.858 196.595C520.218 196.624 519.669 196.457 519.211 196.095C518.753 195.732 518.427 195.2 518.232 194.499Z"
            fill="black"
          />
          <path
            d="M519.565 200.353C519.431 199.801 519.437 199.281 519.582 198.793C519.735 198.304 520.003 197.884 520.388 197.536C520.78 197.185 521.272 196.939 521.863 196.795C522.461 196.65 523.015 196.639 523.522 196.763C524.031 196.895 524.464 197.135 524.821 197.485C525.179 197.843 525.426 198.297 525.56 198.849C525.692 199.393 525.689 199.892 525.552 200.345C525.417 200.806 525.166 201.196 524.799 201.516C524.434 201.843 523.968 202.076 523.4 202.213L522.992 202.313L521.794 197.429L522.535 197.274L523.449 201.041C523.923 200.926 524.269 200.69 524.487 200.332C524.705 199.975 524.755 199.555 524.638 199.073C524.55 198.707 524.392 198.412 524.164 198.188C523.946 197.969 523.665 197.827 523.32 197.763C522.985 197.704 522.603 197.727 522.176 197.831C521.492 197.997 521.001 198.28 520.703 198.682C520.413 199.082 520.339 199.573 520.48 200.156C520.584 200.584 520.755 200.913 520.992 201.143C521.229 201.374 521.522 201.496 521.871 201.51L522.12 202.536C521.48 202.544 520.937 202.358 520.491 201.981C520.045 201.603 519.737 201.061 519.565 200.353Z"
            fill="black"
          />
          <path
            d="M520.523 207.178C520.389 206.626 520.398 206.122 520.551 205.665C520.711 205.206 520.99 204.814 521.388 204.486C521.788 204.167 522.276 203.938 522.851 203.798C523.434 203.656 523.978 203.64 524.481 203.748C524.993 203.854 525.43 204.078 525.792 204.418C526.155 204.758 526.404 205.208 526.54 205.768C526.647 206.211 526.649 206.626 526.546 207.013C526.452 207.407 526.25 207.744 525.939 208.025L529.741 207.102L530.004 208.187L521.421 210.27L521.183 209.29L522.199 208.945C521.772 208.851 521.414 208.646 521.123 208.329C520.834 208.021 520.634 207.637 520.523 207.178ZM521.568 207.159C521.658 207.533 521.821 207.831 522.056 208.053C522.293 208.284 522.581 208.436 522.92 208.511C523.26 208.585 523.627 208.574 524.024 208.477C524.42 208.381 524.748 208.223 525.008 208.004C525.275 207.783 525.461 207.515 525.566 207.202C525.673 206.896 525.681 206.556 525.591 206.183C525.5 205.81 525.334 205.513 525.091 205.292C524.855 205.069 524.569 204.925 524.232 204.858C523.903 204.79 523.544 204.803 523.155 204.897C522.758 204.994 522.426 205.148 522.156 205.362C521.887 205.575 521.699 205.835 521.592 206.141C521.485 206.447 521.477 206.786 521.568 207.159Z"
            fill="black"
          />
          <path
            d="M496.54 139.529L494.13 134.818L501.854 130.867L504.264 135.578L503.302 136.07L501.182 131.925L501.845 132.192L499.014 133.64L500.702 136.942L499.773 137.417L498.084 134.116L495.2 135.591L495.392 134.886L497.512 139.032L496.54 139.529Z"
            fill="black"
          />
          <path
            d="M498.313 141.369L497.703 140.226L501.231 140.669L502.886 137.474L503.502 138.628L502.275 140.969L504.886 141.222L505.49 142.355L501.915 141.95L500.296 145.085L499.68 143.931L500.886 141.628L498.313 141.369Z"
            fill="black"
          />
          <path
            d="M506.037 143.329L507.556 146.406L506.706 146.826L505.187 143.748L506.037 143.329ZM501.784 147.97L501.285 146.959L508.193 143.549L508.693 144.561L501.784 147.97Z"
            fill="black"
          />
          <path
            d="M509.837 151.591L508.886 151.991L508.691 151.526C508.483 151.032 508.175 150.701 507.767 150.534C507.369 150.371 506.908 150.4 506.384 150.619L503.518 151.822L503.082 150.782L508.482 148.516L508.891 149.49L507.843 150.033L507.802 149.934C508.187 149.859 508.549 149.915 508.887 150.103C509.234 150.287 509.495 150.59 509.672 151.01C509.712 151.106 509.742 151.198 509.761 151.285C509.791 151.377 509.816 151.479 509.837 151.591Z"
            fill="black"
          />
          <path
            d="M505.925 157.15C505.706 156.625 505.63 156.111 505.697 155.606C505.771 155.099 505.971 154.643 506.297 154.238C506.629 153.831 507.076 153.51 507.638 153.276C508.206 153.039 508.751 152.942 509.271 152.985C509.795 153.035 510.26 153.205 510.667 153.494C511.077 153.792 511.391 154.202 511.61 154.726C511.826 155.243 511.901 155.736 511.837 156.205C511.775 156.681 511.588 157.106 511.276 157.479C510.967 157.86 510.542 158.162 510.003 158.387L509.616 158.549L507.669 153.913L508.376 153.644L509.868 157.221C510.319 157.033 510.623 156.746 510.783 156.359C510.942 155.972 510.926 155.549 510.735 155.091C510.59 154.744 510.388 154.478 510.128 154.291C509.879 154.109 509.579 154.013 509.228 154.004C508.888 153.998 508.515 154.08 508.109 154.25C507.459 154.521 507.018 154.878 506.787 155.321C506.563 155.761 506.567 156.258 506.798 156.812C506.967 157.218 507.187 157.516 507.457 157.707C507.727 157.897 508.036 157.972 508.383 157.932L508.79 158.906C508.158 159.014 507.593 158.916 507.094 158.612C506.595 158.309 506.205 157.822 505.925 157.15Z"
            fill="black"
          />
          <path
            d="M508.488 162.324L508.097 161.266L513.602 159.234L513.951 160.18L512.992 160.7L513.032 160.532C513.382 160.531 513.713 160.643 514.026 160.869C514.35 161.099 514.592 161.432 514.753 161.867C514.933 162.355 514.951 162.809 514.807 163.228C514.664 163.648 514.392 163.987 513.993 164.245L513.922 164.054C514.385 163.968 514.811 164.046 515.198 164.286C515.585 164.527 515.87 164.895 516.053 165.391C516.286 166.021 516.285 166.584 516.05 167.08C515.815 167.576 515.378 167.942 514.74 168.177L511.138 169.507L510.756 168.471L514.099 167.237C514.535 167.077 514.827 166.841 514.977 166.53C515.137 166.223 515.147 165.883 515.009 165.507C514.912 165.245 514.766 165.034 514.571 164.876C514.387 164.722 514.165 164.629 513.905 164.597C513.646 164.565 513.37 164.603 513.078 164.711L509.813 165.915L509.426 164.868L512.781 163.63C513.217 163.47 513.507 163.239 513.652 162.938C513.804 162.635 513.811 162.295 513.672 161.92C513.576 161.657 513.43 161.447 513.235 161.289C513.05 161.135 512.828 161.042 512.569 161.01C512.317 160.975 512.045 161.011 511.753 161.119L508.488 162.324Z"
            fill="black"
          />
          <path
            d="M511.745 174.219C511.571 173.678 511.538 173.159 511.647 172.662C511.763 172.162 512 171.724 512.358 171.348C512.724 170.97 513.196 170.688 513.775 170.501C514.361 170.312 514.912 170.261 515.427 170.347C515.945 170.44 516.394 170.649 516.776 170.971C517.159 171.301 517.438 171.737 517.613 172.277C517.785 172.81 517.819 173.308 517.716 173.77C517.615 174.239 517.393 174.647 517.051 174.993C516.711 175.346 516.263 175.612 515.707 175.791L515.307 175.92L513.753 171.138L514.48 170.929L515.669 174.618C516.134 174.469 516.461 174.208 516.652 173.835C516.843 173.463 516.863 173.04 516.71 172.568C516.595 172.21 516.416 171.928 516.172 171.72C515.939 171.518 515.648 171.398 515.299 171.359C514.961 171.325 514.582 171.376 514.163 171.511C513.493 171.727 513.024 172.046 512.757 172.468C512.497 172.888 512.46 173.384 512.644 173.955C512.779 174.373 512.973 174.689 513.226 174.902C513.48 175.114 513.781 175.214 514.13 175.203L514.454 176.208C513.816 176.262 513.261 176.118 512.789 175.774C512.317 175.43 511.969 174.912 511.745 174.219Z"
            fill="black"
          />
          <line y1="255.905" x2="12" y2="255.905" stroke="#FF430F" />
          <line
            x1="84.7449"
            y1="70.6536"
            x2="93.0577"
            y2="79.3079"
            stroke="#FF430F"
          />
          <line
            x1="441.639"
            y1="77.3077"
            x2="449.952"
            y2="68.6539"
            stroke="#7DB921"
          />
          <line
            x1="266.656"
            y1="12.3271"
            x2="266.656"
            y2="0.327149"
            stroke="#DBE52C"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2905_99"
              x1="502.213"
              y1="253.439"
              x2="43.6278"
              y2="238.519"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5CA81D" />
              <stop offset="0.495" stopColor="#D9E92C" />
              <stop offset="1" stopColor="#FF420E" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_2905_99"
              x1="261.066"
              y1="6.00025"
              x2="5.56616"
              y2="256"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#DDDC2A" />
              <stop offset="0.460777" stopColor="#DDDC2A" />
              <stop offset="0.515593" stopColor="#FF430F" />
              <stop offset="1" stopColor="#FF430F" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_2905_99"
              x1="274.066"
              y1="6"
              x2="529.566"
              y2="256"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#CAE12A" />
              <stop offset="0.460777" stopColor="#CAE12A" />
              <stop offset="0.515593" stopColor="#62AC1E" />
              <stop offset="1" stopColor="#62AC1E" />
            </linearGradient>
          </defs>
          <rect
            x={labelX - 30.3095} // Adjusted to center the rectangle around the text
            y={labelY - 5.8085} // Adjusted to center the rectangle around the text
            rx="24"
            ry="24"
            width="90"
            height="35.617"
            // fill="rgba(255, 255, 255, 0.77)"
            stroke="transparent"
            strokeWidth="0.5"
            filter="url(#boxShadow)"
            style={{
              position: "absolute",
              zIndex: "40",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)",
              opacity: "80%",
              fill:
                value > 0 && value < 25
                  ? "#FFB3B3"
                  : value >= 25 && value < 50
                    ? "#FFD9B3"
                    : value >= 50 && value < 75
                      ? "#FFF3B3"
                      : "#B3FFB3",
            }}
          />
          <text
            x={labelX + 16.3095}
            y={labelY + 9.8085}
            fontSize="14"
            fill="black"
            textAnchor="middle"
            alignmentBaseline="middle"
            fontWeight="700">
            {value}
          </text>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="300"
          viewBox="0 0 541 257"
          fill="none">
          <path
            d="M493.304 253.798C493.304 138.301 393.096 44.6721 269.484 44.6721C145.872 44.6721 45.6648 138.301 45.6648 253.798"
            stroke="url(#paint0_linear_3128_6)"
            stroke-width="40"
            stroke-dasharray="3.5 6"
          />
          <path
            d="M7.66455 255.673C7.66455 117.602 124.966 5.6731 269.664 5.6731"
            stroke="url(#paint1_linear_3128_6)"
            stroke-miterlimit="16"
          />
          <path
            d="M531.665 255.673C531.665 117.602 414.363 5.67285 269.665 5.67285"
            stroke="url(#paint2_linear_3128_6)"
            stroke-miterlimit="16"
          />
          <rect
            x="38.8052"
            y="104.455"
            width="21.4551"
            height="100.49"
            transform="rotate(21.9745 38.8052 104.455)"
            fill="#1B1D21"
          />
          <path
            d="M21.876 193.856L19.9528 200.645L8.7333 197.826L10.6565 191.038L12.0531 191.388L10.3611 197.361L9.9521 196.517L14.0644 197.55L15.4119 192.793L16.762 193.132L15.4144 197.889L19.6043 198.941L18.7718 199.474L20.4639 193.501L21.876 193.856Z"
            fill="white"
          />
          <path
            d="M22.3226 190.247L21.935 191.931L18.7547 188.491L14.2724 190.352L14.6635 188.652L17.9618 187.301L15.5427 184.832L15.9266 183.164L19.1889 186.604L23.5821 184.774L23.191 186.473L19.9469 187.803L22.3226 190.247Z"
            fill="white"
          />
          <path
            d="M16.2043 182.587L17.3788 178.164L18.6092 178.454L17.4347 182.877L16.2043 182.587ZM24.6004 181.442L24.2144 182.895L14.2157 180.541L14.6017 179.088L24.6004 181.442Z"
            fill="white"
          />
          <path
            d="M20.3857 172.335L21.7156 172.688L21.5227 173.332C21.3176 174.017 21.3564 174.612 21.639 175.117C21.9144 175.609 22.4181 175.953 23.15 176.147L27.1551 177.211L26.7234 178.652L19.1772 176.647L19.5813 175.298L21.1335 175.578L21.0922 175.716C20.628 175.483 20.2994 175.13 20.1064 174.66C19.9032 174.187 19.8888 173.659 20.0633 173.077C20.1031 172.944 20.1502 172.824 20.2045 172.717C20.2515 172.597 20.3119 172.47 20.3857 172.335Z"
            fill="white"
          />
          <path
            d="M29.2486 170.544C29.006 171.262 28.6273 171.849 28.1124 172.307C27.5873 172.761 26.9806 173.058 26.2923 173.197C25.5938 173.332 24.8563 173.283 24.0799 173.05C23.2932 172.814 22.6548 172.456 22.1647 171.975C21.6781 171.484 21.3567 170.92 21.2006 170.284C21.048 169.637 21.093 168.955 21.3356 168.237C21.5748 167.53 21.9347 166.965 22.4155 166.541C22.8997 166.108 23.4655 165.832 24.1129 165.715C24.7637 165.587 25.462 165.635 26.2078 165.859L26.7442 166.02L24.612 172.375L23.6567 172.055L25.3123 167.16C24.689 166.973 24.1252 167.01 23.6207 167.27C23.1163 167.53 22.7581 167.974 22.5463 168.6C22.3857 169.075 22.3546 169.517 22.453 169.925C22.5445 170.319 22.7639 170.669 23.1111 170.973C23.4514 171.265 23.9026 171.495 24.4645 171.663C25.3636 171.933 26.1267 171.939 26.7538 171.682C27.3707 171.422 27.8072 170.913 28.0635 170.155C28.2514 169.6 28.2928 169.111 28.1876 168.69C28.0825 168.269 27.8358 167.933 27.4477 167.683L27.8987 166.35C28.6342 166.771 29.1207 167.345 29.3584 168.073C29.5962 168.801 29.5596 169.625 29.2486 170.544Z"
            fill="white"
          />
          <path
            d="M32.5242 164.521L31.9807 165.924L24.5819 163.38L25.0676 162.126L26.5045 162.401L26.314 162.538C25.9583 162.247 25.7213 161.855 25.6031 161.364C25.4787 160.859 25.5283 160.318 25.7518 159.741C26.0023 159.095 26.387 158.618 26.9058 158.312C27.4245 158.006 28.0015 157.889 28.6366 157.96L28.5384 158.214C27.9919 157.913 27.6288 157.479 27.4492 156.91C27.2696 156.341 27.307 155.728 27.5613 155.072C27.8851 154.236 28.3866 153.665 29.0659 153.357C29.7451 153.05 30.5135 153.043 31.3708 153.338L36.2126 155.003L35.6807 156.376L31.187 154.83C30.6019 154.629 30.0952 154.624 29.6669 154.815C29.2324 154.992 28.9187 155.33 28.726 155.827C28.5911 156.175 28.5524 156.511 28.6097 156.835C28.6608 157.146 28.8037 157.426 29.0384 157.676C29.2731 157.926 29.5872 158.118 29.9805 158.253L34.3684 159.762L33.8307 161.15L29.3218 159.599C28.7368 159.398 28.2371 159.39 27.8227 159.574C27.3982 159.755 27.0897 160.094 26.8969 160.591C26.7621 160.94 26.7233 161.276 26.7806 161.6C26.8317 161.91 26.9746 162.19 27.2093 162.44C27.4339 162.687 27.7429 162.877 28.1363 163.013L32.5242 164.521Z"
            fill="white"
          />
          <path
            d="M38.8613 150.481C38.5572 151.174 38.1283 151.728 37.5747 152.142C37.0111 152.551 36.3796 152.797 35.6802 152.88C34.9709 152.958 34.2385 152.85 33.483 152.556C32.7176 152.259 32.1108 151.851 31.6628 151.334C31.219 150.806 30.9465 150.22 30.8455 149.575C30.7487 148.919 30.8523 148.245 31.1564 147.551C31.4562 146.868 31.8644 146.335 32.381 145.952C32.9019 145.56 33.4908 145.332 34.1475 145.267C34.8085 145.193 35.5018 145.297 36.2275 145.579L36.7493 145.782L34.0726 151.928L33.1461 151.533L35.221 146.8C34.6146 146.564 34.0483 146.555 33.5221 146.774C32.9958 146.992 32.5999 147.404 32.3344 148.01C32.1331 148.469 32.064 148.905 32.1272 149.319C32.1847 149.718 32.3737 150.084 32.6942 150.414C33.0091 150.731 33.4399 150.996 33.9866 151.209C34.8614 151.549 35.623 151.617 36.2715 151.411C36.91 151.202 37.3899 150.732 37.7111 149.999C37.9466 149.462 38.03 148.98 37.9612 148.552C37.8924 148.125 37.6749 147.772 37.3088 147.492L37.8741 146.203C38.5724 146.68 39.0089 147.291 39.1837 148.033C39.3585 148.776 39.251 149.592 38.8613 150.481Z"
            fill="white"
          />
          <path
            d="M32.8626 135.839L43.368 140.682L42.6455 142.073L32.14 137.23L32.8626 135.839ZM36.6623 131.642L33.4994 137.734L32.1916 137.131L35.3546 131.039L36.6623 131.642ZM41.0011 134.593L38.2215 139.946L36.9283 139.35L39.7079 133.996L41.0011 134.593Z"
            fill="white"
          />
          <path
            d="M48.3029 131.893C47.9287 132.551 47.4443 133.059 46.8497 133.415C46.2456 133.766 45.5905 133.949 44.8843 133.963C44.1686 133.971 43.4489 133.793 42.7252 133.428C41.9919 133.058 41.4282 132.594 41.034 132.037C40.645 131.471 40.4331 130.863 40.3982 130.213C40.3685 129.553 40.5408 128.895 40.915 128.236C41.2839 127.587 41.7456 127.098 42.3 126.769C42.8598 126.431 43.4705 126.262 44.1323 126.262C44.7994 126.252 45.4805 126.423 46.1757 126.774L46.6756 127.026L43.3776 132.862L42.4935 132.38L45.0469 127.887C44.466 127.594 43.9019 127.53 43.3546 127.695C42.8072 127.86 42.3702 128.231 42.0434 128.806C41.7957 129.241 41.6823 129.668 41.703 130.084C41.7196 130.486 41.8709 130.867 42.1569 131.226C42.4387 131.571 42.8415 131.875 43.3652 132.14C44.2032 132.563 44.9562 132.704 45.6241 132.564C46.2825 132.419 46.8093 131.998 47.2046 131.303C47.4944 130.793 47.6268 130.323 47.6018 129.892C47.5768 129.462 47.3959 129.09 47.0592 128.777L47.7548 127.553C48.4028 128.095 48.776 128.743 48.8745 129.496C48.973 130.25 48.7825 131.049 48.3029 131.893Z"
            fill="white"
          />
          <path
            d="M51.7024 125.497C51.2643 126.205 50.7273 126.657 50.0914 126.854C49.4611 127.042 48.8375 126.967 48.2206 126.628C47.5945 126.283 47.2292 125.79 47.125 125.15C47.0114 124.503 47.1634 123.773 47.581 122.957L48.7102 120.769L48.5139 120.661C48.1307 120.451 47.7848 120.352 47.4763 120.364C47.1584 120.372 46.8797 120.468 46.6402 120.652C46.3914 120.831 46.1743 121.07 45.9889 121.37C45.6576 121.905 45.516 122.386 45.5643 122.814C45.6089 123.227 45.8415 123.55 46.2621 123.781L45.5712 124.897C45.0946 124.634 44.7517 124.288 44.5426 123.857C44.3335 123.426 44.2628 122.943 44.3305 122.409C44.4038 121.866 44.6174 121.308 44.9712 120.737C45.3138 120.184 45.7143 119.76 46.1726 119.465C46.6273 119.156 47.1304 119.001 47.682 119C48.2299 118.986 48.8123 119.148 49.4291 119.487L53.733 121.855L53.059 122.943L51.8673 122.47C52.1531 122.858 52.2808 123.329 52.2505 123.884C52.2165 124.424 52.0338 124.962 51.7024 125.497ZM50.9065 124.567C51.2828 123.96 51.3907 123.381 51.2303 122.83C51.0605 122.275 50.6532 121.82 50.0083 121.465L49.6438 121.265L48.7309 123.042C48.4388 123.635 48.3216 124.118 48.3793 124.49C48.4426 124.853 48.6425 125.127 48.9789 125.312C49.3434 125.513 49.6921 125.546 50.0251 125.414C50.3543 125.266 50.6481 124.984 50.9065 124.567Z"
            fill="white"
          />
          <path
            d="M49.9546 112.411L51.1381 113.113L50.7649 113.672C50.3681 114.266 50.233 114.843 50.3599 115.401C50.4834 115.945 50.8709 116.41 51.5223 116.796L55.0866 118.91L54.2514 120.161L47.5357 116.178L48.3175 115.007L49.7372 115.7L49.6573 115.82C49.2761 115.47 49.0605 115.045 49.0103 114.545C48.951 114.039 49.0901 113.533 49.4278 113.027C49.5048 112.912 49.585 112.811 49.6685 112.724C49.7488 112.623 49.8441 112.519 49.9546 112.411Z"
            fill="white"
          />
          <rect
            x="176.587"
            y="14.6719"
            width="13.8317"
            height="39.1371"
            transform="rotate(69.0201 176.587 14.6719)"
            fill="#1B1D21"
          />
          <path
            d="M146.042 27.3583L150.956 37.8309L149.536 38.4969L144.623 28.0243L146.042 27.3583ZM151.55 26.3648L145.336 29.2804L144.724 27.9768L150.938 25.0612L151.55 26.3648ZM152.983 31.4539L147.522 34.0161L146.917 32.7269L152.378 30.1647L152.983 31.4539Z"
            fill="white"
          />
          <path
            d="M159.369 33.8459C158.672 34.1422 157.987 34.2481 157.314 34.1635C156.636 34.0692 156.026 33.8069 155.484 33.3768C154.938 32.9368 154.506 32.3438 154.189 31.5978C153.867 30.8419 153.733 30.1167 153.785 29.4222C153.847 28.7235 154.07 28.1015 154.452 27.5564C154.845 27.0071 155.389 26.5842 156.086 26.2879C156.773 25.9957 157.43 25.8905 158.056 25.9721C158.691 26.0495 159.259 26.2949 159.759 26.7081C160.27 27.1171 160.677 27.6799 160.982 28.3965L161.201 28.9119L155.038 31.5498L154.673 30.6096L159.428 28.5875C159.174 27.9887 158.788 27.5849 158.27 27.3761C157.753 27.1674 157.189 27.1924 156.581 27.4511C156.119 27.6473 155.766 27.9194 155.52 28.2675C155.279 28.6015 155.154 29.0024 155.144 29.4702C155.14 29.924 155.253 30.4208 155.482 30.9607C155.85 31.8245 156.33 32.4086 156.923 32.7128C157.512 33.0072 158.174 32.9979 158.91 32.6849C159.45 32.4553 159.846 32.1595 160.098 31.7975C160.35 31.4354 160.447 31.0232 160.389 30.5609L161.685 30.0099C161.834 30.8507 161.708 31.6053 161.309 32.2736C160.909 32.942 160.263 33.4661 159.369 33.8459Z"
            fill="white"
          />
          <path
            d="M166.535 30.8142C165.758 31.1115 165.067 31.1417 164.462 30.9047C163.868 30.6639 163.445 30.2148 163.193 29.5572C162.938 28.8897 162.973 28.2653 163.299 27.6838C163.621 27.0924 164.197 26.598 165.027 26.2005L167.26 25.1408L167.179 24.9316C167.023 24.5231 166.825 24.2279 166.584 24.046C166.339 23.8541 166.068 23.7579 165.771 23.7573C165.47 23.7468 165.155 23.8045 164.826 23.9302C164.239 24.1551 163.828 24.4549 163.594 24.8298C163.367 25.1908 163.339 25.5955 163.511 26.0438L162.285 26.5126C162.091 26.0045 162.051 25.5116 162.165 25.0339C162.279 24.5563 162.53 24.1233 162.919 23.7349C163.317 23.3428 163.83 23.0267 164.457 22.7866C165.065 22.5541 165.635 22.4617 166.167 22.5094C166.706 22.5433 167.183 22.7318 167.6 23.0748C168.023 23.4041 168.36 23.8976 168.611 24.5551L170.367 29.1429L169.171 29.6002L168.57 28.4768C168.541 28.9676 168.34 29.4271 167.967 29.8553C167.6 30.2697 167.123 30.5894 166.535 30.8142ZM166.521 29.569C167.188 29.3136 167.635 28.9143 167.861 28.371C168.084 27.8177 168.063 27.1973 167.8 26.5099L167.652 26.1214L165.841 26.9854C165.246 27.2815 164.853 27.5974 164.662 27.9333C164.48 28.2653 164.458 28.6107 164.596 28.9693C164.744 29.3579 164.986 29.6022 165.321 29.7024C165.663 29.7888 166.063 29.7443 166.521 29.569Z"
            fill="white"
          />
          <path
            d="M174.595 19.0209L175.044 20.3217L174.409 20.5408C173.733 20.7737 173.266 21.1547 173.009 21.6835C172.758 22.1989 172.756 22.8145 173.002 23.5305L174.353 27.4481L172.931 27.9384L170.386 20.5568L171.717 20.0979L172.349 21.5385L172.213 21.5855C172.147 21.0667 172.254 20.5899 172.533 20.1553C172.808 19.7106 173.233 19.3891 173.808 19.1909C173.939 19.1457 174.063 19.1141 174.181 19.096C174.306 19.0643 174.444 19.0393 174.595 19.0209Z"
            fill="white"
          />
          <rect
            x="405.068"
            y="31.4261"
            width="16.7639"
            height="51.0649"
            transform="rotate(114.564 405.068 31.4261)"
            fill="#1B1D21"
          />
          <path
            d="M364.115 16.1618C363.335 15.8717 362.598 15.8024 361.905 15.954C361.211 16.1056 360.595 16.4455 360.057 16.9738C359.519 17.502 359.09 18.196 358.77 19.0557C358.447 19.9255 358.325 20.7279 358.404 21.4631C358.493 22.2019 358.761 22.8423 359.208 23.3842C359.659 23.9161 360.255 24.3197 360.994 24.595C361.464 24.7698 361.921 24.8716 362.366 24.9004C362.81 24.9292 363.229 24.8745 363.622 24.7361C364.019 24.5878 364.376 24.3453 364.695 24.0086C365.024 23.6757 365.289 23.2393 365.489 22.6995L365.869 21.6798L366.296 22.6412L361.978 21.0344L362.463 19.7297L367.472 21.5932L365.245 27.5765L364.016 27.119L364.614 25.0538L364.719 25.3663C364.425 25.6666 364.05 25.8967 363.592 26.0564C363.134 26.2162 362.627 26.2949 362.07 26.2926C361.517 26.2803 360.945 26.1644 360.355 25.9449C359.336 25.5655 358.522 25.0008 357.913 24.2509C357.308 23.4909 356.94 22.6142 356.809 21.6207C356.678 20.6273 356.82 19.5708 357.237 18.4511C357.646 17.3514 358.229 16.4585 358.985 15.7724C359.751 15.09 360.623 14.6577 361.601 14.4754C362.579 14.2931 363.599 14.3991 364.658 14.7934C365.448 15.0872 366.114 15.5 366.656 16.0316C367.201 16.5533 367.588 17.1522 367.814 17.8283C368.054 18.4981 368.099 19.2033 367.949 19.9441L366.389 19.3638C366.446 18.5996 366.257 17.9434 365.824 17.3952C365.404 16.8407 364.835 16.4296 364.115 16.1618Z"
            fill="white"
          />
          <path
            d="M375.204 22.3082L374.677 23.5794L374.056 23.3221C373.396 23.0485 372.794 23.0243 372.251 23.2493C371.721 23.4686 371.311 23.9281 371.021 24.6277L369.435 28.456L368.046 27.8802L371.035 20.6671L372.335 21.2061L371.853 22.7036L371.72 22.6485C372.015 22.2163 372.411 21.93 372.908 21.7896C373.409 21.6393 373.94 21.6805 374.502 21.9133C374.63 21.9664 374.744 22.0252 374.844 22.0898C374.958 22.1487 375.078 22.2215 375.204 22.3082Z"
            fill="white"
          />
          <path
            d="M375.266 31.9721C374.577 31.6567 374.037 31.2217 373.646 30.6671C373.259 30.1028 373.042 29.4753 372.995 28.7849C372.952 28.0847 373.099 27.3661 373.437 26.6291C373.779 25.8823 374.224 25.2944 374.773 24.8652C375.331 24.4404 375.94 24.1857 376.601 24.1011C377.271 24.021 377.951 24.1386 378.639 24.454C379.318 24.7649 379.838 25.1791 380.199 25.6966C380.57 26.2185 380.772 26.8035 380.803 27.4515C380.845 28.104 380.703 28.7842 380.379 29.4922L380.146 30.0013L374.044 27.2241L374.493 26.3209L379.192 28.4731C379.462 27.8815 379.501 27.3242 379.306 26.801C379.112 26.2778 378.714 25.8785 378.113 25.6031C377.657 25.3944 377.217 25.3163 376.794 25.3689C376.386 25.4163 376.005 25.5939 375.653 25.9019C375.315 26.2046 375.024 26.6226 374.779 27.156C374.388 28.0094 374.28 28.7577 374.455 29.4009C374.634 30.0343 375.087 30.5177 375.815 30.8508C376.348 31.0951 376.833 31.1883 377.27 31.1305C377.708 31.0726 378.078 30.8666 378.381 30.5126L379.661 31.099C379.14 31.7754 378.497 32.1911 377.734 32.3461C376.971 32.501 376.148 32.3763 375.266 31.9721Z"
            fill="white"
          />
          <path
            d="M383.067 35.945C382.389 35.6068 381.864 35.1541 381.491 34.5867C381.124 34.0098 380.928 33.3755 380.903 32.6838C380.884 31.9826 381.055 31.2693 381.417 30.544C381.783 29.809 382.248 29.2362 382.811 28.8256C383.383 28.4196 384 28.1854 384.663 28.1229C385.336 28.0651 386.011 28.2053 386.689 28.5434C387.357 28.8768 387.863 29.3081 388.207 29.8374C388.56 30.3714 388.742 30.9627 388.752 31.6115C388.771 32.265 388.607 32.9401 388.26 33.6368L388.01 34.1379L382.004 31.1589L382.483 30.2712L387.107 32.5788C387.397 31.9966 387.454 31.4408 387.277 30.9114C387.1 30.382 386.716 29.9697 386.124 29.6744C385.676 29.4506 385.239 29.3579 384.815 29.3964C384.404 29.4301 384.018 29.595 383.656 29.891C383.308 30.1823 383.003 30.5904 382.741 31.1153C382.322 31.9552 382.189 32.6995 382.342 33.3482C382.5 33.9872 382.937 34.4854 383.653 34.8426C384.178 35.1045 384.659 35.2139 385.098 35.1706C385.537 35.1273 385.914 34.9338 386.228 34.5901L387.488 35.2188C386.945 35.8775 386.289 36.2716 385.521 36.401C384.753 36.5304 383.935 36.3784 383.067 35.945Z"
            fill="white"
          />
          <path
            d="M390.107 39.9236C389.429 39.5855 388.923 39.1423 388.589 38.5939C388.26 38.0361 388.099 37.4137 388.108 36.7268C388.127 36.0446 388.312 35.3504 388.665 34.6441C389.022 33.9283 389.472 33.3602 390.016 32.94C390.564 32.5103 391.167 32.257 391.825 32.1801C392.484 32.1033 393.156 32.2363 393.844 32.5792C394.388 32.8507 394.812 33.2058 395.118 33.6444C395.438 34.0782 395.608 34.5742 395.627 35.1323L397.956 30.4651L399.288 31.1295L394.03 41.6665L392.827 41.0664L393.348 39.735C392.886 40.0884 392.369 40.2772 391.796 40.3016C391.233 40.3306 390.67 40.2047 390.107 39.9236ZM390.986 38.8425C391.445 39.0711 391.888 39.1614 392.317 39.1133C392.756 39.0701 393.159 38.9076 393.526 38.6258C393.893 38.3441 394.198 37.9598 394.441 37.4731C394.684 36.9863 394.806 36.5163 394.805 36.0632C394.81 35.6004 394.697 35.1807 394.468 34.8041C394.248 34.4322 393.909 34.1319 393.451 33.9033C392.993 33.6747 392.547 33.5892 392.113 33.6468C391.684 33.6948 391.29 33.8621 390.933 34.1486C390.58 34.4255 390.284 34.8026 390.046 35.2798C389.803 35.7666 389.675 36.2389 389.661 36.6969C389.647 37.1549 389.75 37.5698 389.97 37.9417C390.189 38.3136 390.528 38.6139 390.986 38.8425Z"
            fill="white"
          />
          <rect
            x="536.4"
            y="209.245"
            width="23.6969"
            height="107.585"
            transform="rotate(164.403 536.4 209.245)"
            fill="#1B1D21"
          />
          <path
            d="M520.85 173.268C520.567 172.485 520.142 171.878 519.577 171.445C519.011 171.012 518.353 170.76 517.6 170.688C516.848 170.615 516.04 170.733 515.176 171.041C514.301 171.351 513.609 171.773 513.098 172.306C512.592 172.848 512.276 173.464 512.152 174.154C512.037 174.84 512.115 175.554 512.384 176.296C512.555 176.767 512.773 177.182 513.039 177.54C513.305 177.898 513.618 178.183 513.979 178.395C514.349 178.603 514.766 178.72 515.23 178.748C515.697 178.785 516.202 178.708 516.745 178.515L517.77 178.15L517.312 179.094L515.742 174.762L517.053 174.295L518.875 179.319L512.86 181.459L512.413 180.225L514.378 179.356L514.208 179.638C513.788 179.606 513.369 179.466 512.95 179.219C512.531 178.972 512.142 178.634 511.783 178.207C511.434 177.777 511.153 177.266 510.938 176.674C510.567 175.651 510.471 174.666 510.65 173.72C510.839 172.77 511.27 171.926 511.944 171.188C512.618 170.45 513.518 169.881 514.644 169.48C515.749 169.087 516.809 168.959 517.824 169.097C518.841 169.244 519.737 169.633 520.51 170.264C521.283 170.895 521.863 171.742 522.248 172.804C522.535 173.597 522.651 174.371 522.596 175.126C522.551 175.878 522.344 176.557 521.974 177.164C521.618 177.778 521.108 178.265 520.444 178.625L519.877 177.061C520.498 176.614 520.877 176.049 521.015 175.366C521.167 174.689 521.112 173.99 520.85 173.268Z"
            fill="white"
          />
          <path
            d="M521.916 185.18L520.604 185.593L520.398 184.954C520.18 184.273 519.809 183.797 519.285 183.526C518.774 183.262 518.158 183.244 517.435 183.472L513.483 184.717L513.023 183.285L520.47 180.938L520.9 182.279L519.444 182.872L519.4 182.735C519.921 182.683 520.396 182.801 520.825 183.091C521.265 183.378 521.577 183.81 521.763 184.389C521.806 184.521 521.835 184.646 521.85 184.764C521.879 184.889 521.901 185.028 521.916 185.18Z"
            fill="white"
          />
          <path
            d="M515.666 190.522C515.461 189.793 515.444 189.101 515.614 188.446C515.795 187.787 516.134 187.218 516.63 186.738C517.137 186.256 517.781 185.906 518.563 185.691C519.355 185.473 520.092 185.436 520.775 185.579C521.461 185.733 522.05 186.036 522.542 186.486C523.038 186.947 523.388 187.542 523.592 188.271C523.794 188.99 523.815 189.654 523.653 190.263C523.495 190.882 523.178 191.412 522.704 191.852C522.232 192.303 521.621 192.632 520.87 192.839L520.331 192.988L518.502 186.538L519.482 186.301L520.88 191.276C521.507 191.103 521.958 190.774 522.232 190.29C522.505 189.805 522.553 189.244 522.374 188.607C522.238 188.125 522.013 187.739 521.699 187.449C521.399 187.167 521.016 186.99 520.553 186.918C520.103 186.854 519.595 186.9 519.03 187.056C518.125 187.306 517.483 187.704 517.105 188.25C516.737 188.794 516.661 189.451 516.878 190.222C517.036 190.786 517.279 191.217 517.607 191.514C517.934 191.812 518.331 191.962 518.797 191.966L519.178 193.322C518.324 193.358 517.591 193.134 516.978 192.65C516.366 192.166 515.928 191.457 515.666 190.522Z"
            fill="white"
          />
          <path
            d="M517.451 198.026C517.271 197.291 517.277 196.598 517.469 195.949C517.672 195.297 518.029 194.739 518.542 194.276C519.065 193.81 519.72 193.482 520.508 193.293C521.307 193.101 522.045 193.088 522.723 193.254C523.403 193.431 523.982 193.753 524.458 194.219C524.938 194.697 525.268 195.303 525.448 196.039C525.625 196.764 525.623 197.428 525.442 198.031C525.263 198.645 524.929 199.164 524.44 199.588C523.953 200.023 523.331 200.332 522.574 200.514L522.03 200.645L520.419 194.137L521.407 193.933L522.636 198.952C523.269 198.8 523.73 198.486 524.02 198.011C524.31 197.535 524.376 196.976 524.219 196.334C524.099 195.847 523.888 195.454 523.584 195.154C523.292 194.862 522.917 194.672 522.456 194.585C522.008 194.507 521.499 194.536 520.929 194.673C520.016 194.892 519.362 195.269 518.966 195.803C518.58 196.334 518.482 196.989 518.672 197.766C518.812 198.336 519.04 198.774 519.357 199.082C519.674 199.39 520.066 199.554 520.532 199.573L520.867 200.941C520.012 200.949 519.287 200.701 518.691 200.197C518.096 199.693 517.682 198.969 517.451 198.026Z"
            fill="white"
          />
          <path
            d="M518.426 205.741C518.246 205.005 518.257 204.334 518.459 203.725C518.672 203.115 519.044 202.592 519.574 202.157C520.107 201.733 520.757 201.429 521.525 201.244C522.303 201.057 523.028 201.037 523.701 201.182C524.384 201.325 524.967 201.624 525.452 202.078C525.937 202.532 526.27 203.132 526.453 203.877C526.598 204.468 526.602 205.021 526.464 205.537C526.34 206.06 526.071 206.509 525.657 206.883L530.729 205.664L531.083 207.109L519.633 209.861L519.313 208.556L520.667 208.098C520.098 207.972 519.619 207.698 519.23 207.276C518.844 206.864 518.576 206.352 518.426 205.741ZM519.821 205.718C519.943 206.216 520.161 206.613 520.475 206.91C520.792 207.218 521.177 207.422 521.63 207.522C522.083 207.621 522.573 207.607 523.102 207.48C523.631 207.353 524.069 207.144 524.415 206.852C524.771 206.558 525.019 206.202 525.158 205.785C525.3 205.378 525.31 204.926 525.188 204.428C525.066 203.931 524.843 203.535 524.518 203.24C524.203 202.943 523.821 202.749 523.371 202.66C522.931 202.568 522.452 202.585 521.933 202.709C521.404 202.837 520.96 203.042 520.602 203.326C520.243 203.609 519.993 203.955 519.851 204.362C519.709 204.769 519.699 205.221 519.821 205.718Z"
            fill="white"
          />
          <path
            d="M489.613 124.819L486.375 118.55L496.695 113.322L499.932 119.591L498.648 120.242L495.799 114.726L496.686 115.084L492.904 117L495.173 121.393L493.931 122.022L491.662 117.629L487.809 119.581L488.063 118.645L490.912 124.161L489.613 124.819Z"
            fill="white"
          />
          <path
            d="M491.498 126.507L490.679 124.985L495.4 125.585L497.604 121.344L498.431 122.879L496.797 125.986L500.29 126.33L501.102 127.837L496.318 127.289L494.162 131.451L493.335 129.915L494.941 126.859L491.498 126.507Z"
            fill="white"
          />
          <path
            d="M501.434 128.389L503.475 132.485L502.34 133.04L500.299 128.944L501.434 128.389ZM495.761 134.546L495.09 133.2L504.319 128.689L504.99 130.035L495.761 134.546Z"
            fill="white"
          />
          <path
            d="M506.248 138.777L504.978 139.305L504.716 138.686C504.437 138.028 504.024 137.588 503.478 137.364C502.945 137.146 502.33 137.183 501.63 137.473L497.803 139.063L497.217 137.679L504.427 134.683L504.976 135.979L503.578 136.699L503.522 136.566C504.037 136.468 504.521 136.544 504.974 136.794C505.438 137.041 505.788 137.444 506.025 138.004C506.079 138.131 506.119 138.253 506.145 138.37C506.185 138.491 506.22 138.627 506.248 138.777Z"
            fill="white"
          />
          <path
            d="M500.783 145.651C500.489 144.953 500.386 144.268 500.473 143.597C500.571 142.922 500.837 142.316 501.27 141.779C501.714 141.238 502.31 140.813 503.059 140.504C503.818 140.19 504.546 140.063 505.242 140.121C505.942 140.19 506.565 140.418 507.109 140.804C507.658 141.201 508.08 141.748 508.374 142.446C508.663 143.134 508.766 143.79 508.681 144.413C508.601 145.047 508.352 145.611 507.936 146.106C507.524 146.611 506.958 147.012 506.238 147.31L505.72 147.523L503.104 141.351L504.048 140.996L506.053 145.759C506.655 145.51 507.061 145.129 507.273 144.614C507.484 144.1 507.462 143.538 507.205 142.928C507.011 142.466 506.74 142.111 506.392 141.862C506.058 141.619 505.657 141.491 505.188 141.477C504.733 141.468 504.234 141.576 503.692 141.8C502.825 142.159 502.237 142.632 501.929 143.221C501.632 143.806 501.638 144.467 501.948 145.204C502.176 145.745 502.471 146.142 502.833 146.397C503.194 146.651 503.607 146.752 504.071 146.699L504.617 147.996C503.774 148.137 503.018 148.005 502.35 147.6C501.682 147.195 501.159 146.545 500.783 145.651Z"
            fill="white"
          />
          <path
            d="M503.823 151.67L503.298 150.261L510.647 147.575L511.116 148.835L509.836 149.524L509.889 149.3C510.356 149.3 510.799 149.45 511.218 149.751C511.652 150.059 511.976 150.502 512.192 151.082C512.434 151.732 512.46 152.335 512.269 152.893C512.079 153.451 511.717 153.901 511.185 154.244L511.09 153.989C511.708 153.876 512.276 153.981 512.794 154.303C513.313 154.624 513.695 155.115 513.94 155.775C514.253 156.614 514.254 157.364 513.941 158.023C513.629 158.682 513.047 159.167 512.196 159.479L507.387 161.236L506.873 159.857L511.336 158.226C511.917 158.013 512.307 157.7 512.506 157.287C512.719 156.88 512.732 156.427 512.546 155.927C512.415 155.577 512.22 155.296 511.959 155.085C511.712 154.88 511.415 154.756 511.069 154.712C510.722 154.668 510.354 154.718 509.963 154.861L505.605 156.453L505.086 155.059L509.564 153.422C510.145 153.21 510.532 152.904 510.724 152.504C510.927 152.101 510.935 151.649 510.749 151.149C510.619 150.8 510.423 150.519 510.162 150.308C509.915 150.103 509.618 149.978 509.272 149.935C508.936 149.887 508.572 149.935 508.181 150.078L503.823 151.67Z"
            fill="white"
          />
          <path
            d="M507.803 166.132C507.569 165.412 507.524 164.721 507.667 164.059C507.821 163.394 508.136 162.812 508.613 162.313C509.1 161.81 509.73 161.436 510.502 161.189C511.285 160.939 512.02 160.873 512.708 160.989C513.4 161.115 514.001 161.393 514.511 161.824C515.025 162.265 515.399 162.845 515.633 163.565C515.864 164.275 515.912 164.937 515.775 165.552C515.642 166.177 515.347 166.719 514.891 167.178C514.438 167.647 513.84 168 513.099 168.237L512.565 168.408L510.475 162.037L511.445 161.761L513.045 166.676C513.665 166.478 514.102 166.131 514.355 165.636C514.609 165.141 514.634 164.578 514.429 163.95C514.274 163.473 514.034 163.096 513.708 162.819C513.396 162.55 513.007 162.388 512.541 162.335C512.088 162.289 511.583 162.356 511.024 162.534C510.13 162.82 509.505 163.243 509.149 163.804C508.804 164.363 508.755 165.022 509.002 165.783C509.184 166.341 509.444 166.761 509.783 167.045C510.122 167.329 510.525 167.463 510.991 167.449L511.427 168.788C510.575 168.858 509.833 168.664 509.201 168.205C508.57 167.746 508.104 167.055 507.803 166.132Z"
            fill="white"
          />
          <line
            x1="2.09863"
            y1="255.578"
            x2="14.0986"
            y2="255.578"
            stroke="#FF430F"
          />
          <line
            x1="86.8435"
            y1="70.3265"
            x2="95.1563"
            y2="78.9808"
            stroke="#FF430F"
          />
          <line
            x1="443.738"
            y1="76.9805"
            x2="452.051"
            y2="68.3266"
            stroke="#7DB921"
          />
          <line
            x1="268.755"
            y1="12"
            x2="268.755"
            y2="7.15256e-07"
            stroke="#DBE52C"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3128_6"
              x1="504.311"
              y1="253.112"
              x2="45.7264"
              y2="238.191"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#5CA81D" />
              <stop offset="0.495" stop-color="#D9E92C" />
              <stop offset="1" stop-color="#FF420E" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_3128_6"
              x1="263.165"
              y1="5.6731"
              x2="7.66455"
              y2="255.673"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#DDDC2A" />
              <stop offset="0.460777" stop-color="#DDDC2A" />
              <stop offset="0.515593" stop-color="#FF430F" />
              <stop offset="1" stop-color="#FF430F" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_3128_6"
              x1="276.165"
              y1="5.67285"
              x2="531.665"
              y2="255.673"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#CAE12A" />
              <stop offset="0.460777" stop-color="#CAE12A" />
              <stop offset="0.515593" stop-color="#62AC1E" />
              <stop offset="1" stop-color="#62AC1E" />
            </linearGradient>
          </defs>
          <rect
            x={labelX - 30.3095} // Adjusted to center the rectangle around the text
            y={labelY - 5.8085} // Adjusted to center the rectangle around the text
            rx="24"
            ry="24"
            width="90"
            height="35.617"
            fill="rgba(255, 255, 255, 0.77)"
            stroke="transparent"
            strokeWidth="0.5"
            filter="url(#boxShadow)"
            style={{
              position: "absolute",
              zIndex: "40",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)",
            }}
          />
          <text
            x={labelX + 16.3095}
            y={labelY + 9.8085}
            fontSize="14"
            fill="black"
            textAnchor="middle"
            alignmentBaseline="middle"
            fontWeight="700">
            {value}
          </text>
        </svg>
      )}
    </div>
  );
};

export default MarketMoodChart;
