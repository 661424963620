import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../context/ThemeContext";

const StockSearchModal = ({ stockList, onClose, onStockItemClick }) => {
  const { theme } = useTheme();

  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const filteredStocks = stockList.filter((stock) =>
    stock.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const defaultStocks = stockList.filter((stock) =>
    ["NIFTY", "BANKNIFTY", "FINNIFTY"].includes(stock.name)
  );
  return (
    <div className="stock-search-modal-container">
      <div className="modal-content">
        <div className="modal-header">
          <input
            type="text"
            placeholder="Search stock..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
          <button
            type="button"
            className={
              theme === "dark" ? "btn-close-white btn-close" : "btn-close"
            }
            onClick={onClose}></button>
        </div>
        <div className="modal-body">
          <ul className="stock-list">
            {searchQuery === "" && (
              <>
                <div className="popular-searches">Popular searches</div>
              </>
            )}
            {searchQuery === "" &&
              defaultStocks.map((stock, index) => (
                <li
                  key={index}
                  className="stock-item list-uns"
                  onClick={() => onStockItemClick(stock.name)}>
                  <div className="stock-name">{stock.name}</div>
                  <div className="stock-info">
                    {stock.is_index ? "index" : stock.exchange}
                  </div>
                </li>
              ))}
            {searchQuery !== "" &&
              filteredStocks.map((stock, index) => (
                <li
                  key={index}
                  className="stock-item"
                  onClick={() => onStockItemClick(stock.name)}>
                  <div className="stock-name">{stock.name}</div>
                  <div className="stock-info">
                    {stock.is_index ? "index" : stock.exchange}
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default StockSearchModal;
