// OptionChainContext.js
import React, { createContext, useContext, useState } from "react";

const OptionChainContext = createContext();

export const OptionChainProvider = ({ children }) => {
  const [optionData, setOptionData] = useState(null);
  return (
    <OptionChainContext.Provider value={{ optionData, setOptionData }}>
      {children}
    </OptionChainContext.Provider>
  );
};

export const useOptionChainContext = () => useContext(OptionChainContext);


const CallsContext = createContext();

export const CallsProvider = ({ children }) => {
  const [callsDataCon, setCallsDataCon] = useState([]);
  return (
    <CallsContext.Provider value={{ callsDataCon, setCallsDataCon }}>
      {children}
    </CallsContext.Provider>
  );
};

export const useCallsContext = () => useContext(CallsContext);