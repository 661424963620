import React, {
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
  useRef,
  useCallback,
} from "react";
import axios from "axios";
import { getSymbols } from "../utils/api";
import { useLtpContext } from "../context/LtpContext";
import { useBasketContext } from "../context/BasketContext";

import ABWebSocketContext from "../context/ABWebsocketContext";
import CustomToastContent from "./CustomToastContent";
import { toast } from "react-toastify";
import SuccessIcon from "../images/success_icon.svg";
import WebsocketWrapperContext from "../context/WebsocketWrapperContext";
import { useTheme } from "../context/ThemeContext";

import { ButtonContext } from "../context/ButtonsContext";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "../images/error_icon.svg";

import { update } from "@react-spring/web";
import { useDataContext } from "../context/DataContext";

import { useBuySellModalContext } from "../context/BuySellModalContext";
import { convert_position } from "fyers-api-v2";
import { e, round } from "mathjs";

const OptionChainTable = ({
  parentCallback2,
  optionData,
  showCalls,
  showPuts,
  symbol,
  calls,
  puts,
  clickEnable,
  showDelta,
  showTheta,
  showGamma,
  showVega,
  showOIChng,
  showOIBuilddup,
  isScalpOrderChecked,
  maxPain,
  selectedDate,
  setObjectData,
  objectData,
}) => {
  const { basketData, setBasketData, removeFromBasket, addToBasket } =
    useBasketContext();
  const { callsDataCon, setCallsDataCon, putsDataCon, setPutsDataCon } =
    useDataContext();
  const { buySellModalData, addToBuySellModal, setBuySellModalData } =
    useBuySellModalContext();
  const { theme } = useTheme();
  const [callsData, setCallsData] = useState([]);
  const { symbolData } = useContext(WebsocketWrapperContext);
  const [callBtnColor, setCallBtnColor] = useState(false);
  const [displayCalls, setDisplayCalls] = useState(showCalls);
  const [displayPuts, setDisplayPuts] = useState(showPuts);
  const [isRowHovered, setIsRowHovered] = useState(false);
  const [atmIndex, setAtmindex] = useState(0);
  const [ltp, setLtp] = useState(0);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [btnColor, setBtnColor] = useState({});
  // const [selectedDate, setSelectedDate] = useState("");
  const [activeDate, setActiveDate] = useState("");
  const [reducedData, setReducedData] = useState();

  const { isLive, isVirtual } = useContext(ButtonContext);
  const broker = localStorage.getItem("broker");
  const access_token = localStorage.getItem("access_token");
  const client_id = localStorage.getItem("client_id");
  const ltpValue = symbolData[selectedSymbol?.code]?.ltp || 0;
  const navigate = useNavigate();

  const [value, setValue] = useState(1);
  const [maxVolume, setMaxVolume] = useState(0);
  const [maxPutVolume, setMaxPutVolume] = useState(0);
  const [activeButtonIndex, setActiveButtonIndex] = useState(null);
  const [activeButtonSellIndex, setActiveButtonSellIndex] = useState(null);
  const [sellbtnState, setSellbtnState] = useState(false);
  const [inc, setInc] = useState(1);
  const [dec, setDec] = useState(inc);

  var iv = require("implied-volatility");
  var jStat = require("jstat");
  var greeks = require("greeks");
  // Update the state variables when props change
  useEffect(() => {
    setDisplayCalls(showCalls);
    setDisplayPuts(showPuts);
  }, [showCalls, showPuts]);
  const getRowColor = (strike, isCall) => {
    if (theme === "light") {
      const strikeCondition = isCall ? strike < ltpValue : strike > ltpValue;

      if (strikeCondition) {
        return isCall ? "#FFF9E5" : "#F4F5FF"; // Light yellow for ITM call options, Light blue for ITM put options
      } else {
        return "white";
      }
    } else {
      const strikeCondition = isCall ? strike < ltpValue : strike > ltpValue;

      if (strikeCondition) {
        return isCall ? "#29292E" : "#29292E";
      } else {
        return "transparent";
      }
    }
  };

  // Function to generate order payload
  const generateOrderPayload = (basketData) => {
    const orderList = basketData.map((currData, ind) => ({
      isBuy: currData.buy_sell === "B" ? true : false,
      isCall: currData.side === "Call" ? true : false,
      isFut: false,
      code: currData.code,
      exchange: currData.exchange,
      // productType: activeOptions[ind] === "normal" ? "NRML" : "MIS",
      productType: "NRML",
      orderType: "MARKET",
      quantity: currData.lotSize * currData.lot_multiplier,
      price: currData.price,
      freeze_qty: currData.freeze_qty,
      strike: currData.strike,
      expiry: currData.expiryDate,
      tradingSymbol: currData.tradingSymbol,
      lot_multiplier: currData.lot_multiplier,
      scrip: currData.scrip,
      expiry: currData.expiryDate,
    }));
    return {
      order_list: orderList,
      broker: broker,
      access_token: access_token,
      client_id: client_id,
    };
  };

  const generateVirtualOrderPayload = (basketData) => {
    const orderList = basketData.map((currData, ind) => ({
      isBuy: currData.buy_sell === "B" ? true : false,
      isCall: currData.side === "Call" ? true : false,
      isFut: false,
      code: currData.code,
      exchange: currData.exchange,
      // productType: activeOptions[ind] === "normal" ? "NRML" : "MIS",
      productType: "NRML",
      orderType: "MARKET",
      quantity: currData.lotSize * currData.lot_multiplier,
      price: currData.price,
      freeze_qty: currData.freeze_qty,
      strike: currData.strike,
      expiry: currData.expiry,
      tradingSymbol: currData.tradingSymbol,
      lot_multiplier: currData.lot_multiplier,
      scrip: currData.scrip,
      expiry: currData.expiryDate,
    }));
    return {
      order_list: orderList,
      broker: "virtual",
      main_broker: broker,
      access_token: access_token,
      client_id: client_id,
    };
  };

  const handleToastToNavigate = (response) => {
    navigate(response.page);
  };

  const sendVirtualOrder = async (payload) => {
    try {
      const response = await axios.post(
        "https://hgb1gw5n6g.execute-api.ap-south-1.amazonaws.com/default/og_virtual_placeorder",
        payload
      );
      return response.data;
    } catch (error) {
      console.error("Error placing order:", error);
      return {
        status: false,
        message_header: "Error placing order",
        message_body: error.message,
      };
    }
  };

  const sendOrder = async (payload) => {
    try {
      const response = await axios.post(
        "https://0eo1dv23p5.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_placeorder",
        payload
      );
      return response.data;
    } catch (error) {
      console.error("Error placing order:", error);
      return {
        status: false,
        message_header: "Error placing order",
        message_body: error.message,
      };
    }
  };

  const handleBuybtnState = () => {
    setCallBtnColor((prevState) => !prevState);
  };
  //Function  to handle lots size by typing
  const handleLotSizeChange = (newValue, index, side) => {
    const newLotsSize = parseInt(newValue); // Convert the new value to an integer

    if (basketData.length > 0) {
      const checkTradingSymbol =
        side === "calls"
          ? callsDataCon[index].trading_symbol
          : putsDataCon[index].trading_symbol;

      const positionIndex = basketData.findIndex(
        (position) => position.tradingSymbol === checkTradingSymbol
      );

      if (positionIndex !== -1) {
        // Position found in basketData
        const updatedBasketData = [...basketData];
        updatedBasketData[positionIndex].lotSize = newLotsSize;
        setBasketData(updatedBasketData);
      }
    } else {
      // Basket is empty, update options data
      if (side === "calls") {
        const updatedCallsDataCon = [...callsDataCon];
        updatedCallsDataCon[index].LotsSize = newLotsSize;
        setCallsDataCon(updatedCallsDataCon);
      } else {
        const updatedPutsDataCon = [...putsDataCon];
        updatedPutsDataCon[index].LotsSize = newLotsSize;
        setPutsDataCon(updatedPutsDataCon);
      }
    }
  };

  //function to handle lots size by clicking on increment
  const incrementValue = (index, side) => {
    if (
      basketData.length > 0 &&
      basketData.some(
        (position) =>
          position.tradingSymbol ===
          (side === "calls"
            ? callsDataCon[index].trading_symbol
            : putsDataCon[index].trading_symbol)
      )
    ) {
      const checkTradingSymbol =
        side === "calls"
          ? callsDataCon[index].trading_symbol
          : putsDataCon[index].trading_symbol;
      for (let i = 0; i < basketData.length; i++) {
        if (basketData[i].tradingSymbol === checkTradingSymbol) {
          let dataObject = basketData[i];
          dataObject["lotSize"] = dataObject["lotSize"] + 1;

          setBasketData((prevData) => [...prevData]);
        }
      }
    } else {
      if (side === "calls") {
        const updatedCallsDataCon = [...callsDataCon];
        updatedCallsDataCon[index].LotsSize += 1;
        setCallsDataCon(updatedCallsDataCon);
      } else {
        const updatedPutsDataCon = [...putsDataCon];
        updatedPutsDataCon[index].LotsSize += 1;
        setPutsDataCon(updatedPutsDataCon);
      }
    }
  };

  //function to handle lots size by clicking on decrement
  const decrementValue = (index, side) => {
    if (
      basketData.length > 0 &&
      basketData.some(
        (position) =>
          position.tradingSymbol ===
          (side === "calls"
            ? callsDataCon[index].trading_symbol
            : putsDataCon[index].trading_symbol)
      )
    ) {
      const checkTradingSymbol =
        side === "calls"
          ? callsDataCon[index].trading_symbol
          : putsDataCon[index].trading_symbol;
      for (let i = 0; i < basketData.length; i++) {
        if (
          basketData[i].tradingSymbol === checkTradingSymbol &&
          basketData[i].lotSize > 1
        ) {
          let dataObject = basketData[i];
          dataObject["lotSize"] = dataObject["lotSize"] - 1;
          setBasketData((prevData) => [...prevData]);
        }
      }
    } else {
      if (side === "calls") {
        if (callsDataCon[index].LotsSize > 1) {
          const updatedCallsDataCon = [...callsDataCon];
          updatedCallsDataCon[index].LotsSize -= 1;
          setCallsDataCon(updatedCallsDataCon);
        }
      } else {
        if (putsDataCon[index].LotsSize > 1) {
          const updatedPutsDataCon = [...putsDataCon];
          updatedPutsDataCon[index].LotsSize -= 1;
          setPutsDataCon(updatedPutsDataCon);
        }
      }
    }
  };

  const returnAtmIndex = () => {
    let index = 0;
    const ltp = symbolData[symbol?.code]?.ltp || 0;
    if (optionData.options && optionData.options) {
      let curr = optionData.options[0].strike,
        diff = Math.abs(ltp - curr);
      for (let val = 0; val < optionData.options.length; val++) {
        let newdiff = Math.abs(ltp - optionData.options[val].strike);
        if (newdiff < diff) {
          diff = newdiff;
          curr = optionData.options[val];
          index = val;
        }
      }
    }
    setAtmindex(index);
  };
  useEffect(() => {
    returnAtmIndex();
  }, [calls, symbol]);
  const getPutRowColor = (strike) => {
    if (theme === "light") {
      if (strike > ltpValue) {
        return "#F4F5FF";
      } else {
        return "white";
      }
    } else {
      if (strike > ltpValue) {
        return "#29292E";
      } else {
        return "transparent";
      }
    }
  };
  // console.log("this is call",symbolData[calls[1]?.code]?.oi/symbolData[calls[1]?.code]?.lot_size)
  //useEffect for calculating maximum OI
  useEffect(() => {
    const updateMaxVolume = () => {
      let newMaxVolume = 0;
      calls?.forEach((call, index) => {
        const temp =
          symbolData[call.code]?.oi / symbolData[call.code]?.lot_size;
        if (temp > newMaxVolume) {
          newMaxVolume = temp;
        }
      });
      setMaxVolume(newMaxVolume);
    };

    const updateMaxPutVolume = () => {
      let newMaxPutVolume = 0;
      puts?.forEach((put, index) => {
        const temp = symbolData[put.code]?.oi / symbolData[put.code]?.lot_size;
        if (temp > newMaxPutVolume) {
          newMaxPutVolume = temp;
        }
      });
      setMaxPutVolume(newMaxPutVolume);
    };

    updateMaxVolume();
    updateMaxPutVolume();
  }, [calls, puts, symbolData, selectedDate, selectedSymbol]);

  // copying of data to callsdatacon and putsdatacon
  useEffect(() => {
    if (calls && calls.length > 0) {
      let callsTempData = calls.map((call) =>
        call
          ? { ...call, buyBtnState: false, sellBtnState: false, LotsSize: 1 }
          : null
      );
      setCallsDataCon(callsTempData.filter(Boolean));
    }
  }, [calls, selectedDate]);

  useEffect(() => {
    if (puts && puts.length > 0) {
      let putsTempData = puts.map((put) =>
        put
          ? { ...put, buyBtnState: false, sellBtnState: false, LotsSize: 1 }
          : null
      );
      setPutsDataCon(putsTempData.filter(Boolean));
    }
  }, [puts, selectedDate]);

  // updateMaxVolume();
  // updateMaxPutVolume();

  //taking from modal table
  useEffect(() => {
    const storedSymbol = sessionStorage.getItem("selectedSymbol");
    if (storedSymbol) {
      setSelectedSymbol(JSON.parse(storedSymbol));
    } else {
      const fetchSymbols = async () => {
        const symbols = await getSymbols();
        if (symbols) {
          const niftySymbol = symbols.find(
            (symbol) => symbol.scrip === "NIFTY"
          );
          setSelectedSymbol(niftySymbol);
          sessionStorage.setItem("selectedSymbol", JSON.stringify(niftySymbol));
        }
      };

      fetchSymbols();
    }
  }, [symbol]);

  // const fetchDataForSymbol = async (symbol, date) => {
  //   let brokerName = localStorage.getItem("broker_name");

  //   if (!brokerName) {
  //     console.error("Broker name not found in local storage.");
  //     return;
  //   }

  //   brokerName = brokerName.toLowerCase().replace(/\s+/g, "");
  //   // console.log("Broker name from localstorage:", brokerName);
  //   const apiEndpoint = `https://y18wfq6w5b.execute-api.ap-south-1.amazonaws.com/default/og_expirysymbols?id=${symbol}${date}&broker=${brokerName}`;
  //   try {
  //     const response = await axios.get(apiEndpoint);
  //     const data = response.data;

  //     setReducedData(f);
  //     console.log("Received data is: ", data);
  //   } catch (error) {
  //     console.error("Error: ", error);
  //   }
  // };

  function updateDivVisibility(callsDataCon, putsDataCon) {
    for (let i = 0; i < callsDataCon.length; i++) {
      if (
        callsDataCon[i] &&
        (callsDataCon[i].buyBtnState || callsDataCon[i].sellbtnState)
      ) {
        setDivVisibility(true);
        setSelectedRowIndex(i);
        return;
      }
    }

    for (let i = 0; i < putsDataCon.length; i++) {
      if (
        putsDataCon[i] &&
        (putsDataCon[i].buyBtnState || putsDataCon[i].sellbtnState)
      ) {
        setDivVisibility(true);
        setSelectedRowIndex(i);
        return;
      }
    }
  }

  useEffect(() => {
    if (selectedSymbol && selectedDate) {
      const f = getReducedData();
      setReducedData(f);
    }
  }, [selectedSymbol, selectedDate]);

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedSide, setSelectedSide] = useState(null);

  const getReducedData = () => {
    const sample = optionData && optionData.options && optionData.options;
    if (sample) {
      let f = sample.reduce((groupedOptions, option) => {
        const strike = option.call.strike;

        if (!groupedOptions[strike]) {
          groupedOptions[strike] = {
            call: option.call,
            put: option.put || {}, // Handle missing put data
          };
        } else {
          groupedOptions[strike].call = {
            ...groupedOptions[strike].call,
            ...option.call,
          };
          groupedOptions[strike].put = {
            ...groupedOptions[strike].put,
            ...option.put,
          };
        }
        return groupedOptions;
      }, {});

      return f;
    }
  };
  const [divVisibility, setDivVisibility] = useState(false);

  const handleRowClick = (event, rowIndex, side) => {
    if (clickEnable) {
      const rowRect = event.currentTarget.getBoundingClientRect();
      const top = rowRect.top + window.scrollY;
      const left = rowRect.left + window.scrollX;
      setSelectedSide(side);
      setSelectedRowIndex(rowIndex);
    }
  };

  const containerRef = useRef(null);
  const selectedRowIndexRef = useRef(null);
  const selectedSideRef = useRef(null);

  const handleRowMouseEnter = (index) => {
    setDivVisibility(true);
    setSelectedRowIndex(index);
  };

  const handleRowMouseLeave = () => {
    setDivVisibility(false);
    setSelectedRowIndex(null);
  };

  const handleButtonClick = (event) => {
    const target = event.target;
    const isBuyButton = target.classList.contains("buy-btn");
    const isSellButton = target.classList.contains("sell-btn");
    const isBuySellButton = target.classList.contains(
      "open-buy-sell-modal-btn"
    );

    if (isBuyButton) {
      setSelectedRowIndex(selectedRowIndexRef.current);
      setSelectedSide(selectedSideRef.current);
      handleBuyClick();
    } else if (isSellButton) {
      setSelectedRowIndex(selectedRowIndexRef.current);
      setSelectedSide(selectedSideRef.current);
      handleSellClick();
    } else if (isBuySellButton) {
      setSelectedRowIndex(selectedRowIndexRef.current);
      setSelectedSide(selectedSideRef.current);
      handleBuySellModalClick();
    }
  };

  //This block of container is used because in a way we have two onclick elements at a single place
  // useEffect(() => {
  //   const container = containerRef.current;
  //   if (container) {
  //     container.addEventListener("click", handleButtonClick);
  //   }

  //   return () => {
  //     if (container) {
  //       container.removeEventListener("click", handleButtonClick);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    selectedRowIndexRef.current = selectedRowIndex;
    selectedSideRef.current = selectedSide;
  }, [selectedRowIndex, selectedSide]);

  let uniqueStrikes = Array.from(
    new Set(
      optionData.options
        ? optionData.options.map((option) => option.call.strike)
        : null
    )
  );

  uniqueStrikes.sort((a, b) => a - b);
  if (uniqueStrikes.length == 0) {
    setTimeout(() => {
      uniqueStrikes = Array.from(
        new Set(
          optionData.options
            ? optionData.options.map((option) => option.call.strike)
            : null
        )
      );
    }, 1000);
  }
  if (calls) {
    calls.sort((a, b) => a.strike - b.strike);
  }
  if (puts) {
    puts.sort((a, b) => a.strike - b.strike);
  }

  const checkIfPresentInBasket = (side, selectedOption, basketData) => {
    return basketData.some((position) => {
      const temp =
        side === "Call"
          ? selectedOption?.call?.trading_symbol
          : selectedOption?.put?.trading_symbol;

      return position.side === side && position.tradingSymbol === temp;
    });
  };

  const updateBasket = (trading_symbol, isBuy) => {
    setBasketData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.tradingSymbol === trading_symbol) {
          if (item.buy_sell === (isBuy ? "B" : "S")) {
            return null;
          } else {
            return { ...item, buy_sell: isBuy ? "B" : "S" };
          }
        }
        return item;
      });
      setBtnColor({ updatedData });
      // Remove null entries (items to be deleted)
      return updatedData.filter((item) => item !== null);
    });
  };

  const updateBuySellBasket = (trading_symbol, isBuy) => {
    setBuySellModalData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.tradingSymbol === trading_symbol) {
          if (item.buy_sell === (isBuy ? "B" : "S")) {
            return null;
          } else {
            return { ...item, buy_sell: isBuy ? "B" : "S" };
          }
        }
        return item;
      });
      setBtnColor({ updatedData });
      // Remove null entries (items to be deleted)
      return updatedData.filter((item) => item !== null);
    });
  };

  //all buttons false if basket is empty
  if (basketData.length <= 0) {
    for (let i = 0; i < callsDataCon.length; i++) {
      if (callsDataCon[i]) {
        callsDataCon[i].buyBtnState = false;
        callsDataCon[i].sellBtnState = false;
      }
    }
    for (let i = 0; i < putsDataCon.length; i++) {
      if (putsDataCon[i]) {
        putsDataCon[i].buyBtnState = false;
        putsDataCon[i].sellBtnState = false;
      }
    }
  }

  //mapping the data to option chain on changing option chain
  if (basketData.length > 0) {
    for (let j = 0; j < callsDataCon.length; j++) {
      let matching_flag = false;
      for (let i = 0; i < basketData.length; i++) {
        const checkTradingSymbol = basketData[i].tradingSymbol;
        const direction = basketData[i].buy_sell;
        const side = basketData[i].side;
        if (side === "Call") {
          if (callsDataCon[j].trading_symbol === checkTradingSymbol) {
            matching_flag = true;
            callsDataCon[j].LotsSize = basketData[i].lotSize;
            if (direction === "B") {
              callsDataCon[j].buyBtnState = true;
              callsDataCon[j].sellBtnState = false;
            } else {
              callsDataCon[j].sellBtnState = true;
              callsDataCon[j].buyBtnState = false;
            }
          }
        }
      }
      if (!matching_flag) {
        callsDataCon[j].buyBtnState = false;
        callsDataCon[j].sellBtnState = false;
      }
    }

    for (let j = 0; j < putsDataCon.length; j++) {
      let matching_flag = false;
      for (let i = 0; i < basketData.length; i++) {
        const checkTradingSymbol = basketData[i].tradingSymbol;
        const direction = basketData[i].buy_sell;
        const side = basketData[i].side;
        if (side === "Put") {
          if (putsDataCon[j].trading_symbol === checkTradingSymbol) {
            matching_flag = true;
            putsDataCon[j].LotsSize = basketData[i].lotSize;
            if (direction === "B") {
              putsDataCon[j].buyBtnState = true;
              putsDataCon[j].sellBtnState = false;
            } else {
              putsDataCon[j].sellBtnState = true;
              putsDataCon[j].buyBtnState = false;
            }
          }
        }
      }
      if (!matching_flag) {
        putsDataCon[j].buyBtnState = false;
        putsDataCon[j].sellBtnState = false;
      }
    }
  }

  const [lotSizeInput, setLotSizeInput] = useState(1);

  const handleBuyClick = useCallback(
    (selectedSide, rowIndex, lotsProp) => {
      setSelectedSide(selectedSide);
      if (callsDataCon && selectedSide === "calls") {
        const updatedCallsDataCon = [...callsDataCon];
        if (updatedCallsDataCon[rowIndex]) {
          setCallsDataCon(updatedCallsDataCon);
          if (updatedCallsDataCon[rowIndex].sellBtnState) {
            updatedCallsDataCon[rowIndex].sellBtnState = false;
          }
        } else {
          console.error(`Error: callsDataCon[${rowIndex}] is undefined`);
        }
      } else {
        console.error("Error: callsDataCon is undefined");
      }
      if (putsDataCon && selectedSide === "puts") {
        const updatedPutsDataCon = [...putsDataCon];
        if (updatedPutsDataCon[rowIndex]) {
          updatedPutsDataCon[rowIndex].buyBtnState =
            !updatedPutsDataCon[rowIndex].buyBtnState;
          // updatedPutsDataCon[rowIndex].LotsSize = lotSizeInput;
          setPutsDataCon(updatedPutsDataCon);
          if (updatedPutsDataCon[rowIndex].sellBtnState) {
            updatedPutsDataCon[rowIndex].sellBtnState = false;
          }
        } else {
          console.error(`Error: putsDataCon[${rowIndex}] is undefined`);
        }
      } else {
        console.error("Error: putsDataCon is undefined");
      }

      const d = getReducedData();
      const selectedStrike = uniqueStrikes[selectedRowIndex];
      const selectedOption = d[selectedStrike];
      const side = selectedSide === "calls" ? "Call" : "Put";
      const existingPositionIndex = checkIfPresentInBasket(
        side,
        selectedOption,
        basketData
      );
      const f = getReducedData();
      if (
        selectedSymbol &&
        reducedData &&
        selectedRowIndex !== null &&
        selectedSide !== null
      ) {
        // const selectedOption = f[selectedRowIndex];
        const selectedStrike = uniqueStrikes[selectedRowIndex]; // Get the selected strike value
        const selectedOption = f[selectedStrike]; // Use the strike value as the key
        // parseInt(lotSizeInput)
        const lotSizeForOrder = lotsProp;
        const side = selectedSide === "calls" ? "Call" : "Put";
        const instrumentName =
          side === "Call"
            ? selectedOption.call.instrument_name
            : selectedOption.put.instrument_name;

        //console.log("instrumentName in buy click:", instrumentName);
        const price =
          selectedSide === "calls"
            ? symbolData[calls[selectedRowIndex]?.code]?.ltp || 0
            : symbolData[puts[selectedRowIndex]?.code]?.ltp || 0;
        const lotSize =
          side === "Call"
            ? selectedOption.call.lot_size
            : selectedOption.put.lot_size;
        const optionType = side === "Call" ? "CE" : "PE";
        const tradingSymbol =
          side === "Call"
            ? selectedOption.call.trading_symbol
            : selectedOption.put.trading_symbol;
        const expiryDate =
          side === "Call"
            ? selectedOption.call.expiry_date
            : selectedOption.put.expiry_date;
        const code =
          side === "Call" ? selectedOption.call.code : selectedOption.put.code;

        const buy_sell = "B";
        const underlying_code = selectedSymbol.code;
        const quantity =
          side === "Call"
            ? selectedOption.call.lot_size
            : selectedOption.put.lot_size;

        const strike =
          side === "Call"
            ? selectedOption.call.strike
            : selectedOption.put.strike;

        let freeze_qty =
          side === "Call"
            ? selectedOption.call.freeze_qty
            : selectedOption.put.freeze_qty;
        let exchange =
          side === "Call"
            ? selectedOption.call.exchange
            : selectedOption.put.exchange;
        let lot_multiplier = parseFloat(optionData?.lot_size);

        const dataObject = {
          side,
          instrumentName,
          lotSize: lotSizeForOrder,
          optionType,
          tradingSymbol,
          expiryDate,
          code,
          price,
          buy_sell,
          underlying_code,
          strike,
          quantity,
          freeze_qty: freeze_qty,
          exchange: exchange,
          lot_multiplier: lot_multiplier,
          scrip: selectedSymbol.scrip,
        };
        if (isScalpOrderChecked) {
          if (isLive) {
            const payload = generateOrderPayload([dataObject]);
            const response = sendOrder(payload);
            console.log("response", response);
            toast.success(
              <CustomToastContent
                heading="Order placed successfully"
                message="Scalp order placed successfully"
              />,
              {
                className: "custom-success-toast",
                icon: <img src={SuccessIcon} alt="Success Icon" />,
              }
            );
          } else {
            const payload = generateVirtualOrderPayload([dataObject]);
            const response = sendVirtualOrder(payload);
            toast.success(
              <CustomToastContent
                heading="Order placed successfully"
                message="Scalp order placed successfully"
              />,
              {
                className: "custom-success-toast",
                icon: <img src={SuccessIcon} alt="Success Icon" />,
              }
            );
          }
          return;
        } else {
          parentCallback2([dataObject, "B"]);
          if (existingPositionIndex) {
            updateBasket(tradingSymbol, true);
          } else {
            addToBasket(dataObject);
            setDivVisibility(false);
            setBtnColor(dataObject);
            setLotSizeInput(1);
          }
        }
      }
      if (selectedSide === "calls") {
        const updatedTags = [...buySellTagsCalls];
        updatedTags[selectedRowIndex] = "buy";
        setBuySellTagsCalls(updatedTags);
      } else if (selectedSide === "puts") {
        const updatedTags = [...buySellTagsPuts];
        updatedTags[selectedRowIndex] = "buy";
        setBuySellTagsPuts(updatedTags);
      }
    },
    [
      lotSizeInput,
      objectData,
      selectedRowIndex,
      selectedSide,
      reducedData,
      basketData,
      optionData,
      removeFromBasket,
      setSelectedSide,
      uniqueStrikes,
    ]
  );

  const handleSellClick = useCallback(
    (selectedSide, rowIndex, lotsProp) => {
      // setDivVisible(false);
      // updateDivVisibility(callsDataCon, putsDataCon);
      if (objectData.isBuy) {
        setObjectData({ ...objectData, isBuy: false });
      } else {
        setObjectData({ ...objectData, isBuy: true });
      }

      setSelectedSide(selectedSide);
      if (callsDataCon && selectedSide === "calls") {
        const updatedCallsDataCon = [...callsDataCon];
        if (updatedCallsDataCon[rowIndex]) {
          updatedCallsDataCon[rowIndex].sellBtnState =
            !updatedCallsDataCon[rowIndex].sellBtnState;
          if (updatedCallsDataCon[rowIndex].buyBtnState) {
            updatedCallsDataCon[rowIndex].buyBtnState = false;
          }

          setCallsDataCon(updatedCallsDataCon);
        } else {
          console.error(`Error: callsDataCon[${rowIndex}] is undefined`);
        }
      } else {
        console.error("Error: callsDataCon is undefined");
      }
      if (putsDataCon && selectedSide === "puts") {
        const updatedPutsDataCon = [...putsDataCon];
        if (updatedPutsDataCon[rowIndex]) {
          updatedPutsDataCon[rowIndex].sellBtnState =
            !updatedPutsDataCon[rowIndex].sellBtnState;
          if (updatedPutsDataCon[rowIndex].buyBtnState) {
            updatedPutsDataCon[rowIndex].buyBtnState = false;
          }
          setPutsDataCon(updatedPutsDataCon);
        } else {
          console.error(`Error: putsDataCon[${rowIndex}] is undefined`);
        }
      } else {
        console.error("Error: putsDataCon is undefined");
      }

      const d = getReducedData();
      const selectedStrike = uniqueStrikes[selectedRowIndex];
      const selectedOption = d[selectedStrike];
      const side = selectedSide === "calls" ? "Call" : "Put";
      const existingPositionIndex = checkIfPresentInBasket(
        side,
        selectedOption,
        basketData
      );
      const f = getReducedData();

      if (
        selectedSymbol &&
        reducedData &&
        selectedRowIndex !== null &&
        selectedSide !== null
      ) {
        //console.log("Selected Row Indexxxx:", selectedRowIndex);
        const selectedStrike = uniqueStrikes[selectedRowIndex]; // Get the selected strike value
        const selectedOption = f[selectedStrike]; // Use the strike value as the key
        //  console.log("Selected Option in handleSellClick:", selectedOption);

        const lotSizeForOrder = lotsProp;
        const side = selectedSide === "calls" ? "Call" : "Put";
        const price =
          selectedSide === "calls"
            ? symbolData[calls[selectedRowIndex]?.code]?.ltp || 0
            : symbolData[puts[selectedRowIndex]?.code]?.ltp || 0;
        const instrumentName =
          side === "Call"
            ? selectedOption.call.instrument_name
            : selectedOption.put.instrument_name;
        const lotSize =
          side === "Call"
            ? selectedOption.call.lot_size
            : selectedOption.put.lot_size;
        const optionType = side === "Call" ? "CE" : "PE";
        const tradingSymbol =
          side === "Call"
            ? selectedOption.call.trading_symbol
            : selectedOption.put.trading_symbol;
        const expiryDate =
          side === "Call"
            ? selectedOption.call.expiry_date
            : selectedOption.put.expiry_date;

        const quantity =
          side === "Call"
            ? selectedOption.call.lot_size
            : selectedOption.put.lot_size;

        const strike =
          side === "Call"
            ? selectedOption.call.strike
            : selectedOption.put.strike;
        const code =
          side === "Call" ? selectedOption.call.code : selectedOption.put.code;

        const buy_sell = "S";
        const underlying_code = selectedSymbol.code;
        let freeze_qty =
          side === "Call"
            ? selectedOption.call.freeze_qty
            : selectedOption.put.freeze_qty;
        let exchange =
          side === "Call"
            ? selectedOption.call.exchange
            : selectedOption.put.exchange;
        let lot_multiplier = parseFloat(optionData?.lot_size);

        const dataObject = {
          side,
          instrumentName,
          lotSize: lotSizeForOrder,
          optionType,
          tradingSymbol,
          expiryDate,
          code,
          price,
          buy_sell,
          underlying_code,
          strike,
          quantity,
          freeze_qty: freeze_qty,
          exchange: exchange,
          lot_multiplier: lot_multiplier,
          scrip: selectedSymbol.scrip,
        };
        if (isScalpOrderChecked) {
          if (isLive) {
            const payload = generateOrderPayload([dataObject]);
            const response = sendOrder(payload);
            console.log("response", response);
            toast.success(
              <CustomToastContent
                heading="Order placed successfully"
                message="Scalp order placed successfully"
              />,
              {
                className: "custom-success-toast",
                icon: <img src={SuccessIcon} alt="Success Icon" />,
              }
            );
          } else {
            const payload = generateVirtualOrderPayload([dataObject]);
            const response = sendVirtualOrder(payload);
            toast.success(
              <CustomToastContent
                heading="Order placed successfully"
                message="Scalp order placed successfully"
              />,
              {
                className: "custom-success-toast",
                icon: <img src={SuccessIcon} alt="Success Icon" />,
              }
            );
          }
          return;
        } else {
          parentCallback2([dataObject, "S"]);
          if (existingPositionIndex) {
            updateBasket(tradingSymbol, false);
          } else {
            addToBasket(dataObject);
            setDivVisibility(false);
            setBtnColor(dataObject);
            setLotSizeInput(1);
          }
        }
        if (selectedSide === "calls") {
          const updatedTags = [...buySellTagsCalls];
          updatedTags[selectedRowIndex] = "sell";
          setBuySellTagsCalls(updatedTags);
        } else if (selectedSide === "puts") {
          const updatedTags = [...buySellTagsPuts];
          updatedTags[selectedRowIndex] = "sell";
          setBuySellTagsPuts(updatedTags);
        }
      }
    },
    [
      lotSizeInput,
      optionData,
      uniqueStrikes,
      reducedData,
      lotSizeInput,
      basketData,
      removeFromBasket,
      setSelectedSide,
      uniqueStrikes,
      selectedRowIndex,
      selectedSide,
      optionData,
      uniqueStrikes,
      lotSizeInput,
    ]
  );

  const handleBuySellModalClick = useCallback(
    (selectedSide, rowIndex, lotsProp) => {
      console.log(
        "selectedSide, rowIndex, lotsProp",
        selectedSide,
        rowIndex,
        lotsProp
      );
      setSelectedSide(selectedSide);
      if (callsDataCon && selectedSide === "calls") {
        const updatedCallsDataCon = [...callsDataCon];
        if (updatedCallsDataCon[rowIndex]) {
          updatedCallsDataCon[rowIndex].sellBtnState =
            !updatedCallsDataCon[rowIndex].sellBtnState;
          if (updatedCallsDataCon[rowIndex].buyBtnState) {
            updatedCallsDataCon[rowIndex].buyBtnState = false;
          }

          setCallsDataCon(updatedCallsDataCon);
        } else {
          console.error(`Error: callsDataCon[${rowIndex}] is undefined`);
        }
      } else {
        console.error("Error: callsDataCon is undefined");
      }
      if (putsDataCon && selectedSide === "puts") {
        const updatedPutsDataCon = [...putsDataCon];
        if (updatedPutsDataCon[rowIndex]) {
          updatedPutsDataCon[rowIndex].sellBtnState =
            !updatedPutsDataCon[rowIndex].sellBtnState;
          if (updatedPutsDataCon[rowIndex].buyBtnState) {
            updatedPutsDataCon[rowIndex].buyBtnState = false;
          }
          setPutsDataCon(updatedPutsDataCon);
        } else {
          console.error(`Error: putsDataCon[${rowIndex}] is undefined`);
        }
      } else {
        console.error("Error: putsDataCon is undefined");
      }

      const d = getReducedData();
      const selectedStrike = uniqueStrikes[selectedRowIndex];
      const selectedOption = d[selectedStrike];
      const side = selectedSide === "calls" ? "Call" : "Put";
      const existingPositionIndex = checkIfPresentInBasket(
        side,
        selectedOption,
        buySellModalData
      );
      const f = getReducedData();

      if (
        selectedSymbol &&
        reducedData &&
        selectedRowIndex !== null &&
        selectedSide !== null
      ) {
        //console.log("Selected Row Indexxxx:", selectedRowIndex);
        const selectedStrike = uniqueStrikes[selectedRowIndex]; // Get the selected strike value
        const selectedOption = f[selectedStrike]; // Use the strike value as the key
        //  console.log("Selected Option in handleSellClick:", selectedOption);

        const lotSizeForOrder = lotsProp;
        const side = selectedSide === "calls" ? "Call" : "Put";
        const price =
          selectedSide === "calls"
            ? symbolData[calls[selectedRowIndex]?.code]?.ltp || 0
            : symbolData[puts[selectedRowIndex]?.code]?.ltp || 0;
        const trigger_price =
          selectedSide === "calls"
            ? symbolData[calls[selectedRowIndex]?.code]?.ltp || 0
            : symbolData[puts[selectedRowIndex]?.code]?.ltp || 0;
        const instrumentName =
          side === "Call"
            ? selectedOption.call.instrument_name
            : selectedOption.put.instrument_name;
        const lot_size =
          side === "Call"
            ? selectedOption.call.lot_size
            : selectedOption.put.lot_size;
        const optionType = side === "Call" ? "CE" : "PE";
        const tradingSymbol =
          side === "Call"
            ? selectedOption.call.trading_symbol
            : selectedOption.put.trading_symbol;
        const expiryDate =
          side === "Call"
            ? selectedOption.call.expiry_date
            : selectedOption.put.expiry_date;

        const quantity =
          side === "Call"
            ? selectedOption.call.lot_size
            : selectedOption.put.lot_size;

        const strike =
          side === "Call"
            ? selectedOption.call.strike
            : selectedOption.put.strike;
        const code =
          side === "Call" ? selectedOption.call.code : selectedOption.put.code;

        const buy_sell = "S";
        const underlying_code = selectedSymbol.code;
        let freeze_qty =
          side === "Call"
            ? selectedOption.call.freeze_qty
            : selectedOption.put.freeze_qty;
        let exchange =
          side === "Call"
            ? selectedOption.call.exchange
            : selectedOption.put.exchange;
        let lot_multiplier = parseFloat(optionData?.lot_size);

        const dataObject = {
          side,
          instrumentName,
          lotSize: lotSizeForOrder,
          lot_size,
          optionType,
          tradingSymbol,
          expiryDate,
          code,
          price,
          trigger_price,
          buy_sell,
          underlying_code,
          strike,
          quantity,
          freeze_qty: freeze_qty,
          exchange: exchange,
          lot_multiplier: lot_multiplier,
          scrip: selectedSymbol.scrip,
        };
        if (isScalpOrderChecked) {
          if (isLive) {
            const payload = generateOrderPayload([dataObject]);
            const response = sendOrder(payload);
            console.log("response", response);
            toast.success(
              <CustomToastContent
                heading="Order placed successfully"
                message="Scalp order placed successfully"
              />,
              {
                className: "custom-success-toast",
                icon: <img src={SuccessIcon} alt="Success Icon" />,
              }
            );
          } else {
            const payload = generateVirtualOrderPayload([dataObject]);
            const response = sendVirtualOrder(payload);
            toast.success(
              <CustomToastContent
                heading="Order placed successfully"
                message="Scalp order placed successfully"
              />,
              {
                className: "custom-success-toast",
                icon: <img src={SuccessIcon} alt="Success Icon" />,
              }
            );
          }
          return;
        } else {
          parentCallback2([dataObject, "S"]);
          if (existingPositionIndex) {
            updateBuySellBasket(tradingSymbol, false);
          } else {
            addToBuySellModal(dataObject);
            setDivVisibility(false);
            setBtnColor(dataObject);
            setLotSizeInput(1);
          }
        }
        if (selectedSide === "calls") {
          const updatedTags = [...buySellTagsCalls];
          updatedTags[selectedRowIndex] = "sell";
          setBuySellTagsCalls(updatedTags);
        } else if (selectedSide === "puts") {
          const updatedTags = [...buySellTagsPuts];
          updatedTags[selectedRowIndex] = "sell";
          setBuySellTagsPuts(updatedTags);
        }
      }
    },
    [
      lotSizeInput,
      optionData,
      uniqueStrikes,
      reducedData,
      lotSizeInput,
      basketData,
      removeFromBasket,
      setSelectedSide,
      uniqueStrikes,
      selectedRowIndex,
      selectedSide,
      optionData,
      uniqueStrikes,
      lotSizeInput,
    ]
  );

  useEffect(() => {
    if (selectedSymbol) {
      const symbolCode = selectedSymbol.code;
      const ltpValue = symbolData[symbolCode]?.ltp || 0;
      setLtp(ltpValue);
    }
  }, [selectedSymbol, symbolData]);

  useLayoutEffect(() => {
    const computeGreeks = () => {
      const spot = parseFloat(symbolData[selectedSymbol?.code]?.ltp);
      const riskFreeRate = 0.1 / 365;

      calls?.forEach((call_option, i) => {
        const strike = parseFloat(call_option?.strike);
        const targetDateTimeString = call_option.expiry_date + " 15:29:59";
        const targetDateTime = new Date(targetDateTimeString);
        const currentDate = new Date();
        let timeToExpiration = Math.max(
          0,
          (targetDateTime - currentDate) / (24 * 60 * 60 * 365 * 1000) ||
            0.00002 / 365
        );

        if (
          strike !== 0 &&
          timeToExpiration > 0 &&
          symbol?.scrip === selectedSymbol?.scrip
        ) {
          const isCallOption = strike > spot;
          const optionType = isCallOption ? "call" : "put";
          const relevantOption = isCallOption ? call_option : puts[i];
          let initialGuessIv;
          if (symbolData[relevantOption?.code] && !relevantOption.iv) {
            const currentStockPrice = parseFloat(
              symbolData[relevantOption?.code]?.ltp
            );

            initialGuessIv = iv.getImpliedVolatility(
              currentStockPrice,
              spot,
              strike,
              timeToExpiration,
              riskFreeRate,
              optionType
            );
          }
          if (initialGuessIv <= 1) {
            call_option.iv = (initialGuessIv * 100).toFixed(2);
            call_option.delta = (
              greeks.getDelta(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "call"
              ) * 100
            ).toFixed(2);
            call_option.theta = (
              greeks.getTheta(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "call"
              ) * 100
            ).toFixed(2);
            call_option.gamma = (
              greeks.getGamma(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "call"
              ) * 100
            ).toFixed(2);
            call_option.vega = (
              greeks.getVega(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "call"
              ) * 100
            ).toFixed(2);

            puts[i].iv = (initialGuessIv * 100).toFixed(2);
            puts[i].delta = (
              greeks.getDelta(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "put"
              ) * 100
            ).toFixed(2);
            puts[i].theta = (
              greeks.getTheta(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "put"
              ) * 100
            ).toFixed(2);
            puts[i].gamma = (
              greeks.getGamma(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "put"
              ) * 100
            ).toFixed(2);
            puts[i].vega = (
              greeks.getVega(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "put"
              ) * 100
            ).toFixed(2);
          }
        }
      });
    };

    computeGreeks();
  }, [symbolData, selectedSymbol, calls, puts]);

  const callsColSpan = showCalls ? 8 : 0;
  const putsColSpan = showPuts ? 7 : 0;

  // Formatting volume
  function formatVolume(volume) {
    if (volume >= 10000000) {
      return `${(volume / 10000000).toFixed(2)}Cr`;
    } else if (volume >= 100000) {
      return `${(volume / 100000).toFixed(2)}L`;
    } else if (volume >= 1000) {
      return `${(volume / 1000).toFixed(2)}K`;
    } else {
      if (isNaN(volume)) {
        return "0";
      }
      return `${volume}`;
    }
  }

  const [buySellTagsCalls, setBuySellTagsCalls] = useState(
    calls ? new Array(calls.length).fill(null) : []
  );

  const [buySellTagsPuts, setBuySellTagsPuts] = useState(
    puts ? new Array(puts.length).fill(null) : []
  );

  const [scrollValue, setScrollValue] = useState(0);

  // Function to handle range input change
  const handleRangeChange = (e) => {
    setScrollValue(e.target.value);
    // Calculate the scroll position based on the range input value
    const scrollPosition =
      (e.target.value / 100) *
      (containerRef.current.scrollWidth - containerRef.current.clientWidth);
    containerRef.current.scrollLeft = scrollPosition;
  };
  // Function to handle table scroll
  const handleScroll = () => {
    // Calculate the range input value based on the scroll position
    const scrollValue =
      (containerRef.current.scrollLeft /
        (containerRef.current.scrollWidth - containerRef.current.clientWidth)) *
      100;
    setScrollValue(scrollValue);
  };

  const callsWidth = showCalls ? "100%" : "50%";
  const putsWidth = showPuts ? "100%" : "50%";
  //console.log("maxPain in table", maxPain);
  return (
    <div className="outer">
      <div
        ref={containerRef}
        className="option-chain-table"
        style={{ fontSize: "14px", overflow: "auto" }}
        onScroll={handleScroll}>
        <div className="calls-puts-header d-flex text-center border-bottom py-1 text-center">
          <div
            className="calls text-center fw-bold"
            style={{ width: callsWidth }}>
            {showCalls && "Calls"}
          </div>
          <div
            className="puts text-center fw-bold"
            style={{ width: putsWidth }}>
            {showPuts && "Puts"}
          </div>
        </div>
        <div className="table-wrapper">
          <table className="table table-responsiveeee oc-page-desktop-table">
            <thead>
              <tr className="text-center sticky-header">
                {showCalls && (
                  <>
                    {/* Call header columns */}
                    {showOIBuilddup && (
                      <th
                        scope="col"
                        className="table-header-columns"
                        style={{ borderRight: "1px solid #DEE2E6" }}>
                        OI Buildup
                      </th>
                    )}
                    {showOIChng && (
                      <th
                        scope="col"
                        className="table-header-columns"
                        style={{ borderRight: "1px solid #DEE2E6" }}>
                        OI Chg %
                      </th>
                    )}
                    {showVega && (
                      <th
                        scope="col"
                        className="table-header-columns"
                        style={{ borderRight: "1px solid #DEE2E6" }}>
                        Vega
                      </th>
                    )}

                    {showGamma && (
                      <th
                        scope="col"
                        className="table-header-columns"
                        style={{ borderRight: "1px solid #DEE2E6" }}>
                        Gamma
                      </th>
                    )}
                    {showTheta && (
                      <th
                        scope="col"
                        className="table-header-columns"
                        style={{ borderRight: "1px solid #DEE2E6" }}>
                        Theta
                      </th>
                    )}
                    {showDelta && (
                      <th
                        scope="col"
                        className="table-header-columns"
                        style={{ borderRight: "1px solid #DEE2E6" }}>
                        Delta
                      </th>
                    )}
                    <th
                      scope="col"
                      className="table-header-columns"
                      style={{ borderRight: "1px solid #DEE2E6" }}>
                      Volume
                    </th>
                    <th
                      scope="col"
                      className="table-header-columns"
                      style={{ borderRight: "1px solid #DEE2E6" }}>
                      OI Chg %
                    </th>
                    <th
                      scope="col"
                      className="table-header-columns"
                      style={{
                        width: "251px",
                        borderRight: "1px solid #DEE2E6",
                        textAlign: "left",
                      }}>
                      OI
                    </th>
                    <th
                      scope="col"
                      className="table-header-columns"
                      style={{ borderRight: "1px solid #DEE2E6" }}>
                      LTP Chg
                    </th>
                    <th
                      scope="col"
                      className="table-header-columns"
                      style={{ borderRight: "1px solid #DEE2E6" }}>
                      LTP
                    </th>
                  </>
                )}
                <th scope="col" className="table-header-columns">
                  Strike
                </th>
                <th
                  scope="col"
                  className="table-header-columns"
                  style={{ borderRight: "1px solid #DEE2E6" }}>
                  IV
                </th>
                {showPuts && (
                  <>
                    {/* Put header columns */}
                    <th
                      scope="col"
                      className="table-header-columns"
                      style={{ borderRight: "1px solid #DEE2E6" }}>
                      LTP
                    </th>
                    <th
                      scope="col"
                      className="table-header-columns"
                      style={{ borderRight: "1px solid #DEE2E6" }}>
                      LTP Chg
                    </th>
                    <th
                      scope="col"
                      className="table-header-columns"
                      style={{
                        width: "251px",
                        borderRight: "1px solid #DEE2E6",
                        textAlign: "right",
                      }}>
                      OI
                    </th>
                    <th
                      scope="col"
                      className="table-header-columns"
                      style={{ borderRight: "1px solid #DEE2E6" }}>
                      OI Chg %
                    </th>
                    <th
                      scope="col"
                      className="table-header-columns"
                      style={{ borderRight: "1px solid #DEE2E6" }}>
                      Volume
                    </th>
                    {showDelta && (
                      <th
                        scope="col"
                        className="table-header-columns"
                        style={{ borderRight: "1px solid #DEE2E6" }}>
                        Delta
                      </th>
                    )}
                    {showTheta && (
                      <th
                        scope="col"
                        className="table-header-columns"
                        style={{ borderRight: "1px solid #DEE2E6" }}>
                        Theta
                      </th>
                    )}

                    {showGamma && (
                      <th
                        scope="col"
                        className="table-header-columns"
                        style={{ borderRight: "1px solid #DEE2E6" }}>
                        Gamma
                      </th>
                    )}
                    {showVega && (
                      <th scope="col" className="table-header-columns">
                        Vega
                      </th>
                    )}
                    {showOIChng && (
                      <th scope="col" className="table-header-columns">
                        OI Chg %
                      </th>
                    )}
                    {showOIBuilddup && (
                      <th scope="col" className="table-header-columns">
                        OI Buildup
                      </th>
                    )}
                  </>
                )}
              </tr>
            </thead>

            <tbody className={theme === "dark" ? "table-dark" : ""}>
              {/* Render Call rows */}
              {calls &&
                puts &&
                calls.map((call, index) => (
                  <tr
                    key={index}
                    className="text-center"
                    onMouseEnter={() => handleRowMouseEnter(index)}
                    onMouseLeave={handleRowMouseLeave}>
                    {showCalls && (
                      <>
                        {showOIBuilddup && (
                          <td
                            style={{
                              backgroundColor: getRowColor(call.strike, true),
                              borderRight: "1px solid #DEE2E6",
                            }}>
                            {/* OI Buildup value */}
                          </td>
                        )}
                        {showOIChng && (
                          <td
                            style={{
                              backgroundColor: getRowColor(call.strike, true),
                              borderRight: "1px solid #DEE2E6",
                            }}>
                            {/* OI Chg value */}
                          </td>
                        )}
                        {showVega && (
                          <td
                            style={{
                              backgroundColor: getRowColor(call.strike, true),
                              borderRight: "1px solid #DEE2E6",
                            }}>
                            {call["vega"]}
                          </td>
                        )}
                        {showGamma && (
                          <td
                            style={{
                              backgroundColor: getRowColor(call.strike, true),
                              borderRight: "1px solid #DEE2E6",
                            }}>
                            {call["gamma"]}
                          </td>
                        )}
                        {showTheta && (
                          <td
                            style={{
                              backgroundColor: getRowColor(call.strike, true),
                              borderRight: "1px solid #DEE2E6",
                            }}>
                            {call["theta"]}
                          </td>
                        )}
                        {showDelta && (
                          <td
                            style={{
                              backgroundColor: getRowColor(call.strike, true),
                              borderRight: "1px solid #DEE2E6",
                            }}>
                            {call["delta"]}
                          </td>
                        )}
                        <td
                          data-side="calls"
                          style={{
                            backgroundColor: getRowColor(call.strike, true),
                            borderRight: "1px solid #DEE2E6",
                          }}
                          // onClick={(event) =>
                          //   handleRowClick(event, index, "calls")
                          // }
                        >
                          {formatVolume(
                            symbolData[call.code]?.volume /
                              symbolData[call.code]?.lot_size
                          )}
                        </td>

                        <td
                          data-side="calls"
                          style={{
                            backgroundColor: getRowColor(call.strike, true),
                            borderRight: "1px solid #DEE2E6",
                            color:
                              symbolData[call.code]?.oi -
                                parseFloat(call?.previous_oi) >=
                              0
                                ? "#5CA81D"
                                : "#DD6565",
                          }}>
                          {formatVolume(
                            call?.previous_oi > 0 ?
                            round(
                              ((symbolData[call.code]?.oi -
                                parseFloat(call?.previous_oi)) /
                                parseFloat(call?.previous_oi)) *
                                100,
                              0
                            ) : 0
                          )}{" "}
                          %
                        </td>
                        <td
                          data-side="calls"
                          style={{
                            backgroundColor: getRowColor(call.strike, true),
                            borderRight: "1px solid #DEE2E6",
                            position: "relative",
                            height: "fixed",
                            textAlign: "left",
                          }}>
                          {(selectedRowIndex !== null &&
                            selectedRowIndex === index &&
                            divVisibility) ||
                          (callsDataCon &&
                            callsDataCon[index] &&
                            (callsDataCon[index].buyBtnState ||
                              callsDataCon[index].sellBtnState)) ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                position: "relative",
                              }}>
                              <div
                                style={{
                                  position: "relative",
                                  zIndex: "2",
                                  paddingLeft: "10px",
                                }}>
                                {formatVolume(
                                  symbolData[call.code]?.oi /
                                    symbolData[call.code]?.lot_size
                                )}
                              </div>
                              <div
                                style={{
                                  zIndex: 2,
                                  position: "absolute",
                                  left: "60%",
                                  transform: "translateX(-50%)",
                                }}
                                onClick={(event) =>
                                  handleRowClick(event, index, "calls")
                                }>
                                <span
                                  className="flex justify-content-center align-items-center gap-1"
                                  style={{ position: "relative", zIndex: "2" }}>
                                  {/* Your buttons */}
                                  <div className="number">
                                    <span className="lots-text">Lots</span>
                                    <input
                                      className="lots-input"
                                      type="number"
                                      value={callsDataCon[index].LotsSize}
                                      onChange={(e) =>
                                        handleLotSizeChange(
                                          e.target.value,
                                          index,
                                          "calls"
                                        )
                                      }
                                    />
                                    <div className="inc-dec-div">
                                      <button
                                        className="plus-modal"
                                        onClick={() =>
                                          incrementValue(index, "calls")
                                        }>
                                        +
                                      </button>
                                      <button
                                        className="minus-modal"
                                        onClick={() =>
                                          decrementValue(index, "calls")
                                        }>
                                        -
                                      </button>
                                    </div>
                                  </div>
                                  <button
                                    // key={index}
                                    className={`buy-btn ${callsDataCon && callsDataCon[index] && callsDataCon[index].buyBtnState ? "active" : ""}`}
                                    onClick={() =>
                                      handleBuyClick(
                                        "calls",
                                        index,
                                        callsDataCon[index].LotsSize
                                      )
                                    }>
                                    B
                                  </button>
                                  <button
                                    //  key={index}
                                    className={`sell-btn ${callsDataCon && callsDataCon[index] && callsDataCon[index].sellBtnState ? "active" : ""}`}
                                    onClick={() =>
                                      handleSellClick(
                                        "calls",
                                        index,
                                        callsDataCon[index].LotsSize
                                      )
                                    }>
                                    S
                                  </button>
                                  <div
                                    type="button"
                                    className="scalp-svg open-buy-sell-modal-btn d-flex align-items-center justify-content-center"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdropBuySell"
                                    onClick={() =>
                                      handleBuySellModalClick(
                                        "calls",
                                        index,
                                        callsDataCon[index].LotsSize
                                      )
                                    }>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="22"
                                      height="22"
                                      viewBox="0 0 22 22"
                                      fill="red">
                                      <g clipPath="url(#clip0_509_803)">
                                        <path
                                          d="M9.60272 7.3488C10.8408 6.29681 13.9006 4.89919 13.9006 4.89919C13.9006 4.89919 14.4877 8.2114 14.3248 9.8279C14.0615 12.4421 10.7132 15.2731 10.7132 15.2731L7.17158 13.4138C7.17158 13.4138 7.60044 9.05009 9.60272 7.3488Z"
                                          fill="url(#paint0_linear_509_803)"
                                        />
                                        <path
                                          d="M7.22105 14.7843C7.63788 14.3656 8.16292 13.9342 8.16292 13.9342L9.71691 14.7501C9.71691 14.7501 9.62195 15.4235 9.55201 16.0081C9.48206 16.5927 7.54791 16.9997 7.54791 16.9997C7.54791 16.9997 6.80421 15.203 7.22105 14.7843Z"
                                          fill="url(#paint1_linear_509_803)"
                                        />
                                        <path
                                          d="M10.7132 15.2731C10.7132 15.2731 14.0615 12.4421 14.3248 9.8279C14.4877 8.2114 13.9006 4.89919 13.9006 4.89919C13.9006 4.89919 10.8408 6.29681 9.60272 7.3488C7.60044 9.05009 7.17158 13.4138 7.17158 13.4138M10.7132 15.2731L7.17158 13.4138M10.7132 15.2731L13.6645 16.8226L13.4579 12.1964M7.17158 13.4138L4.22026 11.8643L8.14552 9.40736M9.6802 7.20124C9.6802 7.20124 10.2557 8.25634 11.7314 9.03106C13.207 9.80578 14.4023 9.68034 14.4023 9.68034M8.16292 13.9342C8.16292 13.9342 7.63788 14.3656 7.22105 14.7843C6.80421 15.203 7.54791 16.9997 7.54791 16.9997C7.54791 16.9997 9.48206 16.5927 9.55201 16.0081C9.62195 15.4235 9.71691 14.7501 9.71691 14.7501L8.16292 13.9342Z"
                                          stroke="#14142B"
                                        />
                                      </g>
                                      <defs>
                                        <linearGradient
                                          id="paint0_linear_509_803"
                                          x1="13.9006"
                                          y1="4.89919"
                                          x2="7.54789"
                                          y2="16.9996"
                                          gradientUnits="userSpaceOnUse">
                                          <stop stopColor="#FF3908" />
                                          <stop
                                            offset="1"
                                            stopColor="#FF7834"
                                          />
                                        </linearGradient>
                                        <linearGradient
                                          id="paint1_linear_509_803"
                                          x1="13.9006"
                                          y1="4.89919"
                                          x2="7.54789"
                                          y2="16.9996"
                                          gradientUnits="userSpaceOnUse">
                                          <stop stopColor="#FF3908" />
                                          <stop
                                            offset="1"
                                            stopColor="#FF7834"
                                          />
                                        </linearGradient>
                                        <clipPath id="clip0_509_803">
                                          <rect
                                            width="16"
                                            height="16"
                                            fill="white"
                                            transform="translate(7.43726) rotate(27.6994)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </div>
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div style={{ position: "relative", zIndex: "2" }}>
                              {formatVolume(
                                symbolData[call.code]?.oi /
                                  symbolData[call.code]?.lot_size
                              )}
                            </div>
                          )}
                          {/* Progress div */}
                          <div
                            style={{
                              position: "absolute",
                              bottom: "50%",
                              right: 0,
                              width: `${
                                (symbolData[call.code]?.oi /
                                  symbolData[call.code]?.lot_size /
                                  maxVolume) *
                                100
                              }%`,
                              height: "4px",
                              backgroundColor: "#f88b9f",
                              zIndex: 0,
                            }}
                          />
                        </td>
                        <td
                          data-side="calls"
                          style={{
                            backgroundColor: getRowColor(call.strike, true),
                            borderRight: "1px solid #DEE2E6",
                          }}>
                          {symbolData[call.code] && (
                            <span>
                              {/* {symbolData[call.code].change} */}
                              <span
                                style={{
                                  color:
                                    symbolData[call.code].change > 0
                                      ? "#5CA81D"
                                      : "#DD6565",
                                }}>
                                ({symbolData[call.code].change_per}%)
                              </span>
                            </span>
                          )}
                        </td>
                        <td
                          data-side="calls"
                          style={{
                            backgroundColor: getRowColor(call.strike, true),
                            borderRight: "1px solid #DEE2E6",
                          }}
                          // onClick={(event) =>
                          //   handleRowClick(event, index, "calls")
                          // }
                        >
                          {symbolData[call.code]?.ltp}
                          {/* {buySellTagsCalls[index] === "buy" && (
                          <span className="tag buy-tag">1 buy</span>
                        )}
                        {buySellTagsCalls[index] === "sell" && (
                          <span className="tag sell-tag">1 sell</span>
                        )} */}
                        </td>
                      </>
                    )}
                    <td
                      className={`strike ${call.strike === maxPain ? "max-pain-row" : ""}`}
                      style={{
                        height: "fixed",
                        width: "fixed",
                        position: "relative",
                      }}>
                      {call.strike}
                      {call.strike === maxPain && (
                        <div className="d-flex align-items-center justify-content-center m-auto max-pain-label">
                          Max Pain
                        </div>
                      )}
                    </td>

                    <td
                      className="strike"
                      style={{
                        height: "fixed",
                        width: "fixed",
                        position: "relative",
                      }}>
                      {call.iv && !isNaN(parseFloat(call.iv))
                        ? parseFloat(call.iv).toFixed(2)
                        : "N/A"}
                    </td>

                    {showPuts && (
                      <>
                        <td
                          data-side="puts"
                          style={{
                            backgroundColor: getPutRowColor(puts[index].strike),
                            borderRight: "1px solid #DEE2E6",
                          }}>
                          {symbolData[puts[index].code]?.ltp}
                        </td>

                        <td
                          data-side="puts"
                          onClick={(event) =>
                            handleRowClick(event, index, "puts")
                          }
                          style={{
                            backgroundColor: getPutRowColor(puts[index].strike),
                            position: "relative",
                            borderRight: "1px solid #DEE2E6",
                          }}>
                          {symbolData[puts[index].code] && (
                            <span>
                              {/* {symbolData[puts[index].code].change} */}
                              <span
                                style={{
                                  color:
                                    symbolData[puts[index].code].change_per > 0
                                      ? "#5CA81D"
                                      : "#DD6565",
                                }}>
                                ({symbolData[puts[index].code].change_per}% )
                              </span>
                            </span>
                          )}
                        </td>
                        <td
                          data-side="puts"
                          style={{
                            backgroundColor: getPutRowColor(puts[index].strike),
                            borderRight: "1px solid #DEE2E6",
                            position: "relative",
                            height: "fixed",
                            textAlign: "right",
                          }}>
                          {(selectedRowIndex !== null &&
                            selectedRowIndex === index &&
                            divVisibility) ||
                          (putsDataCon &&
                            putsDataCon[index] &&
                            (putsDataCon[index].buyBtnState ||
                              putsDataCon[index].sellBtnState)) ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                alignItems: "center",
                                position: "relative",
                              }}>
                              <div
                                style={{
                                  position: "relative",
                                  zIndex: "2",
                                  paddingRight: "10px",
                                }}>
                                {formatVolume(
                                  symbolData[puts[index].code]?.oi /
                                    symbolData[puts[index].code]?.lot_size
                                )}
                              </div>
                              <div
                                style={{
                                  position: "absolute",
                                  left: "37%", // Position the buttons at 50% from the left
                                  transform: "translateX(-50%)", // Center the buttons horizontally
                                  zIndex: "2",
                                }}>
                                <span className="flex gap-2">
                                  <div
                                    type="button"
                                    className="open-buy-sell-modal-btn scalp-svg"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdropBuySell"
                                    onClick={() =>
                                      handleBuySellModalClick(
                                        "puts",
                                        index,
                                        putsDataCon[index].LotsSize
                                      )
                                    }>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="22"
                                      height="22"
                                      viewBox="0 0 22 22"
                                      fill="red">
                                      <g clipPath="url(#clip0_509_803)">
                                        <path
                                          d="M9.60272 7.3488C10.8408 6.29681 13.9006 4.89919 13.9006 4.89919C13.9006 4.89919 14.4877 8.2114 14.3248 9.8279C14.0615 12.4421 10.7132 15.2731 10.7132 15.2731L7.17158 13.4138C7.17158 13.4138 7.60044 9.05009 9.60272 7.3488Z"
                                          fill="url(#paint0_linear_509_803)"
                                        />
                                        <path
                                          d="M7.22105 14.7843C7.63788 14.3656 8.16292 13.9342 8.16292 13.9342L9.71691 14.7501C9.71691 14.7501 9.62195 15.4235 9.55201 16.0081C9.48206 16.5927 7.54791 16.9997 7.54791 16.9997C7.54791 16.9997 6.80421 15.203 7.22105 14.7843Z"
                                          fill="url(#paint1_linear_509_803)"
                                        />
                                        <path
                                          d="M10.7132 15.2731C10.7132 15.2731 14.0615 12.4421 14.3248 9.8279C14.4877 8.2114 13.9006 4.89919 13.9006 4.89919C13.9006 4.89919 10.8408 6.29681 9.60272 7.3488C7.60044 9.05009 7.17158 13.4138 7.17158 13.4138M10.7132 15.2731L7.17158 13.4138M10.7132 15.2731L13.6645 16.8226L13.4579 12.1964M7.17158 13.4138L4.22026 11.8643L8.14552 9.40736M9.6802 7.20124C9.6802 7.20124 10.2557 8.25634 11.7314 9.03106C13.207 9.80578 14.4023 9.68034 14.4023 9.68034M8.16292 13.9342C8.16292 13.9342 7.63788 14.3656 7.22105 14.7843C6.80421 15.203 7.54791 16.9997 7.54791 16.9997C7.54791 16.9997 9.48206 16.5927 9.55201 16.0081C9.62195 15.4235 9.71691 14.7501 9.71691 14.7501L8.16292 13.9342Z"
                                          stroke="#14142B"
                                        />
                                      </g>
                                      <defs>
                                        <linearGradient
                                          id="paint0_linear_509_803"
                                          x1="13.9006"
                                          y1="4.89919"
                                          x2="7.54789"
                                          y2="16.9996"
                                          gradientUnits="userSpaceOnUse">
                                          <stop stopColor="#FF3908" />
                                          <stop
                                            offset="1"
                                            stopColor="#FF7834"
                                          />
                                        </linearGradient>
                                        <linearGradient
                                          id="paint1_linear_509_803"
                                          x1="13.9006"
                                          y1="4.89919"
                                          x2="7.54789"
                                          y2="16.9996"
                                          gradientUnits="userSpaceOnUse">
                                          <stop stopColor="#FF3908" />
                                          <stop
                                            offset="1"
                                            stopColor="#FF7834"
                                          />
                                        </linearGradient>
                                        <clipPath id="clip0_509_803">
                                          <rect
                                            width="16"
                                            height="16"
                                            fill="white"
                                            transform="translate(7.43726) rotate(27.6994)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </div>
                                  <button
                                    // key={index}
                                    // className={`buy-btn  ${putsDataCon && putsDataCon[index].buyBtnState ? 'active' : ''}`}
                                    className={`buy-btn ${putsDataCon && putsDataCon[index] && putsDataCon[index].buyBtnState ? "active" : ""}`}
                                    onClick={() =>
                                      handleBuyClick(
                                        "puts",
                                        index,
                                        putsDataCon[index].LotsSize
                                      )
                                    }>
                                    B
                                  </button>
                                  <button
                                    // key={index}
                                    className={`sell-btn ${putsDataCon && putsDataCon[index] && putsDataCon[index].sellBtnState ? "active" : ""}`}
                                    onClick={() =>
                                      handleSellClick(
                                        "puts",
                                        index,
                                        putsDataCon[index].LotsSize
                                      )
                                    }>
                                    S
                                  </button>
                                  <div className="number">
                                    <span className="lots-text">Lots</span>
                                    <input
                                      className="lots-input"
                                      type="number"
                                      value={putsDataCon[index].LotsSize}
                                      onChange={(e) =>
                                        handleLotSizeChange(
                                          e.target.value,
                                          index,
                                          "puts"
                                        )
                                      }
                                    />
                                    <div className="inc-dec-div">
                                      <button
                                        className="plus-modal"
                                        onClick={() =>
                                          incrementValue(index, "puts")
                                        }>
                                        +
                                      </button>
                                      <button
                                        className="minus-modal"
                                        onClick={() =>
                                          decrementValue(index, "puts")
                                        }>
                                        -
                                      </button>
                                    </div>
                                  </div>
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div style={{ position: "relative", zIndex: "2" }}>
                              {formatVolume(
                                symbolData[puts[index].code]?.oi /
                                  symbolData[puts[index].code]?.lot_size
                              )}
                            </div>
                          )}

                          {/* Progress bar */}
                          <div
                            style={{
                              position: "absolute",
                              bottom: "50%",
                              left: 0,
                              width: `${Math.min((symbolData[puts[index].code]?.oi / symbolData[puts[index].code]?.lot_size / maxPutVolume) * 100, 100)}%`,
                              height: "5px", // Adjust height as needed
                              backgroundColor: "#19C37D",
                              zIndex: 0,
                            }}
                          />
                        </td>
                        <td
                          data-side="puts"
                          // onClick={(event) =>
                          //   handleRowClick(event, index, "puts")
                          // }
                          style={{
                            backgroundColor: getPutRowColor(puts[index].strike),
                            borderRight: "1px solid #DEE2E6",
                            color:
                              symbolData[puts[index].code]?.oi -
                                parseFloat(puts[index]?.previous_oi) >=
                              0
                                ? "#5CA81D"
                                : "#DD6565",
                          }}>
                          {formatVolume(
                            puts[index]?.previous_oi > 0 ?
                            round(
                              ((symbolData[puts[index].code]?.oi -
                                parseFloat(puts[index]?.previous_oi)) /
                                parseFloat(puts[index]?.previous_oi)) *
                                100,
                              0
                            ) : 0
                          )}{" "}
                          %
                        </td>

                        <td
                          data-side="puts"
                          // onClick={(event) =>
                          //   handleRowClick(event, index, "puts")
                          // }
                          style={{
                            backgroundColor: getPutRowColor(puts[index].strike),
                            borderRight: "1px solid #DEE2E6",
                          }}>
                          {formatVolume(
                            symbolData[puts[index].code]?.volume /
                              symbolData[puts[index].code]?.lot_size
                          )}
                        </td>

                        {showDelta && (
                          <td
                            style={{
                              backgroundColor: getPutRowColor(
                                puts[index].strike
                              ),
                              borderRight: "1px solid #DEE2E6",
                            }}>
                            {puts[index]["delta"]}
                          </td>
                        )}
                        {showTheta && (
                          <td
                            style={{
                              backgroundColor: getPutRowColor(
                                puts[index].strike
                              ),
                              borderRight: "1px solid #DEE2E6",
                            }}>
                            {puts[index]["theta"]}
                          </td>
                        )}
                        {showGamma && (
                          <td
                            style={{
                              backgroundColor: getPutRowColor(
                                puts[index].strike
                              ),
                              borderRight: "1px solid #DEE2E6",
                            }}>
                            {puts[index]["gamma"]}
                          </td>
                        )}
                        {showVega && (
                          <td
                            style={{
                              backgroundColor: getPutRowColor(
                                puts[index].strike
                              ),
                            }}>
                            {puts[index]["vega"]}
                          </td>
                        )}
                        {showOIChng && (
                          <td
                            style={{
                              backgroundColor: getPutRowColor(
                                puts[index].strike
                              ),
                              borderRight: "1px solid #DEE2E6",
                            }}>
                            {/* OI Chg value */}
                          </td>
                        )}
                        {showOIBuilddup && (
                          <td
                            style={{
                              backgroundColor: getPutRowColor(
                                puts[index].strike
                              ),
                              borderRight: "1px solid #DEE2E6",
                            }}>
                            {/* OI Buildup value */}
                          </td>
                        )}
                      </>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OptionChainTable;
