import React from "react";
import { useTheme } from "../../context/ThemeContext";

const MarketMoodChartMobile = ({ value }) => {
  const { theme } = useTheme();
  const minValue = 0;
  const maxValue = 100;
  const minAngle = -180;
  const maxAngle = 0;

  const valueToAngle = (val) => {
    return (
      ((val - minValue) * (maxAngle - minAngle)) / (maxValue - minValue) +
      minAngle
    );
  };

  if (value) {
    const angle = valueToAngle(value);
    // Calculate the position for the value label based on the angle
    const radius = 200; // Adjusted for mobile design
    const cx = 250.5; // Center X based on your SVG's viewBox
    const cy = 245.5; // Center Y based on your SVG's viewBox

    const angleInRadians = (angle * Math.PI) / 180;
    const labelX = cx + (radius + 30) * Math.cos(angleInRadians);
    const labelY = cy + (radius + 30) * Math.sin(angleInRadians);

    const rectWidth = 90;
    const rectHeight = 35.617;
    const rectX = labelX - rectWidth / 2;
    const rectY = labelY - rectHeight / 2;

    return (
      <div style={{ width: "100%", margin: "0 auto", position: "relative" }}>
        {theme === "light" ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="166"
            viewBox="0 0 541 257"
            fill="none">
            <path
              d="M494.205 254.125C494.205 138.628 393.998 44.9993 270.386 44.9993C146.774 44.9993 46.5662 138.628 46.5662 254.125"
              stroke="url(#paint0_linear_2905_99)"
              strokeWidth="40"
              strokeDasharray="3.5 6"
            />
            <path
              d="M8.56592 256C8.56592 117.929 125.867 6.00024 270.566 6.00024"
              stroke="url(#paint1_linear_2905_99)"
              strokeMiterlimit="16"
            />
            <path
              d="M532.566 256C532.566 117.929 415.265 6 270.566 6"
              stroke="url(#paint2_linear_2905_99)"
              strokeMiterlimit="16"
            />
            <rect
              x="39.7065"
              y="104.782"
              width="21.4551"
              height="100.49"
              transform="rotate(21.9745 39.7065 104.782)"
              fill="white"
            />
            <path
              d="M22.7774 194.183L20.8541 200.972L9.63467 198.154L11.5579 191.365L12.9545 191.716L11.2624 197.688L10.8535 196.844L14.9657 197.877L16.3133 193.12L17.6633 193.459L16.3158 198.216L20.5056 199.268L19.6732 199.801L21.3652 193.828L22.7774 194.183Z"
              fill="black"
            />
            <path
              d="M23.2239 190.574L22.8364 192.258L19.6561 188.818L15.1737 190.679L15.5649 188.979L18.8631 187.628L16.444 185.159L16.828 183.491L20.0903 186.931L24.4835 185.101L24.0923 186.801L20.8483 188.131L23.2239 190.574Z"
              fill="black"
            />
            <path
              d="M17.1057 182.914L18.2802 178.491L19.5105 178.781L18.3361 183.204L17.1057 182.914ZM25.5018 181.769L25.1157 183.222L15.1171 180.869L15.5031 179.415L25.5018 181.769Z"
              fill="black"
            />
            <path
              d="M21.2871 172.662L22.617 173.015L22.4241 173.659C22.219 174.344 22.2577 174.939 22.5404 175.444C22.8158 175.936 23.3195 176.28 24.0514 176.474L28.0564 177.538L27.6248 178.979L20.0786 176.974L20.4827 175.625L22.0349 175.905L21.9935 176.043C21.5293 175.81 21.2007 175.458 21.0078 174.987C20.8046 174.514 20.7902 173.986 20.9647 173.404C21.0045 173.271 21.0516 173.151 21.1058 173.044C21.1529 172.924 21.2133 172.797 21.2871 172.662Z"
              fill="black"
            />
            <path
              d="M30.15 170.871C29.9074 171.589 29.5287 172.176 29.0138 172.634C28.4887 173.089 27.882 173.385 27.1937 173.524C26.4952 173.659 25.7577 173.61 24.9812 173.378C24.1945 173.142 23.5561 172.783 23.0661 172.302C22.5794 171.811 22.2581 171.247 22.102 170.611C21.9494 169.964 21.9943 169.282 22.2369 168.564C22.4761 167.857 22.8361 167.292 23.3169 166.868C23.8011 166.435 24.3669 166.159 25.0142 166.042C25.665 165.914 26.3634 165.963 27.1092 166.186L27.6456 166.347L25.5134 172.702L24.5581 172.383L26.2136 167.487C25.5904 167.3 25.0265 167.337 24.5221 167.597C24.0176 167.858 23.6595 168.301 23.4476 168.928C23.287 169.402 23.2559 169.844 23.3543 170.252C23.4459 170.647 23.6653 170.996 24.0124 171.301C24.3528 171.592 24.804 171.822 25.3659 171.99C26.265 172.26 27.0281 172.266 27.6552 172.009C28.272 171.749 28.7086 171.24 28.9649 170.482C29.1528 169.927 29.1942 169.438 29.089 169.017C28.9838 168.596 28.7372 168.261 28.3491 168.011L28.8001 166.677C29.5355 167.098 30.0221 167.672 30.2598 168.4C30.4975 169.128 30.4609 169.952 30.15 170.871Z"
              fill="black"
            />
            <path
              d="M33.4255 164.849L32.8821 166.251L25.4833 163.707L25.9689 162.454L27.4058 162.728L27.2154 162.865C26.8596 162.574 26.6227 162.182 26.5045 161.691C26.3801 161.186 26.4296 160.645 26.6532 160.068C26.9037 159.422 27.2884 158.945 27.8071 158.639C28.3259 158.333 28.9029 158.216 29.538 158.288L29.4397 158.541C28.8932 158.241 28.5302 157.806 28.3506 157.237C28.171 156.668 28.2083 156.055 28.4627 155.399C28.7865 154.563 29.288 153.992 29.9672 153.684C30.6465 153.377 31.4148 153.371 32.2722 153.665L37.114 155.33L36.5821 156.703L32.0883 155.158C31.5033 154.956 30.9966 154.951 30.5683 155.142C30.1337 155.32 29.8201 155.657 29.6274 156.154C29.4925 156.502 29.4537 156.838 29.5111 157.162C29.5621 157.473 29.7051 157.753 29.9398 158.003C30.1745 158.253 30.4885 158.445 30.8819 158.581L35.2698 160.089L34.7321 161.477L30.2232 159.926C29.6382 159.725 29.1385 159.717 28.7241 159.901C28.2996 160.082 27.991 160.421 27.7983 160.919C27.6634 161.267 27.6246 161.603 27.682 161.927C27.7331 162.237 27.876 162.518 28.1107 162.767C28.3353 163.014 28.6443 163.205 29.0377 163.34L33.4255 164.849Z"
              fill="black"
            />
            <path
              d="M39.7626 150.808C39.4586 151.502 39.0297 152.055 38.4761 152.469C37.9125 152.878 37.281 153.124 36.5816 153.207C35.8723 153.285 35.1399 153.178 34.3844 152.884C33.619 152.586 33.0122 152.178 32.5641 151.661C32.1203 151.134 31.8479 150.547 31.7468 149.902C31.6501 149.247 31.7537 148.572 32.0578 147.879C32.3576 147.195 32.7658 146.662 33.2824 146.28C33.8033 145.888 34.3921 145.659 35.0488 145.595C35.7098 145.52 36.4032 145.624 37.1288 145.907L37.6507 146.11L34.974 152.255L34.0474 151.86L36.1224 147.127C35.516 146.891 34.9497 146.883 34.4234 147.101C33.8972 147.319 33.5013 147.731 33.2357 148.337C33.0345 148.796 32.9654 149.232 33.0285 149.646C33.086 150.046 33.275 150.411 33.5956 150.741C33.9105 151.058 34.3413 151.323 34.888 151.536C35.7628 151.876 36.5244 151.944 37.1729 151.739C37.8114 151.53 38.2912 151.059 38.6124 150.326C38.848 149.789 38.9313 149.307 38.8625 148.88C38.7937 148.453 38.5763 148.099 38.2102 147.82L38.7755 146.53C39.4737 147.008 39.9103 147.618 40.0851 148.36C40.2598 149.103 40.1524 149.919 39.7626 150.808Z"
              fill="black"
            />
            <path
              d="M33.7639 136.166L44.2694 141.009L43.5468 142.4L33.0414 137.557L33.7639 136.166ZM37.5637 131.969L34.4007 138.061L33.093 137.458L36.256 131.366L37.5637 131.969ZM41.9024 134.92L39.1229 140.273L37.8297 139.677L40.6092 134.323L41.9024 134.92Z"
              fill="black"
            />
            <path
              d="M49.2043 132.22C48.8301 132.878 48.3457 133.386 47.7511 133.742C47.147 134.094 46.4918 134.276 45.7857 134.29C45.07 134.298 44.3503 134.12 43.6265 133.755C42.8933 133.385 42.3296 132.921 41.9353 132.364C41.5464 131.798 41.3345 131.19 41.2995 130.54C41.2699 129.881 41.4421 129.222 41.8163 128.563C42.1853 127.914 42.6469 127.425 43.2014 127.096C43.7611 126.758 44.3719 126.589 45.0337 126.589C45.7008 126.58 46.3819 126.75 47.077 127.101L47.577 127.353L44.2789 133.189L43.3948 132.707L45.9483 128.214C45.3674 127.921 44.8033 127.857 44.2559 128.022C43.7086 128.188 43.2715 128.558 42.9448 129.133C42.6971 129.569 42.5836 129.995 42.6044 130.411C42.6209 130.813 42.7722 131.194 43.0583 131.553C43.3401 131.898 43.7428 132.203 44.2666 132.467C45.1046 132.89 45.8576 133.031 46.5255 132.891C47.1838 132.746 47.7107 132.325 48.1059 131.63C48.3958 131.12 48.5282 130.65 48.5032 130.219C48.4781 129.789 48.2972 129.417 47.9605 129.104L48.6562 127.88C49.3041 128.422 49.6773 129.07 49.7759 129.823C49.8744 130.577 49.6839 131.376 49.2043 132.22Z"
              fill="black"
            />
            <path
              d="M52.6037 125.824C52.1657 126.532 51.6287 126.984 50.9927 127.181C50.3624 127.37 49.7389 127.294 49.122 126.955C48.4958 126.61 48.1306 126.118 48.0264 125.477C47.9128 124.831 48.0648 124.1 48.4824 123.284L49.6115 121.096L49.4153 120.988C49.0321 120.778 48.6862 120.679 48.3776 120.691C48.0597 120.699 47.7811 120.795 47.5416 120.979C47.2927 121.158 47.0756 121.398 46.8903 121.697C46.5589 122.232 46.4174 122.713 46.4657 123.141C46.5103 123.555 46.7429 123.877 47.1634 124.108L46.4726 125.224C45.996 124.962 45.6531 124.615 45.444 124.184C45.2348 123.753 45.1641 123.27 45.2318 122.736C45.3052 122.193 45.5187 121.635 45.8726 121.064C46.2152 120.511 46.6157 120.087 47.074 119.792C47.5286 119.483 48.0318 119.328 48.5834 119.327C49.1313 119.313 49.7137 119.475 50.3305 119.814L54.6344 122.182L53.9604 123.27L52.7686 122.797C53.0545 123.185 53.1822 123.656 53.1519 124.211C53.1178 124.752 52.9351 125.289 52.6037 125.824ZM51.8078 124.894C52.1841 124.287 52.2921 123.708 52.1317 123.157C51.9619 122.602 51.5546 122.147 50.9097 121.792L50.5452 121.592L49.6323 123.369C49.3402 123.962 49.2229 124.445 49.2806 124.817C49.344 125.18 49.5438 125.454 49.8803 125.639C50.2448 125.84 50.5935 125.874 50.9265 125.741C51.2557 125.593 51.5495 125.311 51.8078 124.894Z"
              fill="black"
            />
            <path
              d="M50.8559 112.738L52.0394 113.44L51.6663 113.999C51.2694 114.593 51.1344 115.17 51.2612 115.728C51.3848 116.272 51.7722 116.737 52.4236 117.123L55.9879 119.237L55.1528 120.488L48.437 116.505L49.2189 115.334L50.6386 116.027L50.5587 116.147C50.1775 115.797 49.9619 115.372 49.9117 114.872C49.8524 114.366 49.9915 113.86 50.3291 113.355C50.4061 113.239 50.4864 113.138 50.5699 113.052C50.6501 112.951 50.7455 112.846 50.8559 112.738Z"
              fill="black"
            />
            <rect
              x="177.489"
              y="14.999"
              width="13.8317"
              height="39.1371"
              transform="rotate(69.0201 177.489 14.999)"
              fill="white"
            />
            <path
              d="M146.944 27.6855L151.857 38.158L150.438 38.824L145.524 28.3515L146.944 27.6855ZM152.451 26.692L146.237 29.6076L145.625 28.3039L151.839 25.3883L152.451 26.692ZM153.884 31.781L148.424 34.3432L147.819 33.0541L153.28 30.4919L153.884 31.781Z"
              fill="black"
            />
            <path
              d="M160.271 34.173C159.574 34.4694 158.889 34.5752 158.215 34.4907C157.537 34.3963 156.928 34.134 156.385 33.7039C155.839 33.264 155.407 32.671 155.09 31.9249C154.769 31.1691 154.634 30.4439 154.687 29.7493C154.749 29.0506 154.971 28.4287 155.354 27.8835C155.746 27.3342 156.291 26.9114 156.988 26.6151C157.675 26.3229 158.331 26.2176 158.957 26.2992C159.593 26.3767 160.161 26.622 160.661 27.0352C161.171 27.4443 161.578 28.0071 161.883 28.7237L162.102 29.239L155.939 31.8769L155.574 30.9368L160.33 28.9146C160.075 28.3158 159.689 27.912 159.172 27.7033C158.654 27.4945 158.091 27.5195 157.482 27.7783C157.021 27.9744 156.667 28.2466 156.421 28.5946C156.181 28.9287 156.055 29.3296 156.046 29.7973C156.042 30.2511 156.154 30.748 156.384 31.2879C156.751 32.1517 157.231 32.7357 157.824 33.0399C158.413 33.3343 159.076 33.325 159.812 33.012C160.352 32.7824 160.748 32.4866 160.999 32.1246C161.251 31.7626 161.348 31.3504 161.291 30.888L162.586 30.3371C162.735 31.1779 162.61 31.9324 162.21 32.6008C161.81 33.2691 161.164 33.7932 160.271 34.173Z"
              fill="black"
            />
            <path
              d="M167.436 31.1414C166.659 31.4387 165.968 31.4688 165.364 31.2318C164.769 30.9911 164.346 30.5419 164.094 29.8844C163.839 29.2169 163.874 28.5924 164.2 28.011C164.522 27.4196 165.098 26.9251 165.928 26.5276L168.161 25.4679L168.081 25.2587C167.925 24.8503 167.726 24.5551 167.485 24.3731C167.24 24.1812 166.969 24.085 166.672 24.0845C166.371 24.074 166.057 24.1316 165.728 24.2574C165.14 24.4822 164.729 24.7821 164.496 25.1569C164.268 25.518 164.241 25.9227 164.412 26.371L163.187 26.8397C162.992 26.3316 162.952 25.8388 163.066 25.3611C163.181 24.8834 163.432 24.4504 163.82 24.0621C164.218 23.6699 164.731 23.3538 165.359 23.1137C165.966 22.8812 166.536 22.7888 167.069 22.8365C167.607 22.8704 168.084 23.0589 168.501 23.402C168.924 23.7313 169.261 24.2247 169.513 24.8822L171.268 29.47L170.072 29.9273L169.471 28.8039C169.442 29.2948 169.241 29.7543 168.868 30.1825C168.501 30.5969 168.024 30.9165 167.436 31.1414ZM167.422 29.8961C168.09 29.6408 168.537 29.2414 168.763 28.6981C168.985 28.1448 168.965 27.5245 168.702 26.8371L168.553 26.4485L166.742 27.3125C166.147 27.6086 165.754 27.9246 165.563 28.2604C165.382 28.5925 165.36 28.9378 165.497 29.2965C165.646 29.685 165.888 29.9294 166.223 30.0295C166.564 30.1159 166.964 30.0714 167.422 29.8961Z"
              fill="black"
            />
            <path
              d="M175.497 19.348L175.945 20.6489L175.31 20.8679C174.634 21.1009 174.168 21.4818 173.91 22.0107C173.659 22.526 173.657 23.1417 173.904 23.8576L175.255 27.7753L173.833 28.2655L171.288 20.884L172.619 20.425L173.251 21.8657L173.115 21.9126C173.049 21.3938 173.155 20.9171 173.434 20.4824C173.709 20.0377 174.134 19.7162 174.709 19.5181C174.84 19.4729 174.965 19.4412 175.083 19.4231C175.207 19.3915 175.345 19.3665 175.497 19.348Z"
              fill="black"
            />
            <rect
              x="405.969"
              y="31.7533"
              width="16.7639"
              height="51.0649"
              transform="rotate(114.564 405.969 31.7533)"
              fill="white"
            />
            <path
              d="M365.016 16.4889C364.237 16.1988 363.5 16.1296 362.806 16.2812C362.112 16.4328 361.496 16.7727 360.958 17.3009C360.42 17.8291 359.992 18.5231 359.672 19.3829C359.348 20.2526 359.226 21.0551 359.305 21.7902C359.395 22.5291 359.663 23.1694 360.11 23.7114C360.561 24.2433 361.156 24.6469 361.896 24.9221C362.366 25.0969 362.823 25.1988 363.267 25.2276C363.712 25.2564 364.131 25.2016 364.523 25.0633C364.92 24.9149 365.278 24.6724 365.596 24.3358C365.925 24.0029 366.19 23.5665 366.391 23.0266L366.77 22.0069L367.198 22.9684L362.879 21.3615L363.364 20.0569L368.373 21.9204L366.147 27.9037L364.917 27.4462L365.515 25.3809L365.621 25.6934C365.327 25.9938 364.951 26.2239 364.493 26.3836C364.035 26.5433 363.528 26.622 362.971 26.6197C362.418 26.6074 361.847 26.4915 361.257 26.2721C360.237 25.8927 359.423 25.328 358.814 24.578C358.209 23.818 357.841 22.9413 357.71 21.9479C357.579 20.9545 357.722 19.8979 358.138 18.7782C358.547 17.6785 359.13 16.7857 359.886 16.0996C360.652 15.4172 361.524 14.9848 362.503 14.8025C363.481 14.6202 364.5 14.7262 365.56 15.1205C366.349 15.4143 367.015 15.8271 367.557 16.3588C368.103 16.8805 368.489 17.4794 368.715 18.1554C368.956 18.8252 369 19.5305 368.85 20.2712L367.29 19.691C367.347 18.9268 367.159 18.2706 366.725 17.7224C366.306 17.1679 365.736 16.7568 365.016 16.4889Z"
              fill="black"
            />
            <path
              d="M376.105 22.6354L375.579 23.9065L374.958 23.6493C374.298 23.3757 373.696 23.3514 373.152 23.5765C372.623 23.7958 372.213 24.2552 371.923 24.9548L370.336 28.7832L368.947 28.2074L371.936 20.9942L373.237 21.5332L372.755 23.0308L372.622 22.9756C372.916 22.5435 373.312 22.2572 373.809 22.1167C374.31 21.9664 374.841 22.0077 375.403 22.2404C375.531 22.2935 375.645 22.3524 375.746 22.417C375.86 22.4758 375.98 22.5486 376.105 22.6354Z"
              fill="black"
            />
            <path
              d="M376.167 32.2993C375.479 31.9839 374.939 31.5489 374.548 30.9942C374.161 30.4299 373.944 29.8025 373.896 29.112C373.853 28.4118 374 27.6932 374.338 26.9562C374.68 26.2095 375.125 25.6215 375.674 25.1923C376.232 24.7675 376.842 24.5129 377.502 24.4283C378.172 24.3481 378.852 24.4657 379.54 24.7811C380.219 25.092 380.739 25.5062 381.101 26.0237C381.472 26.5456 381.673 27.1306 381.705 27.7787C381.746 28.4312 381.605 29.1114 381.28 29.8193L381.047 30.3285L374.945 27.5512L375.394 26.6481L380.093 28.8002C380.364 28.2087 380.402 27.6513 380.208 27.1281C380.013 26.605 379.615 26.2057 379.014 25.9303C378.558 25.7215 378.119 25.6434 377.696 25.6961C377.287 25.7434 376.906 25.9211 376.554 26.229C376.216 26.5317 375.925 26.9498 375.681 27.4832C375.29 28.3366 375.182 29.0848 375.356 29.728C375.535 30.3615 375.989 30.8448 376.716 31.178C377.249 31.4223 377.735 31.5155 378.172 31.4576C378.609 31.3997 378.979 31.1938 379.282 30.8398L380.562 31.4261C380.041 32.1026 379.399 32.5183 378.636 32.6732C377.873 32.8281 377.05 32.7035 376.167 32.2993Z"
              fill="black"
            />
            <path
              d="M383.968 36.2721C383.29 35.934 382.765 35.4812 382.393 34.9139C382.025 34.3369 381.829 33.7027 381.805 33.011C381.785 32.3098 381.956 31.5965 382.318 30.8711C382.685 30.1362 383.149 29.5634 383.712 29.1527C384.284 28.7468 384.902 28.5125 385.565 28.45C386.237 28.3922 386.912 28.5324 387.59 28.8706C388.258 29.204 388.764 29.6353 389.108 30.1645C389.461 30.6985 389.643 31.2899 389.653 31.9386C389.673 32.5921 389.509 33.2673 389.161 33.964L388.911 34.4651L382.905 31.486L383.384 30.5984L388.008 32.9059C388.299 32.3237 388.356 31.7679 388.179 31.2386C388.002 30.7092 387.617 30.2969 387.026 30.0016C386.577 29.7777 386.141 29.6851 385.716 29.7236C385.306 29.7573 384.92 29.9221 384.557 30.2182C384.209 30.5095 383.904 30.9176 383.642 31.4425C383.223 32.2824 383.09 33.0267 383.243 33.6753C383.401 34.3144 383.838 34.8125 384.554 35.1697C385.079 35.4317 385.561 35.541 386 35.4978C386.438 35.4545 386.815 35.261 387.13 34.9173L388.39 35.546C387.846 36.2047 387.191 36.5987 386.423 36.7282C385.655 36.8576 384.837 36.7056 383.968 36.2721Z"
              fill="black"
            />
            <path
              d="M391.008 40.2508C390.331 39.9126 389.825 39.4694 389.49 38.9211C389.161 38.3632 389.001 37.7408 389.01 37.0539C389.028 36.3717 389.214 35.6775 389.566 34.9712C389.923 34.2554 390.374 33.6874 390.917 33.2672C391.465 32.8374 392.069 32.5841 392.727 32.5073C393.385 32.4304 394.058 32.5635 394.745 32.9064C395.289 33.1779 395.714 33.5329 396.019 33.9715C396.339 34.4054 396.509 34.9014 396.529 35.4595L398.858 30.7923L400.189 31.4567L394.931 41.9936L393.728 41.3935L394.25 40.0622C393.787 40.4156 393.27 40.6044 392.697 40.6287C392.135 40.6578 391.572 40.5318 391.008 40.2508ZM391.888 39.1696C392.346 39.3982 392.79 39.4885 393.219 39.4405C393.658 39.3972 394.061 39.2347 394.428 38.953C394.795 38.6712 395.1 38.287 395.343 37.8002C395.586 37.3135 395.707 36.8435 395.707 36.3903C395.711 35.9276 395.599 35.5079 395.369 35.1312C395.15 34.7593 394.811 34.4591 394.352 34.2304C393.894 34.0018 393.448 33.9163 393.014 33.9739C392.585 34.022 392.191 34.1892 391.834 34.4757C391.481 34.7527 391.186 35.1298 390.948 35.607C390.705 36.0937 390.576 36.5661 390.562 37.0241C390.548 37.482 390.651 37.8969 390.871 38.2689C391.091 38.6408 391.43 38.941 391.888 39.1696Z"
              fill="black"
            />
            <rect
              x="537.302"
              y="209.572"
              width="23.6969"
              height="107.585"
              transform="rotate(164.403 537.302 209.572)"
              fill="white"
            />
            <path
              d="M521.752 173.595C521.468 172.813 521.044 172.205 520.478 171.772C519.913 171.339 519.254 171.087 518.502 171.015C517.749 170.943 516.941 171.06 516.077 171.368C515.203 171.679 514.51 172.1 514 172.633C513.493 173.175 513.177 173.791 513.053 174.481C512.939 175.167 513.016 175.881 513.285 176.623C513.456 177.094 513.674 177.509 513.94 177.867C514.206 178.225 514.52 178.51 514.88 178.722C515.25 178.93 515.667 179.047 516.131 179.075C516.599 179.112 517.104 179.035 517.646 178.842L518.671 178.477L518.214 179.421L516.643 175.089L517.955 174.622L519.776 179.646L513.761 181.786L513.314 180.552L515.28 179.683L515.109 179.965C514.689 179.933 514.27 179.793 513.851 179.546C513.433 179.299 513.044 178.962 512.685 178.535C512.336 178.104 512.054 177.593 511.839 177.001C511.469 175.978 511.373 174.994 511.551 174.047C511.74 173.097 512.171 172.253 512.846 171.515C513.52 170.777 514.42 170.208 515.545 169.808C516.651 169.414 517.711 169.287 518.725 169.424C519.743 169.571 520.638 169.96 521.412 170.591C522.185 171.222 522.764 172.069 523.15 173.132C523.437 173.924 523.553 174.698 523.498 175.453C523.453 176.205 523.245 176.884 522.875 177.491C522.519 178.105 522.009 178.592 521.346 178.952L520.778 177.388C521.399 176.941 521.779 176.376 521.917 175.693C522.068 175.016 522.013 174.317 521.752 173.595Z"
              fill="black"
            />
            <path
              d="M522.818 185.507L521.505 185.92L521.3 185.281C521.081 184.6 520.71 184.125 520.186 183.854C519.675 183.589 519.059 183.571 518.337 183.799L514.384 185.045L513.924 183.613L521.371 181.266L521.802 182.606L520.345 183.199L520.301 183.062C520.822 183.01 521.297 183.129 521.727 183.418C522.166 183.705 522.479 184.137 522.665 184.716C522.707 184.848 522.736 184.973 522.752 185.092C522.781 185.217 522.803 185.355 522.818 185.507Z"
              fill="black"
            />
            <path
              d="M516.567 190.85C516.362 190.121 516.345 189.428 516.516 188.773C516.696 188.114 517.035 187.545 517.532 187.065C518.039 186.583 518.683 186.234 519.464 186.018C520.256 185.8 520.994 185.763 521.677 185.906C522.362 186.06 522.952 186.363 523.444 186.813C523.939 187.274 524.289 187.87 524.494 188.599C524.696 189.317 524.716 189.981 524.555 190.59C524.396 191.209 524.08 191.739 523.605 192.179C523.134 192.63 522.522 192.959 521.772 193.166L521.232 193.315L519.403 186.865L520.384 186.628L521.781 191.603C522.409 191.43 522.859 191.102 523.133 190.617C523.407 190.132 523.454 189.571 523.275 188.934C523.14 188.452 522.915 188.066 522.601 187.776C522.3 187.494 521.918 187.317 521.455 187.246C521.004 187.182 520.497 187.227 519.931 187.383C519.026 187.633 518.385 188.031 518.006 188.578C517.638 189.122 517.563 189.779 517.779 190.549C517.938 191.114 518.181 191.545 518.508 191.842C518.835 192.139 519.232 192.289 519.699 192.294L520.079 193.649C519.225 193.685 518.492 193.461 517.88 192.977C517.267 192.493 516.83 191.784 516.567 190.85Z"
              fill="black"
            />
            <path
              d="M518.353 198.354C518.172 197.618 518.178 196.925 518.371 196.276C518.573 195.624 518.931 195.066 519.443 194.603C519.966 194.137 520.622 193.809 521.41 193.62C522.208 193.428 522.947 193.415 523.624 193.581C524.304 193.758 524.883 194.08 525.36 194.547C525.839 195.024 526.169 195.63 526.349 196.366C526.527 197.091 526.525 197.755 526.343 198.358C526.164 198.972 525.83 199.491 525.341 199.916C524.855 200.351 524.233 200.659 523.476 200.841L522.931 200.972L521.32 194.464L522.308 194.26L523.538 199.279C524.17 199.127 524.632 198.813 524.921 198.338C525.211 197.862 525.278 197.303 525.12 196.661C525.001 196.174 524.789 195.781 524.485 195.481C524.194 195.189 523.818 194.999 523.357 194.913C522.91 194.834 522.401 194.863 521.83 195C520.918 195.219 520.263 195.596 519.867 196.13C519.481 196.662 519.383 197.316 519.574 198.093C519.713 198.663 519.942 199.102 520.259 199.409C520.576 199.717 520.967 199.881 521.433 199.9L521.768 201.268C520.914 201.276 520.188 201.028 519.593 200.524C518.997 200.02 518.584 199.296 518.353 198.354Z"
              fill="black"
            />
            <path
              d="M519.327 206.068C519.147 205.333 519.158 204.661 519.361 204.053C519.573 203.442 519.945 202.919 520.476 202.485C521.009 202.06 521.659 201.756 522.426 201.571C523.204 201.385 523.929 201.364 524.602 201.509C525.285 201.652 525.869 201.951 526.353 202.405C526.838 202.859 527.172 203.459 527.354 204.205C527.499 204.795 527.503 205.348 527.366 205.864C527.241 206.387 526.972 206.836 526.559 207.21L531.63 205.991L531.984 207.436L520.535 210.188L520.215 208.883L521.569 208.426C521 208.299 520.521 208.025 520.132 207.603C519.745 207.191 519.477 206.679 519.327 206.068ZM520.722 206.046C520.844 206.543 521.062 206.94 521.377 207.238C521.694 207.545 522.078 207.749 522.531 207.849C522.984 207.948 523.475 207.935 524.004 207.807C524.533 207.68 524.97 207.471 525.316 207.179C525.672 206.885 525.92 206.53 526.059 206.112C526.201 205.705 526.211 205.253 526.089 204.756C525.967 204.258 525.744 203.862 525.419 203.567C525.105 203.27 524.722 203.077 524.272 202.987C523.832 202.896 523.353 202.912 522.835 203.037C522.306 203.164 521.862 203.369 521.503 203.653C521.144 203.937 520.894 204.282 520.752 204.689C520.61 205.096 520.6 205.548 520.722 206.046Z"
              fill="black"
            />
            <path
              d="M490.515 125.146L487.277 118.877L497.596 113.649L500.834 119.918L499.549 120.569L496.701 115.053L497.588 115.411L493.805 117.327L496.074 121.72L494.832 122.349L492.564 117.956L488.71 119.909L488.965 118.972L491.813 124.488L490.515 125.146Z"
              fill="black"
            />
            <path
              d="M492.4 126.834L491.58 125.312L496.301 125.912L498.505 121.671L499.332 123.206L497.699 126.313L501.191 126.657L502.003 128.164L497.219 127.616L495.063 131.778L494.236 130.242L495.842 127.186L492.4 126.834Z"
              fill="black"
            />
            <path
              d="M502.336 128.716L504.377 132.812L503.241 133.367L501.2 129.271L502.336 128.716ZM496.663 134.873L495.992 133.527L505.22 129.016L505.891 130.362L496.663 134.873Z"
              fill="black"
            />
            <path
              d="M507.15 139.104L505.879 139.632L505.617 139.014C505.338 138.355 504.926 137.915 504.379 137.691C503.847 137.474 503.231 137.51 502.532 137.801L498.705 139.391L498.118 138.006L505.329 135.01L505.878 136.306L504.48 137.026L504.423 136.893C504.938 136.795 505.422 136.871 505.875 137.122C506.339 137.368 506.689 137.771 506.926 138.331C506.98 138.459 507.021 138.58 507.047 138.697C507.087 138.819 507.121 138.955 507.15 139.104Z"
              fill="black"
            />
            <path
              d="M501.684 145.978C501.39 145.28 501.287 144.596 501.375 143.925C501.472 143.249 501.738 142.643 502.172 142.107C502.615 141.566 503.211 141.14 503.961 140.831C504.72 140.517 505.447 140.39 506.143 140.449C506.843 140.517 507.466 140.745 508.011 141.131C508.56 141.528 508.981 142.075 509.275 142.773C509.565 143.461 509.667 144.117 509.583 144.74C509.502 145.374 509.254 145.938 508.837 146.433C508.425 146.938 507.859 147.339 507.139 147.637L506.622 147.851L504.005 141.678L504.949 141.323L506.955 146.086C507.556 145.837 507.963 145.456 508.174 144.941C508.386 144.427 508.363 143.865 508.107 143.256C507.912 142.794 507.641 142.438 507.293 142.189C506.959 141.946 506.558 141.818 506.089 141.804C505.634 141.796 505.136 141.903 504.593 142.127C503.726 142.486 503.138 142.959 502.831 143.548C502.533 144.133 502.539 144.794 502.85 145.531C503.077 146.072 503.372 146.469 503.734 146.724C504.096 146.978 504.509 147.079 504.972 147.026L505.519 148.323C504.675 148.464 503.919 148.332 503.251 147.927C502.583 147.522 502.061 146.873 501.684 145.978Z"
              fill="black"
            />
            <path
              d="M504.725 151.997L504.199 150.588L511.548 147.903L512.017 149.162L510.737 149.851L510.791 149.627C511.257 149.627 511.7 149.778 512.12 150.078C512.553 150.386 512.878 150.829 513.094 151.409C513.336 152.059 513.361 152.663 513.171 153.221C512.98 153.778 512.619 154.229 512.086 154.571L511.991 154.316C512.609 154.204 513.178 154.308 513.696 154.63C514.214 154.952 514.596 155.442 514.842 156.102C515.155 156.942 515.155 157.691 514.843 158.35C514.531 159.009 513.949 159.495 513.097 159.806L508.288 161.563L507.774 160.184L512.238 158.553C512.819 158.34 513.209 158.027 513.408 157.614C513.62 157.207 513.633 156.754 513.447 156.254C513.317 155.904 513.121 155.623 512.86 155.412C512.613 155.207 512.317 155.083 511.97 155.039C511.624 154.995 511.255 155.045 510.864 155.188L506.506 156.78L505.987 155.386L510.465 153.749C511.046 153.537 511.433 153.231 511.626 152.831C511.828 152.428 511.836 151.976 511.65 151.477C511.52 151.127 511.324 150.846 511.064 150.635C510.816 150.43 510.52 150.305 510.173 150.262C509.837 150.214 509.473 150.262 509.083 150.405L504.725 151.997Z"
              fill="black"
            />
            <path
              d="M508.705 166.459C508.47 165.739 508.425 165.048 508.569 164.386C508.722 163.721 509.038 163.139 509.515 162.64C510.002 162.137 510.631 161.763 511.403 161.516C512.186 161.266 512.921 161.2 513.61 161.316C514.301 161.442 514.902 161.72 515.413 162.151C515.926 162.592 516.3 163.172 516.535 163.892C516.766 164.602 516.813 165.265 516.676 165.879C516.543 166.504 516.249 167.046 515.792 167.505C515.339 167.974 514.742 168.327 514 168.564L513.467 168.735L511.377 162.365L512.347 162.088L513.946 167.003C514.566 166.805 515.003 166.458 515.257 165.963C515.511 165.468 515.535 164.906 515.33 164.277C515.175 163.8 514.935 163.423 514.609 163.146C514.297 162.877 513.908 162.715 513.442 162.662C512.99 162.617 512.484 162.683 511.925 162.861C511.031 163.147 510.406 163.57 510.05 164.132C509.705 164.69 509.656 165.349 509.903 166.11C510.085 166.668 510.345 167.089 510.684 167.372C511.024 167.656 511.426 167.79 511.893 167.776L512.328 169.115C511.476 169.185 510.735 168.991 510.103 168.532C509.471 168.073 509.005 167.382 508.705 166.459Z"
              fill="black"
            />
            <line x1="3" y1="255.905" x2="15" y2="255.905" stroke="#FF430F" />
            <line
              x1="87.7449"
              y1="70.6536"
              x2="96.0577"
              y2="79.3079"
              stroke="#FF430F"
            />
            <line
              x1="444.639"
              y1="77.3077"
              x2="452.952"
              y2="68.6539"
              stroke="#7DB921"
            />
            <line
              x1="269.656"
              y1="12.3271"
              x2="269.656"
              y2="0.327149"
              stroke="#DBE52C"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2905_99"
                x1="505.213"
                y1="253.439"
                x2="46.6278"
                y2="238.519"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#5CA81D" />
                <stop offset="0.495" stopColor="#D9E92C" />
                <stop offset="1" stopColor="#FF420E" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_2905_99"
                x1="264.066"
                y1="6.00025"
                x2="8.56592"
                y2="256"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#DDDC2A" />
                <stop offset="0.460777" stopColor="#DDDC2A" />
                <stop offset="0.515593" stopColor="#FF430F" />
                <stop offset="1" stopColor="#FF430F" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_2905_99"
                x1="277.066"
                y1="6"
                x2="532.566"
                y2="256"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#CAE12A" />
                <stop offset="0.460777" stopColor="#CAE12A" />
                <stop offset="0.515593" stopColor="#62AC1E" />
                <stop offset="1" stopColor="#62AC1E" />
              </linearGradient>
            </defs>
            <rect
              x={rectX + 17.3095}
              y={rectY + 7}
              rx="24"
              ry="24"
              width={rectWidth}
              height={rectHeight}
              // fill="rgba(255, 255, 255, 0.77)"
              stroke="transparent"
              strokeWidth="0.5"
              filter="url(#boxShadow)"
              style={{
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)",
                opacity: "80%",
                fill:
                  value > 0 && value < 25
                    ? "#FFB3B3"
                    : value >= 25 && value < 50
                      ? "#FFD9B3"
                      : value >= 50 && value < 75
                        ? "#FFF3B3"
                        : "#B3FFB3",
              }}
            />
            <text
              x={labelX + 17.3}
              y={labelY + 7}
              fontSize="14"
              fill="black"
              textAnchor="middle"
              alignmentBaseline="middle"
              fontWeight="700">
              {value}
            </text>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="166"
            viewBox="0 0 541 257"
            fill="none">
            <path
              d="M493.304 253.798C493.304 138.301 393.096 44.6721 269.484 44.6721C145.872 44.6721 45.6648 138.301 45.6648 253.798"
              stroke="url(#paint0_linear_3128_6)"
              stroke-width="40"
              stroke-dasharray="3.5 6"
            />
            <path
              d="M7.66455 255.673C7.66455 117.602 124.966 5.6731 269.664 5.6731"
              stroke="url(#paint1_linear_3128_6)"
              stroke-miterlimit="16"
            />
            <path
              d="M531.665 255.673C531.665 117.602 414.363 5.67285 269.665 5.67285"
              stroke="url(#paint2_linear_3128_6)"
              stroke-miterlimit="16"
            />
            <rect
              x="38.8052"
              y="104.455"
              width="21.4551"
              height="100.49"
              transform="rotate(21.9745 38.8052 104.455)"
              fill="#1B1D21"
            />
            <path
              d="M21.876 193.856L19.9528 200.645L8.7333 197.826L10.6565 191.038L12.0531 191.388L10.3611 197.361L9.9521 196.517L14.0644 197.55L15.4119 192.793L16.762 193.132L15.4144 197.889L19.6043 198.941L18.7718 199.474L20.4639 193.501L21.876 193.856Z"
              fill="white"
            />
            <path
              d="M22.3226 190.247L21.935 191.931L18.7547 188.491L14.2724 190.352L14.6635 188.652L17.9618 187.301L15.5427 184.832L15.9266 183.164L19.1889 186.604L23.5821 184.774L23.191 186.473L19.9469 187.803L22.3226 190.247Z"
              fill="white"
            />
            <path
              d="M16.2043 182.587L17.3788 178.164L18.6092 178.454L17.4347 182.877L16.2043 182.587ZM24.6004 181.442L24.2144 182.895L14.2157 180.541L14.6017 179.088L24.6004 181.442Z"
              fill="white"
            />
            <path
              d="M20.3857 172.335L21.7156 172.688L21.5227 173.332C21.3176 174.017 21.3564 174.612 21.639 175.117C21.9144 175.609 22.4181 175.953 23.15 176.147L27.1551 177.211L26.7234 178.652L19.1772 176.647L19.5813 175.298L21.1335 175.578L21.0922 175.716C20.628 175.483 20.2994 175.13 20.1064 174.66C19.9032 174.187 19.8888 173.659 20.0633 173.077C20.1031 172.944 20.1502 172.824 20.2045 172.717C20.2515 172.597 20.3119 172.47 20.3857 172.335Z"
              fill="white"
            />
            <path
              d="M29.2486 170.544C29.006 171.262 28.6273 171.849 28.1124 172.307C27.5873 172.761 26.9806 173.058 26.2923 173.197C25.5938 173.332 24.8563 173.283 24.0799 173.05C23.2932 172.814 22.6548 172.456 22.1647 171.975C21.6781 171.484 21.3567 170.92 21.2006 170.284C21.048 169.637 21.093 168.955 21.3356 168.237C21.5748 167.53 21.9347 166.965 22.4155 166.541C22.8997 166.108 23.4655 165.832 24.1129 165.715C24.7637 165.587 25.462 165.635 26.2078 165.859L26.7442 166.02L24.612 172.375L23.6567 172.055L25.3123 167.16C24.689 166.973 24.1252 167.01 23.6207 167.27C23.1163 167.53 22.7581 167.974 22.5463 168.6C22.3857 169.075 22.3546 169.517 22.453 169.925C22.5445 170.319 22.7639 170.669 23.1111 170.973C23.4514 171.265 23.9026 171.495 24.4645 171.663C25.3636 171.933 26.1267 171.939 26.7538 171.682C27.3707 171.422 27.8072 170.913 28.0635 170.155C28.2514 169.6 28.2928 169.111 28.1876 168.69C28.0825 168.269 27.8358 167.933 27.4477 167.683L27.8987 166.35C28.6342 166.771 29.1207 167.345 29.3584 168.073C29.5962 168.801 29.5596 169.625 29.2486 170.544Z"
              fill="white"
            />
            <path
              d="M32.5242 164.521L31.9807 165.924L24.5819 163.38L25.0676 162.126L26.5045 162.401L26.314 162.538C25.9583 162.247 25.7213 161.855 25.6031 161.364C25.4787 160.859 25.5283 160.318 25.7518 159.741C26.0023 159.095 26.387 158.618 26.9058 158.312C27.4245 158.006 28.0015 157.889 28.6366 157.96L28.5384 158.214C27.9919 157.913 27.6288 157.479 27.4492 156.91C27.2696 156.341 27.307 155.728 27.5613 155.072C27.8851 154.236 28.3866 153.665 29.0659 153.357C29.7451 153.05 30.5135 153.043 31.3708 153.338L36.2126 155.003L35.6807 156.376L31.187 154.83C30.6019 154.629 30.0952 154.624 29.6669 154.815C29.2324 154.992 28.9187 155.33 28.726 155.827C28.5911 156.175 28.5524 156.511 28.6097 156.835C28.6608 157.146 28.8037 157.426 29.0384 157.676C29.2731 157.926 29.5872 158.118 29.9805 158.253L34.3684 159.762L33.8307 161.15L29.3218 159.599C28.7368 159.398 28.2371 159.39 27.8227 159.574C27.3982 159.755 27.0897 160.094 26.8969 160.591C26.7621 160.94 26.7233 161.276 26.7806 161.6C26.8317 161.91 26.9746 162.19 27.2093 162.44C27.4339 162.687 27.7429 162.877 28.1363 163.013L32.5242 164.521Z"
              fill="white"
            />
            <path
              d="M38.8613 150.481C38.5572 151.174 38.1283 151.728 37.5747 152.142C37.0111 152.551 36.3796 152.797 35.6802 152.88C34.9709 152.958 34.2385 152.85 33.483 152.556C32.7176 152.259 32.1108 151.851 31.6628 151.334C31.219 150.806 30.9465 150.22 30.8455 149.575C30.7487 148.919 30.8523 148.245 31.1564 147.551C31.4562 146.868 31.8644 146.335 32.381 145.952C32.9019 145.56 33.4908 145.332 34.1475 145.267C34.8085 145.193 35.5018 145.297 36.2275 145.579L36.7493 145.782L34.0726 151.928L33.1461 151.533L35.221 146.8C34.6146 146.564 34.0483 146.555 33.5221 146.774C32.9958 146.992 32.5999 147.404 32.3344 148.01C32.1331 148.469 32.064 148.905 32.1272 149.319C32.1847 149.718 32.3737 150.084 32.6942 150.414C33.0091 150.731 33.4399 150.996 33.9866 151.209C34.8614 151.549 35.623 151.617 36.2715 151.411C36.91 151.202 37.3899 150.732 37.7111 149.999C37.9466 149.462 38.03 148.98 37.9612 148.552C37.8924 148.125 37.6749 147.772 37.3088 147.492L37.8741 146.203C38.5724 146.68 39.0089 147.291 39.1837 148.033C39.3585 148.776 39.251 149.592 38.8613 150.481Z"
              fill="white"
            />
            <path
              d="M32.8626 135.839L43.368 140.682L42.6455 142.073L32.14 137.23L32.8626 135.839ZM36.6623 131.642L33.4994 137.734L32.1916 137.131L35.3546 131.039L36.6623 131.642ZM41.0011 134.593L38.2215 139.946L36.9283 139.35L39.7079 133.996L41.0011 134.593Z"
              fill="white"
            />
            <path
              d="M48.3029 131.893C47.9287 132.551 47.4443 133.059 46.8497 133.415C46.2456 133.766 45.5905 133.949 44.8843 133.963C44.1686 133.971 43.4489 133.793 42.7252 133.428C41.9919 133.058 41.4282 132.594 41.034 132.037C40.645 131.471 40.4331 130.863 40.3982 130.213C40.3685 129.553 40.5408 128.895 40.915 128.236C41.2839 127.587 41.7456 127.098 42.3 126.769C42.8598 126.431 43.4705 126.262 44.1323 126.262C44.7994 126.252 45.4805 126.423 46.1757 126.774L46.6756 127.026L43.3776 132.862L42.4935 132.38L45.0469 127.887C44.466 127.594 43.9019 127.53 43.3546 127.695C42.8072 127.86 42.3702 128.231 42.0434 128.806C41.7957 129.241 41.6823 129.668 41.703 130.084C41.7196 130.486 41.8709 130.867 42.1569 131.226C42.4387 131.571 42.8415 131.875 43.3652 132.14C44.2032 132.563 44.9562 132.704 45.6241 132.564C46.2825 132.419 46.8093 131.998 47.2046 131.303C47.4944 130.793 47.6268 130.323 47.6018 129.892C47.5768 129.462 47.3959 129.09 47.0592 128.777L47.7548 127.553C48.4028 128.095 48.776 128.743 48.8745 129.496C48.973 130.25 48.7825 131.049 48.3029 131.893Z"
              fill="white"
            />
            <path
              d="M51.7024 125.497C51.2643 126.205 50.7273 126.657 50.0914 126.854C49.4611 127.042 48.8375 126.967 48.2206 126.628C47.5945 126.283 47.2292 125.79 47.125 125.15C47.0114 124.503 47.1634 123.773 47.581 122.957L48.7102 120.769L48.5139 120.661C48.1307 120.451 47.7848 120.352 47.4763 120.364C47.1584 120.372 46.8797 120.468 46.6402 120.652C46.3914 120.831 46.1743 121.07 45.9889 121.37C45.6576 121.905 45.516 122.386 45.5643 122.814C45.6089 123.227 45.8415 123.55 46.2621 123.781L45.5712 124.897C45.0946 124.634 44.7517 124.288 44.5426 123.857C44.3335 123.426 44.2628 122.943 44.3305 122.409C44.4038 121.866 44.6174 121.308 44.9712 120.737C45.3138 120.184 45.7143 119.76 46.1726 119.465C46.6273 119.156 47.1304 119.001 47.682 119C48.2299 118.986 48.8123 119.148 49.4291 119.487L53.733 121.855L53.059 122.943L51.8673 122.47C52.1531 122.858 52.2808 123.329 52.2505 123.884C52.2165 124.424 52.0338 124.962 51.7024 125.497ZM50.9065 124.567C51.2828 123.96 51.3907 123.381 51.2303 122.83C51.0605 122.275 50.6532 121.82 50.0083 121.465L49.6438 121.265L48.7309 123.042C48.4388 123.635 48.3216 124.118 48.3793 124.49C48.4426 124.853 48.6425 125.127 48.9789 125.312C49.3434 125.513 49.6921 125.546 50.0251 125.414C50.3543 125.266 50.6481 124.984 50.9065 124.567Z"
              fill="white"
            />
            <path
              d="M49.9546 112.411L51.1381 113.113L50.7649 113.672C50.3681 114.266 50.233 114.843 50.3599 115.401C50.4834 115.945 50.8709 116.41 51.5223 116.796L55.0866 118.91L54.2514 120.161L47.5357 116.178L48.3175 115.007L49.7372 115.7L49.6573 115.82C49.2761 115.47 49.0605 115.045 49.0103 114.545C48.951 114.039 49.0901 113.533 49.4278 113.027C49.5048 112.912 49.585 112.811 49.6685 112.724C49.7488 112.623 49.8441 112.519 49.9546 112.411Z"
              fill="white"
            />
            <rect
              x="176.587"
              y="14.6719"
              width="13.8317"
              height="39.1371"
              transform="rotate(69.0201 176.587 14.6719)"
              fill="#1B1D21"
            />
            <path
              d="M146.042 27.3583L150.956 37.8309L149.536 38.4969L144.623 28.0243L146.042 27.3583ZM151.55 26.3648L145.336 29.2804L144.724 27.9768L150.938 25.0612L151.55 26.3648ZM152.983 31.4539L147.522 34.0161L146.917 32.7269L152.378 30.1647L152.983 31.4539Z"
              fill="white"
            />
            <path
              d="M159.369 33.8459C158.672 34.1422 157.987 34.2481 157.314 34.1635C156.636 34.0692 156.026 33.8069 155.484 33.3768C154.938 32.9368 154.506 32.3438 154.189 31.5978C153.867 30.8419 153.733 30.1167 153.785 29.4222C153.847 28.7235 154.07 28.1015 154.452 27.5564C154.845 27.0071 155.389 26.5842 156.086 26.2879C156.773 25.9957 157.43 25.8905 158.056 25.9721C158.691 26.0495 159.259 26.2949 159.759 26.7081C160.27 27.1171 160.677 27.6799 160.982 28.3965L161.201 28.9119L155.038 31.5498L154.673 30.6096L159.428 28.5875C159.174 27.9887 158.788 27.5849 158.27 27.3761C157.753 27.1674 157.189 27.1924 156.581 27.4511C156.119 27.6473 155.766 27.9194 155.52 28.2675C155.279 28.6015 155.154 29.0024 155.144 29.4702C155.14 29.924 155.253 30.4208 155.482 30.9607C155.85 31.8245 156.33 32.4086 156.923 32.7128C157.512 33.0072 158.174 32.9979 158.91 32.6849C159.45 32.4553 159.846 32.1595 160.098 31.7975C160.35 31.4354 160.447 31.0232 160.389 30.5609L161.685 30.0099C161.834 30.8507 161.708 31.6053 161.309 32.2736C160.909 32.942 160.263 33.4661 159.369 33.8459Z"
              fill="white"
            />
            <path
              d="M166.535 30.8142C165.758 31.1115 165.067 31.1417 164.462 30.9047C163.868 30.6639 163.445 30.2148 163.193 29.5572C162.938 28.8897 162.973 28.2653 163.299 27.6838C163.621 27.0924 164.197 26.598 165.027 26.2005L167.26 25.1408L167.179 24.9316C167.023 24.5231 166.825 24.2279 166.584 24.046C166.339 23.8541 166.068 23.7579 165.771 23.7573C165.47 23.7468 165.155 23.8045 164.826 23.9302C164.239 24.1551 163.828 24.4549 163.594 24.8298C163.367 25.1908 163.339 25.5955 163.511 26.0438L162.285 26.5126C162.091 26.0045 162.051 25.5116 162.165 25.0339C162.279 24.5563 162.53 24.1233 162.919 23.7349C163.317 23.3428 163.83 23.0267 164.457 22.7866C165.065 22.5541 165.635 22.4617 166.167 22.5094C166.706 22.5433 167.183 22.7318 167.6 23.0748C168.023 23.4041 168.36 23.8976 168.611 24.5551L170.367 29.1429L169.171 29.6002L168.57 28.4768C168.541 28.9676 168.34 29.4271 167.967 29.8553C167.6 30.2697 167.123 30.5894 166.535 30.8142ZM166.521 29.569C167.188 29.3136 167.635 28.9143 167.861 28.371C168.084 27.8177 168.063 27.1973 167.8 26.5099L167.652 26.1214L165.841 26.9854C165.246 27.2815 164.853 27.5974 164.662 27.9333C164.48 28.2653 164.458 28.6107 164.596 28.9693C164.744 29.3579 164.986 29.6022 165.321 29.7024C165.663 29.7888 166.063 29.7443 166.521 29.569Z"
              fill="white"
            />
            <path
              d="M174.595 19.0209L175.044 20.3217L174.409 20.5408C173.733 20.7737 173.266 21.1547 173.009 21.6835C172.758 22.1989 172.756 22.8145 173.002 23.5305L174.353 27.4481L172.931 27.9384L170.386 20.5568L171.717 20.0979L172.349 21.5385L172.213 21.5855C172.147 21.0667 172.254 20.5899 172.533 20.1553C172.808 19.7106 173.233 19.3891 173.808 19.1909C173.939 19.1457 174.063 19.1141 174.181 19.096C174.306 19.0643 174.444 19.0393 174.595 19.0209Z"
              fill="white"
            />
            <rect
              x="405.068"
              y="31.4261"
              width="16.7639"
              height="51.0649"
              transform="rotate(114.564 405.068 31.4261)"
              fill="#1B1D21"
            />
            <path
              d="M364.115 16.1618C363.335 15.8717 362.598 15.8024 361.905 15.954C361.211 16.1056 360.595 16.4455 360.057 16.9738C359.519 17.502 359.09 18.196 358.77 19.0557C358.447 19.9255 358.325 20.7279 358.404 21.4631C358.493 22.2019 358.761 22.8423 359.208 23.3842C359.659 23.9161 360.255 24.3197 360.994 24.595C361.464 24.7698 361.921 24.8716 362.366 24.9004C362.81 24.9292 363.229 24.8745 363.622 24.7361C364.019 24.5878 364.376 24.3453 364.695 24.0086C365.024 23.6757 365.289 23.2393 365.489 22.6995L365.869 21.6798L366.296 22.6412L361.978 21.0344L362.463 19.7297L367.472 21.5932L365.245 27.5765L364.016 27.119L364.614 25.0538L364.719 25.3663C364.425 25.6666 364.05 25.8967 363.592 26.0564C363.134 26.2162 362.627 26.2949 362.07 26.2926C361.517 26.2803 360.945 26.1644 360.355 25.9449C359.336 25.5655 358.522 25.0008 357.913 24.2509C357.308 23.4909 356.94 22.6142 356.809 21.6207C356.678 20.6273 356.82 19.5708 357.237 18.4511C357.646 17.3514 358.229 16.4585 358.985 15.7724C359.751 15.09 360.623 14.6577 361.601 14.4754C362.579 14.2931 363.599 14.3991 364.658 14.7934C365.448 15.0872 366.114 15.5 366.656 16.0316C367.201 16.5533 367.588 17.1522 367.814 17.8283C368.054 18.4981 368.099 19.2033 367.949 19.9441L366.389 19.3638C366.446 18.5996 366.257 17.9434 365.824 17.3952C365.404 16.8407 364.835 16.4296 364.115 16.1618Z"
              fill="white"
            />
            <path
              d="M375.204 22.3082L374.677 23.5794L374.056 23.3221C373.396 23.0485 372.794 23.0243 372.251 23.2493C371.721 23.4686 371.311 23.9281 371.021 24.6277L369.435 28.456L368.046 27.8802L371.035 20.6671L372.335 21.2061L371.853 22.7036L371.72 22.6485C372.015 22.2163 372.411 21.93 372.908 21.7896C373.409 21.6393 373.94 21.6805 374.502 21.9133C374.63 21.9664 374.744 22.0252 374.844 22.0898C374.958 22.1487 375.078 22.2215 375.204 22.3082Z"
              fill="white"
            />
            <path
              d="M375.266 31.9721C374.577 31.6567 374.037 31.2217 373.646 30.6671C373.259 30.1028 373.042 29.4753 372.995 28.7849C372.952 28.0847 373.099 27.3661 373.437 26.6291C373.779 25.8823 374.224 25.2944 374.773 24.8652C375.331 24.4404 375.94 24.1857 376.601 24.1011C377.271 24.021 377.951 24.1386 378.639 24.454C379.318 24.7649 379.838 25.1791 380.199 25.6966C380.57 26.2185 380.772 26.8035 380.803 27.4515C380.845 28.104 380.703 28.7842 380.379 29.4922L380.146 30.0013L374.044 27.2241L374.493 26.3209L379.192 28.4731C379.462 27.8815 379.501 27.3242 379.306 26.801C379.112 26.2778 378.714 25.8785 378.113 25.6031C377.657 25.3944 377.217 25.3163 376.794 25.3689C376.386 25.4163 376.005 25.5939 375.653 25.9019C375.315 26.2046 375.024 26.6226 374.779 27.156C374.388 28.0094 374.28 28.7577 374.455 29.4009C374.634 30.0343 375.087 30.5177 375.815 30.8508C376.348 31.0951 376.833 31.1883 377.27 31.1305C377.708 31.0726 378.078 30.8666 378.381 30.5126L379.661 31.099C379.14 31.7754 378.497 32.1911 377.734 32.3461C376.971 32.501 376.148 32.3763 375.266 31.9721Z"
              fill="white"
            />
            <path
              d="M383.067 35.945C382.389 35.6068 381.864 35.1541 381.491 34.5867C381.124 34.0098 380.928 33.3755 380.903 32.6838C380.884 31.9826 381.055 31.2693 381.417 30.544C381.783 29.809 382.248 29.2362 382.811 28.8256C383.383 28.4196 384 28.1854 384.663 28.1229C385.336 28.0651 386.011 28.2053 386.689 28.5434C387.357 28.8768 387.863 29.3081 388.207 29.8374C388.56 30.3714 388.742 30.9627 388.752 31.6115C388.771 32.265 388.607 32.9401 388.26 33.6368L388.01 34.1379L382.004 31.1589L382.483 30.2712L387.107 32.5788C387.397 31.9966 387.454 31.4408 387.277 30.9114C387.1 30.382 386.716 29.9697 386.124 29.6744C385.676 29.4506 385.239 29.3579 384.815 29.3964C384.404 29.4301 384.018 29.595 383.656 29.891C383.308 30.1823 383.003 30.5904 382.741 31.1153C382.322 31.9552 382.189 32.6995 382.342 33.3482C382.5 33.9872 382.937 34.4854 383.653 34.8426C384.178 35.1045 384.659 35.2139 385.098 35.1706C385.537 35.1273 385.914 34.9338 386.228 34.5901L387.488 35.2188C386.945 35.8775 386.289 36.2716 385.521 36.401C384.753 36.5304 383.935 36.3784 383.067 35.945Z"
              fill="white"
            />
            <path
              d="M390.107 39.9236C389.429 39.5855 388.923 39.1423 388.589 38.5939C388.26 38.0361 388.099 37.4137 388.108 36.7268C388.127 36.0446 388.312 35.3504 388.665 34.6441C389.022 33.9283 389.472 33.3602 390.016 32.94C390.564 32.5103 391.167 32.257 391.825 32.1801C392.484 32.1033 393.156 32.2363 393.844 32.5792C394.388 32.8507 394.812 33.2058 395.118 33.6444C395.438 34.0782 395.608 34.5742 395.627 35.1323L397.956 30.4651L399.288 31.1295L394.03 41.6665L392.827 41.0664L393.348 39.735C392.886 40.0884 392.369 40.2772 391.796 40.3016C391.233 40.3306 390.67 40.2047 390.107 39.9236ZM390.986 38.8425C391.445 39.0711 391.888 39.1614 392.317 39.1133C392.756 39.0701 393.159 38.9076 393.526 38.6258C393.893 38.3441 394.198 37.9598 394.441 37.4731C394.684 36.9863 394.806 36.5163 394.805 36.0632C394.81 35.6004 394.697 35.1807 394.468 34.8041C394.248 34.4322 393.909 34.1319 393.451 33.9033C392.993 33.6747 392.547 33.5892 392.113 33.6468C391.684 33.6948 391.29 33.8621 390.933 34.1486C390.58 34.4255 390.284 34.8026 390.046 35.2798C389.803 35.7666 389.675 36.2389 389.661 36.6969C389.647 37.1549 389.75 37.5698 389.97 37.9417C390.189 38.3136 390.528 38.6139 390.986 38.8425Z"
              fill="white"
            />
            <rect
              x="536.4"
              y="209.245"
              width="23.6969"
              height="107.585"
              transform="rotate(164.403 536.4 209.245)"
              fill="#1B1D21"
            />
            <path
              d="M520.85 173.268C520.567 172.485 520.142 171.878 519.577 171.445C519.011 171.012 518.353 170.76 517.6 170.688C516.848 170.615 516.04 170.733 515.176 171.041C514.301 171.351 513.609 171.773 513.098 172.306C512.592 172.848 512.276 173.464 512.152 174.154C512.037 174.84 512.115 175.554 512.384 176.296C512.555 176.767 512.773 177.182 513.039 177.54C513.305 177.898 513.618 178.183 513.979 178.395C514.349 178.603 514.766 178.72 515.23 178.748C515.697 178.785 516.202 178.708 516.745 178.515L517.77 178.15L517.312 179.094L515.742 174.762L517.053 174.295L518.875 179.319L512.86 181.459L512.413 180.225L514.378 179.356L514.208 179.638C513.788 179.606 513.369 179.466 512.95 179.219C512.531 178.972 512.142 178.634 511.783 178.207C511.434 177.777 511.153 177.266 510.938 176.674C510.567 175.651 510.471 174.666 510.65 173.72C510.839 172.77 511.27 171.926 511.944 171.188C512.618 170.45 513.518 169.881 514.644 169.48C515.749 169.087 516.809 168.959 517.824 169.097C518.841 169.244 519.737 169.633 520.51 170.264C521.283 170.895 521.863 171.742 522.248 172.804C522.535 173.597 522.651 174.371 522.596 175.126C522.551 175.878 522.344 176.557 521.974 177.164C521.618 177.778 521.108 178.265 520.444 178.625L519.877 177.061C520.498 176.614 520.877 176.049 521.015 175.366C521.167 174.689 521.112 173.99 520.85 173.268Z"
              fill="white"
            />
            <path
              d="M521.916 185.18L520.604 185.593L520.398 184.954C520.18 184.273 519.809 183.797 519.285 183.526C518.774 183.262 518.158 183.244 517.435 183.472L513.483 184.717L513.023 183.285L520.47 180.938L520.9 182.279L519.444 182.872L519.4 182.735C519.921 182.683 520.396 182.801 520.825 183.091C521.265 183.378 521.577 183.81 521.763 184.389C521.806 184.521 521.835 184.646 521.85 184.764C521.879 184.889 521.901 185.028 521.916 185.18Z"
              fill="white"
            />
            <path
              d="M515.666 190.522C515.461 189.793 515.444 189.101 515.614 188.446C515.795 187.787 516.134 187.218 516.63 186.738C517.137 186.256 517.781 185.906 518.563 185.691C519.355 185.473 520.092 185.436 520.775 185.579C521.461 185.733 522.05 186.036 522.542 186.486C523.038 186.947 523.388 187.542 523.592 188.271C523.794 188.99 523.815 189.654 523.653 190.263C523.495 190.882 523.178 191.412 522.704 191.852C522.232 192.303 521.621 192.632 520.87 192.839L520.331 192.988L518.502 186.538L519.482 186.301L520.88 191.276C521.507 191.103 521.958 190.774 522.232 190.29C522.505 189.805 522.553 189.244 522.374 188.607C522.238 188.125 522.013 187.739 521.699 187.449C521.399 187.167 521.016 186.99 520.553 186.918C520.103 186.854 519.595 186.9 519.03 187.056C518.125 187.306 517.483 187.704 517.105 188.25C516.737 188.794 516.661 189.451 516.878 190.222C517.036 190.786 517.279 191.217 517.607 191.514C517.934 191.812 518.331 191.962 518.797 191.966L519.178 193.322C518.324 193.358 517.591 193.134 516.978 192.65C516.366 192.166 515.928 191.457 515.666 190.522Z"
              fill="white"
            />
            <path
              d="M517.451 198.026C517.271 197.291 517.277 196.598 517.469 195.949C517.672 195.297 518.029 194.739 518.542 194.276C519.065 193.81 519.72 193.482 520.508 193.293C521.307 193.101 522.045 193.088 522.723 193.254C523.403 193.431 523.982 193.753 524.458 194.219C524.938 194.697 525.268 195.303 525.448 196.039C525.625 196.764 525.623 197.428 525.442 198.031C525.263 198.645 524.929 199.164 524.44 199.588C523.953 200.023 523.331 200.332 522.574 200.514L522.03 200.645L520.419 194.137L521.407 193.933L522.636 198.952C523.269 198.8 523.73 198.486 524.02 198.011C524.31 197.535 524.376 196.976 524.219 196.334C524.099 195.847 523.888 195.454 523.584 195.154C523.292 194.862 522.917 194.672 522.456 194.585C522.008 194.507 521.499 194.536 520.929 194.673C520.016 194.892 519.362 195.269 518.966 195.803C518.58 196.334 518.482 196.989 518.672 197.766C518.812 198.336 519.04 198.774 519.357 199.082C519.674 199.39 520.066 199.554 520.532 199.573L520.867 200.941C520.012 200.949 519.287 200.701 518.691 200.197C518.096 199.693 517.682 198.969 517.451 198.026Z"
              fill="white"
            />
            <path
              d="M518.426 205.741C518.246 205.005 518.257 204.334 518.459 203.725C518.672 203.115 519.044 202.592 519.574 202.157C520.107 201.733 520.757 201.429 521.525 201.244C522.303 201.057 523.028 201.037 523.701 201.182C524.384 201.325 524.967 201.624 525.452 202.078C525.937 202.532 526.27 203.132 526.453 203.877C526.598 204.468 526.602 205.021 526.464 205.537C526.34 206.06 526.071 206.509 525.657 206.883L530.729 205.664L531.083 207.109L519.633 209.861L519.313 208.556L520.667 208.098C520.098 207.972 519.619 207.698 519.23 207.276C518.844 206.864 518.576 206.352 518.426 205.741ZM519.821 205.718C519.943 206.216 520.161 206.613 520.475 206.91C520.792 207.218 521.177 207.422 521.63 207.522C522.083 207.621 522.573 207.607 523.102 207.48C523.631 207.353 524.069 207.144 524.415 206.852C524.771 206.558 525.019 206.202 525.158 205.785C525.3 205.378 525.31 204.926 525.188 204.428C525.066 203.931 524.843 203.535 524.518 203.24C524.203 202.943 523.821 202.749 523.371 202.66C522.931 202.568 522.452 202.585 521.933 202.709C521.404 202.837 520.96 203.042 520.602 203.326C520.243 203.609 519.993 203.955 519.851 204.362C519.709 204.769 519.699 205.221 519.821 205.718Z"
              fill="white"
            />
            <path
              d="M489.613 124.819L486.375 118.55L496.695 113.322L499.932 119.591L498.648 120.242L495.799 114.726L496.686 115.084L492.904 117L495.173 121.393L493.931 122.022L491.662 117.629L487.809 119.581L488.063 118.645L490.912 124.161L489.613 124.819Z"
              fill="white"
            />
            <path
              d="M491.498 126.507L490.679 124.985L495.4 125.585L497.604 121.344L498.431 122.879L496.797 125.986L500.29 126.33L501.102 127.837L496.318 127.289L494.162 131.451L493.335 129.915L494.941 126.859L491.498 126.507Z"
              fill="white"
            />
            <path
              d="M501.434 128.389L503.475 132.485L502.34 133.04L500.299 128.944L501.434 128.389ZM495.761 134.546L495.09 133.2L504.319 128.689L504.99 130.035L495.761 134.546Z"
              fill="white"
            />
            <path
              d="M506.248 138.777L504.978 139.305L504.716 138.686C504.437 138.028 504.024 137.588 503.478 137.364C502.945 137.146 502.33 137.183 501.63 137.473L497.803 139.063L497.217 137.679L504.427 134.683L504.976 135.979L503.578 136.699L503.522 136.566C504.037 136.468 504.521 136.544 504.974 136.794C505.438 137.041 505.788 137.444 506.025 138.004C506.079 138.131 506.119 138.253 506.145 138.37C506.185 138.491 506.22 138.627 506.248 138.777Z"
              fill="white"
            />
            <path
              d="M500.783 145.651C500.489 144.953 500.386 144.268 500.473 143.597C500.571 142.922 500.837 142.316 501.27 141.779C501.714 141.238 502.31 140.813 503.059 140.504C503.818 140.19 504.546 140.063 505.242 140.121C505.942 140.19 506.565 140.418 507.109 140.804C507.658 141.201 508.08 141.748 508.374 142.446C508.663 143.134 508.766 143.79 508.681 144.413C508.601 145.047 508.352 145.611 507.936 146.106C507.524 146.611 506.958 147.012 506.238 147.31L505.72 147.523L503.104 141.351L504.048 140.996L506.053 145.759C506.655 145.51 507.061 145.129 507.273 144.614C507.484 144.1 507.462 143.538 507.205 142.928C507.011 142.466 506.74 142.111 506.392 141.862C506.058 141.619 505.657 141.491 505.188 141.477C504.733 141.468 504.234 141.576 503.692 141.8C502.825 142.159 502.237 142.632 501.929 143.221C501.632 143.806 501.638 144.467 501.948 145.204C502.176 145.745 502.471 146.142 502.833 146.397C503.194 146.651 503.607 146.752 504.071 146.699L504.617 147.996C503.774 148.137 503.018 148.005 502.35 147.6C501.682 147.195 501.159 146.545 500.783 145.651Z"
              fill="white"
            />
            <path
              d="M503.823 151.67L503.298 150.261L510.647 147.575L511.116 148.835L509.836 149.524L509.889 149.3C510.356 149.3 510.799 149.45 511.218 149.751C511.652 150.059 511.976 150.502 512.192 151.082C512.434 151.732 512.46 152.335 512.269 152.893C512.079 153.451 511.717 153.901 511.185 154.244L511.09 153.989C511.708 153.876 512.276 153.981 512.794 154.303C513.313 154.624 513.695 155.115 513.94 155.775C514.253 156.614 514.254 157.364 513.941 158.023C513.629 158.682 513.047 159.167 512.196 159.479L507.387 161.236L506.873 159.857L511.336 158.226C511.917 158.013 512.307 157.7 512.506 157.287C512.719 156.88 512.732 156.427 512.546 155.927C512.415 155.577 512.22 155.296 511.959 155.085C511.712 154.88 511.415 154.756 511.069 154.712C510.722 154.668 510.354 154.718 509.963 154.861L505.605 156.453L505.086 155.059L509.564 153.422C510.145 153.21 510.532 152.904 510.724 152.504C510.927 152.101 510.935 151.649 510.749 151.149C510.619 150.8 510.423 150.519 510.162 150.308C509.915 150.103 509.618 149.978 509.272 149.935C508.936 149.887 508.572 149.935 508.181 150.078L503.823 151.67Z"
              fill="white"
            />
            <path
              d="M507.803 166.132C507.569 165.412 507.524 164.721 507.667 164.059C507.821 163.394 508.136 162.812 508.613 162.313C509.1 161.81 509.73 161.436 510.502 161.189C511.285 160.939 512.02 160.873 512.708 160.989C513.4 161.115 514.001 161.393 514.511 161.824C515.025 162.265 515.399 162.845 515.633 163.565C515.864 164.275 515.912 164.937 515.775 165.552C515.642 166.177 515.347 166.719 514.891 167.178C514.438 167.647 513.84 168 513.099 168.237L512.565 168.408L510.475 162.037L511.445 161.761L513.045 166.676C513.665 166.478 514.102 166.131 514.355 165.636C514.609 165.141 514.634 164.578 514.429 163.95C514.274 163.473 514.034 163.096 513.708 162.819C513.396 162.55 513.007 162.388 512.541 162.335C512.088 162.289 511.583 162.356 511.024 162.534C510.13 162.82 509.505 163.243 509.149 163.804C508.804 164.363 508.755 165.022 509.002 165.783C509.184 166.341 509.444 166.761 509.783 167.045C510.122 167.329 510.525 167.463 510.991 167.449L511.427 168.788C510.575 168.858 509.833 168.664 509.201 168.205C508.57 167.746 508.104 167.055 507.803 166.132Z"
              fill="white"
            />
            <line
              x1="2.09863"
              y1="255.578"
              x2="14.0986"
              y2="255.578"
              stroke="#FF430F"
            />
            <line
              x1="86.8435"
              y1="70.3265"
              x2="95.1563"
              y2="78.9808"
              stroke="#FF430F"
            />
            <line
              x1="443.738"
              y1="76.9805"
              x2="452.051"
              y2="68.3266"
              stroke="#7DB921"
            />
            <line
              x1="268.755"
              y1="12"
              x2="268.755"
              y2="7.15256e-07"
              stroke="#DBE52C"
            />
            <defs>
              <linearGradient
                id="paint0_linear_3128_6"
                x1="504.311"
                y1="253.112"
                x2="45.7264"
                y2="238.191"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#5CA81D" />
                <stop offset="0.495" stop-color="#D9E92C" />
                <stop offset="1" stop-color="#FF420E" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_3128_6"
                x1="263.165"
                y1="5.6731"
                x2="7.66455"
                y2="255.673"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#DDDC2A" />
                <stop offset="0.460777" stop-color="#DDDC2A" />
                <stop offset="0.515593" stop-color="#FF430F" />
                <stop offset="1" stop-color="#FF430F" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_3128_6"
                x1="276.165"
                y1="5.67285"
                x2="531.665"
                y2="255.673"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#CAE12A" />
                <stop offset="0.460777" stop-color="#CAE12A" />
                <stop offset="0.515593" stop-color="#62AC1E" />
                <stop offset="1" stop-color="#62AC1E" />
              </linearGradient>
            </defs>
            <rect
              x={rectX + 17.3095}
              y={rectY + 7}
              rx="24"
              ry="24"
              width={rectWidth}
              height={rectHeight}
              fill="rgba(255, 255, 255, 0.77)"
              stroke="transparent"
              strokeWidth="0.5"
              filter="url(#boxShadow)"
              style={{
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)",
              }}
            />
            <text
              x={labelX + 17.3}
              y={labelY + 7}
              fontSize="14"
              fill="black"
              textAnchor="middle"
              alignmentBaseline="middle"
              fontWeight="700">
              {value}
            </text>
          </svg>
        )}
      </div>
    );
  }
};

export default MarketMoodChartMobile;
