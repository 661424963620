import React from 'react'
import { BsExclamationCircle } from "react-icons/bs";
import MarketMoodChartMobile from './MarketMoodChartMobile';
const ChartMobile = ({data}) => {
  return (
    <div className='chart-main-div'>
      <div className='chart-svg'>
        <MarketMoodChartMobile
        //  value={data?.market_mood?.value}
        value={data?.market_mood?.value}
         />
        {/* Chart values labeling  */}
        {/* <div style={{position: "absolute", left: "32px", bottom: "30px",fontSize:"10px",fontWeight:"500"}}>0.0</div>
        <div style={{position: "absolute", left: "62px", bottom: "0px",top:"32px",fontSize:"10px",fontWeight:"500"}}>25.0</div>
        <div style={{position: "absolute", left: "181px", top: "-14px",fontSize:"10px",fontWeight:"500"}}>50.0</div>
        <div style={{position: "absolute", right: "59px", top: "32px",fontSize:"10px",fontWeight:"500"}}>75.0</div>
        <div style={{position: "absolute", right: "23px", bottom: "30px",fontSize:"10px",fontWeight:"500"}}>100.0</div> */}
        <div style={{width:"100%",textAlign:"center",fontSize:"14px",fontWeight:'500',position:"absolute",bottom:'12px'}}>Market Mood</div>
        {data?.market_mood.value > 0 && data?.market_mood?.value < 25 && (
        <div style={{ width: "100%", textAlign: "center",color:'#FF420E', position: "absolute", bottom: '28px' }} className='chart-result'>
          Extreme Fear
        </div>
        )}
        {data?.market_mood?.value >= 25 && data?.market_mood?.value < 50 && (
        <div style={{ width: "100%", textAlign: "center",color:'#FF420E', position: "absolute", bottom: '28px' }} className='chart-result'>
          Fear
        </div>
        )}
        {data?.market_mood?.value >= 50 && data?.market_mood?.value < 75 && (
        <div style={{ width: "100%", textAlign: "center",color:'#D9E92C', position: "absolute", bottom: '28px' }} className='chart-result'>
          Greed
        </div>
        )}
        {data?.market_mood?.value >= 75.0 && data?.market_mood?.value <= 100 && (
        <div style={{ width: "100%", textAlign: "center",color:'#5CA81D', position: "absolute", bottom: '28px' }} className='chart-result'>
          Greed
        </div>
        )}
              <div style={{fontSize:10,color:'grey',position:'absolute', bottom:-2,width:'100%',textAlign:'center'}}>Last updated : {data?.market_mood.updated_on}</div>
      </div>
      <div className='chart-warning'>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path d="M9 5.36364V9M9 11.9091V12.2727M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        <p className='warning-text'>
          {data?.market_mood?.messsage}
        </p>
      </div>
    </div>
  )
}

export default ChartMobile;
