import { createContext, useContext, useState } from "react";

const BuySellModalContext = createContext();

export const BuySellModalProvider = ({ children }) => {
  const [buySellModalData, setBuySellModalData] = useState([]);

  const addToBuySellModal = (dataObject) => {
    setBuySellModalData([dataObject]);
  };

  const toggleBuySellStatus = (index) => {
    let dataObject = buySellModalData[index];
    dataObject["buy_sell"] =
      buySellModalData[index]["buy_sell"] === "B" ? "S" : "B";
    setBuySellModalData((prevData) => [...prevData]);
  };

  const handlePriceChange = (e) => {
    const inputValue = e.target.value.trim();

    if (
      inputValue === "" ||
      (!isNaN(inputValue) && parseFloat(inputValue) >= 0)
    ) {
      const updatedItem = {
        ...buySellModalData[0],
        price: inputValue === "" ? "" : parseFloat(inputValue),
      };
      setBuySellModalData([updatedItem]);
    }
  };

  const handleTriggerPriceChange = (e) => {
    const inputValue = e.target.value.trim();

    if (
      inputValue === "" ||
      (!isNaN(inputValue) && parseFloat(inputValue) >= 0)
    ) {
      const updatedItem = {
        ...buySellModalData[0],
        trigger_price: inputValue === "" ? "" : parseFloat(inputValue),
      };
      setBuySellModalData([updatedItem]);
    }
  };

  const handleIncrement = () => {
    setBuySellModalData((prevData) => {
      const newData = [...prevData];
      newData.forEach((dataObject) => {
        dataObject.lotSize += 1;
      });
      return newData;
    });
  };

  const handleDecrement = () => {
    setBuySellModalData((prevData) => {
      const newData = [...prevData];
      newData.forEach((dataObject) => {
        if (dataObject.lotSize > 1) {
          dataObject.lotSize -= 1;
        }
      });
      return newData;
    });
  };

  const clearBuySellModalData = () => {
    if (buySellModalData.length > 0) {
      setBuySellModalData([]);
    }
  };

  return (
    <BuySellModalContext.Provider
      value={{
        buySellModalData,
        setBuySellModalData,
        addToBuySellModal,
        toggleBuySellStatus,
        handleIncrement,
        handleDecrement,
        clearBuySellModalData,
        handlePriceChange,
        handleTriggerPriceChange,
      }}>
      {children}
    </BuySellModalContext.Provider>
  );
};

export const useBuySellModalContext = () => {
  return useContext(BuySellModalContext);
};
