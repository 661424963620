import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { IoArrowUpOutline } from "react-icons/io5";
import MarketMoodChart from "./MarketMoodChart";
import { useNavigate } from "react-router-dom";

const LeftPanel = ({ data }) => {
  const navigate = useNavigate();
  const tabsName = ["Top gainers", "Top losers", "Gap up", "Gap down"];

  const [currentTab, setCurrentTab] = useState(tabsName[0]);
  const [performanceData, setPerformanceData] = useState(null);
  const [optionsValue, setOptionsValue] = useState("NIFTY 50");
  const [isLoading, setIsLoading] = useState(false);
  const setMytab = (value) => {
    setCurrentTab(value);
  };

  const handleSelectChange = (event) => {
    setOptionsValue(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://cmtdqn92t6.execute-api.ap-south-1.amazonaws.com/default/viewScreenerDetails",
          {
            path: "scanners",
            name: `${currentTab}`,
            selected_timeframe: null,
            selected_group: `${optionsValue}`,
          }
        );
        setPerformanceData(response.data);
      } catch (error) {
        console.log("Error in fetching performance api", error);
      }
    };
    fetchData();
  }, [currentTab, optionsValue]);
  const handleRowClick = async (stockName) => {
    const url = `/stockDetails?stock=${encodeURIComponent(stockName)}`;
    navigate(url);
  };
  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
        <div className="cards-subheader-d">
          <h1 className="cards-heading">Market mood</h1>
          <p className="cards-subheader">
            Index to analyse overall market mood
          </p>
        </div>
      </div>
      <div>
        <div className="chart-div-desktop">
          {data && data.market_mood && (
            <div className="chart-warning-desktop">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none">
                <path
                  d="M9 5.36364V9M9 11.9091V12.2727M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="warning-text-desktop">
                {data.market_mood.messsage}
              </p>
            </div>
          )}
          {data && data.market_mood && (
            <div className="chart-desktop">
              <MarketMoodChart value={data.market_mood.value} />
              {/* <div style={{ position: "absolute", left: "13px", bottom: "-16px", fontSize: "10px", fontWeight: "500" }}>0.0</div>
            <div style={{ position: "absolute", left: "18px", top: "70px", fontSize: "10px", fontWeight: "500" }}>25.0</div>
            <div style={{ position: "absolute", left: "235px", top: "-25px", fontSize: "10px", fontWeight: "500" }}>50.0</div>
            <div style={{ position: "absolute", right: "18px", top: "70px", fontSize: "10px", fontWeight: "500" }}>75.0</div>
            <div style={{ position: "absolute", right: "6px", bottom: "-16px", fontSize: "10px", fontWeight: "500" }}>100.0</div> */}
              <div className="chart-result-desktop">
                {data.market_mood.value > 0 && data.market_mood.value < 25 && (
                  <div className="chart-result" style={{ color: "#FF420E" }}>
                    Extreme Fear
                  </div>
                )}
                {data.market_mood.value >= 25 &&
                  data.market_mood.value < 50 && (
                    <div className="chart-result" style={{ color: "#FF420E" }}>
                      Fear
                    </div>
                  )}
                {data.market_mood.value >= 50 &&
                  data.market_mood.value < 75 && (
                    <div className="chart-result" style={{ color: "#D9E92C" }}>
                      Greed
                    </div>
                  )}
                {data.market_mood.value >= 75.0 &&
                  data.market_mood.value <= 100 && (
                    <div className="chart-result" style={{ color: "#5CA81D" }}>
                      Extreme Greed
                    </div>
                  )}
                <div className="market-mood-desktop">Market mood</div>
              </div>
            </div>
          )}
          <div
            style={{
              fontSize: 12,
              color: "grey",
              position: "absolute",
              bottom: "12.2%",
            }}>
            Last updated : {data?.market_mood.updated_on}
          </div>
        </div>
      </div>
      <div className="performance-d">
        <div className="cards-header-d">
          <div className="cards-subheader-d">
            <h1 className="cards-heading">Performance</h1>
            <p className="cards-subheader">
              Market performance updated real-time
            </p>
          </div>
          {performanceData && performanceData.stock_groups && (
            <select
              className="performance-dropdown"
              value={optionsValue}
              onChange={handleSelectChange}>
              {performanceData.stock_groups.map((group, index) => (
                <option key={index} value={group}>
                  {group}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className="performance-tabs-div">
          {tabsName.map((tab, index) => {
            return (
              <div
                class={`performance-tab ${currentTab === tab ? "active" : ""}`}
                key={index}
                onClick={() => setMytab(tab)}>
                {tab}
              </div>
            );
          })}
        </div>
        {performanceData &&
        performanceData.table_data &&
        performanceData.table_data.length > 0 &&
        !isLoading ? (
          performanceData.table_data.slice(0, 2).map((item, index) => (
            <div
              key={index}
              className="performance-item-desktop cursor-pointer"
              onClick={() => handleRowClick(item.stock)}>
              <div className="performance-item-header-d">
                <div style={{ display: "flex", gap: "5px" }}>
                  <span className="performance-item-name-d">{item.stock}</span>
                  <span className="performance-item-exchange-d">
                    {item.exchange}
                  </span>
                </div>
                <p className="performance-item-description-d">
                  {item.company_name}
                </p>
              </div>
              <div className="performance-item-body-d">
                <span className="performance-item-price-d">
                  <span className="integer-part">
                    {item.ltp.toString().split(".")[0]}
                  </span>
                  {item.ltp.toString().split(".")[1] ? (
                    <span style={{ fontWeight: "normal" }}>
                      .{item.ltp.toString().split(".")[1]}
                    </span>
                  ) : (
                    <span style={{ fontWeight: "normal" }}>.00</span>
                  )}
                </span>
                <div className="item-change-div">
                  <span
                    className={`item-change ${item.change > 0 ? "positive" : "negative"}`}>
                    {item.change > 0 ? "+" : ""}
                    {item.change.toFixed(1)}%
                  </span>
                  <IoArrowUpOutline
                    style={{ color: "#9B9B9B" }}
                    className="asset-goto"
                  />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>Loading....</div>
        )}
        <a
          href={`/screenerDetails?pattern=${encodeURIComponent(currentTab)}&path=scanners`}
          className="view-all">
          View all
        </a>
      </div>
    </div>
  );
};

export default LeftPanel;
