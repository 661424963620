//Get All Symbols API
export const GET_ALL_SYMBOLS_API =
  "https://qb7sqx6ypk.execute-api.ap-south-1.amazonaws.com/default/og_allsymbols";

//Get Expiry Symbols API
export const GET_EXPIRY_SYMBOLS_API =
  "https://y18wfq6w5b.execute-api.ap-south-1.amazonaws.com/default/og_expirysymbols?id=";

export const CLIENT_ID = localStorage.getItem("client_id");

export const BROKER_NAME = localStorage.getItem("broker");

export const USER_NAME = localStorage.getItem("name");
