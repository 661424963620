import { createContext, useContext, useState } from "react";

const VisibilityContext = createContext();

export function useVisibility() {
  return useContext(VisibilityContext);
}

export function VisibilityProvider({ children }) {
  const [areButtonsVisible, setButtonsVisibility] = useState(false);

  return (
    <VisibilityContext.Provider
      value={{ areButtonsVisible, setButtonsVisibility }}>
      {children}
    </VisibilityContext.Provider>
  );
}
