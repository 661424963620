import React, { createContext, useContext, useEffect, useState } from "react";
const ThemeContext = createContext();
export const useTheme = () => useContext(ThemeContext);
export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
      document.getElementById("root").className=storedTheme;
    }
  }, []);



  const setLightMode = () => {
    setTheme("light");
    document.getElementById("root").className = "light";
    localStorage.setItem("theme", "light");
  };

  const setDarkMode = () => {
    setTheme("dark");
    document.getElementById("root").className = "dark";
    localStorage.setItem("theme", "dark");
  };


  return (
    <ThemeContext.Provider value={{ theme, setLightMode, setDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};
